import { getAuthTokenWithRefresh, getBackendApiUrl, getEasyPlvApiToken } from "../config";
import { checkApiResponse } from "./auth";

export const getDiscountDataById = async (discountId) => {
    try {
        const authToken = await getAuthTokenWithRefresh();
        const locale = localStorage.getItem('lang');
        const apiKey = getEasyPlvApiToken();
        const discountDataPromise = await fetch(getBackendApiUrl(`discounts/${discountId}?locale=${locale}`), {
            method: "GET",
            headers: {
                Authorization : `Bearer ${authToken}`,
                "X-EasyPLV-Api-Token": apiKey,
                "Content-Type": "application/json",
            }
        });

        checkApiResponse(discountDataPromise);
        if (discountDataPromise.status === 500) return null;
        return await discountDataPromise.json();
    } catch (e) {console.log(e); return null}
}