import { useContext } from "react"
import { DesignEditorContext } from "../contexts/DesignEditor"
import { DesignType } from "../../../interfaces/DesignEditor"

const useEditorType = () : DesignType => {
  const { editorType } = useContext(DesignEditorContext)
  return editorType
}

export default useEditorType
