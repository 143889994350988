import ListFreePoster from "../../components/FreePoster/ListFreePoster";


function ListFreePosterPage() {
 

  return (
    <>
      <ListFreePoster />
    </>
  );
}

export default ListFreePosterPage;