import { Button, SIZE } from "baseui/button"
import { textComponents } from "../../../../../constants/editor"
import { useStyletron } from "styletron-react"
import { useEditor } from "@layerhub-io/react"
import { FontItem } from "../../../../../interfaces/common"
import { loadFonts } from "../../../../../utils/fonts"
import { IStaticText } from "@layerhub-io/types"
import { nanoid } from "nanoid"
import { Block } from "baseui/block"
import Scrollable from "../../../../../components/Scrollable"
import useIsSidebarOpen from "../../../hooks/useIsSidebarOpen"
import { useTranslation } from "react-i18next"

const Text = () => {
  const editor = useEditor()
  const isSidebarItemOpen = useIsSidebarOpen();
  const {t} = useTranslation();
  const addObject = async () => {
    if (editor) {
      const font: FontItem = {
        name: "OpenSans-Regular",
        url: "https://fonts.gstatic.com/s/opensans/v27/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0C4nY1M2xLER.ttf",
      }
      await loadFonts([font])
      const options = {
        id: nanoid(),
        type: "StaticText",
        width: 420,
        text: "Add some text",
        fontSize: 92,
        fontFamily: font.name,
        textAlign: "center",
        fontStyle: "normal",
        fontURL: font.url,
        fill: "#333333",
        metadata: {},
      }
      // EDITOR_CHANGES removed IStaticText type from add function 
      editor.objects.add(options)
    }
  }
  const addComponent = async (component: any) => {
    if (editor) {
      const fontItemsList: FontItem[] = []
      if (component.objects) {
        component.objects.forEach((object: any) => {
          if (object.type === "StaticText" || object.type === "DynamicText") {
            fontItemsList.push({
              name: object.fontFamily,
              url: object.fontURL,
            })
          }
        })
        const filteredFonts = fontItemsList.filter((f) => !!f.url)
        await loadFonts(filteredFonts)
      } else {
        if (component.type === "StaticText" || component.type === "DynamicText") {
        fontItemsList.push({
          name: component.fontFamily,
          url: component.fontURL,
        })
        await loadFonts(fontItemsList)
      }
      }
      editor.objects.add(component)
    }
  }

  const downloadCanvasAsImage = () => {
    if (editor) {
      const currentScene = editor.scene.exportToJSON();
      const dataUrl = editor.renderer.toDataURL({
        id: currentScene.id,
        frame: currentScene.frame,
        layers: currentScene.layers,
        metadata: currentScene.metadata
      }, {},{});

      dataUrl.then((data: any) => downloadURI(data, 'test'));
    }
  }

  function downloadURI(uri: any, name: string) {
    var link = document.createElement("a");
    link.download = name;
    link.href = uri;
    link.click(); 
  }

  return (
    <Block className="pt-4 px-3" $style={{ flex: 1, display: "flex", height: '100%', flexDirection: "column" }}>
      <Block
        className="mb-4 nw-font-open-sans"
        $style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: isSidebarItemOpen ? ".1rem" : "1.5rem",
        }}
      >
        <Block>{t('editor.panels.panelsList.text')}</Block>
      </Block>
      <Scrollable>
        <Block>
          <Button
            onClick={addObject}
            size={SIZE.compact}
            className="rounded-1"
            style={{
              fontFamily: "Montserrat-SemiBold",
              color: "var(--nw-primary-color)",
              backgroundColor: "var(--nw-secondary-color)",
            }}
            overrides={{
              Root: {
                style: {
                  width: "100%",
                },
              },
            }}
          >
            {t('editor.panels.panelsList.addText')}
          </Button>

          {/* <Block
            $style={{
              paddingTop: "0.5rem",
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              gap: "8px",
            }}
          >
            {[...textComponents].map((tc) => (
              <TextComponentItem onClick={addComponent} key={tc.id} component={tc} />
            ))}
          </Block> */}
        </Block>
      </Scrollable>
    </Block>
  )
}

interface TextComponent {
  id: string
  metadata: {
    preview: string
  }
}
const TextComponentItem = ({ component, onClick }: { component: any; onClick: (option: any) => void }) => {
  const [css] = useStyletron()
  return (
    <div
      onClick={() => onClick(component.layers[0])}
      className={css({
        position: "relative",
        height: "84px",
        background: "#f8f8fb",
        cursor: "pointer",
        padding: "12px",
        borderRadius: "8px",
        overflow: "hidden",
        "::before:hover": {
          opacity: 1,
        },
      })}
    >
      <div
        className={css({
          backgroundImage: `linear-gradient(to bottom,
          rgba(0, 0, 0, 0) 0,
          rgba(0, 0, 0, 0.006) 8.1%,
          rgba(0, 0, 0, 0.022) 15.5%,
          rgba(0, 0, 0, 0.047) 22.5%,
          rgba(0, 0, 0, 0.079) 29%,
          rgba(0, 0, 0, 0.117) 35.3%,
          rgba(0, 0, 0, 0.158) 41.2%,
          rgba(0, 0, 0, 0.203) 47.1%,
          rgba(0, 0, 0, 0.247) 52.9%,
          rgba(0, 0, 0, 0.292) 58.8%,
          rgba(0, 0, 0, 0.333) 64.7%,
          rgba(0, 0, 0, 0.371) 71%,
          rgba(0, 0, 0, 0.403) 77.5%,
          rgba(0, 0, 0, 0.428) 84.5%,
          rgba(0, 0, 0, 0.444) 91.9%,
          rgba(0, 0, 0, 0.45) 100%)`,
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          opacity: 0,
          transition: "opacity 0.3s ease-in-out",
          height: "100%",
          width: "100%",
          ":hover": {
            opacity: 1,
          },
        })}
      />
      {/* EDITOR_CHANGES added alt to img tag */}
      <img alt=""
        src={component.preview}
        className={css({
          width: "100%",
          height: "100%",
          objectFit: "contain",
          pointerEvents: "none",
          verticalAlign: "middle",
        })}
      />
    </div>
  )
}

export default Text
