// Pages
import Home from './pages/Home/Home';
import Login from './pages/Login/Login';
import Operation from './pages/Operation/Operation';
import OperationProducts from './pages/Operation/OperationProductsPage';
import Impersonator from './pages/Impersonator';
import EditUserProfile from './pages/Profile/EditUserProfile';
import CartPage from './pages/Cart/Cart';
import PosterPage from './pages/Poster/PosterPage';
import PosterPreview from './pages/Poster/PosterPreview';
import PrintOrderItemDownloadPage from './pages/PrintOrderItemDownload/PrintOrderItemDownloadPage';
import MessagePage from './pages/Messages/MessagePage';
import MessageDetailPage from './pages/Messages/MessageDetailPage';
import PrintersPage from './pages/Printers/PrintersPage';
import ProductLocalPage from './pages/ProductLocal/ProductLocalPage';
import MainPosterPage from './pages/Poster/MainPosterPage';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import Conditions from './pages/ConditionsOfUse/Conditions';
import ProductLocalEditFormPage from './pages/ProductLocal/ProductLocalEditFormPage';
import ListFreePosterPage from './pages/FreePosters/ListFreePosterPage';
import ForgotPasswordPage from './pages/Profile/ForgotPasswordPage';
import ResetPasswordPage from './pages/Profile/ResetPasswordPage';
import CampaignPostersPage from "./pages/SocialMedia/CampaignPostersPage";

// Sharebox
import ShareBox from './pages/ShareBox/ShareBox';

//Ecatalog/Campaign
import Campaign from './pages/Campaign/Campaign';

// Social Media Page
import SocialMedia from './pages/SocialMedia';
import SocialMediaEditorPage from './pages/SocialMedia/SocialMediaEditorPage';

// ICONS BEING USED
import { 
	House,
	BoxArrowInLeft,
	ListNested,
	Folder,
	Plus,
	Layers,
	LayoutWtf,
	BoxSeam,
	Download,
	Envelope,
	Printer,
	Stack

} from 'react-bootstrap-icons';

// Nw Custom Icons
import { 
	NwFrenchFlag,
	NwSpainFlag,
	NwPortugalFlag,
	NwUkFlag,
	NwCampaignIcon,
	NwCartIcon,
	NwModulePlv,
	NwSocialMedia,
	NwTrailIcon,
	NwBellIcon
} from './components/_utilityComponents/NwIcons/NwIcons';

import FactoryGraphicEditor from "./views/DesignEditor/FactoryGraphicEditor";

import Test from './pages/Test/test';
import PageSlug from './components/Pages/PageSlug';
import { refreshToken, shouldRefreshToken } from './helpers/helpers';
import {UpdateUserFrontSession} from "./services/auth";
import Notifications from './pages/Notifications/Notifications';
import FreePacksPage from './pages/SocialMedia/FreePacksPage';
import FreePosters from './pages/SocialMedia/FreePosters';
import PacksPage from './pages/SocialMedia/PacksPage';
import PostersPage from './pages/SocialMedia/PostersPage';
import CampaignsPage from './pages/SocialMedia/CampaignsPage';


/**
 * The function `getAppName` returns the value of the `REACT_APP_NAME` environment variable.
 * @returns The function `getAppName` returns the value of the environment variable `REACT_APP_NAME`.
 */
export const getAppName = () => {
	return process.env.REACT_APP_NAME;
}

/**
 * The function `getBackendApiUrl` returns the URL for a backend API endpoint, using a base URL from an
 * environment variable or a default value.
 * @param [endpoint] - The `endpoint` parameter is a string that represents the specific API endpoint
 * or route that you want to access on the backend server. It is an optional parameter, so if you don't
 * provide a value for it, the default value will be an empty string.
 * @returns a string that concatenates the base URL with the provided endpoint.
 */
export const getBackendApiUrl = (endpoint = '', useUrlObject = true) => {
    const baseUrl = process.env.REACT_APP_BACKEND_API_URL || 'http://localhost:8000/api';
    
	if (useUrlObject){
		return new URL(`${baseUrl}/${endpoint}`);
	}

	return `${baseUrl}/${endpoint}`
};

/**
 * The function `getBackendUrl` returns the URL for a backend endpoint, using a base URL from an
 * environment variable or a default value.
 * @param [endpoint] - The `endpoint` parameter is a string that represents the specific endpoint or
 * route of the backend API that you want to access. It is an optional parameter, so if no value is
 * provided, it will default to an empty string.
 * @returns a string that concatenates the base URL with the provided endpoint.
 */
export const getBackendUrl = (endpoint = '', useUrlObject = true) => {
    const baseUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000/';

	if (useUrlObject){
		return new URL(`${baseUrl}/${endpoint}`);
	}

	return `${baseUrl}/${endpoint}`
};

/**
 * The function `getDebugMode` returns the value of the `REACT_APP_DEBUG_MODE` environment variable.
 * @returns the value of the environment variable REACT_APP_DEBUG_MODE.
 */
export const getDebugMode = () => {
	return process.env.REACT_APP_DEBUG_MODE === "true";
}

/**
 * The function `getEasyPlvApiToken` returns the value of the `REACT_APP_EASY_PLV_API_TOKEN`
 * environment variable.
 * @returns the value of the environment variable REACT_APP_EASY_PLV_API_TOKEN.
 */
export const getEasyPlvApiToken = () => {
    return process.env.REACT_APP_EASY_PLV_API_TOKEN;
}

/**
 * The function `getEventTargetOrigin` returns the value of the environment variable
 * `REACT_APP_EVENT_TARGET_ORIGIN` or the default value `'http://localhost:8000'`.
 * @returns the value of the environment variable REACT_APP_EVENT_TARGET_ORIGIN if it exists, otherwise
 * it is returning the default value 'http://localhost:8000'.
 */
export const getEventTargetOrigin = () => {
	return process.env.REACT_APP_EVENT_TARGET_ORIGIN || 'http://localhost:8000';
}

/**
 * The function `getAuthToken` retrieves the authentication token from the browser's local storage.
 * @returns The function `getAuthToken` returns the value of the "auth_token" key stored in the
 * browser's localStorage.
 */
export const getAuthToken = () => {
    return localStorage.getItem("auth_token");
}

export const getAuthTokenWithRefresh = async (allowBeforeExpiryRefresh = true)  => {
	const token = localStorage.getItem("auth_token");
	try {
		if (shouldRefreshToken(token, allowBeforeExpiryRefresh)) {
			console.log("Refreshing token now...");
			const newTokenData = await refreshToken();
			if (newTokenData.success) {
				localStorage.setItem("auth_token", newTokenData?.newToken);
				localStorage.setItem("refresh_token", newTokenData.newRefreshToken);

				let dataSession = {
					action: 'refreshToken',
					impersonateSession : localStorage.getItem('impersonateSession')
				}
				UpdateUserFrontSession(dataSession);

				return newTokenData?.newToken;
			} else {
				return token;
			}
		}
	} catch (e) {
		// console.error(e);
	}

	return token;
}

/**
 * The function checks if the language was switched by retrieving a value from the localStorage.
 * @returns the value of the "wasLangSwitched" key from the localStorage.
 */
export const checkIfLanguageSwitched = () => {
	return localStorage.getItem("wasLangSwitched");
}

/**
 * The function `getLanguageOptions` returns an array of language options with their corresponding
 * values and labels.
 * @returns The function `getLanguageOptions` returns an array of language options. Each language
 * option is an object with two properties: `value` and `label`. The `value` property represents the
 * language code (e.g., 'fr', 'es', 'en', 'pt'), and the `label` property represents the corresponding
 * language icon wrapped in a `div` element.
 */
export const getLanguageOptions = () => {
	const languageOptions = [
		{ value: 'fr', label: <div className='rounded-circle overflow-hidden'><NwFrenchFlag/></div> },
		{ value: 'es', label: <div className='rounded-circle overflow-hidden'><NwSpainFlag/></div>  },
		{ value: 'en', label: <div className='rounded-circle overflow-hidden'><NwUkFlag/></div>  },
		{ value: 'pt', label: <div className='rounded-circle overflow-hidden'><NwPortugalFlag/></div>  },
	];

	return languageOptions;
}

/**
 * The function formats a selected option using the current language.
 * @param value - The value parameter is the value of the selected option that needs to be formatted.
 * @returns the current language option that matches the given value.
 */
export const formatSelectedOptionUsingCurrentLang = (value) => {
	const languageOptions = getLanguageOptions();

	const currentOption = languageOptions.find(option => option.value === value);

	return currentOption;
}

/**
 * The function `getRequestAccessMail` returns the email address for requesting access, either from the
 * environment variable `REACT_APP_REQUEST_ACCESS_MAIL` or a default value of "someone@example.com".
 * @returns the value of the environment variable REACT_APP_REQUEST_ACCESS_MAIL, or
 * "someone@example.com" if the environment variable is not set.
 */
export const getRequestAccessMail = () => {
	return process.env.REACT_APP_REQUEST_ACCESS_MAIL || "someone@example.com";
}

/**
 * The function `synchroniseUserData` is an asynchronous function that takes in two optional parameters
 * `action` and `value`, and if `action` is provided, it calls the `getBackendApiUrl` function with the
 * argument `'endpoint'`, otherwise it logs a message saying "No Action Specified.".
 * @param [action=null] - The action parameter is used to specify the type of action to be performed.
 * It can be any valid value that represents a specific action.
 * @param [value=null] - The value parameter is used to pass any additional data or information that
 * may be required for the specified action.
 */
export const synchroniseUserData = async(action=null, value=null) => {
	if (action){
		//getBackendApiUrl('endpoint');
	} else {
		console.log("No Action Specified.")
	}	
}

/**
 * Generates the application routes.
 * 
 * @returns {Array<Object>} The application routes.
 */
export const getAllRoutes = () => {
	const appRoutes = [
		//////// SIDE BAR ITEMS /////////////////
		//AUTHENTICATED
		// MAKE SURE EACH ROUTE SHOULD HAVE THEIR OWN translationKey SO THAT LAYOUT KNOWS ABOUT A CHANGE
		generateRouteData('home', '/', <Home/>, "Home", true, "sideBarLabels.homeLabel", true),
		// generateHeaderData(),
		generateHeaderData('Tools', 'tools', [
			generateRouteData('module_plv', '/operation', <Operation/>, "Module Plv", true, "sideBarLabels.modulePlv", true, <NwModulePlv/>,"operationsPage.operationsCard1.screenTitle", [
				generateRouteData('operation', '/operation', <Operation/>, "Operations", true, "sideBarLabels.operationsLabel", true, <Folder/>,"operationsPage.operationsCard1.screenTitle"),
				generateRouteData('operation_products', '/operation/:operationId/products', <OperationProducts/>, "Products", true, "pageTitle.products", false),
				generateRouteData('poster_create', '/posters/new', <PosterPage new/>, "New Poster", true, "pageTitle.newPoster", false),
				generateRouteData('poster_edit', '/posters/edit', <PosterPage/>, "New Poster", true, "pageTitle.newPoster", false),
				generateRouteData('posters_index','/posters', <MainPosterPage/>, "Posters", true, "sideBarLabels.posters", true, <Layers/>),
				generateRouteData('free_posters_index', '/free_posters', <ListFreePosterPage/>, "Free Posters", true, "freePosters.title", true, <LayoutWtf/>),
				generateRouteData('cart', '/cart', <CartPage/>, "Cart", true, "sideBarLabels.cart", true, <NwCartIcon/>, "cartPage.cartTable.screenTitle", null, {
					focusBlockData:{
						isExpandedItem: true,
						trailIcon: <NwTrailIcon/>
					}
				}),
				generateRouteData('downloads', '/downloads', <PrintOrderItemDownloadPage/>, "Downloads", true, "pageTitle.downloads", true, <Download/>, null, null, {
					focusBlockData: {
						isFocusButton: true
					}
				}),
			]),
			generateRouteData('social_media', '/social-media', <SocialMedia/>, "Social Media", true, "sideBarLabels.socialMedia.title", true, <NwSocialMedia/>, null, [
				generateRouteData('social_media_index', '/social-media', <SocialMedia/>, "Social Media", true, "sideBarLabels.socialMedia.title", true, <Stack/>, null, null, 
					{
						useExactPath: true,
					}
				),
				// generateRouteData('social_media_editor', '', <PacksPage />, 'Social Media Packs', true, 'sideBarLabels.socialMedia.listings.packs.title', true, <ListNested/>, null, 
				// 	[
				// 		generateRouteData('social_media_editor', '/social-media/free-packs', <FreePacksPage />, 'Social Media Free Packs', true, 'sideBarLabels.socialMedia.listings.packs.free', true, <NwSocialMedia/>, null, null,
				// 		{
				// 			useExactPath: true,
				// 		}),
				// 		generateRouteData('social_media_editor', '/social-media/packs', <PacksPage />, 'Social Media Packs', true, 'sideBarLabels.socialMedia.listings.packs.editor', true, <ListNested/>, null, null, {
				// 			useExactPath: true
				// 		})
				// 	], 
				// 	{
				// 		useExactPath: true,
				// 		useSidebarPopover: true
				// 	}
				// ),
                generateRouteData('social_media_campaigns', '/social-media/campaigns', <CampaignsPage />, "Social Media Campaigns", true, "sideBarLabels.socialMediaNew.listing.campaigns", true, <ListNested/>, null, null, 
					{
						useExactPath: true,
					}
				),
				generateRouteData('social_media_editor', '', <SocialMediaEditorPage new />, 'Social Media', true, 'sideBarLabels.socialMediaNew.editor.create', true, <Plus className='icon-bold'/>, null, null, 
					{
						useForceCustomLink: '/social-media/editor/new/social_media_product/0',
						useExactPath: true,
						focusBlockData: {
							isFocusButton: true
						}
					}
				),
				generateRouteData('social_media_free_editor', '/social-media/free-editor/:mode/:entity/:id', <FactoryGraphicEditor usage={"social_media"} />, 'Social Media Free Editor', true, 'socia_media_free_editor', true, <Plus className='icon-bold'/>, null, null,
					{
						useForceCustomLink: '/social-media/free-editor/new/social_media_product/0',
						useExactPath: true,
						focusBlockData: {
							isFocusButton: true
						}
					}
				),
			]),
			generateRouteData('base_products','/product-local', <ProductLocalPage/>, "Base Produits", true, "sideBarLabels.productLocal", true, <BoxSeam/>, null, [
				generateRouteData('product_local_index','/product-local', <ProductLocalPage/>, "Product Local", true, "sideBarLabels.productLocal", true, <Folder/>, null, null, {
					useExactPath: true,
				}),
				generateRouteData('product_local_new', '/product-local/form/new/0',<ProductLocalEditFormPage/>, "Product Local New",true, "sideBarLabels.productLocalNew", true, <BoxSeam/>, null, null, {
					useExactPath: true,
				})
			]),
			// generateRouteData('share_box','/share-box', <ShareBox/>, "Share Box", true, "sideBarLabels.shareBox", true, <Folder/>, null, [
			// 	generateRouteData('share_box_index','/share-box', <ShareBox/>, "Share Box List", true, "sideBarLabels.shareBoxList", true, <Folder/>),
			// ]),
			// generateRouteData('e_catalog', '/campaign', <Campaign/>, "E-Catalogue", true, "sideBarLabels.ecatalog", true, <NwCampaignIcon/>, null, [
			// 	generateRouteData('e_catalog_index', '/campaign', <Campaign/>, "Campaigns List", true, "sideBarLabels.ecatalogList", true, <NwCampaignIcon/>),
			// ])
		]),
		generateHeaderData("Informations", "informations", [
			generateRouteData('notifications','/notifications', <Notifications />, "Notifications", true, "Notifications", true, <NwBellIcon/>),
			generateRouteData('messages', '/messages', <MessagePage/>, "Messages", true, "sideBarLabels.messages", true, <Envelope/>),
		], null, { theme: "primary" }),
		// NOT AUTHENTICATED
		generateRouteData('login', '/login', <Login/>, "Login", false, "loginPage.formSection.form.formTitle", true, <BoxArrowInLeft/>),
		/////////////////////////////////////////////////////
		/////// OTHER ROUTES ///////
		generateRouteData('printers', '/printers', <PrintersPage/>, "Printers", true, "sideBarLabels.printers", false, <Printer/>),
		generateRouteData('free_poster_editor', '/editor/:mode/:freePosterId', <FactoryGraphicEditor usage="free_poster" />, "Editor", true, 'Editor'),
		generateRouteData('privacy_policy', '/privacypolicy', <PrivacyPolicy/>, "Privacy Policy", false, "pageTitle.privacyPolicy"),
		generateRouteData('conditions', '/conditions', <Conditions/>, "Conditions Of Use", false, "pageTitle.conditionsOfUse"),
		generateRouteData('impersonator', '/impersonator', <Impersonator/>, "Impersonator", false, "pageTitle.impersonator"),
		generateRouteData('user_edit', '/user/edit', <EditUserProfile/>, "Edit Profile", true, "pageTitle.editProfile"),
		generateRouteData('poster_preview', '/posters/preview', <PosterPreview/>, "Preview Poster", true, "pageTitle.posterPreview"),
		generateRouteData('message_view', '/view/message/:id', <MessageDetailPage/>, "Message Detail", true, "pageTitle.messageDetails"),
		generateRouteData('test_page', '/testpage', <Test/>, "TEST", false),
		generateRouteData('product_local_edit', '/product-local/form/:mode/:productLocalId',<ProductLocalEditFormPage/>,"Product Local",true, "sideBarLabels.productLocal"),
		generateRouteData('page_slug', '/pages/:locale/:slug',<PageSlug/>,'Page',false,'Page'),
		generateRouteData('social_media_editor', '/social-media/editor/:mode/:entity/:id', <SocialMediaEditorPage />, 'Social Media', true, 'social_media_editor', false),
		generateRouteData('social_media_free_editor', '/social-media/free-editor/:mode/:entity/:id', <FactoryGraphicEditor usage={"social_media"} />, 'Social Media Free Editor', true, 'socia_media_free_editor', false),
		generateRouteData('social_media_tg_model_editor', '/social-media/tg-editor/:mode/:entity/:uuid', <FactoryGraphicEditor usage={"tg_model"} />, 'Social Media Template Group Model Editor', true, 'socia_media_tg_model_editor', false, <LayoutWtf/>),
		generateRouteData('social_media_free_posters', '/social-media/free-packs/:id', <FreePosters />, 'Social Media Free Posters', true, 'social_media_free_posters', false, <LayoutWtf/>),
		generateRouteData('social_media_posters', '/social-media/packs/:id', <PostersPage />, 'Social Media Posters', true, 'social_media_posters', false, <LayoutWtf/>),
        generateRouteData('social_media_campaign_posters', '/social-media/campaigns/:id/posters', <CampaignPostersPage />, 'Social Media Campaign Posters', true, 'social_media_campaign_posters', false, <LayoutWtf/>),
        generateRouteData('forgot_password',  '/forgot-password', <ForgotPasswordPage/>, "Forgot Password", false, "loginPage.formSection.form.formTitle"),
		generateRouteData('reset_password', '/reset-password/:token', <ResetPasswordPage/>, "Reset Password", false, "loginPage.formSection.form.formTitle"),
	];

	return appRoutes;
}

/**
 * @typedef {Object} FocusBlockData
 * @property {boolean} [isExpandedItem] - Indicates if the item is expanded.
 * @property {JSX.Element} [trailIcon] - The icon to display as a trail icon.
 * @property {boolean} [isFocusButton] - Indicates if the button should be focused.
 */

/**
 * @typedef {Object} AdditionalData
 * @property {FocusBlockData} [focusBlockData] - Data related to the focus block.
 * @property {boolean} [useExactPath] - Indicates if the exact path should be used.
 * @property {boolean} [useSidebarPopover] - Indicates if a sidebar popover should be used.
 * @property {string} [useForceCustomLink] - A custom link to force usage.
 */

/**
 * @typedef {Object} RouteData
 * @property {string} id - The unique identifier for the route.
 * @property {string} path - The path for the route.
 * @property {JSX.Element} element - The component to be rendered for the route.
 * @property {string} title - The title for the route.
 * @property {boolean} isProtected - Whether the route is protected and requires authentication.
 * @property {string} translationKey - The translation key for the route title.
 * @property {boolean} isSidebarItem - Whether the route should appear in the sidebar.
 * @property {Array<RouteData>} subMenu - The sub-menu items for the route.
 * @property {JSX.Element} icon - The icon to be displayed for the route.
 * @property {string} screenTitletranslationKey - The translation key for the screen title.
 * @property {AdditionalData} additionalData - Additional data for the route.
 */

/**
 * Generates a route configuration object.
 * 
 * @param {string} identifier - The unique identifier for the route.
 * @param {string} [path='/'] - The path for the route.
 * @param {JSX.Element} [element=<Home/>] - The component to be rendered for the route.
 * @param {string} [title='Home'] - The title for the route.
 * @param {boolean} [isProtected=true] - Whether the route is protected and requires authentication.
 * @param {string} [translationKey='sideBarLabels.homeLabel'] - The translation key for the route title.
 * @param {boolean} [isSidebarItem=false] - Whether the route should appear in the sidebar.
 * @param {JSX.Element} [icon=<House/>] - The icon to be displayed for the route.
 * @param {string} [screenTitletranslationKey=''] - The translation key for the screen title.
 * @param {Array<RouteData>} [subMenu=[]] - The sub-menu items for the route.
 * @param {AdditionalData} [additionalData={}] - Additional data for the route.
 * @returns {RouteData} The route configuration object.
 */
const generateRouteData = (
	identifier,
	path = '/',
	element = <Home/>,
	title = "Home",
	isProtected = true,
	translationKey = "sideBarLabels.homeLabel",
	isSidebarItem = false,
	icon = <House/>,
	screenTitletranslationKey='',
	subMenu = [],
	additionalData = {}
) => {
	return {
		id: identifier,
		path: path,
		element: element,
		title: title,
		isProtected: isProtected,
		translationKey: translationKey ? translationKey : null,
		isSidebarItem: isSidebarItem,
		subMenu: subMenu, 
		icon: icon,
		screenTitletranslationKey: screenTitletranslationKey,
		additionalData: additionalData
	}
}

/**
 * @typedef {Object} AdditionalHeaderData
 * @property {string} [theme] - The theme for the header.
 */

/**
 * Generates a header route configuration object.
 * 
 * @param {string} [title='Store Display'] - The title for the header.
 * @param {string} [translationKey='storeDisplay'] - The translation key for the header title.
 * @param {Array<RouteData>} [subMenu=[]] - The sub-menu items for the header.
 * @param {JSX.Element} [icon=<House/>] - The icon to be displayed for the header.
 * @param {AdditionalHeaderData} [additionalHeaderData=null] - Additional data for the header.
 * @returns {Object} The header route configuration object.
 */
const generateHeaderData = (
	title = "Store Display",
	translationKey = "storeDisplay",
	subMenu = [],
	icon = <House/>,
	additionalHeaderData = null
) => {
	return {
		title: title,
		translationKey: "sideBarLabels.headers." + translationKey,
		icon: icon,
		subMenu: subMenu,
		isSidebarItem: true,
		isProtected: true,
		isHeader: true,
		additionalHeaderData: additionalHeaderData ?? 'accent-1'
	}
}

export const getUserDropdownItems = (t, logoutUser) => {
	return [
		{
			href: '/user/edit',
			eventKey: 'editUser',
			displayText: t("pageTitle.editProfile")
		},
		{
			eventKey: 'logoutUser',
			displayText: t("logout"),
			onClick: logoutUser
		}
	];
}


// -- CONFIG ENUMS --
export const USER_SYNC_ACTION = Object.freeze({
	LOGOUT: 'logout',
	LANGUAGE_CHANGE: 'locale'
});