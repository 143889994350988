import Toast from 'react-bootstrap/Toast';
import { NotificationPrintContext } from '../../../../contexts/NotificationPrintContext';
import { useContext, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { seeNotification } from '../../../../services/notification';

function SocialMediaZipGenerationSuccess({notification, handleCloseNotification}) {
    const { t } = useTranslation();
    const body = notification.body  ? notification.body : "";
    const otherData = notification.otherDataJson ? notification.otherDataJson : undefined
    const subject = notification.subject ? notification.subject : "";
    const zipFileName = notification?.otherDataJson?.zipLocationSaveInfo.filename

    // const downloadPdfHandler = () => {
    //     handleCloseNotification(notification.id);
    //     if (otherData === undefined) { 
    //         console.log("Missing data to download pdf");
    //         return;
    //     }
    //     window.open(otherData.pdfFileInfo.cdnUrl);
    // }

    useEffect(() => {
        let ignore = false
        seeNotification(notification.id).then(response => {
            if (response?.seen) {
                if (!ignore) {
                    const zipFileInfoCdnUrl = otherData?.zipLocationSaveInfo?.cdnUrl;
                    var link = document.createElement('a');
                    link.href = zipFileInfoCdnUrl;
                    link.target = "_blank";
                    link.download = zipFileName;
                    link.dispatchEvent(new MouseEvent('click'));
                }
            }
        });

        return () => {
            ignore = true;
        }
    }, []);


    return (
        <>
        </>
    )
}

export default SocialMediaZipGenerationSuccess;