import { useEditor } from "@layerhub-io/react"
import { Block } from "baseui/block"
import AngleDoubleLeft from "../../../../../components/Icons/AngleDoubleLeft"
import useSetIsSidebarOpen from "../../../hooks/useSetIsSidebarOpen"
// @ts-ignore
import { useCallback } from "react"
// @ts-ignore
import PreviewList from "../../../../../components/Posters/PreviewList/PreviewList"
// @ts-ignore
import { getTemplateGroupMetadata } from "../../../../../services/template_group"
import FormatAndOrientationModal from "../../FormatAndOrientationModal";
import useDesignEditorContext from "../../../hooks/useDesignEditorContext"
import useTemplateGroupEditorContext from "../../../hooks/useTemplateGroupEditorContext"
import useEditorUseContext from "../../../hooks/useEditorUseContext"

import { NwLoaderSpinner } from "../../../../../components/_utilityComponents/NwIcons/NwIcons"
import { getBackendUrl } from "../../../../../config"
import { useTranslation } from "react-i18next"
import NwCustomInput from "../../../../../components/_utilityComponents/NwFormsUi/CustomInput/NwCustomInput"

const General = () => {
    const editor = useEditor()
    const setIsSidebarOpen = useSetIsSidebarOpen()
    const {
        chartesGraphiquesOptions,
        selectedOptionChartesGraphique,
        setSelectedOptionChartesGraphique,
        templateGroupMetadata,
        setTemplateGroupMetadata,
        currentFormat,
        setCurrentFormat,
        currentOrientation,
        setCurrentOrientation,
        title,
        setTitle,
        getTemplateGroups,
        handleSearchData,
        handleSortData,
        handleFetchMoreData,
        setChartesGraphiquesOptions,
        originalChartesGraphiqueOption,
        setOriginalChartesGraphiqueOption,
        chartesGraphiquesBodyData,
        setChartesGraphiquesBodyData,
    } = useTemplateGroupEditorContext();

    const { currentDesign, setCurrentDesign} = useDesignEditorContext();
    const {t} = useTranslation();

    const {setUse} = useEditorUseContext();

//   const applyResize = useCallback(async (width : number, height: number) => {
//     if (editor) {
//       editor.frame.resize({
//         width : width,
//         height : height,
//       })
//       setCurrentDesign({
//         ...currentDesign,
//         frame: {
//           width: width,
//           height: height
//         }
//       })
//     }
//   }, [editor, currentDesign, setCurrentDesign])

    const applyResize = async (width : number, height: number) => {
        if (editor) {
            editor.frame.resize({
                width : width,
                height : height,
            })
            setCurrentDesign({
                ...currentDesign,
                frame: {
                width: width,
                height: height
                }
            })
        }
    }

//   const applyFormatAndOrientation = useCallback(async (format: any, orientation: any) => {
//     const widthPx: number = format.sizeInPX.width;
//     const heightPx: number = format.sizeInPX.height;
//     const orientationValue = orientation.value;

//     if (widthPx < heightPx) {
//       if (orientationValue === 'portrait') await applyResize(widthPx, heightPx);
//       else await applyResize(heightPx, widthPx);
//     } else {
//       if (orientationValue === 'landscape') await applyResize(widthPx, heightPx);
//       else await applyResize(heightPx, widthPx);
//     }
//   }, [applyResize]);

    const applyFormatAndOrientation = async (format: any, orientation: any) => {
        const widthPx: number = format.sizeInPX.width;
        const heightPx: number = format.sizeInPX.height;
        const orientationValue = orientation.value;

        if (widthPx < heightPx) {
            if (orientationValue === 'portrait') await applyResize(widthPx, heightPx);
            else await applyResize(heightPx, widthPx);
        } else {
            if (orientationValue === 'landscape') await applyResize(widthPx, heightPx);
            else await applyResize(heightPx, widthPx);
        }
    };

//   const applyBackgroundByUrl = useCallback(async (url: any) => {
//     console.log('here');
//     if (editor) {
//       const sceneJson = editor.scene.exportToJSON();
//       const layers = sceneJson.layers;
//       for (const layer of layers) {
//         if (layer.name === "BackgroundImage") {
//           editor.objects.remove(layer.id);
//         }
//       }
//       const options : any = {
//         type: "BackgroundImage",
//         src: getBackendUrl(`assets/serve?cloudfrontUrl=${url}`)
//       }

//       await editor.objects.add(options);
//     }
//   }, [editor])

    const applyBackgroundByUrl = async (url: any) => {
        console.trace('here');
        if (editor) {
        const sceneJson = editor.scene.exportToJSON();
        const layers = sceneJson.layers;
        for (const layer of layers) {
            if (layer.name === "BackgroundImage") {
            editor.objects.remove(layer.id);
            }
        }
        const options : any = {
            type: "BackgroundImage",
            src: getBackendUrl(`assets/serve?cloudfrontUrl=${url}`)
        }

        await editor.objects.add(options);
        }
    }

//   const applyBackgroundByMetadata = useCallback(async (metadata : any, format: any = null, orientation: any = null) => {
//     if (metadata === null) return;
//     let backgroundFormat = null;
//     let backgroundOrientation = null;

//     if (format === null && orientation === null) {
//       backgroundFormat = currentFormat;
//       backgroundOrientation = currentOrientation;
//     } else {
//       backgroundFormat = format;
//       backgroundOrientation = orientation;
//     }

//     const metadataBackgrounds = metadata?.posterTemplatesBackgroundDataByOrientationAndFormatId;
//     if (metadataBackgrounds === undefined) return;
//     if (!(backgroundOrientation.value in metadataBackgrounds)) return;
//     const metadataBackgroundsByOrientation = metadataBackgrounds[`${backgroundOrientation.value}`];
//     if (!(backgroundFormat.value in metadataBackgroundsByOrientation)) return;
//     const metadataBackgroundByOrientationAndFormat = metadataBackgroundsByOrientation[`${backgroundFormat.value}`];
//     if ('background_image_complete_url' in metadataBackgroundByOrientationAndFormat) applyBackgroundByUrl( metadataBackgroundByOrientationAndFormat.background_image_complete_url);
//   }, [applyBackgroundByUrl, currentFormat, currentOrientation])

    const applyBackgroundByMetadata = async (metadata : any, format: any = null, orientation: any = null) => {
        if (metadata === null) return;
        let backgroundFormat = null;
        let backgroundOrientation = null;

        if (format === null && orientation === null) {
            backgroundFormat = currentFormat;
            backgroundOrientation = currentOrientation;
        } else {
            backgroundFormat = format;
            backgroundOrientation = orientation;
        }

        const metadataBackgrounds = metadata?.posterTemplatesBackgroundDataByOrientationAndFormatId;
        if (metadataBackgrounds === undefined) return;
        if (!(backgroundOrientation.value in metadataBackgrounds)) return;
        const metadataBackgroundsByOrientation = metadataBackgrounds[`${backgroundOrientation.value}`];
        if (!(backgroundFormat.value in metadataBackgroundsByOrientation)) return;
        const metadataBackgroundByOrientationAndFormat = metadataBackgroundsByOrientation[`${backgroundFormat.value}`];
        if ('background_image_complete_url' in metadataBackgroundByOrientationAndFormat) applyBackgroundByUrl( metadataBackgroundByOrientationAndFormat.background_image_complete_url);
    }

    const handleFormatAndOrientationChange = async (format: any, orientation: any) : Promise<void> => {
        setCurrentFormat(format);
        setCurrentOrientation(orientation);
        await applyFormatAndOrientation(format, orientation);
        await applyBackgroundByMetadata(templateGroupMetadata, format, orientation);
    }

    const handleTemplateGroupChange = async (templateGroup: any) => {
        if (!templateGroup) return;
        if (selectedOptionChartesGraphique === null) {
        const metadata = await getTemplateGroupMetadata(templateGroup.id);
            setTemplateGroupMetadata(metadata);
            setSelectedOptionChartesGraphique(templateGroup);
            setOriginalChartesGraphiqueOption(templateGroup);
            applyBackgroundByMetadata(metadata);
            return;
        }

        if (selectedOptionChartesGraphique?.id !== templateGroup?.id) {
        const metadata = await getTemplateGroupMetadata(templateGroup.id);
            setTemplateGroupMetadata(metadata);
            setSelectedOptionChartesGraphique(templateGroup);
            setOriginalChartesGraphiqueOption(templateGroup);
            applyBackgroundByMetadata(metadata);
            return;
        }
        
        if (selectedOptionChartesGraphique?.id === templateGroup?.id) {
            applyBackgroundByMetadata(templateGroupMetadata);
        }
    }

    return (
        <Block $style={{ flex: 1, display: "flex", flexDirection: "column", fontSize: "1em" }}>
        <Block
            $style={{
            display: "flex",
            alignItems: "center",
            fontWeight: 500,
            justifyContent: "space-between",
            padding: "1.5rem",
            }}
        >
            <Block>{t('editor.panels.panelsList.general')}</Block>
            <Block onClick={() => setIsSidebarOpen(false)} $style={{ cursor: "pointer", display: "flex" }}>
            <AngleDoubleLeft size={18} />
            </Block>
        </Block>
        <Block className="my-3 d-flex justify-content-between ">
            <div className="w-75">
            <NwCustomInput label={t('editor.inputs.title')} onChange={(e: any) => setTitle(e.target.value)} value={title} placeholder={t('editor.inputs.title')}/>
            </div>
            <div className="w-25 d-flex align-items-end justify-content-end ">
            <FormatAndOrientationModal selectedFormat={currentFormat} selectedOrientation={currentOrientation} templateGroupMetadata={templateGroupMetadata} handleFormatAndOrientationChange={handleFormatAndOrientationChange} />
            </div>
        </Block>
        <Block className="d-flex justify-content-center">
            {
            chartesGraphiquesOptions === null ? <NwLoaderSpinner isSpinner size={30} /> : 
            <PreviewList
                hasFilter
                initialSelection={originalChartesGraphiqueOption}
                getCurrentSelected={handleTemplateGroupChange}
                listData={chartesGraphiquesOptions}
                updateListData={setChartesGraphiquesOptions}
                fetchSearchData={handleSearchData}
                handleSortData={handleSortData}
                fetchMoreData={handleFetchMoreData}
                bodyData={chartesGraphiquesBodyData}
                getListData={getTemplateGroups}
                bodyDataUpdator={setChartesGraphiquesBodyData}
            />
            }
        </Block>
        </Block>
    )
}

export default General;
