import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { 
    getUserSocialMediaPostersByPack,
    downloadSocialMediaPosterImageById,
    deleteSocialMediaPosterById,
 } from "../../services/social_media";
import ListCard from './ListCard/ListCard';
import { Row, Col } from 'react-bootstrap';
import { 
    PencilSquare,
    EyeFill,
    Download,
    XLg
} from 'react-bootstrap-icons';

export default function PostersPage() {
    const params = useParams();
    const packId = params.id;
    const [ socialMediaPosters, setSocialMediaPosters ] = useState([]);

    const handleDownloadSocialMediaPoster = async (posterId) => {
        const responseData = await downloadSocialMediaPosterImageById(posterId);
        if (responseData) {
            const url = URL.createObjectURL(responseData);
			const a = document.createElement("a");
			a.href = url;
	
			a.download = "media.zip";
			document.body.appendChild(a);
			a.click();
	
			document.body.removeChild(a);
			URL.revokeObjectURL(url);
        }
    }

    const handleDeletePoster = async (posterId) => {
        const success = await deleteSocialMediaPosterById(posterId);
        if (success) {
            getUserSocialMediaPostersByPack(packId).then(posters => {
                setSocialMediaPosters(posters);
            })
        }
    }

    useEffect(() => {
        if (!packId) return; 
        getUserSocialMediaPostersByPack(packId).then(posters => {
            setSocialMediaPosters(posters);
        })
    }, [packId])

    return (
        <>
            <div className="d-flex mb-3 px-3">
                <Row className='w-75 gx-2'>     
                {
                    socialMediaPosters.map(poster => {
                        return (
                            <Col className='flex-grow-0'>
                            <ListCard 
                                key={poster.id}
                                title={poster.title}
                                imgSrc={poster.thumbnailPreviewUrl}
                                actions={[
                                    {
                                        title: 'Download',
                                        type: "button",
                                        onClick: () => handleDownloadSocialMediaPoster(poster.id),
                                        icon: <Download/>,
                                    },
                                    {
                                        title: "Edit",
                                        href: `#`,
                                        type: "link",
                                        icon: <PencilSquare/>,
                                    },
                                    {
                                        title: "View",
                                        href: `#`,
                                        type: "link",
                                        icon: <EyeFill/>,
                                    },
                                    {
                                        title: "Delete",
                                        type: "button",
                                        onClick: () => handleDeletePoster(poster.id),
                                        icon: <XLg/>,
                                    }
                                ]}   
                            />
                            </Col>
                        )
                    })
                }
                </Row>     
            </div>
        </>
    )
}