import React from "react";
import { useEffect, useState, useRef } from "react";
import {
	Form,
	Row,
	Col,
	ListGroup,
	Tab,
	Button,
	Container,
	ListGroupItem,
	Dropdown,
} from "react-bootstrap";
import { Plus, Folder, ThreeDotsVertical } from "react-bootstrap-icons";
import {
	getUserCategory,
	addNewUserCategory,
	deleteUserCategory,
	editUserCategory,
} from "../../services/posters";
import NwCustomModal from "../_utilityComponents/NwCustomModal/NwCustomModal";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import Modal from "react-bootstrap/Modal";
import { NwLoaderSpinner } from "../_utilityComponents/NwIcons/NwIcons";

const MainPosterSideFilter = (props) => {
	const [userCategory, setUserCategory] = useState();
	const [showModal, setShowModal] = useState(false);
	const { i18n, t } = useTranslation();
	const [showEditModal, setEditShowModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState();
	const [successMessage, setSuccessMessage] = useState("");
	const [isNewCatSaving, setIsNewCatSaving] = useState();
	const [isCatDelete, setIsCatDelete] = useState();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [categoryIdInModal, setCategoryIdInModal] = useState();
	const [categoryNameInModal, setCategoryNameInModal] = useState();
	const [getCategoryAgain, setGetCategoryAgain] = useState(0);
	const [waitingResult,setwaitingResult] = useState(false);
	const [closeModalFlag,setCloseModalFlag] = useState(false);
	

	const getUserCategoryList = async () => {
		setwaitingResult(true);
		const CategoryResponse = await getUserCategory();
		setUserCategory(CategoryResponse.items);
		setwaitingResult(false);
	};

	useEffect(() => {
		getUserCategoryList();
	}, []);

	useEffect(() => {
		getUserCategoryList();
		// console.log("Reload category");
	}, [getCategoryAgain,props.fetchCategoryAgain]);

	useEffect(() => {
		// console.log(userCategory);
	}, [userCategory]);

	useEffect(()=>{
		setSuccessMessage("");
		setErrorMessage("");
	},[showModal])

	const handleClick = (categoryId) => {
		props.sideFilterSelectedValue(categoryId);
	};

	const handleNewCategory = () => {
		// console.log("new category");
		setSuccessMessage("");
		setShowModal(true);
	};

	const handleCloseModal = () => {
		setShowModal(false);
		setSuccessMessage("");
	};

	const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
		<a
			ref={ref}
			onClick={(e) => {
				e.preventDefault();
				onClick(e);
			}}
		className="d-flex align-items-center text-black"
		style={{
		height: 'fit-content'
		}}
		>
			{/* Render custom icon here */}
			<ThreeDotsVertical></ThreeDotsVertical>
			{children}
		</a>
	));

	const handleSubmitSaveDoc = async (e) => {
		
		e.preventDefault();
		const newCategoryName = e.target.elements.cat_name.value;
		setGetCategoryAgain(false);
		props.getCategoryAgain(0);
		if (newCategoryName == "") {
			setErrorMessage(t("pageTitle.errorMsgInsert"));
			return false;
		} else {
			setErrorMessage("");
		}

		setSuccessMessage("");
		setIsNewCatSaving(true);
		try {
			const newCateValue = { name: newCategoryName };
			const newCatSave = await addNewUserCategory(newCateValue);
		//   console.log(newCatSave);
		//   console.log(newCatSave.success);
			if (newCatSave.success == true) {
				setSuccessMessage(t("pageTitle.successMsg"));
			}
		} catch (error) {
			console.error("Error adding new category:", error);
			// Handle error as needed
		} finally {
			setIsNewCatSaving(false);
			setGetCategoryAgain(true);
			props.getCategoryAgain(1);
		}
		// console.log(newCategoryName);
		setShowModal(false);
		setCloseModalFlag(closeModalFlag+1);
	};

	const handleEditCategory = (categoryId, categoryName) => {
		// console.log(categoryId);
		setCategoryIdInModal(categoryId);
		setCategoryNameInModal(categoryName);
		setShowModal(true);
	};

	const handleSubmitEditCategoryForm = async (e) => {
		e.preventDefault();
		const newCategoryNameEdit = e.target.elements.cat_name.value;

	 

		setSuccessMessage("");
		setIsNewCatSaving(true);
		setGetCategoryAgain(false);
		props.getCategoryAgain(0);
		try {
			const newCateValue = { name: newCategoryNameEdit };
			const newCatSave = await editUserCategory(
				categoryIdInModal,
				newCateValue
			);
		//   console.log(newCatSave);
		//   console.log(newCatSave.success);
			if (newCatSave.success == true) {
				setSuccessMessage(t("pageTitle.editSuccessMsg"));
				
			}
		} catch (error) {
			console.error("Error adding new category:", error);
			// Handle error as needed
		} finally {
			setIsNewCatSaving(false);
			setGetCategoryAgain(true);
			props.getCategoryAgain(1);
			
		}
		// console.log(newCategoryNameEdit);
	};

	const handleInputChangeCatModal = (e) => {
		setCategoryNameInModal(e.target.value);
	};

	const handleClickAllData = () => {
		// console.log("click all");
		props.getAllPosters("Get all posters");
	}

	const handleDeleteCategory = async (categoryId) => {
		// console.log("delete-" + categoryId);

		Swal.fire({
			title: t("postersPage.sure"),
			text: t("postersPage.revertMsg"),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: t("postersPage.confirmDelete")
		}).then(async (result) => {
			if (result.isConfirmed) {
				const newCatDelete = await deleteUserCategory(categoryId);
	
				// Corrected logging statement
				// console.log(newCatDelete);
	
				if (newCatDelete === 'deleted') {
					Swal.fire(
						t("postersPage.deleted"),
						t("postersPage.deleteCat"),
						'success'
					);
				}
				
				// Move setIsCatDelete(true) inside the conditional block
				setIsCatDelete(true);
	
				// Move setIsCatDelete(false) inside the conditional block
				setIsCatDelete(false);
	
				setGetCategoryAgain(true);
				props.getCategoryAgain(1);
			}
		});
	};

	const closeModalAction = (data) =>{
	 
		setCloseModalFlag(closeModalFlag+1);
		setSuccessMessage("");
	}

	return (
		<>
			{/* <a onClick={handleClickAllData}>{t("pageTitle.all")} ({props.totalItems})</a> */}
		<ListGroup className="bg-white rounded-2 overflow-hidden shadow-sm">
		<ListGroup.Item className="nw-text-accent-color-1 d-flex align-items-center column-gap-2" action style={{ border: "none", borderRadius: 0, color: '#0D6DFD', fontWeight: 'bold'  }} onClick={()=>handleClickAllData()}>
			<Folder className="text-black"/> 
			<span>{t("pageTitle.all")} ({props.totalItems})</span>
		</ListGroup.Item>
		<hr className="mt-0 mb-3"></hr>
		<h6 className="px-3 ">{t("postersPage.documentsTitle")}</h6>

		{waitingResult ? (
			<div className="nw-responsive-font-size w-100 d-flex justify-content-center">
				<NwLoaderSpinner isSpinner size={'1em'}/>
			</div>			
		) : (
			userCategory?.map((category, index) => (
				<div
					key={index}
					className="d-flex justify-content-between align-items-center bg-white px-3"
				>
					<ListGroup.Item
						data-id={category.id}
						action
						disabled={ category?.productCount <= 0 ? true  : false }
						className="d-flex align-items-center justify-content-between px-0"
						onClick={() => handleClick(category.id)}
						style={{ border: "none", borderRadius: 0, color: '#0D6DFD'  }}
					>
						<div className="d-flex align-items-center column-gap-2 nw-text-accent-color-1">
							<Folder className="text-black"/>
							<span>{category.name} ({category.productCount})</span>
						</div>
					</ListGroup.Item>
					<div className="dropdown">
						<Dropdown>
							<Dropdown.Toggle
								as={CustomToggle}
								id={`dropdown-custom-components-${index}`}
							></Dropdown.Toggle>

							<Dropdown.Menu>
								<Dropdown.Item
								as="button"
								onClick={() => handleEditCategory(category.id, category.name)}
								>
								{t("pageTitle.mod")}
								</Dropdown.Item>
								<Dropdown.Item
								as="button"
								onClick={() => handleDeleteCategory(category.id)}
								>
								{t("pageTitle.delete")}
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</div>
			))
		)}
				<NwCustomModal
				closeModal = {closeModalFlag}
					customButton={
						<ListGroup.Item action style={{ border: "none", borderRadius: 0, fontWeight: 'bold'  }}>
							<Plus></Plus> {t("pageTitle.newCat")}
						</ListGroup.Item>
					}
					customHeaderContent={<h1>{t("pageTitle.newCat")}</h1>}
					modalProps={{
						style: {
							width: '65%'
						}
					}}
					children={
						<Form onSubmit={(e) => handleSubmitSaveDoc(e)}>
							<Form.Group className="mb-3" controlId="formNewCategory">
								<Form.Label>{t("pageTitle.nameCat")}</Form.Label>
								<Form.Control type="input" name="cat_name" />
							</Form.Group>
							<div className="buttons-custom" style={{ float: 'right'}}>
							<Button variant="primary" type="submit" style={{ marginRight: "15px" }}>
								{t("pageTitle.save")}
								{isNewCatSaving ? (
									<span
										style={{ marginLeft: "10px" }}
										className="spinner-border spinner-border-sm"
										role="status"
										aria-hidden="true"
									></span>
								) : null}
							</Button>

							<Button variant="outline-primary" id="close-button" style={{ marginRight: "10px"}} onClick={closeModalAction}> {t("pageTitle.cancel")}</Button>
							</div>

							<br></br>
							<p style={{ color: errorMessage ? "red" : "inherit" }}>
								{errorMessage ?? ""}
							</p>

							<br></br>
							<p style={{ color: successMessage ? "green" : "inherit" }}>
								{successMessage ?? ""}
							</p>
						</Form>
					}
					title={"Nouveau Dossier"}
					onClose={handleCloseModal}
				/>
			</ListGroup>

			<Modal show={showModal} onHide={handleCloseModal}>
				<Modal.Header closeButton>
					<Modal.Title>{t("pageTitle.editDoc")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={(e) => handleSubmitEditCategoryForm(e)}>
						<Form.Group className="mb-3" controlId="formNewCategory">
							<Form.Label>{t("pageTitle.nameCat")}</Form.Label>
							<Form.Control
								type="input"
								name="cat_name"
								value={categoryNameInModal}
								onChange={handleInputChangeCatModal}
							/>
						</Form.Group>
						<Button variant="primary" type="submit">
							{t("pageTitle.save")}
							{isNewCatSaving ? (
								<span
									style={{ marginLeft: "10px" }}
									className="spinner-border spinner-border-sm"
									role="status"
									aria-hidden="true"
								></span>
							) : null}
						</Button>
						<br></br>
						<p style={{ color: errorMessage ? "red" : "inherit" }}>
							{errorMessage ?? ""}
						</p>

						<br></br>
						<p style={{ color: successMessage ? "green" : "inherit" }}>
							{successMessage ?? ""}
						</p>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-primary" onClick={handleCloseModal}>
					{t("pageTitle.cancel")}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};
export default MainPosterSideFilter;
