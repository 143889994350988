import { getBackendApiUrl, getEasyPlvApiToken, getBackendUrl, getAuthTokenWithRefresh } from "../config";
import { checkApiResponse } from "./auth";

export const fetchAllPages = async (locale) => {
    const authToken = await getAuthTokenWithRefresh();
    const res = await fetch(
      getBackendUrl(
        `public/pages/${locale}`
      ),
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${authToken}`,
          "X-EasyPLV-Api-Token": getEasyPlvApiToken(),
        },
      }
    );
    checkApiResponse(res);
    const data = await res.json();
    return data;
};

export const fetchPageByLocaleAndSlug = async (locale,slug) => {
  const authToken = await getAuthTokenWithRefresh();
  const res = await fetch(
    getBackendUrl(
      `public/pages/${locale}/${slug}`
    ),
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "X-EasyPLV-Api-Token": getEasyPlvApiToken(),
      },
    }
  );
  checkApiResponse(res);
  const data = await res.json();
  return data;
};