import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

const Impersonator = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const {isAuthenticated, Authenticate, setIsImpersonator } = useAuth();
    const params = new URLSearchParams(location.search);
    const callbackPath = params.get('callbackPath');

    useEffect(() => {
        const jwt_token = params.get('jwt');

        Authenticate(jwt_token);
        setIsImpersonator(true);

        localStorage.setItem('impersonateSession', true);

    }, []);

    useEffect(() => {
        if (isAuthenticated !== null && isAuthenticated == true ){
            console.log(callbackPath)
            if (callbackPath) navigate(callbackPath);
            else {navigate('/');}
        }
    }, [isAuthenticated])
}

export default Impersonator;