import React, { useEffect, useState } from "react";
import ModelSelection from '../components/ModelSelection';
import Canvas from '../components/Canvas';
import { useStyletron } from 'baseui';


export const EditorUseContext = React.createContext<any>({});

export const EditorUseProvider = ({usage, children} : any) => {
    const [use, setUse] = useState();
    const [editorContents, setEditorContents] = useState(
        {
            'model_selection' : ModelSelection,
            'canvas' : () => {return <></>}
        }
    )
    const [currentEditorContent, setCurrentEditorContent] = useState({"hideCanvas" : false, "content": "canvas"});

    useEffect(() => {
            setUse(usage);
    }, [setUse, usage]);

    const context = {
        use,
        setUse,
        editorContents,
        setEditorContents,
        currentEditorContent,
        setCurrentEditorContent,
    }

    return <EditorUseContext.Provider value={context}>{children}</EditorUseContext.Provider>
}