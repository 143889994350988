import {
	ListGroup,
	OverlayTrigger,
	Tooltip
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Nw Custom Components
import NwBadgeWithCount from "../_utilityComponents/NwBadgeWithCount/NwBadgeWithCount";
import NwCustomCheckbox from "../_utilityComponents/NwFormsUi/CustomCheckbox/NwCustomCheckbox";

// Bootstrap Icons
import { CircleFill } from "react-bootstrap-icons";
import { useCart } from "../../contexts/CartContext";

function PrintersList(props) {
	const printers = props.printers;
	const printerFormat = props.format;
	const formatName = printerFormat["formatName"];
	const formatCode = printerFormat["formatCode"];
	const countCartItems = props.countCartItems;
	const updatePrinter = props.updatePrinter;
	const availablePrinters = props.availablePrinters ?? [];
	const selectedPrinters = props.selectedPrinters;
	const { t } = useTranslation();
	const { allowPrint, allowPrintFromSubscription } = useCart();

	function isPrinterAmongSelectedOnesForFormat(printer, formatCode) {
		if (!printer) return false;
		if (!formatCode) return false;
		if (formatCode in selectedPrinters) {
			return selectedPrinters[formatCode] === printer.id;
		}
	}

	function truncateLabel(label) {
		const maxLabelLength = 25; // Set the maximum length for the displayed label
		return label.length > maxLabelLength
		? `${label.substring(0, maxLabelLength)}...`
		: label;
	}

	return (
		<div className={`cart-group-item-header-wrapper ${props.className}`}>
			<div className="header-format-container">
				<span className="me-2">Format:</span>
				<span>
					{formatName}
				</span>
				<NwBadgeWithCount bg="white" className="text-black ms-2" isDefault countValue={countCartItems} />
			</div>
			{
				allowPrintFromSubscription && (
					<ListGroup className="cart-group-item-header-printer-list-container text-white" horizontal>
						<ListGroup.Item className="cart-group-item-header-printer-list-title text-white d-flex justify-content-center align-items-center bg-transparent border-0 ">
							{t('cartPage.cartTable.choosePrinter')}
						</ListGroup.Item>
						{
							printers.map((printer) => {
								return (
									<ListGroup.Item
										key={printer.id}
										className="cart-group-item-header-printer-list-item d-flex align-items-center bg-transparent text-white justify-content-center border-0 p-1"
									>
										<div className="position-relative">
											<NwCustomCheckbox
												label={truncateLabel((printer?.displayName ?? "") + "(" + printer.name + ")")}
												initialIsCheck={isPrinterAmongSelectedOnesForFormat(
													printer,
													formatCode
												)}
												checkboxSize="nw-md"
												labelClassName="cart-group-item-header-printer-list-item-name"
												wrapperProps={{
													className: 'border-white bg-black',
													isCheckedClassName: 'border-white bg-black'
												}}
												OnChange={(isChecked) => {
													updatePrinter(formatCode, printer.id, isChecked);
												}}
											/>
		
																		
												<div className="cart-group-item-header-printer-list-item-status">
													{availablePrinters.includes(printer.name) ? (
															<OverlayTrigger
																placement="top"
																overlay={
																	<Tooltip className="nw-tooltip">
																		{printer?.displayName}({printer.name}) <span className="text-success fw-bold text-uppercase">online</span>
																	</Tooltip>
																}
															>	
																<CircleFill className="text-success" size={10}/>
															</OverlayTrigger>
														) : (
															<OverlayTrigger
																placement="top"
																overlay={
																	<Tooltip className="nw-tooltip">
																		{printer?.displayName}({printer.name}) <span className="text-danger fw-bold text-uppercase">offline</span>
																	</Tooltip>
																}
															>	
																<CircleFill className="text-danger" size={10}/>
															</OverlayTrigger>	
													)}
												</div>
											
										</div>
									</ListGroup.Item>
								);
							})
						}
					</ListGroup>
				)
			}

		</div>
	);
}

export default PrintersList;
