import { Block } from "baseui/block"
import { Button, SIZE } from "baseui/button"
import { Input } from "baseui/input"
import { Modal, ROLE } from "baseui/modal"
import { Tab, Tabs } from "baseui/tabs"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import Scrollbar from "@layerhub-io/react-custom-scrollbar"
import { ArrowsExpand } from "react-bootstrap-icons"
import React, { Fragment } from "react";
// @ts-ignore
import landscape from  "../../../../assets/images/landscape.png";
// @ts-ignore
import portrait from  "../../../../assets/images/portrait.png";
import {Search} from 'baseui/icon';
import { useTranslation } from "react-i18next"

const FormatAndOrientationModal = ({templateGroupMetadata, handleFormatAndOrientationChange, selectedFormat, selectedOrientation} : any) => {
  const {t} = useTranslation();
  const [originalFormats, setOriginalFormats] = React.useState<any>([]);
  const [formats, setFormats] = React.useState<any>([]);
  const [isOpen, setIsOpen] = React.useState(false)
  // EDITOR_CHANGES added options type bigint to activeKey state, reason: typescritp warning
  const [activeKey, setActiveKey] = React.useState<string | number | bigint>("0")
  const [searchQuery, setSearchQuery] = React.useState("");

  const formatAndOrientationSelectHandler = (format: any, orientation: any) => {
    setIsOpen(false);
    handleFormatAndOrientationChange(format, orientation);
  }

  React.useEffect(() => {
    if (templateGroupMetadata?.format?.select) {
      let allFormats = [...templateGroupMetadata?.format?.select];
      allFormats.splice(0, 1);
      let activeIndex = 0;
      allFormats.map((format: any, index: number) => {
        if (format.value === selectedFormat.value) {
          format.active = true;
          activeIndex = index;
        }
        else format.active = false;
        
        return format;
      });

      allFormats.unshift(allFormats.splice(activeIndex, 1)[0]);
      setFormats(allFormats);
      setOriginalFormats(allFormats);
    }
  }, [templateGroupMetadata?.format?.select, selectedFormat]);

  React.useEffect(() => {
    const handleSearch = async () => {
      let newFormats = [...originalFormats];

      const searchedFormats: any = newFormats.filter((newFormat: any) => {
        return newFormat.label.toLowerCase().includes(searchQuery.toLowerCase());
      });

      setFormats(searchedFormats);
    }

    handleSearch();
  }, [searchQuery, originalFormats])

  const activeFormatStyle = {
    border: "5px solid #23eb94",
    borderRadius: "5%",
  }


  return (
    <>
      <OverlayTrigger overlay={<Tooltip>{t('editor.inputs.resize_template')}</Tooltip>}>
        <Button
          onClick={() => setIsOpen(true)}
          size={SIZE.compact}
          style={{
            fontFamily: "Montserrat-SemiBold",
            backgroundColor: "#0D6EFD",
            borderRadius: "2px",
            aspectRatio: "1",
            maxWidth: "fit-content"
          }}
          overrides={{
            Root: {
              style: {
                width: "100%",
              },
            },
          }}
        >
          <ArrowsExpand size={30}/>
        </Button>
      </OverlayTrigger>
      <Modal
        onClose={() => setIsOpen(false)}
        closeable={true}
        isOpen={isOpen}
        animate
        autoFocus
        size="auto"
        role={ROLE.dialog}
        overrides={{
          Dialog: {
            style: {
              borderTopRightRadius: "8px",
              borderEndStartRadius: "8px",
              borderEndEndRadius: "8px",
              borderStartEndRadius: "8px",
              borderStartStartRadius: "8px",
            },
          },
        }}
      >
        <Block $style={{ padding: "0 1.5rem", width: "640px" }}>
          <Block
            $style={{
              padding: "2rem 1rem 1rem",
              textAlign: "center",
              fontWeight: 500,
            }}
          >
            {t('editor.resize_template.title')}
          </Block>
          <Tabs
            overrides={{
              TabContent: {
                style: {
                  paddingLeft: 0,
                  paddingRight: 0,
                },
              },
              TabBar: {
                style: {
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "#ffffff",
                },
              },
            }}
            activeKey={activeKey}
            onChange={({ activeKey }) => {
              setActiveKey(activeKey)
            }}
          >
            <Tab title={t('editor.resize_template.preset_size')}>
              <Block $style={{ width: "100%", height: "400px"  }}>
              <Input value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} endEnhancer={<Search />} />
              <Block className="mt-2" style={{
                height: '80%',
                overflow: 'scroll'
              }}>
                  <Block $style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr" }}>
                    {formats.map((format: any, index: number) => (
                      <Block 
                        $style={{
                          padding: "0.5rem",
                          backgroundColor: false ? "rgb(243,244,245)" : "#ffffff",
                          //   backgroundColor: "rgb(246,247,248)",
                          ...(format.active && activeFormatStyle),
                        }}
                        key={format.value}
                      >
                        <Block
                          $style={{
                            height: "120px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {
                            templateGroupMetadata?.orientation?.byFormatselect[`${format.value}`]?.map((orientation: any) => {
                              return (
                                <Fragment key={orientation.value}>
                                  {
                                    <Block
                                    $style={{
                                      ":hover": {
                                        backgroundColor: "rgb(246,247,248)",
                                        cursor: "pointer",
                                      },
                                    }}>
                                    <img style={{
                                      border: '1px solid #abbab0',
                                       borderRadius: '5%',
                                        margin: '2px',
                                         ...(format.active && selectedOrientation.value === orientation.value && {background: '#23eb94'})
                                        }} onClick={(e) => {formatAndOrientationSelectHandler(format, orientation);}} alt='portrait' src={
                                      orientation.value === 'landscape' ? landscape : portrait
                                    }/>
                                    </Block>
                                  }
                                </Fragment>
                              );
                            })
                          }
                        </Block>
                        <Block $style={{ fontSize: "13px", textAlign: "center" }}>
                          <Block $style={{ fontWeight: 500 }}>{format.label}</Block>
                          <Block $style={{ color: "rgb(119,119,119)" }}>
                            {format.size.width} x {format.size.height}{format.size.unit}
                          </Block>
                        </Block>
                      </Block>
                    ))}
                  </Block>
                </Block>
              </Block>
            </Tab>
            {/* <Tab title="Custom size">
              <Block $style={{ padding: "2rem 2rem" }}>
                <Block
                  $style={{ display: "grid", gridTemplateColumns: "1fr 50px 1fr", alignItems: "end", fontSize: "14px" }}
                >
                  <Input
                    startEnhancer="W"
                    size={SIZE.compact}
                  />
                  <Button
                    overrides={{
                      Root: {
                        style: {
                          height: "32px",
                        },
                      },
                    }}
                    size={SIZE.compact}
                    kind="tertiary"
                  >
                    <SwapHorizontal size={24} />
                  </Button>
                  <Input
                    startEnhancer="H"
                    size={SIZE.compact}
                  />
                </Block>
              </Block>
            </Tab> */}
          </Tabs>
        </Block>
      </Modal>
    </>
  )
}

export default FormatAndOrientationModal;