import { Accordion, Col, Row } from 'react-bootstrap';
import PrintersList from './PrintersList';
import CartItem from './CartItem';
import { convertCartToOrder } from '../../services/cart';
import { useEffect, useState } from 'react';
import { findAllAvailablePrinters } from '../../services/print';
import { useTranslation } from "react-i18next";

// Boostrap Icons
import { ChevronDown, ThreeDotsVertical } from 'react-bootstrap-icons';

// Nw Custom Utility Components
import NwCustomDropdown from '../_utilityComponents/NwFormsUi/CustomDropdown/NwCustomDropdown';
import { useCart } from '../../contexts/CartContext';

function GroupedByFormatCart(props) {
    const isOrderingGeneralState = props.isOrderingGeneralState;
    const { allowPrint, allowPrintFromSubscription } = useCart();
    const { t } = useTranslation();
    const cartId = props.cartId;
    const groupedCartItemsByFormat = props.groupedCartItemsByFormat;
    const formatCodes = Object.keys(groupedCartItemsByFormat);
    const selectedPrinters = props.selectedPrinters;
    const updatePrinter = props.updatePrinter;
    const formats = [];
    const [formatsIsOrdering, setFormatsIsOrdering] = useState({
        'print' : {},
        'zip' : {},
        'pdf': {}
    });
    const [availablePrinters, setAvailablePrinters] = useState([]); 	

    formatCodes.forEach(formatCode => {
        formats.push({
            'formatName': groupedCartItemsByFormat[formatCode]['formatName'],
            'formatCode': formatCode,
            'formatId': groupedCartItemsByFormat[formatCode]['formatId']
        })
    });

    useEffect(() => {
        formats.forEach(format => {
            setFormatsIsOrdering(prevFormatsIsOrdering => {
                const newFormatsIsOrdering = {...prevFormatsIsOrdering};
                newFormatsIsOrdering['print'][format.formatId] = false;
                newFormatsIsOrdering['zip'][format.formatId] = false;
                return newFormatsIsOrdering;
            });
        }); 
    }, []);

    useEffect(() => {
        async function findAndSetAvailablePrinters() {
            let availablePrinters = await findAllAvailablePrinters();
            if (!availablePrinters) availablePrinters = [];
            setAvailablePrinters(availablePrinters);
            for (const format of formats) {
                const printers = groupedCartItemsByFormat[format.formatCode]['printers'];
                for (const printer of printers) {
                    if (availablePrinters.includes(printer.name)) updatePrinter(format.formatCode, printer.id);
                }
            }
        }

        if (allowPrintFromSubscription){
            findAndSetAvailablePrinters();
        } else {
            setAvailablePrinters([]);
        }
        
    }, [])

    async function orderFormat(format, type="print") {
        const formatId = format.formatId;
        isOrderingGeneralState.update(true);
        setFormatsIsOrdering(prevFormatsIsOrdering => {
            const newFormatsIsOrdering = {...prevFormatsIsOrdering};
            newFormatsIsOrdering[type][format.formatId] = true;
            return newFormatsIsOrdering;
        })
        await convertCartToOrder(cartId, selectedPrinters, formatId, type);
        window.location.reload(false);
    }

    const CartItemHeader = () => (
        <Row className="border-0 py-3 nw-cart-item-header w-100">
			{/* <Col xs={2} /> */}
            <Col xs={4} className="d-flex align-items-center ps-5" style={{ fontWeight: 'bold', color: '#0d6efd' }}>{t("cartPage.cartTable.productName")}</Col>
            <Col xs={4} className="d-flex align-items-center" style={{ fontWeight: 'bold', color: '#0d6efd' }}>{t("cartPage.cartTable.theme")}</Col>
            <Col xs={2} className="d-flex justify-content-center align-items-center p-1" style={{ fontWeight: 'bold', color: '#0d6efd' }}>{t("cartPage.cartTable.qty")}</Col>
            <Col xs={2} className="d-flex justify-content-center align-items-center p-2" style={{ fontWeight: 'bold', color: '#0d6efd' }}>{t("cartPage.cartTable.remove")}</Col>
        </Row>
    );

	// Additional Group Options
	const groupMoreOptions = (format) => {
		const IsPrinterSelected = selectedPrinters.hasOwnProperty(format["formatCode"]);
		return [
			{
				displayText: t("cartPage.cartTable.orderbuttonLabel"),
				eventKey: 0,
				isDisabled: allowPrintFromSubscription ? !IsPrinterSelected : true,
                isVisible: allowPrintFromSubscription,
				onClick: () => orderFormat(format, 'print')
			},
			{
				displayText: t("cartPage.cartTable.zip"),
				eventKey: 1,
				onClick: () => orderFormat(format, 'zip')
			},
			{
				displayText: t("cartPage.cartTable.pdf"),
				eventKey: 2,
				onClick: () => orderFormat(format, 'pdf')
			},
		];
	}
    
    return (
        <>
            <Accordion 
                className='rounded-0 shadow-sm'
				defaultActiveKey={[0, 1]} 
                alwaysOpen
            >
                {
                    formats.map((format, index) => {
                        let printers = groupedCartItemsByFormat[format['formatCode']]['printers'];
                        let cartItems = groupedCartItemsByFormat[format['formatCode']]['cartItems'];
                        return (  
                            <Accordion.Item 
                                className='rounded-0' 
                                key={format['formatCode']} 
                                eventKey={index}
                            >
								<div className="cart-group-item-header-container d-flex w-100 bg-black text-white">
                                    <PrintersList 
                                        className="w-100 d-flex justify-content-between px-4 py-2" 
                                        selectedPrinters={selectedPrinters} 
                                        availablePrinters={availablePrinters} 
                                        updatePrinter={props.updatePrinter} 
                                        format={format} 
                                        printers={printers} 
                                        countCartItems={cartItems.length}
                                    />
									<div className={`d-flex align-items-center justify-content-center`}>
										<NwCustomDropdown
											hideDropdownIcon
											title={t("cartPage.cartTable.moreOptions")}
											dropdownItems={groupMoreOptions(format)}
										>
											<ThreeDotsVertical className='text-white' size={24}/>
										</NwCustomDropdown>
									</div>
									<Accordion.Button 
                                        variant="dark"
                                        as="button"
                                        className='cart-group-item-header-accordion-btn bg-transparent border-0 text-white shadow-none'
                                        style={{width: 'fit-content'}}
                                    >
                                        <ChevronDown className='cart-group-item-header-accordion-btn-icon' size={20}/>
                                    </Accordion.Button>
                                
								</div>
                                <Accordion.Body className='d-flex flex-column align-items-center px-0 py-0'>          
                                    <CartItemHeader/>                   
                                    {cartItems.map(cartItem => {
                                        return <CartItem cartId={cartId} updateCart={props.updateCart} key={cartItem.id} cartItemData={cartItem}/>
                                    })}
                                </Accordion.Body>
                            </Accordion.Item> 
                        )
                    })
                }
            </Accordion>
        </>
    );
}

export default GroupedByFormatCart;