import React, { useEffect, useState, useRef } from "react";
import { Form, Row, Col, ListGroup, Tab, Button } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { getProductLocal, getProductLocalFiltered } from "../../services/productLocal";
import { Search } from "react-bootstrap-icons";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

// Nw Custom Filters
import { NwCommonFilter } from "../NwCustomFilters/NwCustomFilters";

const ProductLocalFilter = ({ getFilteredData,getFilteredQueryString,resetPaginationNumber }) => {
  const [posts, setPosts] = useState();
  const [queryStr,setQueryStr] = useState();
  const { i18n, t } = useTranslation();

  const handleFilterSubmit = async (e) => {
    e.preventDefault();

    let queryString = "";
    let page = "";

    const searchValue = e.target.search_name.value;
    const eanValue = e.target.search_ean.value;

    if (searchValue) {
      queryString += `&filters[name]=${searchValue}`;
    }

    if (eanValue) {
      if (queryString) {
        queryString += `&filters[ean]=${eanValue}`;
      } else {
        queryString += `&filters[ean]=${eanValue}`;
      }
    }
    
    page = `?options[page]=1`;
    const res = await getProductLocalFiltered(page,queryString);
    setPosts(res.items);
    setQueryStr(queryString);
    if (res) {
        getFilteredData(res);
    }
    resetPaginationNumber(0);
  };

  useEffect(() => {
    getFilteredQueryString(queryStr);
  }, [posts]);

  return (
    <NwCommonFilter
        onSubmit={handleFilterSubmit}
        className= "w-100"
        autoSubmitOnReset
        onFilterReset={() => resetPaginationNumber(0)}
        filterWrapperProps={{
            className: "w-100 justify-content-around"
        }}
        filterButtonProps={{
            type: 'submit',
            children: t('validateButtonLabel')
        }}
        resetButtonProps={{
            children: t('resetFilterButton')
        }}
        filterModalProps={{
          headerContent: t('productLocalPage.filterModalHeader')
        }}
        formFields={[
            {
                type: 'input',
                wrapperProps: {
                    sm: 3
                },
                props: {
                    controlId: "search-by-name-filter",
                    name: "search_name",
                    placeholder: t('productLocalPage.topSection.nameFieldLabel'),
                    hideLabel: true
                }
            },
            {
                type: 'input',
                wrapperProps: {
                    sm: 3
                },
                props: {
                    controlId: "search-by-ean-filter",
                    name: "search_ean",
                    placeholder: t('productLocalPage.topSection.eanFieldLabel'),
                    hideLabel: true
                }
            },
        ]}
    />
  );
}

export default ProductLocalFilter;