import {getAuthToken, getBackendApiUrl, getEasyPlvApiToken} from "../config";

export const updateUserFrontSession = async (data) => {
    const authToken = getAuthToken();
    const apiKey = getEasyPlvApiToken();
    const updateUserFrontSessionPromise = await fetch(getBackendApiUrl(`user/addUserFrontSessionMetaData`), {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${authToken}`,
            "X-EasyPLV-Api-Token": apiKey,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    }).catch(e => console.log(e));

    const response = updateUserFrontSessionPromise;
    return response;


}