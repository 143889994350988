import React from 'react';

const SpecialFieldLogo = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" width={props.size} height={props.size} >
            <path d="M12.692 18h-2.77a1.624 1.624 0 0 1-1.61-1.483l-.004-.133v-.923a1.155 1.155 0 0 0-2.302-.118L6 15.461v.923c0 .836-.652 1.543-1.483 1.61L4.385 18h-2.77a1.624 1.624 0 0 1-1.61-1.483L0 16.384v-2.769c0-.835.651-1.542 1.483-1.61L1.615 12h.924a1.154 1.154 0 0 0 .118-2.3l-.118-.007h-.924A1.624 1.624 0 0 1 .005 8.21L0 8.077v-2.77c0-.835.651-1.542 1.483-1.61l.132-.005h2.77a.23.23 0 0 0 .224-.178l.007-.052v-.923C4.616 1.139 5.754 0 7.154 0a2.54 2.54 0 0 1 2.533 2.383l.005.156v.923c0 .107.074.2.178.224l.053.006h2.77c.835 0 1.542.651 1.61 1.483l.004.133v2.769c0 .107.073.2.178.225l.053.006h.923c1.4 0 2.539 1.138 2.539 2.538a2.542 2.542 0 0 1-2.384 2.534l-.155.004h-.923c-.106 0-.2.075-.224.178l-.007.053v2.77c0 .835-.651 1.542-1.483 1.61l-.132.005Zm-5.538-5.077a2.54 2.54 0 0 1 2.533 2.384l.005.154v.923c0 .108.074.2.178.225l.053.006h2.77a.23.23 0 0 0 .224-.178l.006-.053v-2.769c0-.835.652-1.542 1.483-1.61l.132-.006h.923a1.155 1.155 0 0 0 .118-2.3l-.118-.007h-.923a1.623 1.623 0 0 1-1.61-1.482l-.005-.133v-2.77a.23.23 0 0 0-.178-.224l-.053-.006h-2.77a1.624 1.624 0 0 1-1.61-1.483l-.004-.132v-.923a1.155 1.155 0 0 0-2.302-.118L6 2.539v.923c0 .835-.652 1.542-1.483 1.61l-.132.005h-2.77a.23.23 0 0 0-.224.178l-.006.053v2.769c0 .107.073.2.178.225l.052.006h.924c1.4 0 2.538 1.138 2.538 2.538a2.541 2.541 0 0 1-2.384 2.534l-.154.004h-.924a.23.23 0 0 0-.224.178l-.006.053v2.77c0 .107.073.2.178.224l.052.006h2.77a.23.23 0 0 0 .224-.178l.007-.053v-.923a2.54 2.54 0 0 1 2.538-2.538Z" fill="currentColor" fillRule="evenodd"/>
        </svg>
    )
}

export default SpecialFieldLogo;