import { useEffect } from "react";
import { Form } from "react-bootstrap";
import NwCustomInput from "../_utilityComponents/NwFormsUi/CustomInput/NwCustomInput";

function LongTextType(props) {
	const isDisabled = props?.isDisabled || false;
    const formFieldJson = props.formFieldJson;
	const entityFormValuesJson = props.entityFormValuesJson;
	const updateEntityFormValues = props.updateEntityFormValues;
	const configValues = formFieldJson.configValues;
	const metadata = configValues?.values?.metadata;
    const longTextValueKey = `${configValues.frontUniqueKeys.textarea1}_${formFieldJson.id}`;
    const longTextValueJson = `${longTextValueKey}` in entityFormValuesJson 
	? entityFormValuesJson[`${longTextValueKey}`]
	: {
		name: formFieldJson.name,
		type: 'long_text',
		value: '',
		metadata: metadata,
	}

	const updateLongTextValue = (newLongTextValue) => {
		updateEntityFormValues(prevFieldValuesJson => {
			const newFieldValuesJson = {...prevFieldValuesJson};
            longTextValueJson.value = newLongTextValue;
            newFieldValuesJson[`${longTextValueKey}`] = longTextValueJson;
            return newFieldValuesJson;
		})
	}

	useEffect(() => {
		updateLongTextValue(longTextValueJson.value);
	}, []);

    return (
		<div key={props.id} className={`mb-3 ${props.elementClasses}`}>
			<Form.Label>{props.formFieldJson.title}</Form.Label>
        	<NwCustomInput disabled={isDisabled} hideLabel noBar onChange={(e) => updateLongTextValue(e.target.value)} placeholder={longTextValueJson.value} value={longTextValueJson.value} id={props.id} as="textarea" name={props.formFieldJson.name + "_" + props.formFieldJson.type}/>
		</div>
	);
}

export default LongTextType;