import { EyeSlashFill, InfoCircle } from "react-bootstrap-icons"
import { 
	NwBrowserIcon, 
	NwAdjustmensIcon, 
	NwTextIcon, 
	NwModulePlv, 
	NwUploadIcon 
} from "../components/_utilityComponents/NwIcons/NwIcons"

export const BASE_ITEMS = [
	// {
	//   id: "templates",
	//   name: "Templates",
	// },
	{
		id: "general",
		name: "General",
		icon: <InfoCircle size={24} />
	},
	{
		id: "customize",
		name: "Customize",
		icon: <NwBrowserIcon size={24}/>

	},
	// {
	//   id: "elements",
	//   name: "Elements",
	// },
	// {
	//   id: "images",
	//   name: "Images",
	// },
	{
		id: "text",
		name: "Text",
		icon: <NwTextIcon size={24}/>
	},
	{
		id: "uploads",
		name: "Uploads",
		icon: <NwUploadIcon size={24}/>
	},
	// {
	//   id: "graphics",
	//   name: "Graphics",
	// },

	// {
	//   id: "pixabay",
	//   name: "Pixabay",
	// },
	{
		id: "layers",
		name: "Layers",
		icon: <NwModulePlv size={24}/>
	},
]

export const VIDEO_PANEL_ITEMS = [
	{
		id: "templates",
		name: "Templates",
	},
	{
		id: "customize",
		name: "Customize",
	},
	{
		id: "elements",
		name: "Elements",
	},
	{
		id: "images",
		name: "Images",
	},
	{
		id: "videos",
		name: "Videos",
	},
	{
		id: "uploads",
		name: "Uploads",
	},
	{
		id: "text",
		name: "Text",
	},
	{
		id: "graphics",
		name: "Graphics",
	},

	{
		id: "pixabay",
		name: "Pixabay",
	},
]

export enum PanelType {
    TEMPLATES = "Templates",
    GENERAL = "General",
    BACKGROUND = "Background",
    TEMPLATE_GROUP_MODELS = "TemplateGroupModels",
    LAYERS = "Layers",
    TEXT = "Text"
}

export const SOCIAL_MEDIA_ITEMS = [
	{
		id: "thematique_et_models",
		name: "TemplateGroupModels",
		icon: <InfoCircle size={24} />,
		additionalData: {
			panelContentTitle: "Aperçu des visuels",
			panelContentDescription: "Choisissez un visuel parmi les modèles disponibles pour cette thématique"
		}
	},
	{
		id: "customize",
		name: "Customize",
		icon: <NwBrowserIcon size={24}/>
	},
	{
		id: "text",
		name: "Text",
		icon: <NwTextIcon size={20}/>
	},
	{
		id: "uploads",
		name: "Uploads",
		icon: <NwUploadIcon size={20}/>
	},
	{
		id: "layers",
		name: "Layers",
		icon: <NwModulePlv size={20}/>
	},
]

interface TAB_CONTENT_ITEM {
	tab: string,
	content: string
}

export const TAB_EDITOR_CONTENT : any = {
	"TemplateGroupModels" : "model_selection",
}

export const TEMPLATE_GROUP_MODEL_ITEMS = [
	{
		id: "text",
		name: "Text",
		icon: <NwTextIcon size={20}/>
	},
	{
		id: "uploads_template_group_model",
		name: "UploadsTemplateGroupModel",
		icon: <NwUploadIcon size={20}/>

	},
	{
		id: "layers",
		name: "Layers",
		icon: <NwModulePlv size={20}/>
	},
]
