import TemplateGroupModel from './TemplateGroupModel';
import { Accordion, Row, Col } from 'react-bootstrap';
import { ChevronDown } from 'react-bootstrap-icons';

const SocialMediaPlatform = ({
    title, 
    handleModelSelection, 
    models, 
    isActive = false,
    platform
}: { 
    title: any, 
    handleModelSelection:any, 
    models: any, 
    isActive: any,
    platform: any
}) => {
    if (typeof models === 'object' && models !== null) {
        models = Object.values(models)
    }

    return (
        <>
            <div className="social-media-platform-accordion-header py-2 px-3 d-flex justify-content-between align-items-center w-100 rounded-4 shadow">
                <div className="platform-title">
                    {title}
                </div>
                <Accordion.Button 
                    as="button"
                    className='bg-transparent social-media-platform-accordion-btn border-0 text-black shadow-none p-0 d-flex justify-content-center align-items-center'
                    style={{
                        width: 'fit-content', 
                    }}
                >
                    <ChevronDown className={`social-media-platform-accordion-btn-icon ${ isActive ? 'is-active' : '' }`} size={14}/>
                </Accordion.Button>
            </div>
            <Accordion.Body className='shadow-none border-0 mt-3 social-media-accordion-body'>
                <Row xs={3}>                
                    {
                        models?.map((model:any, index:any) => (
                            <Col key={model?.id}>
                                <TemplateGroupModel platform={platform} key={index} handleModelSelection={handleModelSelection} model={model}/>
                            </Col>
                        ))
                    }
                </Row>
            </Accordion.Body>
        </>
    )
}

export default SocialMediaPlatform;