import React, { ReactElement } from "react"
import { Block } from "baseui/block"
import { useEditor } from "@layerhub-io/react"
import NwImage from "../../../../components/_utilityComponents/NwImage/NwImage"
import useDesignEditorContext from "../../hooks/useDesignEditorContext"
import { DesignEditorContext } from "../../contexts/DesignEditor";

type ImageOverlay = {
  header?: ReactElement;
  footer?: ReactElement;
}

type NwCanvasProps = {
  className?: string;
  imgClassName?:string;
  imgOverlay?: ImageOverlay;
  aspectRatio?: number | `${number}/${number}`;
}

const Graphic:React.FC<NwCanvasProps> = ({
  className = '',
  imgClassName = '',
  imgOverlay = null,
  aspectRatio = 1
}) => {
  const editor = useEditor()
  const { currentPreview } =  React.useContext(DesignEditorContext)
  const [loading, setLoading] = React.useState(true)
  const [state, setState] = React.useState({
    image: "",
  })

  const makePreview = React.useCallback(async () => {
    if (editor) {
      const template = editor.scene.exportToJSON()
      const image = (await editor.renderer.render(template)) as string
      setState({ image })
      setLoading(false)
    }
  }, [editor])

  React.useEffect(() => {
    makePreview()
  }, [editor, currentPreview])

  return (
    <Block className={`w-100 h-100 ${ className }`} $style={{ flex: 1, alignItems: "center", justifyContent: "center", display: "flex", padding: "1rem" }}>
      <div 
        className="free-editor-social-media-preview-wrapper"
        style={{
          height: 'fit-content',
          width: 'fit-content',
          position: 'relative'
        }}
      >
        { imgOverlay?.header }
        { !loading && (
          <NwImage 
            imgClassName={`w-100 h-100 object-fit-contain ${ imgClassName }`}
            wrapperStyles={{
              aspectRatio: aspectRatio
            }}
            src={state.image}
          />
        )}
        { imgOverlay?.footer }
      </div>
    </Block>
  )
}

export default Graphic
