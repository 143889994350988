import React from 'react'
import ProductLocal from '../../components/ProductLocal/ProductLocal'

function ProductLocalPage() {


  return (
    <>
    
    <ProductLocal/>
    
    </>
  )



}


export default  ProductLocalPage;