/* This block of code in JavaScript is defining an object named `nwMediaQueries` using the `const`
keyword, which means the object cannot be reassigned to a different value. The object is then frozen
using `Object.freeze()`, which prevents any modifications to its properties. */
export const nwMediaQueries = Object.freeze({
    'is-mobile': '(max-width: 768px)',
    'is-tablet': '(min-width: 768px) and (max-width: 1024px)',
    'is-small-desktop': '(min-width: 1025px) and (max-width: 1280px)',
    'is-medium-desktop': '(min-width: 1281px) and (max-width: 1440px)',
    'is-large-desktop': '(min-width: 1441px) and (max-width: 1680px)'
});

export const moduleTypes = Object.freeze({
    plv: 'plv',
    social_media: 'social_media'
})

export const SUBCRIPTION_MODE = Object.freeze({
    CLOUD: 'cloud',
    LOCAL_SERVER: 'local_server'
})