import React from 'react';
import { Badge } from "react-bootstrap";
import './NwBadgeWithCount.css';

const NwBadgeWithCount = (
        { 
            children,
            showBadge = true,
            countValue = 0,
			isDefault = false,
            isBottom, 
            isLeft, 
            overrideVerticalValue=null,
            overrideHorizontalValues={
                oneCharacter:null,
                twoCharacter:null,
                threeCharacter:null
            },
            ...badgeProps
        }
    ) => {

    if (!countValue){
       return null; 
    }    
    const displayValue = countValue ? countValue > 99 ? '99+' : countValue.toString() : '0';
    const charCount = displayValue?.length ?? 1;

    // Determine the right offset based on character count
    let horizontalOffset;
    switch (charCount) {
        case 1:
            horizontalOffset = overrideHorizontalValues?.oneCharacter ?? '0ch';
            break;
        case 2:
            horizontalOffset = overrideHorizontalValues?.twoCharacter ?? '-1ch';
            break;
        case 3:
        default:
            horizontalOffset = overrideHorizontalValues?.threeCharacter ??'-2ch';
            break;
    }

    // Determine the position based on isBottom and isLeft props
    const verticalPosition = isBottom ? { bottom: overrideVerticalValue ?? 2 } : { top: overrideVerticalValue ?? 2 };
    const horizontalPosition = isLeft ? { left: horizontalOffset } : { right: horizontalOffset };

    return (
        <Badge 
            {...badgeProps} // Spread any additional props here
            key={countValue ?? 0}
            className={`${isDefault ? '' : 'position-absolute p-0'} nw-icon-badge ${badgeProps.className || ''}`}
            style={{
                ...badgeProps.style,
                ...verticalPosition,
                ...horizontalPosition,
            }} // Merge style objects
        >
            <div key={countValue ?? 0} className="nw-icon-badge-content-wrapper">
                {children ?? displayValue}
            </div>
        </Badge>
    );
}

export default NwBadgeWithCount;