import React, {useState, useEffect} from 'react';
import { CheckLg } from 'react-bootstrap-icons';
import './NwCustomCheckbox.css'

const NwCustomCheckbox = ({ 
    label, 
    initialIsCheck = false, 
    checkboxSize = '', 
    OnChange, 
    labelClassName,
    wrapperProps = {},
    variant='',
    isDisabled = false, 
    debug 
}) => {
    const [isChecked, setIsChecked] = useState(initialIsCheck);
	// Checkbox size options include: nw-sm, nw-md, nw-lg, or else no options specified
    // Checkbox variant options: primary, secondary
    useEffect(() => {
        if (OnChange) {
            if (debug){
                console.log("CHECKBOX ON CHANGED");
            }
            OnChange(isChecked);
        }
    }, [isChecked])

    useEffect(() => {
        setIsChecked(initialIsCheck);
    }, [initialIsCheck])

    const toggleCheckbox = () => {
        if (!isDisabled) setIsChecked(prevIsChecked => !prevIsChecked);
    }

    const getVariantClasses = (isChecked) => {
        let classNames = wrapperProps.className ?? ""
        switch (variant) {
            case 'primary':
                classNames = 'bg-white text-primary'
                if (isChecked){
                    classNames = "bg-primary text-white"
                }
                if (isDisabled){
                    classNames = "bg-secondary text-white border-secondary"
                }
                break;
            case 'secondary':
                classNames = 'bg-white text-primary'
                if (isChecked){
                    classNames = "bg-white text-primary"
                }
                if (isDisabled){
                    classNames = "bg-secondary text-white border-secondary"
                }
                break;
            default:
                if (isChecked && wrapperProps?.isCheckedClassName) {
                    classNames = wrapperProps.isCheckedClassName
                }
                break;
        }
        return classNames;
    }

    return (
        <div className={`nw-custom-checkbox ${variant} ${label ? 'd-flex' : ''} ${debug ? 'border border-1' : ''}`} onClick={toggleCheckbox}>
            <div className={`nw-custom-checkbox-container d-flex align-items-center justify-content-center ${debug ? 'border border-1 border-success' : ''}`}>
                <div className={`nw-custom-checkbox-wrapper rounded-circle d-flex align-items-center justify-content-center border border-1 border-primary ${ getVariantClasses(isChecked) } ${checkboxSize ?? ''}`}>
                    {isChecked && <CheckLg className='h-auto w-auto' size={25}/>}
                </div>
            </div>
            {label && (
                <span className={`nw-custom-checkbox-label p-2 text-center ${labelClassName ?? ''}`}>{label}</span>
            )}
        </div>
    );
}

export default NwCustomCheckbox;