import React, { useState ,useContext, useEffect, useRef } from "react";
import MainLayoutContext from "../../contexts/MainLayoutContext";
import { getRouteDataFromRouteId } from "../../helpers/helpers";
import { Link, useNavigate } from "react-router-dom";
import { NwLockIcon } from "../../components/_utilityComponents/NwIcons/NwIcons";
import Chart from "chart.js/auto";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useTranslation } from "react-i18next";
import {getDashboardStats} from "../../services/dashboard";
import NwCustomDropdown from "../../components/_utilityComponents/NwFormsUi/CustomDropdown/NwCustomDropdown";

const DashboardChart = ({
  routeId,
  title,
  icon,
  description,
  bgVariant = "primary",
  isDisabled,
}) => {
  const { i18n,t } = useTranslation();
  const chartRef = useRef(null);

  const [posts, setPosts] = useState([]);
  const [isChartloading, setIsChartLoading] = useState(false);
  const [dateLablels, setDateLabels] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [maxValue, setMaxValue] = useState([100]);


  const fetchData = async (type, sort,locale) => {
    setIsChartLoading(true);
    try {
      const res = await getDashboardStats(type, sort,locale);
      // console.log('Fetched data:', res);
      setPosts(res);
      if (res?.print) {
        setDateLabels(res.print.dateOnly);
        setChartData(res.print.statsOnly);
        setMaxValue(res.print.maxValue);
      }else{
        setIsChartLoading(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsChartLoading(false);
    }
  };


  useEffect(() => {

    fetchData('print','month',i18n.language);

  },[]);

  const handleThisMonthClick = () => {
    fetchData('print','month',i18n.language);
  };

  const handleThisYearClick = () => {
      fetchData('print','year',i18n.language);
  };

  

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");

    const myLineChart = new Chart(ctx, {
      type: "line",
      data: {
        labels: dateLablels,
        datasets: [
          {
            label: "Affiches Imprimeés",
            backgroundColor: "rgba(75,192,192,0.4)",
            borderColor: "rgba(75,192,192,1)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(75,192,192,0.4)",
            hoverBorderColor: "rgba(75,192,192,1)",
			      data: chartData,
            cubicInterpolationMode: "monotone", // Enable smooth lines
            tension: 0.4,
          },
        ],
      },
      options: {
        scales: {
            y: {
                suggestedMin: 0,
                suggestedMax: maxValue,
            },
			
        },
		plugins: {
			legend: {
			  position: "bottom", // Set legend position to bottom
			},
		  },
      },
     
    });

    // Cleanup function to destroy the chart instance when the component unmounts
    return () => {
      myLineChart.destroy();
    };
  }, [dateLablels, chartData, maxValue]);

  return (
    <div 
		className="rounded-4 bg-white shadow-lg h-100 rounded-3 py-3"
		style={{
			paddingInline: "var(--nw-responsive-padding-lg)",
		}}
	>
      <div
	  	className="mb-2"
        style={{
          display: "flex",
          alignItems: "center",
		  height: "fit-content",
          justifyContent: "space-between",
        }}
      >
        <span className="nw-responsive-font-size ps-2">
			{t("dashboard.chartTitle")} 
			{isChartloading ? 
				<span 
				style={{ marginLeft: "10px" }} 
				className="spinner-border spinner-border-sm" 
				role="status" 
				aria-hidden="true"></span> : null
			}
		</span>
		<NwCustomDropdown
			dropdownToggleProps = {{
				iconSize: 10
			}}
			dropdownItems={[
				{
					eventKey: 'month',
					displayText: t("dashboard.filterItems.thisMonth"),
					onClick: handleThisMonthClick
				},
				{
					eventKey: 'year',
					displayText: t("dashboard.filterItems.thisYear"),
					onClick: handleThisYearClick
				},
			]}
			>
			{t("dashboard.filtre")}
		</NwCustomDropdown>
      </div>
      <canvas className="w-100" ref={chartRef}></canvas>
    </div>
  );
};

export default DashboardChart;
