import React, { useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { FiletypePng } from 'react-bootstrap-icons';
import { formatFileSize } from '../../../helpers/helpers';

const FileListItem = (props) => {

    return (
        <div className={`d-flex column-gap-2 file-list-item`}>
            <div className="file-list-item-icon d-flex align-items-center" style={{width: '10%'}}>
                <FiletypePng size={30}/>
            </div>
            <div className="file-list-item-name-size-progress d-flex flex-column row-gap-2" style={{width: '80%'}}>
                <div className="file-list-item-name-size d-flex justify-content-between align-items-center mw-100 h-50">
                    <div className="file-name text-truncate" style={{ maxWidth: '8vw' }}>
                        {props.filename ?? ''}
                    </div>
                    <div className="file-size">
                        {props.filesize ? formatFileSize(props.filesize, 1): ''}
                    </div>
                </div>
                <div className="file-list-item-progress d-flex align-items-center w-100 h-50">
                    <ProgressBar className='w-100 h-75' variant="primary" now={props.progress ? props.progress : 0}/>
                </div>
            </div>
            <div className="file-list-item-close-icon d-flex align-items-center justify-content-center" style={{width: '10%'}}>
                { props.uploadState?.ICON }
            </div>
        </div>
    );
}

export default FileListItem;