import { useEffect } from "react";
import { Form } from "react-bootstrap";
import Select from 'react-select';
import NwCustomSelect from "../_utilityComponents/NwFormsUi/CustomSelect/NwCustomSelect";

function MultipleCollectionType(props) {
    const isDisabled = props?.isDisabled || false;
    const formFieldJson = props.formFieldJson;
	const entityFormValuesJson = props.entityFormValuesJson;
	const updateEntityFormValues = props.updateEntityFormValues;
	const configValues = formFieldJson.configValues;
    const metadata = configValues?.values?.metadata;
    const translatedConfigValues = formFieldJson.translatedConfigValues.values;
    const translatedFormattedChoices = translatedConfigValues.choices;
    const multipleCollectionValueKey = `${configValues.frontUniqueKeys.select1}_${formFieldJson.id}`;
    const defaultChoices = configValues.values.default.split();
    if (defaultChoices[0] === '') defaultChoices.pop();
    const multipleCollectionValueJson = `${multipleCollectionValueKey}` in entityFormValuesJson
    ? entityFormValuesJson[`${multipleCollectionValueKey}`]
    : {
        name: formFieldJson.name,
        value : defaultChoices,
        type : 'multiple_collection',
        metadata: metadata,
    }
    const choices = configValues.values.parsedChoices;
    choices.concat(defaultChoices);
    const distinctChoices =[...new Set(choices)].map(choice => {
        return {value: choice, label: translatedFormattedChoices[`${choice}`]};
    });

    const updateMultipleCollectionValues = (newValues) => {
        updateEntityFormValues(prevEntityFormValues => {
            const newEntityFormValues = {...prevEntityFormValues};
            multipleCollectionValueJson.value = newValues.map(newValue => newValue.value);
            newEntityFormValues[`${multipleCollectionValueKey}`] = multipleCollectionValueJson;
            return newEntityFormValues;
        })
    }

    useEffect(() => {
        updateMultipleCollectionValues(multipleCollectionValueJson.value.map(value=> { return {value: value, label: value}}))
    }, [])

    return (
        <>
        <Form.Label>{formFieldJson.title}</Form.Label>
        <NwCustomSelect isDisabled={isDisabled} className="text-black" isMulti={true} onChange={(newValue) => updateMultipleCollectionValues(newValue)} options={distinctChoices} value={
            multipleCollectionValueJson.value.map(value => {return {value: value, label: translatedFormattedChoices[`${value}`]}})
        }/>
    </>
    )
}

export default MultipleCollectionType;