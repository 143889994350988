import { useEffect, useMemo, useState } from "react";
import {
	Col,
	Card,
	Button,
	Badge,
	Form,
	OverlayTrigger,
	Tooltip,
} from "react-bootstrap";
import "./OperationProductsCard.css";
import NwCustomSelect from "../_utilityComponents/NwFormsUi/CustomSelect/NwCustomSelect";
import NwCustomCheckbox from "../_utilityComponents/NwFormsUi/CustomCheckbox/NwCustomCheckbox";
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { isUrlNoImage } from "../../helpers/helpers";
import NwCustomModal from "../_utilityComponents/NwCustomModal/NwCustomModal";
import Preview from "../Posters/Preview";

const OperationProductsCard = (props) => {
	const { t } = useTranslation();
	const [isHover, setIsHover] = useState(false);
	const [ isAddedToCart, setAddedToCart ] = useState(false);
    const [displayPreview, setDisplayPreview] = useState(false);
	const [isProductNoImage, setIsProductNoImage] = useState(false);

	const handleMouseOver = () => {
		setIsHover(true);  // Set hover state to true when mouse is over the component
	};
	
	const handleMouseOut = () => {
		setIsHover(false);  // Set hover state to false when mouse leaves the component
	};

	const linkStyles = {
		textDecoration: 'none', // Remove underline
		color: 'black', // Set font color to black
	};

	const addToCart = () => {
		// TO REFACTOR ADD TO CART , TO USE STATE
	}

	useEffect(() => {
		if (props?.item?.defaultDisplayImage){
			setIsProductNoImage(isUrlNoImage(props?.item?.defaultDisplayImage))
		}
	}, [])

	// Memoize props for Preview to prevent unnecessary re-renders
	const previewProps = useMemo(() => ({
		posterId: props.item.id,
		formatId: props.selectedFormatValues?.value,
		orientationQuery: props.selectedOrientationValues?.value,
		showImageProduct: props.isChecked
	}), [props.item.id, props.selectedFormatValues, props.selectedOrientationValues, props.isChecked]);
  
    return (
		<Col key={props.item.id} className='list-product-item'>
			<Card className={`border-0 ${isHover ? 'onhover shadow-lg' : 'shadow'} position-relative list-product-item-card mb-0`} id={`product-card-${props.item.id}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
					<OverlayTrigger overlay={<Tooltip >Department</Tooltip>}>
						<Badge bg="success" className='list-product-item-department-badge shadow'>{props.item?.department}</Badge>
					</OverlayTrigger>
					<Card.Img 
						variant="top" 
						as="div" 
						className="list-product-item-img" 
						style={{
							backgroundImage: `url('${props.item.defaultDisplayImage}')`,
							backgroundSize: isProductNoImage ? '80%' : 'cover',
							backgroundColor: isProductNoImage ? 'var(--nw-no-image-background-color)' : ''
						}} 
					/>
					<Card.Body className='d-flex flex-column justify-content-between px-3 pt-2 pb-4' style={{height: '50%'}}>
						<Card.Title className='m-0 product-name'>
							<Link style={linkStyles} title={props.item?.name} to={``}>
								{props.item?.name}
							</Link>
						</Card.Title>
						<Form className="" onSubmit={(e) => props.handleSubmit(e, props.item.id)}>
							<div className="form-group d-none">
								<input
									type="hidden"
									name="customData"
									value="0"
									onChange={props.handleInputChange}
								/>
								<label>
									<input
									type="hidden"
									name="itemID"
									value={props.item.id}
									onChange={props.handleInputChange}
									/>
								</label>
							</div>
							<Form.Group className="mb-2 mb-xxl-3 ms-xxl-3 ms-2" controlId={`radioValue-${props.item.id}`}>
								<NwCustomCheckbox 
									initialIsCheck={props.isChecked}
									checkboxSize="nw-sm"
									variant="primary"
									labelClassName="text-black nw-responsive-font-size-md" label={ t('imagecheckboxLabel') }
									OnChange={props.handleRadioChange}
								/>
							</Form.Group>
							<Form.Group className="mb-2 mb-xxl-3" controlId={`format-select-${props.item.id}`}>
								<NwCustomSelect
									options={props.formatSelectData}
									secondary
									sm
									isClearable
									className="text-black"
									placeholder={t('formatDropdownLabel')}
									onChange={(selectedOption) => props.handleInputChange(selectedOption, props.item.id, { type: 'format'})}
									value={props.selectedFormatValues}
									uiOverrides={{
										border: {
											control: {
												borderRadius: {
													onMenuOpen: '0px',
													onMenuClose: '0px'
												},
												borderBottom: {
													onMenuOpen: '2px solid #CCCCCC',
													onMenuClose: '2px solid #CCCCCC'
												}
											}
										}
									}}
								/>
							</Form.Group>
							<Form.Group className="mb-2 mb-xxl-3" controlId={`orientation-select-${props.item.id}`}>
								<NwCustomSelect
									options={props.OrientationSelectDataFinal}
									secondary
									sm
									isClearable
									className="text-black"
									placeholder={t('orientationDropdownLabel')}
									onChange={(selectedOption) => props.handleInputChange(selectedOption, props.item.id, {type: 'orientation'})}
									value={props.selectedOrientationValues}
									uiOverrides={{
										border: {
											control: {
												borderRadius: {
													onMenuOpen: '0px',
													onMenuClose: '0px'
												},
												borderBottom: {
													onMenuOpen: '2px solid #CCCCCC',
													onMenuClose: '2px solid #CCCCCC'
												}
											}
										}
									}}
								/>
							</Form.Group>
							<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
								<button
									id={`addToCart-${props.item.id}`}
										className="btn add-to-cart-btn btn-primary rounded-1 py-1 nw-font-montserrat-semi-bold"
										type="submit"
										style={{ width: "95%" }}
								>
										{ t('addToCartButtonLabel') }
										{props.addToCartLoading[props.item.id] ? (
											<span
												style={{ marginLeft: "10px" }}
												className="spinner-border spinner-border-sm"
												role="status"
												aria-hidden="true"
											></span>
										) : null}
								</button>
							</div>
						</Form >
						<div className="card-btn-container d-flex w-100 justify-content-between column-gap-2">
							<Button 
								id="editButton"
								style={{
									backgroundColor: '#E6F0FF'			
								}}
								className="editButton card-btn btn text-primary py-1 w-50 nw-font-montserrat-semi-bold"
								onClick={() => {
									props.redirectToEditPage(
										props.editPosterData.endOn,
										props.editPosterData.operationId,
										props.editPosterData.startOn,
										props.item.id,
										props.selectedFormatValues,
										props.selectedOrientationValues
									);
								}}
							>
								{ t('editButtonLabel') }
							</Button>
                            <NwCustomModal
                                onShow={() => setDisplayPreview(true)}
                                onClose={() => setDisplayPreview(false)}
                                openDialogCustomCallback = {
                                    () => {
                                        return props.redirectToPreviewPage(
                                            props.item.id,
                                            props.selectedFormatValues,
                                            props.selectedOrientationValues
                                        );
                                    }
                                }
                                customButton={
                                    <Button
                                        id="previewButton"
                                        variant="outline-primary"
                                        className="previewButton w-50 card-btn py-1 nw-font-montserrat-semi-bold"
                                    >
                                        { t('previewButtonLabel') }
                                    </Button>
                                }
                                modalProps={{
									className: 'bg-transparent h-100',
									style: {
										backdropFilter: 'blur(15px)',
										width: '50%'
									},
                                    body: {
                                        className: 'd-flex bg-transparent'
                                    }
                                }}
                            >
                                <div className="w-100 h-100" >
									{ 
										displayPreview && (<Preview {...previewProps}/>)
									}                                    
                                </div>
                            </NwCustomModal>
						</div>
					</Card.Body>
			</Card>
		</Col>
    );
};

export default OperationProductsCard;
