import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import NwCustomInput from '../_utilityComponents/NwFormsUi/CustomInput/NwCustomInput';

const IntegerType = (props) => {
	const isDisabled = props?.isDisabled || false;
	const formFieldJson = props.formFieldJson;
	const entityFormValuesJson = props.entityFormValuesJson;
	const updateEntityFormValues = props.updateEntityFormValues;
	const configValues = formFieldJson.configValues;
	const metadata = configValues?.values?.metadata;
    const integerValueKey = `${configValues.frontUniqueKeys.number1}_${formFieldJson.id}`;
    const integerValueJson = `${integerValueKey}` in entityFormValuesJson 
	? entityFormValuesJson[`${integerValueKey}`]
	: {
		name: formFieldJson.name,
		type: 'integer',
		value: "",
		metadata: metadata,
	}

	const updateIntegerValue = (newIntegerValue) => {
		updateEntityFormValues(prevFieldValuesJson => {
			const newFieldValuesJson = {...prevFieldValuesJson};
            integerValueJson.value = parseInt(newIntegerValue);
            newFieldValuesJson[`${integerValueKey}`] = integerValueJson;
            return newFieldValuesJson;
		})
	}

	useEffect(() => {
		updateIntegerValue(integerValueJson.value);
	}, []);
	
	return (
		<div key={props.id} className={`mb-3 ${props.elementClasses}`}>
			<Form.Label>{props.formFieldJson.title}</Form.Label>
        	<NwCustomInput disabled={isDisabled} hideLabel onChange={(e) => updateIntegerValue(e.target.value)} placeholder={integerValueJson.value} value={integerValueJson.value} id={props.id} type="number" name={props.formFieldJson.name + "_" + props.formFieldJson.type}/>
		</div>
	);
}

export default IntegerType;