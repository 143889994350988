import React, { useEffect, useState, useRef } from "react";
import { Form, Row, Col, ListGroup, Tab, Button } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import { Input } from "react-select/animated";
import { productByEan, productLocalByCommercialName } from "../../../services/posters";
import { getProductLocalAssetsById, getProductLocaleBarcodeFromEan } from "../../../services/productLocal";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";

////// UTILITY COMPONENTS ////////
import NwCustomInput from "../../_utilityComponents/NwFormsUi/CustomInput/NwCustomInput";

const NameWithSearch = ({ getGencodeData, productName, handleProductNameInput, setEanValue, disabled = false }) => {
  const { t } = useTranslation();
  const [posts, setPosts] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalItemLeft, setTotalItemLeft] = useState(0);
  const [showResults, setShowResults] = useState(false);
  
  const handleSearchByName = async (e) => {
    if (productName) {
        setIsLoading(true);
        const res = await productLocalByCommercialName(productName, 1);
        setPosts(res.items);
        setTotalItems(res.totalItems);
        setIsLoading(false);
        setPageNumber(1);
        setShowResults(true);
    }
  };

  const fetchMoreData = async () => {
    if (productName) {
        const res = await productLocalByCommercialName(productName, pageNumber + 1);
        setPosts(posts.concat(res.items));
        setTotalItems(res.totalItems);
        setPageNumber(pageNumber + 1);
    }
  };

  useEffect(() => {
  }, [posts, totalItems, pageNumber]);

  const handleSelectedData = async (itemData) => {
    const dataValueObject = itemData;
    //Get Assets for Product Local
    const productAssetsData = await getProductLocalAssetsById(itemData.id);
    dataValueObject.productAssetsData = productAssetsData ?? null;
    const ean = itemData?.ean;
    setEanValue(ean ? ean : "");
    const productLocalBarcode = await getProductLocaleBarcodeFromEan(ean);
    dataValueObject.barcodeGenerated = productLocalBarcode;
    getGencodeData(dataValueObject);
    setShowResults(false);
  };

  return (
    <div className="ean-search">
      <div className="justify-content-between align-items-center">
        <NwCustomInput 
            isSearch
            label={ t('postersPage.newPosterPage.productName') }
            className="mb-3"
            // as="textarea"
            style={{ height: '10vh' }}
            controlId="productNameFormElem"
            placeholder={ t('postersPage.newPosterPage.productName') }
            value={productName}
            disabled={disabled}
            onChange={handleProductNameInput}
            buttonOnClick={handleSearchByName}
            buttonIsDisabled={!productName || disabled}
            isLoading={isLoading}
        />
      </div>

      <div className={"search-result" + (!showResults ? " d-none" : "")} style={{ marginTop: "10px", maxHeight: "500px", overflowY: "auto" }}>
        {posts && (
          <ListGroup>
            <InfiniteScroll 
              dataLength={totalItems} 
              next={fetchMoreData} 
              hasMore={true} 
            >
              {posts.map((item) => (
                  <Col key={item?.id}>
                    <ListGroup.Item>
                      <Badge bg="primary" pill>
                        {item?.ean}
                      </Badge>
                      <br />
                      <div className="d-flex justify-content-between align-items-center">
                        <div>{item?.name}</div>
                        <div>
                          <Button variant="secondary" size="sm" onClick={() => handleSelectedData(item)}>
                            Select
                          </Button>
                        </div>
                      </div>
                    </ListGroup.Item>
                  </Col>
              ))}
            </InfiniteScroll>
            <ListGroup.Item>{totalItems}</ListGroup.Item>
          </ListGroup>
        )}
      </div>
    </div>
  );
};

export default NameWithSearch;
