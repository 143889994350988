import React, { useMemo } from 'react';
import FileListItem from './FileListItem';

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    height: '40vh',
    justifyContent: 'center',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const StyledDropzone = (props) => {
    const style = useMemo(() => ({
            ...baseStyle,
            ...(props.isFocused ? focusedStyle : {}),
            ...(props.isDragAccept ? acceptStyle : {}),
            ...(props.isDragReject ? rejectStyle : {})
        }), [
            props.isFocused,
            props.isDragAccept,
            props.isDragReject
        ]
    );
    return (
        <section className="p-2">
            <div {...props.getRootProps({style})}>
                <input {...props.getInputProps()} />
                {
                    <div className='d-flex flex-column align-items-center'>
                        <span className='fs-4'>Drag And Drop Files</span>
                        <span className='text-primary'>Or Browse</span>
                    </div>       
                }
            </div>
            <div className='nw-dropzone-file-list d-flex flex-column row-gap-2 mt-3 w-100'>
                {props.fileUploads?.map((fileUpload, idx) => {
                    if (fileUpload?.uploadedInfo?.imageCdnUrl || fileUpload?.preview)
                    {
                        return (
                            <FileListItem 
                                key={idx} 
                                filename={fileUpload.file?.name} 
                                filesize={fileUpload.file?.size} 
                                progress={fileUpload.progress} 
                                uploadState={fileUpload.uploadedState}
                            />
                        )
                    }
                })}
            </div>
        </section>
    );
}

export default StyledDropzone;