import React, {useState, useEffect} from 'react';
import SidebarItem from '../components/Sidebar/SidebarItem';
import './SidebarFocusBlock.css';

const SidebarFocusBlock = ({
    title,
    icon,
    getBadgeValue,
    onItemClick,
    translationFunction,
    location,
    menuItems = [],
    collapsedSideBar
}) => {
    const [renderKey, setRenderKey] = useState(0);

    useEffect(() => {
        setRenderKey(prevKey => prevKey + 1); // Increment key to force re-render
    }, [collapsedSideBar]); // Dependency array ensures this runs only when collapsedSideBar changes

    const onFocusBlockItemClick = (data) => {
        if (onItemClick) {
            onItemClick({
                ...data,
                isFocusBlockItem: true
            })
        }
    }

    return (
        <div className='nw-sidebar-focus-block'>
            <div className={`nw-sidebar-header ${collapsedSideBar ? 'collapsed' : ''} d-flex justify-content-center align-items-center`}>
                {
                    collapsedSideBar ? (
                        <div key={renderKey} className="header-line"></div>
                    ) : (
                        <div key={renderKey}  className="header-title-wrapper">
                            <div className="header-title-icon-container">
                                {React.cloneElement(icon, { size: '100%', className: 'header-title-icon' })}
                            </div>
                            <span className="header-title text-uppercase">{title}</span>
                        </div>
                    )
                }
            </div>

            {/* HANDLE SIDEBAR MENU ITEMS */}
            {
                menuItems && menuItems.map((route, index) => {
                    if (route.isSidebarItem)
                    {
                        return (
                            <SidebarItem
                                key={index}
                                onItemClick={onFocusBlockItemClick}
                                collapsedSideBar={collapsedSideBar} 
                                itemIcon={React.cloneElement(route.icon, { size: '100%', className: `${route.icon.props.className ?? ''} sidebar-item-icon` })} 
                                itemTitle={translationFunction(route.translationKey)} 
                                linkTo={route.path}
                                menuItems={route?.subMenu}
                                badgeValue={getBadgeValue(route.path)}
                                currentLocation={location.pathname}
                                additionalData={route.additionalData}
                                isFocusBlockItem
                                useTrailIcon
                            />
                        )
                    }
                })
            }
        </div>
    );
}

export default SidebarFocusBlock;