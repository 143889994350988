import React from 'react'

const ShareBox = () => {
    return (
        <div>
            Share box
        </div>
    )

}

export default ShareBox;