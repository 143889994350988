import React, { useContext } from 'react';
import MainLayoutContext from '../../contexts/MainLayoutContext';
import { getRouteDataFromRouteId } from '../../helpers/helpers';
import { Link, useNavigate } from 'react-router-dom';
import { NwLockIcon } from '../../components/_utilityComponents/NwIcons/NwIcons';

const ModuleListItem = ({ routeId, title, icon, description, bgVariant = 'primary', isDisabled }) => {
	const { setFocusBlockData } = useContext(MainLayoutContext);
	const routeData = getRouteDataFromRouteId(routeId)
	const navigate = useNavigate();
	const handleListItemClick = () => {
		setFocusBlockData({
			title: routeData?.title,
			icon: routeData?.icon,
			menuItems: routeData?.subMenu,
			blockIdentifier: routeId
		})
	}

    return (
        <Link to={routeData?.path} onClick={handleListItemClick} className={`module-list-item-container d-flex justify-content-center align-items-center w-100 h-100 ${isDisabled ? 'is-disabled pe-none' : ''}`}>
            <div className={`module-list-item-wrapper bg-white position-relative rounded-4 ${ isDisabled ? 'shadow' : 'shadow-lg' }`}>
                
                { isDisabled && (
                    <NwLockIcon size={25} className='item-disabled-lock-icon position-absolute'/>
                ) }

                <div className="item-icon-container d-flex justify-content-center align-items-center">
                    <div className={`item-icon-wrapper ${bgVariant} rounded-circle d-flex justify-content-center align-items-center ps-1`}>
                        {icon && React.cloneElement(icon, { size: '70%', className: 'item-icon text-white' })}
                    </div>
                </div>
                <span className="item-title text-center">
                    {title}
                </span>
                <span className="item-description text-center">
                    {description}
                </span>
            </div>

        </Link>
    )
}

const ModuleList = ({ moduleItems = [] }) => {
    return (
        <div className="module-list h-100">
            {
                moduleItems?.map((moduleItem, index) => 
                    <ModuleListItem
                        key={index}
						routeId={moduleItem?.routeId}
                        title={moduleItem?.title}
                        icon={moduleItem?.icon}
                        description={moduleItem?.description}
                        isDisabled={moduleItem?.isDisabled}
						bgVariant={moduleItem?.bgVariant}
                    />
                )
            }
        </div>
    )
}

export default ModuleList;