import MainPoster from "../../components/Posters/MainPoster";
import "./Poster.css";


function MainPosterPage() {
 

  return (
    <>
      <MainPoster />
    </>
  );
}

export default MainPosterPage;
