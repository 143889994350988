import { getInitials } from "../../../helpers/helpers";
import { CircleFill } from "react-bootstrap-icons";
import './NwUserProfile.css'
const NwUserProfile = ({ username = "John Doe" }) => {
    return (
        <div className="nw-user-profile rounded-circle d-flex justify-content-center align-items-center fw-semibold text-white">
            {getInitials(username)}

            <div className="status-indicator rounded-circle bg-success border border-2 border-white"></div>
        </div>
    );
}

export default NwUserProfile;