import React, { useState, useContext, useEffect, useRef } from "react";
import MainLayoutContext from "../../contexts/MainLayoutContext";
import { getRouteDataFromRouteId } from "../../helpers/helpers";
import { Link, useNavigate } from "react-router-dom";
import { NwLockIcon } from "../../components/_utilityComponents/NwIcons/NwIcons";
import Chart from "chart.js/auto";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Image from "react-bootstrap/Image";
import { useTranslation } from "react-i18next";
import { getDashboardStats } from "../../services/dashboard";
import Table from "react-bootstrap/Table";
import {
  getAuthTokenWithRefresh,
  getBackendApiUrl,
  getEasyPlvApiToken,
} from "../../config";
import ListGroup from "react-bootstrap/ListGroup";
import { ArrowRight, ArrowClockwise, GraphUpArrow } from "react-bootstrap-icons";
import { Container, Row, Col } from 'react-bootstrap';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ProgressBar from 'react-bootstrap/ProgressBar';

const DashboardStats = ({}) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const[formatStats,setFormatStats] = useState();
  const[monthlyStats,setMonthlyStats] = useState();
  const[totalDownload,setTotalDownload] = useState();

  const apiKey = getEasyPlvApiToken();
  const { locale } = useContext(MainLayoutContext);

  async function getStats() {
    try {
      const authToken = await getAuthTokenWithRefresh();
      const response = await fetch(
        getBackendApiUrl("activities/print/stats?locale="+locale),
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "X-EasyPLV-Api-Token": apiKey,
          },
        }
      );
      if (response.status === 401) {
        return false;
      }
      const data = await response.json();
      setFormatStats(data?.formatStats);
      setMonthlyStats(data?.monthlyStats);
      setTotalDownload(data?.totalDownload?.row_count);
      // console.log(data);

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data: ", error);
      // Set loading state to false in case of an error
      setLoading(false);
    }
  }

  

  useEffect(() => {
    getStats();
    
  }, []);

  

  const handleThisMonthClick = () => {};

  const handleThisYearClick = () => {};

  const handleClick = () => {
    getStats();
  };

  const percentage = 64;

  return (
    <div className="rounded-4 shadow-lg h-100 bg-white p-3">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h6>
          {t("dashboard.keyFigures")}
          {loading ? (
            <span
              style={{ marginLeft: "10px" }}
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : null}
        </h6>
        <div className="dropdownButton d-none">
          <style>
            {`
    .dropdownButton .dropdown-toggle {
      color: #0d6efd; /* Set button text color to blue */
      background-color: transparent !important; /* Remove primary background color */
      border: none; /* Remove button border */
    }

    .dropdownButton .dropdown-toggle:focus {
      box-shadow: none; /* Remove focus box shadow */
    }
    `}
          </style>
          <DropdownButton
            id="dropdown-basic-button"
            title={t("dashboard.filtre")}
          >
            <Dropdown.Item href="#" onClick={handleThisMonthClick}>
              {t("dashboard.filterItems.thisMonth")}
            </Dropdown.Item>
            {/* {/* <Dropdown.Item href="#/action-2">Another action</Dropdown.Item> */}
            <Dropdown.Item href="#" onClick={handleThisYearClick}>
              {t("dashboard.filterItems.thisYear")}
            </Dropdown.Item>
          </DropdownButton>
        </div>
      </div>


      <Container>
      <Row>
      <Col md={6}>
      <div className="rounded-4 shadow-sm leftTopContent d-flex" style={{padding : '15px'}}>
        
        <div className="d-flex flex-column justify-content-center">
          <p className="mb-1">{t("dashboard.favCreation")}</p>
          <b><p className="mb-0">Affiches Opération</p></b>
        </div>
        <div style={{ width: 75, height: 75, marginLeft: 'auto' }}>
          <CircularProgressbar value={percentage} text={`${percentage}%`} />
        </div>
      </div>
        
        <div className="rounded-4 shadow-sm leftBottomContent" style={{ marginTop: '15px', Height:'15px' }}>
        <Container>
        <div style={{ float: 'right' }}>
          <button onClick={handleClick} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
            <ArrowClockwise />
          </button>
        </div>

          <p><GraphUpArrow></GraphUpArrow> {t("dashboard.mostUserdPosters")}</p>
          <h4>{formatStats?.formatName} - {formatStats?.usageCount} {t("posters")}</h4>
          </Container>
        </div>
        </Col>
        <Col md={6} className="rounded-4 shadow-sm">
          <div className="rightContent">

            <h4>{totalDownload} {t("dashboard.downloads")}</h4>
           
            {monthlyStats?.map((month, index) => (
        <div key={index} className="monthlyData">
            <p style={{ marginBottom: '5px' }}>{month?.month}</p>
           <ProgressBar now={month?.data?.row_count ?? 0} style={{marginBottom: '15px'}}/>
        </div>
      ))}
            
            </div>
        </Col>
      </Row>
    </Container>

    </div>
  );
};

export default DashboardStats;
