import { Button, FormLabel } from 'react-bootstrap';
import { useEffect } from "react";
import { XLg, Plus } from 'react-bootstrap-icons';
import NwCustomSelect from "../_utilityComponents/NwFormsUi/CustomSelect/NwCustomSelect";
import NwCustomInput from "../_utilityComponents/NwFormsUi/CustomInput/NwCustomInput";

function EnergeticType(props) {
    const isDisabled = props?.isDisabled || false;
    const formFieldJson = props.formFieldJson;
	const entityFormValuesJson = props.entityFormValuesJson;
	const updateEntityFormValues = props.updateEntityFormValues;
	const configValues = formFieldJson.configValues;
    const translatedConfigValues = formFieldJson.translatedConfigValues.values;
    const translatedFormattedChoices = translatedConfigValues.choices;
    const energeticValueKey = `${configValues.frontUniqueKeys.energeticselect1}_${formFieldJson.id}`;
    const choices = configValues.values.parsedChoices.map(choice => { return {value: choice, label: translatedFormattedChoices[`${choice}`]}});
    const metadata = configValues?.values?.metadata;
    const energeticValueJson = `${energeticValueKey}` in entityFormValuesJson
    ? entityFormValuesJson[`${energeticValueKey}`]
    : {
        name: formFieldJson.name,
        value : [],
        type : 'energetic_value',
        metadata: metadata
    }

    const addNewEmptySingleEnergeticField = () => {
        const newFieldValuesJson = {...entityFormValuesJson};
        energeticValueJson.value.push(['', '']);
        newFieldValuesJson[`${energeticValueKey}`] = energeticValueJson;
        updateEntityFormValues(newFieldValuesJson);
    }

    const removeSingleEnergeticField = (fieldIndex) => {
        const newFieldValuesJson = {...entityFormValuesJson};
        energeticValueJson.value.splice(fieldIndex, 1);
        newFieldValuesJson[`${energeticValueKey}`] = energeticValueJson;
        updateEntityFormValues(newFieldValuesJson);
    }

    const updateEnergeticTextValue = (value, index) => {
        updateEntityFormValues(prevFieldValuesJson => {
            const newFieldValuesJson = {...prevFieldValuesJson};
            energeticValueJson.value[index][1] = value;
            newFieldValuesJson[`${energeticValueKey}`] = energeticValueJson;
            return newFieldValuesJson;
        })
    }  
    const updateEnergeticChoiceValue = (newValue, index) => {
        updateEntityFormValues(prevFieldValuesJson => {
            const newFieldValuesJson = {...prevFieldValuesJson};
            energeticValueJson.value[index][0] = newValue.value;
            newFieldValuesJson[`${energeticValueKey}`] = energeticValueJson;
            return newFieldValuesJson;
        })
    }

    useEffect(() => {
        updateEntityFormValues(prevFieldValuesJson => {
            const newFieldValuesJson = {...prevFieldValuesJson};
            newFieldValuesJson[`${energeticValueKey}`] = energeticValueJson;
            return newFieldValuesJson;
        })
    }, []);

    return (
        <div>
            <FormLabel>{formFieldJson.title}</FormLabel>
            <div className="energetic-fields-container d-flex flex-column row-gap-3">
                {
                    energeticValueJson.value.map((energeticSingleValue, index) => {
                        return <div className="d-flex flex-column w-100 row-gap-1" key={index}>
                            <div className='d-flex flex-column w-100 gap-1'>
                                <NwCustomSelect isDisabled={isDisabled} className='text-black' value={{label: translatedFormattedChoices[`${energeticSingleValue[0]}`], value: energeticSingleValue[0]}} onChange={(newValue) => updateEnergeticChoiceValue(newValue, index)} options={choices}/>
                                <NwCustomInput hideLabel disabled={isDisabled} onChange={(e) => updateEnergeticTextValue(e.target.value, index)} placeholder={energeticSingleValue[1]} value={energeticSingleValue[1]} type="text" />
                            </div>
                            <div className="d-flex w-100 justify-content-center align-items-center">
                                <Button className="rounded-0 w-100 p-2 d-flex justify-content-center align-items-center" disabled={isDisabled} onClick={() => removeSingleEnergeticField(index)} variant={'danger'}><XLg size={15}/></Button>
                            </div>
                        </div>
                    })
                }
            </div>
            <Button className={`mt-2 rounded-0 w-100`} disabled={isDisabled} onClick={addNewEmptySingleEnergeticField} variant={'primary'}><Plus size={24}/></Button>
        </div>
    )
}

export default EnergeticType;