import { getBackendApiUrl, getEasyPlvApiToken, getAuthToken, getAuthTokenWithRefresh } from "../config";
import { checkApiResponse } from "./auth";

// const getEasyPlvApiToken() = getEasyPlvApiToken();

export const getProductLocal = async (currentPage) => {
  console.log(currentPage);
  if (currentPage === "NaN") {
    return false;
  }
  const res = await fetch(getBackendApiUrl(`product_local/byStoreAndFilters?options[page]=${currentPage}`), {
    method: "GET",
    headers: {
      Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
      "X-EasyPLV-Api-Token": getEasyPlvApiToken(),
    },
  });
  checkApiResponse(res);
  const data = await res.json();
  console.log(data);
  return data;
};

export const getProductLocalFiltered = async (page = "?options[page]=1", filter) => {
  let apiUrl = getBackendApiUrl(`product_local/byStoreAndFilters${page}`);

  if (filter) {
    apiUrl = getBackendApiUrl(`product_local/byStoreAndFilters${page}${filter}`);
  }
  console.log(apiUrl);
  // console.log(filter);
  const res = await fetch(apiUrl, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
      "X-EasyPLV-Api-Token": getEasyPlvApiToken(),
    },
  });
  checkApiResponse(res);
  const data = await res.json();
  console.log(data);
  return data;
};


export const getProductLocalById = async(productLocalId) => {
 
  const res = await fetch(getBackendApiUrl(`product_local/byId/${productLocalId}`), {
    method: "GET",
    headers: {
      Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
      "X-EasyPLV-Api-Token": getEasyPlvApiToken(),
    },
  });
  checkApiResponse(res);
  const data = await res.json();
  console.log(data);
  return data;

}
export const getProductLocalAssetsById = async(productLocalId) => {
 
  const res = await fetch(getBackendApiUrl(`product_local_assets/byId/${productLocalId}`), {
    method: "GET",
    headers: {
      Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
      "X-EasyPLV-Api-Token": getEasyPlvApiToken(),
    },
  });
  checkApiResponse(res);
  const data = await res.json();
  // console.log(data);
  return data;

}

export const getProductLocaleBarcodeFromEan = async(eanValue) => {
  let barCodeData = null;

  const response = await fetch(getBackendApiUrl(`poster/getBarcodeByEan/${eanValue}`), {
    method: "GET",
    headers: {
      Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
      "X-EasyPLV-Api-Token": getEasyPlvApiToken(),
    },
  })
  checkApiResponse(response);
  if (response.status === 200){
    barCodeData = await response.json();
  }

  return barCodeData;
}

export const editProductLocal = async(productLocalObject) => {
 
  const productLocalToUpdateData = new FormData();
  Object.keys(productLocalObject).forEach(key => {
    productLocalToUpdateData.append(key, productLocalObject[`${key}`]);
  });
  productLocalToUpdateData.append('action', 'EDIT');
  const res = await fetch(getBackendApiUrl(`product_local/createOrEdit`), {
    method: "POST",
    headers: {
      Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
      "X-EasyPLV-Api-Token": getEasyPlvApiToken(),
    },
    body: productLocalToUpdateData
  });
  checkApiResponse(res);
  const data = await res.json();
  console.log(data);
  return data;

}

export const newProductLocal = async(productLocalObject) => {
  // must be in form data because of image
  const productLocalToSaveData = new FormData();
  Object.keys(productLocalObject).forEach(key => {
    productLocalToSaveData.append(key, productLocalObject[`${key}`]);
  });
  productLocalToSaveData.append('action', 'CREATE');
  const res = await fetch(getBackendApiUrl(`product_local/createOrEdit`), {
    method: "POST",
    headers: {
      Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
      "X-EasyPLV-Api-Token": getEasyPlvApiToken(),
    },
    body: productLocalToSaveData
  });
  checkApiResponse(res);
  const data = await res.json();
  console.log(data);
  return data;

}

export const deleteProductLocal = async(productLocalId) => {

  const res = await fetch(getBackendApiUrl(`product_local/${productLocalId}/deleteUserProduct`), {
    method: "POST",
    headers: {
      Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
      "X-EasyPLV-Api-Token": getEasyPlvApiToken(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify([])
  });
  checkApiResponse(res);
  const data = await res.json();
  console.log(data);
  return data;

}

export const deleteProductLocalProductImage = async (productLocalId) => {
  if (!productLocalId) return;
  const deleteProductLocalProductImagePromise = await fetch(getBackendApiUrl(`product_local/${productLocalId}/deleteProductImage`), {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
      "X-EasyPLV-Api-Token": getEasyPlvApiToken(),
      "Content-Type": "application/json",
    },
  });
  checkApiResponse(deleteProductLocalProductImagePromise);

  return deleteProductLocalProductImagePromise;
}
