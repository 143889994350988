import { getAuthTokenWithRefresh, getBackendApiUrl, getEasyPlvApiToken } from "../config";
import { checkApiResponse } from "./auth";

export const fetchEktatekMediaData = async (forceDisplayedCategories = [], currentCategoriesId=0, page=1, itemsPerPage=20, $searchQuery = "")  => {
    const authToken = await getAuthTokenWithRefresh();
	const apiKey = getEasyPlvApiToken();
    forceDisplayedCategories = forceDisplayedCategories.join(',');
    page = parseInt(page);
    itemsPerPage = parseInt(itemsPerPage);
    const ektatekUrl = getBackendApiUrl(`ektatek_media/byStoreAndFilters?filters[forceDisplayedCategories]=${forceDisplayedCategories}&filters[current_categories_id]=${currentCategoriesId}&filters[searchQuery]=${$searchQuery}&options[page]=${page}&options['itemsPerPage]=${itemsPerPage}`);
    const fetchEktatekMediaDataPromise = await fetch(ektatekUrl, {
        method: "GET",
        headers: {
            Authorization: `Bearer ${authToken}`,
            "X-EasyPLV-Api-Token": apiKey,
            "Content-Type": "application/json",
        }
    });

    checkApiResponse(fetchEktatekMediaDataPromise);
    if (fetchEktatekMediaDataPromise.status === 500) return {};
    if (fetchEktatekMediaDataPromise.status === 200) return await fetchEktatekMediaDataPromise.json();
}