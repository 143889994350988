import React, {useEffect} from 'react';
import { Form } from 'react-bootstrap';

const BooleanType = (props) => {
	const isDisabled = props?.isDisabled || false
	const formFieldJson = props.formFieldJson;
	const entityFormValuesJson = props.entityFormValuesJson;
	const updateEntityFormValues = props.updateEntityFormValues;
	const configValues = formFieldJson.configValues;
	const metadata = configValues?.values?.metadata;
	const booleanValueKey = `${configValues.frontUniqueKeys.checkbox1}_${formFieldJson.id}`;
	const booleanValueJson = `${booleanValueKey}` in entityFormValuesJson 
	? entityFormValuesJson[`${booleanValueKey}`] 
	: {
		name : formFieldJson.name,
		type : 'boolean',
		value : false,
		metadata: metadata,
	};

	useEffect(() => {
        updateBooleanFieldValues(booleanValueJson.value);
    }, []);

	const updateBooleanFieldValues = (value) => {
		updateEntityFormValues(prevFieldValuesJson => {
			const newFieldValuesJson = {...prevFieldValuesJson};
			booleanValueJson.value = value
			newFieldValuesJson[`${booleanValueKey}`] = booleanValueJson;
			return newFieldValuesJson;
		});
	} 

	return (
		<div className='my-3' key={formFieldJson.id}>
			<Form.Check // prettier-ignore
				type="switch"
				disabled={isDisabled}
				id={formFieldJson.id}
				name={formFieldJson.name + "_" + formFieldJson.type}
				label={formFieldJson.title}
				onChange={(e) => updateBooleanFieldValues(e.target.checked)}
				checked={booleanValueJson.value}
			/>
		</div>
	);
}

export default BooleanType;
