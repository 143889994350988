import qz from 'qz-tray';
import { getAuthTokenWithRefresh, getBackendApiUrl } from '../config';
import { getEasyPlvApiToken, getBackendUrl } from '../config';
import { checkApiResponse } from './auth';
import { getStoreDataFromLocalStorage } from './store';

export const printPdfUsingPrinter = async (pdfUrl, printerName, quantity, failMessage = "", startMessage="") => {
    const response = await fetch(getBackendUrl('assets/serve/pdf') + '?cloudfrontUrl=' + pdfUrl, {});
    const buffer = (await response.arrayBuffer());
    const b64 = window.btoa([].slice.call(new Uint8Array(buffer)).map(function(bin) { return String.fromCharCode(bin) }).join(""));
    if (qz.websocket.isActive()){
        var config = qz.configs.create(printerName);
        var data = [{ 
            type: 'pixel',
            format: 'pdf',
            flavor: 'base64',
            data: b64,
        }];
        for (let i = 0; i < quantity; i++) {
            qz.print(config, data).then(response => {
                recordPrintActivity();
            }).catch(e => {
                console.error(e)
            })
        }
    }
} 

export const qzAuthenticationSetup = async () => {
    const authToken = await getAuthTokenWithRefresh();
    const apiKey = getEasyPlvApiToken();
    try {
        qz.security.setCertificatePromise(function(resolve, reject) {
            fetch(getBackendApiUrl('printers/qzTrayDigitalCertificate'), 
                {
                    cache: 'no-store', 
                    headers: {
                        'Content-Type': 'text/plain', 
                        'Authorization' : `Bearer ${authToken}`,
                        'X-EasyPLV-Api-Token' : apiKey
                    }
                }
            ).catch(e=>{}).then(function(data) {data.ok ? resolve(data.text()) : reject(data.text()); })
        });
    } catch (e) {}
    try {qz.security.setSignatureAlgorithm("SHA1"); } catch(e){}// Since 2.1
    try {
        qz.security.setSignaturePromise(function(toSign) {
            return function(resolve, reject) {
                fetch(getBackendApiUrl(`printers/qzTraySignature?request=${toSign}`), 
                    {
                        cache: 'no-store', 
                        headers: {
                            'Content-Type': 'text/plain',
                            'Authorization' : `Bearer ${authToken}`,
                            'X-EasyPLV-Api-Token' : apiKey
                        }
                    }
                ).catch(e=>{}).then(function(data) {data.ok ? resolve(data.text()) : reject(data.text()); })
            };
        });
    } catch (e){};
}


export const authenticateAndConnect = async () => {
    await connectToQzTrayPrintServer();
    await qzAuthenticationSetup();
}

export const connectToQzTrayPrintServer = async () => {
    try {
        let printServerIp = 'localhost';
        try {
            const storeData = getStoreDataFromLocalStorage();
            printServerIp = storeData.printServerIp;
        } catch (e) {}
        if (printServerIp === undefined || printServerIp === null || printServerIp === "") printServerIp = 'localhost';
        if (!qz.websocket.isActive()) {
            await qz.websocket.connect({host: printServerIp, usingSecure: true}).catch(e => {});
        }
    } catch (e){};
}

export const getUserStorePrintServerIp  = async () => {

}

export const recordPrintActivity = async () => {
    const authToken = await getAuthTokenWithRefresh();
    const apiKey = getEasyPlvApiToken();
    const payload = {
        title: "print_pdf",
        type: "print",
        relatedEntity : "",
        entityId: null,
    };

    const recordPrintActivityPromise = await fetch(getBackendApiUrl('activities/log'), {
        method : "POST",
        headers : {
            'Content-Type' : 'application/json',
            'Authorization' : `Bearer ${authToken}`,
            'X-EasyPLV-Api-Token' : apiKey
        },
        body : JSON.stringify(payload)
    });

    checkApiResponse(recordPrintActivityPromise);

    return;
}

export const findAllAvailablePrinters = async () => {
    try {
        if (!qz.websocket.isActive()) {
            const printersPromise = new Promise((resolve, reject) => {
                setTimeout(async () => {
                    await connectToQzTrayPrintServer();
                    if (!qz.websocket.isActive()) {
                        resolve([]);
                    }
                    const printers = await qz.printers.find().catch(e => {});
                    if (!printers) resolve([]);
                    resolve(printers);
                }, 10000);
            });

            return await printersPromise
        } else {
            const printers = await qz.printers.find();
            if (!printers) return [];
            return printers;
        }
    } catch (e) {};
}

export const getAllFormatsForPrintersCrud = async () => {
    try {
        const authToken = await getAuthTokenWithRefresh();
        const apiKey = getEasyPlvApiToken();
        const formatPromise = await fetch(getBackendApiUrl('formats/printersCrud'), {
            method: "GET",
            headers : {
                'Content-Type' : 'application/json',
                'Authorization' : `Bearer ${authToken}`,
                'X-EasyPLV-Api-Token' : apiKey
            }
        }).catch (e => {});

        checkApiResponse(formatPromise);
        if (formatPromise.status === 500) return [];
        return await formatPromise.json();
    } catch (e) {return []}
}

export const savePrinterFromQz = async (printer) => {
    try {
        if (!printer) return;
        const body = {
            "name" : printer.printerName,
            "outputFormat" : printer.selectedCompanyFormats.map(format => format.formatId),
            "sourceFormat" : printer.selectedSourceFormats.map(format => format.formatId),
            "enable" : printer.enable,
            "displayName" : printer.displayName
        }
        const authToken = await getAuthTokenWithRefresh();
        const apiKey = getEasyPlvApiToken();
        const saveNewPrinterPromise = await fetch(getBackendApiUrl('printers/addfromstore'), {
            method : "POST",
            headers : {
                'Content-Type' : 'application/json',
                'Authorization' : `Bearer ${authToken}`,
                'X-EasyPLV-Api-Token' : apiKey
            },
            body : JSON.stringify(body)
        })
        checkApiResponse(saveNewPrinterPromise);
        if (saveNewPrinterPromise.status === 500) return;
        return await saveNewPrinterPromise.json(); 
    } catch (e) {};
}

export const updateConfiguredPrinter = async (printer) => {
    try {
        if (!printer) return;
        const body = {
            "name" : printer.printerName,
            "outputFormat" : printer.selectedCompanyFormats.map(format => format.formatId),
            "sourceFormat" : printer.selectedSourceFormats.map(format => format.formatId),
            "enable" : printer.enable,
            "displayName" : printer.displayName
        }
        const authToken = await getAuthTokenWithRefresh();
        const apiKey = getEasyPlvApiToken();
        const updateConfiguredPrinterPromise = await fetch(getBackendApiUrl(`printers/${printer.printerId}`), {
            method : "PUT",
            headers : {
                'Content-Type' : 'application/json',
                'Authorization' : `Bearer ${authToken}`,
                'X-EasyPLV-Api-Token' : apiKey
            },
            body : JSON.stringify(body)
        })
        checkApiResponse(updateConfiguredPrinterPromise);
        if (updateConfiguredPrinterPromise.status === 500) return;
        return await updateConfiguredPrinterPromise.json(); 
    } catch (e) {};
}

export const getConfiguredPrinters = async () => {
    try {
        const authToken = await getAuthTokenWithRefresh();
        const apiKey = getEasyPlvApiToken();
        const configuredPrintersPromise = await fetch(getBackendApiUrl('printers/byUser'), {
            method: "GET",
            headers : {
                'Content-Type' : 'application/json',
                'Authorization' : `Bearer ${authToken}`,
                'X-EasyPLV-Api-Token' : apiKey
            },
        });
        checkApiResponse(configuredPrintersPromise);
        if (configuredPrintersPromise.status === 500) return [];
        return await configuredPrintersPromise.json();
    } catch (e) {return [];}
}

export const deletePrinterById = async (printerId) => {
    try {   
        const authToken = await getAuthTokenWithRefresh();
        const apiKey = getEasyPlvApiToken();
        const deletePrinterPromise = await fetch(getBackendApiUrl(`printers/${printerId}`), {
            method : "DELETE",
            headers: {
                'Content-Type' : 'application/json',
                'Authorization' : `Bearer ${authToken}`,
                'X-EasyPLV-Api-Token' : apiKey
            }
        });

        checkApiResponse(deletePrinterPromise);
        return;
    } catch (e) {return;}
}

export const updateStorePrintServerIp = async (ip) => {
    try {
        const authToken = await getAuthTokenWithRefresh();
        const apiKey = getEasyPlvApiToken();
        const storeData = getStoreDataFromLocalStorage();
        const storeId = storeData.id;
        if (!storeId) return;
        const updateStorePrinterServerIpPromise = await fetch(getBackendApiUrl(`stores/${storeId}/printServerIp`), {
            method: "PUT",
            headers: {
                'Content-Type' : 'application/json',
                'Authorization' : `Bearer ${authToken}`,
                'X-EasyPLV-Api-Token' : apiKey
            },
            body: JSON.stringify({'printServerIp' : ip})
        }).catch(e => {});

        storeData.printServerIp = ip;
        localStorage.setItem('store_details', JSON.stringify(storeData));
        checkApiResponse(updateStorePrinterServerIpPromise);
        if (updateStorePrinterServerIpPromise.status === 500) return false;
        return await updateStorePrinterServerIpPromise.json();
    } catch (e) {}
}


