import React, { createContext, useContext, useState, useEffect } from 'react';
import {
    loginUser,
    logoutUser,
    fetchUserData,
    saveUserDataInLocalStorage,
    ApiResponseStatus,
    UpdateUserFrontSession
} from '../services/auth';
import { fetchUserStoreData } from '../services/store';

// ----------------------------- OTHER AUTHENTICATION DEFINITIONS ---------------------------------------
// ---------------------- ACCESS RIGHTS -----------------------------------
/**
 * @typedef {Object} AccessRights
 * @property {Modules} MODULES - An object representing the modules and their associated access rights.
 */

/**
 * @typedef {Object} Modules
 * @property {Module} modulePlv - Access rights for the PLV module.
 * @property {Module} moduleSocialMedia - Access rights for the Social Media module.
 * // Add additional modules as needed.
 */

/**
 * @typedef {Object} Module
 * @property {number} access - Access level for the module (1 for access, 0 for no access).
 * @property {SubRights[]} subRights - An array of sub-rights for the module.
 */

/**
 * @typedef {Object} SubRights
 * @property {string} key - The key representing the sub-right (e.g., 'cart', 'print').
 * @property {number} access - Access level for the sub-right (1 for access, 0 for no access).
 * @property {SubRights[]} [subRights] - An optional array of nested sub-rights.
 */

// ---------------------- SUBSCRIPTION DATA -----------------------------------
/**
 * @typedef {Object} SubscriptionData - Subscription Data given from user object
 * @property {string} subscriptionName - Subscription Name
 * @property {string} subscriptionCode - Subscription Code
 * @property {number} validityUser - Whether subscription is valid or not
 * @property {AccountUser} accountUser - Account data
 * @property {string} dateStartUser - When the subscription started
 * @property {string} dateEndUser - When the subscription is going to end or has ended
 * @property {SubscriptionRights} subscriptionRights - rights associated with user's subscription
 * 
 */

/**
 * @typedef AccountUser
 * @property {string} mode - Mode of User account
 */

/**
 * @typedef SubscriptionRights - Right Asccociated with subscription data
 * @property {boolean} allowPrint - whether we should allow print or not
 */
// --------------------------------------------------------------------------------------------------------

/**
 * @typedef {Object} AuthContextValue
 * @property {boolean|null} isAuthenticated - Indicates whether the user is authenticated. `null` when authentication status is being determined.
 * @property {string|null} username - The username of the authenticated user.
 * @property {Object|null} userNw - The network information of the authenticated user.
 * @property {AccessRights|null} userRights - The access rights of the authenticated user.
 * @property {SubscriptionData|null} subscriptionData - The subscription data of the authenticated user.
 * @property {Function} Authenticate - Function to authenticate a user with a JWT token.
 * @property {Function} handleLogin - Function to handle user login with email and password.
 * @property {Function} logoutUser - Function to handle user logout.
 * @property {Function} UpdateUserFrontSession - Function to update the user's front-end session status.
 * @property {boolean} isImpersonator - Indicates if the current user is impersonating another user.
 * @property {Function} setIsImpersonator - Function to set the impersonation status.
 */

/**
 * Authentication Context providing user authentication state and related actions.
 * @type {React.Context}
 */
export const AuthContext = createContext();

/**
 * Provides authentication context to its children components.
 *
 * @param {Object} props - Properties passed to the provider.
 * @param {React.ReactNode} props.children - Child components that consume the authentication context.
 * @returns {JSX.Element} Authentication context provider.
 */
export const AuthProvider = ({ children }) => {
    /**
     * Indicates whether the user is authenticated.
     * @type {[boolean|null, Function]}
     */
    const [isAuthenticated, setIsAuthenticated] = useState(null);

    /**
     * Stores the access rights of the authenticated user.
     * @type {[AccessRights|null, Function]}
     */
    const [userRights, setUserRights] = useState(null);

    /**
     * Stores the subscription data of the authenticated user.
     * @type {[SubscriptionData|null, Function]}
     */
    const [subscriptionData, setUserSubscriptionData] = useState(null);

    /**
     * Stores the network information of the authenticated user.
     * @type {[Object|null, Function]}
     */
    const [userNw, setUserNw] = useState(null);

    /**
     * Stores the username of the authenticated user.
     * @type {[string|null, Function]}
     */
    const [username, setUsername] = useState(null);

    /**
     * Indicates whether the current user is impersonating another user.
     * @type {[boolean, Function]}
     */
    const [isImpersonator, setIsImpersonator] = useState(false);

    /**
     * Handles API response events, specifically unauthorized responses.
     *
     * @param {CustomEvent} event - The custom event triggered by API responses.
     */
    const handleApiResponseEvent = (event) => {
        const eventData = event.detail.message;

        if (eventData === ApiResponseStatus.UNAUTHORISED) {
            handleLogout();
        }
    };

    /**
     * Fetches and sets user authentication data on component mount.
     */
    useEffect(() => {
        const fetchData = async () => {
            const token = localStorage.getItem('auth_token');
            const loggedInUsername = localStorage.getItem('plv_username');

            if (token) {
                setIsAuthenticated(true);
                setUsername(loggedInUsername);

                const tokenData = await fetchUserData(token, false);
                setUserNw(tokenData.userData);
                setUserRights(tokenData?.userData?.accessRights);
                setUserSubscriptionData(tokenData?.userData?.subscriptionData);
            } else {
                setIsAuthenticated(false);
            }
        };

        // Add the event listener for API response events
        window.addEventListener('apiResponseEvent', handleApiResponseEvent);

        fetchData();

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('apiResponseEvent', handleApiResponseEvent);
        };
    }, []);

    /**
     * Handles user login by authenticating with provided credentials.
     *
     * @param {Object} userData - User credentials for login.
     * @param {string} userData.email - User's email address.
     * @param {string} userData.password - User's password.
     * @returns {Promise<Object|null>} The result of the login attempt.
     */
    const handleLogin = async (userData) => {
        const result = await loginUser(userData);
        if (result) {
            setIsAuthenticated(true);
            setUsername(userData.email);
        }

        return result;
    };

    /**
     * Handles user logout by clearing authentication data and updating session.
     */
    const handleLogout = () => {
        const dataSession = {
            action: 'logout',
        };
        UpdateUserFrontSession(dataSession);
        logoutUser();
        setIsAuthenticated(false);
        setUsername(null);
        setUserNw(null);
        setIsImpersonator(false);
    };

    /**
     * Authenticates user using a provided JWT token and fetches user data.
     *
     * @param {string} jwtToken - JSON Web Token for authentication.
     * @returns {Promise<boolean>} Indicates whether authentication was successful.
     */
    const Authenticate = async (jwtToken) => {
        const data = await fetchUserData(jwtToken);
        const userStoreData = await fetchUserStoreData(jwtToken);

        if (!data.isError) {
            const isDataSaved = await saveUserDataInLocalStorage(
                data.jwtToken,
                data.userData,
                userStoreData
            );

            if (isDataSaved) {
                setIsAuthenticated(true);
                setUsername(data?.userData?.firstName);
                setUserNw(data?.userData);
                setUserRights(data?.userData?.accessRights);
                setUserSubscriptionData(data?.userData?.subscriptionData);

                const dataSession = {
                    action: 'login',
                    impersonateSession: localStorage.getItem('impersonateSession'),
                };
                UpdateUserFrontSession(dataSession);
                return true;
            } else {
                setIsAuthenticated(false);
                setUsername(null);
                setUserNw(null);
                return false;
            }
        } else {
            return false;
        }
    };

    return (
        <AuthContext.Provider
            value={{
                isAuthenticated,
                username,
                userNw,
                userRights,
                Authenticate,
                handleLogin,
                logoutUser: handleLogout,
                UpdateUserFrontSession,
                isImpersonator,
                setIsImpersonator,
                subscriptionData
            }}
        >
            {isAuthenticated !== null && children}
        </AuthContext.Provider>
    );
};

/**
 * Custom hook to access authentication context.
 *
 * @returns {AuthContextValue} Authentication context values and actions.
 */
export const useAuth = () => {
    return useContext(AuthContext);
};
