import Preview from "../../components/Posters/Preview";
import './Poster.css'
import { useLocation } from "react-router-dom";


function PosterPreview () {  
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const posterId = queryParams.get("poster_id");
    const formatId = queryParams.get("format");
    const orientationQuery = queryParams.get("orientation");
	let showImageProductQuery = queryParams.get("showImageProduct");

    return (
        <Preview posterId={posterId} formatId={formatId} orientationQuery={orientationQuery} showImageProduct={showImageProductQuery}/>
    )
}

export default PosterPreview;