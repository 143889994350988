import { getAuthTokenWithRefresh, getBackendApiUrl, getEasyPlvApiToken } from "../config";
import { checkApiResponse } from "./auth";

export const addProductToCart = async (cartData, posterType="poster") => {
	const authToken = await getAuthTokenWithRefresh();
	const apiKey = getEasyPlvApiToken();
	let updatedCartData = null
  cartData.posterType = posterType;
	try {
		const loginPromiseReponse = fetch(getBackendApiUrl("cart/addToCart"), {
			method: "PUT",
			headers: {
				Authorization: `Bearer ${authToken}`,
				"X-EasyPLV-Api-Token": apiKey,
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ token: authToken, cart: cartData }),
		})
		.then(response => {
			// Check if the request was successful
			if (response.status === 200) {
				return response.json();  // Parse the JSON of the response
			} else {
				throw response;  // If the response status is not 200, we throw it to the catch block
			}
		})
		.then(data => {
			console.log(data);  // Handle your successful response here
			updatedCartData = data.updatedCartData;
			setUpdatedCartItemsAndCount(data.updatedCartData);
		})
		.catch(response => {
			// Handle your errors here
			checkApiResponse(response);
		});

		// toast.promise(loginPromiseReponse, {
		// 	loading: 'Loading',
		// 	success: 'Added to cart!',
		// 	error: 'Error Adding to cart!',
		// });
	} catch (error) {
		console.error(error);
	}
  	return updatedCartData;
};


export const getCart = async () => {
  const authToken = await getAuthTokenWithRefresh();
  const apiKey = getEasyPlvApiToken();

  try {
    const cartDataPromise = await fetch(getBackendApiUrl('cart/byUser'), {
      method: "GET",
      headers : {
        Authorization : `Bearer ${authToken}`,
        "X-EasyPLV-Api-Token": apiKey,
        "Content-Type": "application/json",
      }
    });

    if (cartDataPromise.status === 200) {
      const cartData = await cartDataPromise.json();
      return cartData;
    } else {
      checkApiResponse(cartDataPromise);
    }
  } catch (error) {
    console.error(error);
  }
}

export const convertCartToOrder = async (cartId, selectedPrinters, format=null, type="print") => {
  const authToken = await getAuthTokenWithRefresh();
  const apiKey = getEasyPlvApiToken();
  const response = await fetch(getBackendApiUrl('cart/order'), {
      method: 'POST',
      headers : {
          Authorization : `Bearer ${authToken}`,
          "X-EasyPLV-Api-Token": apiKey,
          "Content-Type": "application/json",
      },
      body: JSON.stringify({'cartId': cartId, 'selectedPrinters': selectedPrinters , 'token' : authToken, 'type' : type, format: format})
  });

  checkApiResponse(response);
  return await response.json();
}

export const setUpdatedCartItemsAndCount = (updatedCartData) => {
  localStorage.setItem('updatedCartItems', JSON.stringify(updatedCartData.cartItems));
  localStorage.setItem('updatedCartItemsCount', updatedCartData.cartItemsCount);
  window.dispatchEvent(new Event('storage'))
}

export const updateCartItemQuantity = async (prevQuantity, newQuantity, cartItemId) => {
  const authToken = await getAuthTokenWithRefresh();
  const apiKey = getEasyPlvApiToken();
  try {
    const updateCartItemQuantityPromise = await fetch(getBackendApiUrl('cart/updateCartItemQuantity'), {
      method: "POST",
      headers: {
        Authorization : `Bearer ${authToken}`,
          "X-EasyPLV-Api-Token": apiKey,
          "Content-Type": "application/json",
      },
      body: JSON.stringify({'newQuantity' : newQuantity, 'cartItemId' : cartItemId})
    }).catch(e => {});

    checkApiResponse(updateCartItemQuantityPromise);
    if (updateCartItemQuantityPromise.status === 500) return {'updatedQuantity' : prevQuantity}
    return await updateCartItemQuantityPromise.json();
  } catch (e) {}
}

export const removeCartItem = async (cartItemId) => {
  const authToken = await getAuthTokenWithRefresh();
  const apiKey = getEasyPlvApiToken();
  const deletePromise = fetch(getBackendApiUrl(`cart_items/${cartItemId}`), {
      method: 'DELETE',
      headers : {
          Authorization : `Bearer ${authToken}`,
          "X-EasyPLV-Api-Token": apiKey,
          "Content-Type": "application/json",
      } 
  });

  checkApiResponse(deletePromise);

  return deletePromise;
}

export const resetCart = async(cartId) => {
  const authToken = await getAuthTokenWithRefresh();
  const apiKey = getEasyPlvApiToken();

  const resetCartPromise = await fetch(getBackendApiUrl(`carts/${cartId}`), {
    method: 'DELETE',
    headers : {
        Authorization : `Bearer ${authToken}`,
        "X-EasyPLV-Api-Token": apiKey,
        "Content-Type": "application/json",
    } 
  });

  checkApiResponse(resetCartPromise);

  return resetCartPromise;
}

