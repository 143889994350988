import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import NwCustomSelect from '../_utilityComponents/NwFormsUi/CustomSelect/NwCustomSelect';

function ColorPickerType(props) {
    const isDisabled = props?.isDisabled || false;
    const formFieldJson = props.formFieldJson;
	const entityFormValuesJson = props.entityFormValuesJson;
	const updateEntityFormValues = props.updateEntityFormValues;
	const configValues = formFieldJson.configValues;
    const metadata = configValues?.values?.metadata;
    const translatedConfigValues = formFieldJson.translatedConfigValues.values;
    const translatedFormattedChoices = translatedConfigValues.choices;
    const colorPickerValueKey = `${configValues.frontUniqueKeys.select1}_${formFieldJson.id}`;
    const colorPickerValueJson = `${colorPickerValueKey}` in entityFormValuesJson
    ? entityFormValuesJson[`${colorPickerValueKey}`]
    : {
        name: formFieldJson.name,
        value : "",
        type : 'color_picker',
        metadata: metadata,
    }
    const choices = configValues.values.parsedChoices;
    choices.push(configValues.values.default);
    const distinctChoices =[...new Set(choices)].map(choice => {
        return {value: choice, label: translatedFormattedChoices[`${choice}`]};
    });

    useEffect(() => {
        updateColorPickerValue({value: colorPickerValueJson.value});
    }, []);

    const updateColorPickerValue = (newValue) => {
		updateEntityFormValues(prevFieldValuesJson => {
            const newFieldValuesJson = {...prevFieldValuesJson};
            colorPickerValueJson.value = newValue.value;
            newFieldValuesJson[`${colorPickerValueKey}`] = colorPickerValueJson;
            return newFieldValuesJson;
        });
    }

    return (
        <>
            <Form.Label>{formFieldJson.title}</Form.Label>
            <NwCustomSelect isDisabled={isDisabled} className='text-black' onChange={(newValue) => updateColorPickerValue(newValue)}options={distinctChoices} value={{value: colorPickerValueJson.value, label: translatedFormattedChoices[`${colorPickerValueJson.value}`]}}/>
        </>
    )
}

export default ColorPickerType;