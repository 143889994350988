import React, { cloneElement } from 'react';
import { NwLoaderSpinner } from '../NwIcons/NwIcons';
import './NwPageLoader.css';

/**
 * NwPageLoader component renders a loading spinner centered within a full-screen overlay.
 * 
 * @component
 * 
 * @param {Object} props - The props for the component.
 * @param {Object} [props.loaderProps={}] - Additional properties to customize the loader.
 * @param {Object} [props.loaderProps.style] - Inline styles for the loader wrapper.
 * @param {string} [props.loaderProps.className] - Additional class names for the loader wrapper.
 * @param {Object} [props.loaderProps.innerWrapper] - Additional properties for the inner wrapper.
 * @param {Object} [props.loaderProps.innerWrapper.style] - Inline styles for the inner wrapper.
 * @param {string} [props.loaderProps.innerWrapper.className] - Additional class names for the inner wrapper.
 * @param {Object} [props.loaderProps.icon] - Additional properties for the loader icon.
 * @param {string} [props.loaderProps.icon.className] - Additional class names for the loader icon.
 * @param {React.ReactElement} [props.loaderIcon=<NwLoaderSpinner size={'1em'}/>] - Custom loader icon element.
 * 
 * @returns {JSX.Element} The rendered loader component.
 * 
 * @example
 * <div className="position-relative">
 *   <NwPageLoader 
 *     loaderProps={{
 *       className: 'my-loader',
 *       style: { backgroundColor: 'rgba(0,0,0,0.5)' },
 *       innerWrapper: {
 *         className: 'my-inner-wrapper',
 *         style: { padding: '20px' }
 *       },
 *       icon: {
 *         className: 'my-icon'
 *       }
 *     }} 
 *   />
 * </div>
 * 
 * Note: This component should be placed inside a parent element with `position: relative`.
 */
const NwPageLoader = ({
    loaderProps = {},
    loaderIcon = <NwLoaderSpinner size={'1em'}/>
}) => {
    return (
        <div 
            className={`position-absolute nw-loader-wrapper d-flex justify-content-center text-white align-items-center nw-responsive-font-size-xl top-0 z-3 w-100 h-100 ${ loaderProps?.className ?? ''}`}
            style={loaderProps?.style ?? {}}
        >
            <div className={`position-relative nw-loader-inner-wrapper d-flex justify-content-center w-100 h-100 ${ loaderProps?.innerWrapper?.className }`} style={loaderProps?.innerWrapper?.style ?? {}}>
                {
                    cloneElement(loaderIcon, { className: `position-sticky nw-loader-icon top-50 ${ loaderProps?.icon?.className }` })
                }
            </div>
        </div>
    )
}

export default NwPageLoader;