import React, {useState} from "react"
import { Block } from "baseui/block"
import AngleDoubleLeft from "../../../../../components/Icons/AngleDoubleLeft"
import Scrollable from "../../../../../components/Scrollable"
import { Button, SIZE } from "baseui/button"
import DropZone from "../../../../../components/Dropzone"
import { useEditor } from "@layerhub-io/react"
import useSetIsSidebarOpen from "../../../hooks/useSetIsSidebarOpen"
import { nanoid } from "nanoid"
import { ILayer } from "@layerhub-io/types"
import { toBase64 } from "../../../../../utils/data"
import { useTranslation } from "react-i18next"
import useTemplateGroupModelContext from '../../../hooks/useTemplateGroupModelContext';
import { Checkbox } from 'baseui/checkbox';


// EDITOR CHANGES named the export function Uploads
export default function UploadsTemplateGroupModel () {
    const inputFileRef = React.useRef<HTMLInputElement>(null)
    const [uploads, setUploads] = React.useState<any[]>([])
    const editor = useEditor()
    const setIsSidebarOpen = useSetIsSidebarOpen()
    const {templateGroupModel} = useTemplateGroupModelContext();
    const [isFitImage, setIsFitImage] = useState(false);

    const {t} = useTranslation();

    const handleDropFiles = async (files: FileList) => {
        const file = files[0]
        const reader = new FileReader();

        //Read the contents of Image File.
        reader.readAsDataURL(file);
        reader.onload = function (e) {

            //Initiate the JavaScript Image object.
            let image: any = new Image();

            //Set the Base64 string return from FileReader as source.
            // @ts-ignore
            image.src = e.target.result;

            //Validate the File Height and Width.
            image.onload = async function () {
                let height = this.height;
                let width = this.width;


                const base64 = (await toBase64(file)) as string
                let preview = base64

                const type = "StaticImage"
            
                const upload : any = {
                    id: nanoid(),
                    src: base64,
                    preview: preview,
                    type: type,
                }
                if (isFitImage) {
                    const size = templateGroupModel?.postType?.size;
                    if (size) {
                        const frameWidth = size.width;
                        const frameHeight = size.height;
      
                        // @ts-ignore
                        const scaleX = frameWidth / width;
                        // @ts-ignore
                        const scaleY = frameHeight / height;
    
                        upload.scaleX = scaleX;
                        upload.scaleY = scaleY;
                    }
                }

                addImageToCanvas(upload);
                setUploads([...uploads, upload])
            };
        };

    }

    const handleInputFileRefClick = () => {
        inputFileRef.current?.click()
    }

    const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleDropFiles(e.target.files!)
    }

    const addImageToCanvas = (props: Partial<ILayer>) => {
        editor.objects.add(props)
    }

    return (
        <DropZone handleDropFiles={handleDropFiles}>
        <Block $style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <Block
            $style={{
                display: "flex",
                alignItems: "center",
                fontWeight: 500,
                justifyContent: "space-between",
                padding: "1.5rem",
            }}
            >
            <Block>{t('editor.panels.panelsList.uploads')}</Block>

            <Block onClick={() => setIsSidebarOpen(false)} $style={{ cursor: "pointer", display: "flex" }}>
                <AngleDoubleLeft size={18} />
            </Block>
            </Block>
            <Scrollable>
            <Block padding={"0 1.5rem"}>
                <Button
                onClick={handleInputFileRefClick}
                size={SIZE.compact}
                style={{
                    fontFamily: "Montserrat-SemiBold",
                    backgroundColor: "#0D6EFD",
                    borderRadius: "2px",
                }}
                overrides={{
                    Root: {
                    style: {
                        width: "100%",
                    },
                    },
                }}
                >
                {t('editor.inputs.upload')}
                </Button>
                <Checkbox checked={isFitImage} onChange={() => setIsFitImage(!isFitImage)}>
                    Fit background
                </Checkbox>
                <input onChange={handleFileInput} type="file" id="file" ref={inputFileRef} style={{ display: "none" }} />

                <div
                style={{
                    marginTop: "1rem",
                    display: "grid",
                    gap: "0.5rem",
                    gridTemplateColumns: "1fr 1fr",
                }}
                >
                {uploads.map((upload) => (
                    <div
                    key={upload.id}
                    style={{
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                    }}
                    onClick={() => addImageToCanvas(upload)}
                    >
                    <div>
                        <img width="100%" src={upload.preview ? upload.preview : upload.url} alt="preview" />
                    </div>
                    </div>
                ))}
                </div>
            </Block>
            </Scrollable>
        </Block>
        </DropZone>
    )
}
