
import React, { useState } from 'react';
import './NwImage.css'

const NwImage = ({ 
    src, 
    loadingBgInherit = false, 
    imgClassName = '',
    imgStyles={},
    wrapperClassName = '', 
    wrapperStyles={},
    onErrorCallback = null
}) => {
    const [ isImageLoading, setIsImageLoading ] = useState(true);
    const [ onError, setOnError ] = useState(false);

    const OnErrorHandler = () => {
        setOnError(true)
        if (onErrorCallback) {
            onErrorCallback();
        }
    }

    return (
        <div 
            className={`nw-image-wrapper ${ isImageLoading && !onError ? `is-loading ${ loadingBgInherit ? 'bg-inherit' : '' }` : '' } ${wrapperClassName}`}
            style={wrapperStyles}
        >
            <img 
                src={src} 
                alt={src}
                onLoad={() => setIsImageLoading(false)}
                onError={OnErrorHandler}
                className={`nw-image ${ isImageLoading ? 'opacity-0' : 'opacity-100' } ${ onError ? ' d-none' : '' } ${imgClassName}`}
                style={imgStyles}
            />
        </div>
        
    )
}

export default NwImage;