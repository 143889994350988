import React, { Fragment, useState } from 'react';
import NwImage from '../../../components/_utilityComponents/NwImage/NwImage';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './ListCard.css';

const ListCard = ({
    imgSrc = '',
    title,
    actions = []
}) => {

    const [ onImgError, setOnImgError ] = useState(false);

    const handleImageOnError = () => {
        setOnImgError(true);
    }

    return (
        <div className='list-card-container'>
            <div className="list-card-wrapper">
                <div className="list-card-image-container d-flex justify-content-center align-items-center">
                    <div className={`list-card-image-wrapper w-100 ${ onImgError ? 'on-img-error rounded-4 shadow' : '' }`}>
                        {
                            onImgError ? (
                                <div className='list-card-on-error-content-wrapper h-100 d-flex justify-content-center align-items-center  text-white fw-bolder nw-responsive-font-size-lg'>
                                    { title }
                                </div>
                            ) : (
                                <NwImage imgClassName='w-100 h-100 object-fit-contain' src={imgSrc} onErrorCallback={handleImageOnError}/>
                            )
                        }
                        
                    </div>
                </div>
                <div className="list-card-btn-container d-flex justify-content-center px-3">
                    <div className="list-card-btn-wrapper d-flex justify-content-center rounded-pill overflow-hidden column-gap-2">
                        {
                            actions?.map((action, index) => (
                                <Fragment key={index}>
                                    {
                                        {
                                            "button" : <Button className='list-card-btn rounded-1 nw-responsive-font-size-sm' onClick={action?.onClick}>
                                                { action?.icon ? React.cloneElement(action?.icon, { className: 'w-100 h-100' }) : action?.title }
                                            </Button>,
                                            "link" : <Link className='list-card-btn rounded-1 nw-responsive-font-size-sm' to={action?.href}>
                                                { action?.icon ? React.cloneElement(action?.icon, { className: 'w-100 h-100' }) : action?.title }
                                            </Link>,
                                            "custom" : <>
                                                {action.component}
                                            </>
                                        }[action.type]
                                    }
                                </Fragment>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ListCard;