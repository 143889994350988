import { useNavigate } from 'react-router';
import { getStoreDataFromLocalStorage } from '../../services/store';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import { Carousel } from 'react-bootstrap';
import { ChevronRight } from 'react-bootstrap-icons';
import ModuleList from './ModuleList';
import NoImage from '../../assets/images/no-image-lg.png'
import DashboardChart from './DashboardChart';
import DashboardOperationList from './DashboardOperationList';
import DashboardMessagesList from './DashboardMessagesList';
import DashboardStats from './DashboardStats';
import DashboardSocialMedia from './DashboardSocialMedia';
import {getCarouselItems} from '../../services/dashboard';

// Module List Icons
import {
    NwBaseProductsModuleList,
	NwSocialMediaModuleList,
	NwPlvModuleList,
	NwECatalogModuleList,
	NwShareBoxModuleList
} from '../../components/_utilityComponents/NwIcons/NwIcons';

import './Home.css';
import { useEffect,useState } from 'react';

const HomeSection = ({ children, className = '' }) => {
    return (
        <div className={`home-section ${ className }`}>
            { children }
        </div>
    )
}

const AdSpotCarousel = ({ carouselItems = [], t }) => {
    return (
        <Carousel touch wrap className='ad-spot-carousel w-100'>
            {carouselItems && carouselItems?.map(item => (
                <Carousel.Item className='w-100 h-100 position-relative ad-spot-carousel-item'>
                    <Link 
                        target={ item?.callToAction?.openInNewTab ? '_blank' : ''} 
                        to={item?.callToAction?.link}
                    >
                        <img
                            className="d-block w-100 h-100 object-fit-fill"
                            src={item?.imgSrc ?? NoImage}
                            alt={'not-found'}
                            onError={(e) => e.target.src = NoImage}
                        />
                    </Link>
                </Carousel.Item>
            ))}
        </Carousel>
    )
}

const Home = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const [posts, setPosts] = useState([]);
    const [isCarouselItemLoading, setIsCarouselItemLoading] = useState(false);
    const [adSpotItems, setAdSpotItems] = useState([]);

    const adSpotItemsHardCoded = [
        {
            imgSrc: 'https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg',
            callToAction: {
                openInNewTab: false,
                link: '/operation'
            }
        },
        {
            imgSrc: 'https://img.freepik.com/free-photo/painting-mountain-lake-with-mountain-background_188544-9126.jpg',
            callToAction: {
                openInNewTab: true,
                link: '/posters'
            }
        },
        {
            imgSrc: '',
            callToAction: {
                openInNewTab: false,
                link: '/free_posters'
            }
        },
    ];



    const fetchCarouselItems = async () => {
        setIsCarouselItemLoading(true);
        try {
          const res = await getCarouselItems();
        //   setPosts(res);
          setAdSpotItems(res);
          if (res) {

          }else{

            setAdSpotItems(adSpotItemsHardCoded);              


            setIsCarouselItemLoading(false);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
            setIsCarouselItemLoading(false);
        }
    };

    useEffect(() => {

        fetchCarouselItems();
    
      },[]);


    const moduleItems = [
        {
			routeId: 'module_plv',
            title: 'PLV',
            description: 'Théâtralisation locale',
            icon: <NwPlvModuleList/>,
			bgVariant: 'teal'
        },
        {
			routeId: 'base_products',
            title: 'BASE PRODUITS',
            description: 'Votre base produits magasin',
            icon: <NwBaseProductsModuleList/>
        },
        {
			routeId: 'social_media',
            title: 'SOCIAL MEDIA',
            description: 'Créez, innovez, communiquez autour de vos produits',
            icon: <NwSocialMediaModuleList/>,
			bgVariant: 'danger'
        },
        {
			routeId: 'share_box',
            title: 'SHARE BOX',
            description: 'Stockage et Partage de fichiers statiques',
            isDisabled: true,
            icon: <NwShareBoxModuleList/>,
			bgVariant: 'blue'
        },
        {
			routeId: 'e_catalog',
            title: 'E-CATALOGUE',
            description: 'Le nouveau prospectus 3.0',
            isDisabled: true,
            icon: <NwECatalogModuleList/>,
			bgVariant: 'warning'
        },
    ]

    const storeData = getStoreDataFromLocalStorage();
    return (
        <div className="p-2 d-flex justify-content-center w-100 h-100">
            <div className="home-main-wrapper px-3 pb-3">
                <HomeSection className='ad-spot-section bg-primary'>
                    { adSpotItems && (<AdSpotCarousel carouselItems={adSpotItems} t={t} />) }
                </HomeSection>
                <HomeSection className='module-list-section'>
                    { moduleItems && <ModuleList moduleItems={moduleItems} /> }
                </HomeSection>
                <HomeSection className='operation-list-section'>
                    <DashboardOperationList/>
                </HomeSection>
                <HomeSection className='activity-graph-section'>
                    <DashboardChart/>
                </HomeSection>
                 <HomeSection className='messages-list-section'>
                   <DashboardMessagesList/>
                </HomeSection>
                <HomeSection className='new-features-section'>
                    <DashboardSocialMedia/>
                </HomeSection> 
            </div>
        </div>
    )
}

export default Home;