function SimpleListing (props) {
    const pagesList = props.pagesList || [];
    return (
        <ul className="list-group">
            {
                pagesList.map((page, index) => {
                    return (
                        <li key={index} className="list-group-item">{page.name}</li>
                    )
                })
            }
        </ul>
    )
}

export default SimpleListing;