import ListOperation from "../../components/Operation/ListOperation";


function Operation () { 

    return (
        <>
            <ListOperation/>
        </>
    )
}

export default Operation;