import React, { useState } from 'react';
import { Col, Card, Button, Badge, Placeholder, Container, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getStateDataByFrontState,formatDateRange } from '../../services/operation';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import { isUrlNoImage } from '../../helpers/helpers';
import NwImage from '../_utilityComponents/NwImage/NwImage';
import NotFoundImg from '../../assets/images/no-image-md.png'

const ListOperationItem = (props) => {
	const { t } = useTranslation();
	const currentStateData = getStateDataByFrontState(props.item?.stateFront, t);
	const navigate = useNavigate();
	const [isHover, setIsHover] = useState(false);
	const [isImgError, setIsImageError] = useState(false);

	const handleMouseOver = () => {
		setIsHover(true);  // Set hover state to true when mouse is over the component
	};
	
	const handleMouseOut = () => {
		setIsHover(false);  // Set hover state to false when mouse leaves the component
	};

	const splitWordsWithKeys = (text) => {
		const words = text.match(/(\b\w+\b)/g);
		let finalTranslatedDate = "";
 		if (!words) {
		  return [];
		}
		// Add a key to each word in the array
		const wordsWithKeys = words.map((word, index) => ({
		  key: index,
		  value: word,
		}));
		
		if(wordsWithKeys){
			if(wordsWithKeys.length == 7){
			// Different month or year	
				const fromText = t(`months.${wordsWithKeys[0]?.value}`)
				const toText = t(`months.${wordsWithKeys[3]?.value}`)
				const monthsFrom = t(`months.${wordsWithKeys[2]?.value}`)
				const monthsTo = t(`months.${wordsWithKeys[5]?.value}`)
				finalTranslatedDate = fromText +" "+wordsWithKeys[1]?.value +" "+monthsFrom+" "+toText+" "+wordsWithKeys[4]?.value + " "+monthsTo + " "+ wordsWithKeys[6]?.value;

			}else if(wordsWithKeys.length == 6){
			// Same month and year
				const fromText = t(`months.${wordsWithKeys[0]?.value}`)
				const toText = t(`months.${wordsWithKeys[2]?.value}`)
				const monthsFrom = t(`months.${wordsWithKeys[1]?.value}`)
				const monthsTo = t(`months.${wordsWithKeys[4]?.value}`)
				finalTranslatedDate = fromText + " "+wordsWithKeys[1]?.value+ " "+ toText+" "+wordsWithKeys[3]?.value + " "+monthsTo + " "+ wordsWithKeys[5]?.value;
			}
			
		}

		return finalTranslatedDate;
	  }

	  const linkStyles = {
		textDecoration: 'none', // Remove underline
		color: 'black', // Set font color to black
	  };

    return (
		
        <Col key={props.item?.id} className='list-operation-item'>
			{props.isPlaceholder ? (
				<Card className="border-0 rounded-4 shadow position-relative list-operation-item-card">
					<Badge className="list-operation-item-state-badge list-operation-item-badge shadow z-3" style={{ height: '10%', backgroundColor: 'var(--nw-primary-color)'}}/>
					<div className='w-100 p-3' style={{height: '65%'}}>
						{/* <Placeholder className="h-100" as={Container} animation="wave">
							<Placeholder className="w-100 h-100" />
						</Placeholder> */}
						<div className="list-operation-item-placeholder-img w-100 h-100"></div>
					</div>
					<Card.Body className='d-flex flex-column'>
						<Placeholder as={Card.Text} animation="wave">
							<Placeholder xs={7} />
						</Placeholder>
						<Placeholder className="m-0 operation-name mb-3" as={Card.Title} animation="wave">
							<Placeholder xs={6} />
						</Placeholder>
						<div className="d-flex flex-column justify-content-end row-gap-2 align-items-center">
							<Placeholder.Button className="rounded-1" style={{width:'100%', backgroundColor: 'var(--nw-primary-color)'}}/>
							<Placeholder.Button className='border-0 rounded-1 w-100' variant="primary" style={{ backgroundColor: '#e6f0ff'}}/>
						</div>

					</Card.Body>
				</Card>
			) : (
				<Card className={`border-0 rounded-4 overflow-hidden ${isHover ? 'onhover shadow-lg' : 'shadow'} position-relative list-operation-item-card`} id={`operation_${props.item?.id}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
					<OverlayTrigger overlay={<Tooltip className='nw-responsive-font-size-sm'>Operation State</Tooltip>}>
						<Badge bg={currentStateData.stateColor} text={currentStateData.textColor} className='list-operation-item-state-badge list-operation-item-badge shadow border-0 border-light border-2'>{t(currentStateData.translationKey)}</Badge>
					</OverlayTrigger>
					{/* <OverlayTrigger overlay={<Tooltip className='nw-responsive-font-size-sm'>Operation Code</Tooltip>}>
						<Badge bg={currentStateData.stateColor} text={currentStateData.textColor} className={`list-operation-item-code-badge list-operation-item-badge shadow border-0 border-light border-2`}>{props.item.code}</Badge>
					</OverlayTrigger> */}

					{ 
						currentStateData?.isDisabled && (
							<div 
								className="bg-white position-absolute w-100 h-100" 
								style={{ 
									opacity: '0.6',
									zIndex: 9
								}}
							/>
						)
					}
	
					<div 
						style={{
							height: '65%',
							backgroundColor: isUrlNoImage(props.item?.defaultDisplayImage, 'operation_list_item') || isImgError ? 'var(--nw-no-image-background-color)' : ''
						}}
					>
						{
							isImgError ? (
								<img 
									src={NotFoundImg} 
									alt={props.item?.name}
									className='rounded-2 w-100 h-100 object-fit-contain'
								/>
							) : (
								<NwImage 
									src={props.item?.defaultDisplayImage} 
									alt={props.item?.name}
									wrapperClassName='d-flex justify-content-center align-items-center'
									imgClassName='rounded-2 w-100 h-100 object-fit-contain'
									onErrorCallback={() => setIsImageError(true)}
								/>
							)
						}

					</div>
					<Card.Body className={`d-flex flex-column justify-content-center py-1`} style={{height: '35%'}}>
						<div className="list-operation-item-date-and-title-container">
							<Card.Text className='mb-0 operation-date'>
								{props.item?.frontDisplayData?.dateOperationText}
							</Card.Text>
							<Card.Title className='m-0 operation-name'>
								<Link style={linkStyles} title={props.item?.name} href={(`/operation/${props.item?.id}/products`)}>
									{props.item?.name}
								</Link>
							</Card.Title>
							<Card.Text className={`operation-date fw-semibold text-${ currentStateData?.stateColor }`}>
								{props.item.code}	
							</Card.Text>
						</div>
						<div style={{fontFamily: 'OpenSans'}} className="operation-list-item-buttons-container mt-2 d-flex flex-column justify-content-center row-gap-2 align-items-center">
							<Button
								className="rounded-1 w-100 h-50 list-operation-cta-btn list-operation-view-products-btn"
								style={{backgroundColor: 'var(--nw-primary-color)'}}
								disabled={currentStateData.isDisabled}
								onClick={() => {navigate(`/operation/${props.item?.id}/products`);}}
							>
								{ t('operationsPage.operationsCard1.viewProductsButtonLabel') }
							</Button>

							<Dropdown className='w-100 h-50 list-operation-download-pdf-dropdown'>
								<Dropdown.Toggle variant="secondary" 
								 id="dropdown-basic"
								 className='border-0 rounded-1 w-100 h-100 list-operation-cta-btn list-operation-download-pdf-btn'
								 drop='up'
								 disabled={currentStateData.isDisabled}
								 style={{color: 'var(--nw-primary-color)', backgroundColor: '#e6f0ff'}}>
									{t('operationsPage.operationsCard1.downloadButtonLabel')}
								</Dropdown.Toggle>
								<Dropdown.Menu>
										
								{props.item?.assets?.PDF_HD?.assets_meta?.file?.cdnUrl ? (
								// If file exists, render the download link
								<Dropdown.Item target="_blank" href={props.item.assets.PDF_HD.assets_meta.file.cdnUrl}>
									{t('operationsPage.operationsCard1.downloadButtonLabelPDF_HD')}
								</Dropdown.Item>
								) : props.item?.assets?.PDF_HD?.assets_meta?.fileData ? (
								// If file doesn't exist, check for fileData
								<Dropdown.Item target="_blank" href={props.item.assets.PDF_HD.assets_meta.fileData.cdnUrl}>
									{t('operationsPage.operationsCard1.downloadButtonLabelPDF_HD')}
								</Dropdown.Item>
								) : (
								// If neither file nor fileData exists, render a disabled item
								<Dropdown.Item disabled>
									{t('operationsPage.operationsCard1.downloadButtonLabelPDF_HDNoAvail')}
								</Dropdown.Item>
								)}

								{props.item?.assets?.PDF_Web?.assets_meta?.file?.cdnUrl ? (
								// If file exists, render the download link
								<Dropdown.Item target="_blank" href={props.item.assets.PDF_Web.assets_meta.file.cdnUrl}>
									{t('operationsPage.operationsCard1.downloadButtonLabelPDF_Web')}
								</Dropdown.Item>
								) : props.item?.assets?.PDF_Web?.assets_meta?.fileData ? (
								// If file doesn't exist, check for fileData
								<Dropdown.Item target="_blank" href={props.item.assets.PDF_Web.assets_meta.fileData.cdnUrl}>
									{t('operationsPage.operationsCard1.downloadButtonLabelPDF_Web')}
								</Dropdown.Item>
								) : (
								// If neither file nor fileData exists, render a disabled item
								<Dropdown.Item disabled>
									{t('operationsPage.operationsCard1.downloadButtonLabelPDF_WebNoAvail')}
								</Dropdown.Item>
								)}


								</Dropdown.Menu>
							</Dropdown>

						</div>
					</Card.Body>
				</Card>
			)}
        </Col>
    );
}

export default ListOperationItem;