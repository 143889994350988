import { useEffect, useState, Fragment, cloneElement } from "react"
import { getUserSocialMedias } from "../../../services/social_media";
import Form from 'react-bootstrap/Form';
import { Row, Col, Button } from "react-bootstrap";
import { SocialMediaIconMapping, SocialMediaPlatformHeaderBackgroundMapping } from "../../../helpers/socialMediaHelper";
import { formatLabelforFormatPreview, isEmptyObject } from "../../../helpers/helpers";
import './PlatformFormatTab.css';

export const PlatformFormat = ({
	title = '',
	initialSelected = false,
	size = {},
	isSocialMediaActive = false,
	onFormatClick = null,
    imgUrl = null,
}) => {

	const [ isCurrentSelected, setIsSelected ] = useState(initialSelected) 
	const aspectRatio = isEmptyObject(size) ? 1 : formatLabelforFormatPreview(size.width / size.height > 1 ? 'landscape' : 'portrait', size).aspectRatio;

	useEffect(() => {
        if (isCurrentSelected) {
            if (onFormatClick) onFormatClick(isCurrentSelected);
        }
	}, [ isCurrentSelected ])

	const handleOnClick = () => {
		setIsSelected(prev => !prev)
	}

	return (
		<div className="platform-format w-100 h-100 d-flex flex-column row-gap-2">
			<Button 
				className={`platform-format-wrapper position-relative text-black border-0 d-flex justify-content-center align-items-center bg-white rounded-0 nw-responsive-font-size-sm p-0 ${ isCurrentSelected ? 'is-selected' : '' } w-100 border border-2`}
				style={{
					aspectRatio: aspectRatio
				}}
				onClick={handleOnClick}
			>
                {
                    imgUrl ?
                    <img src={imgUrl} style={{height: "100%", width: "100%"}} alt="cover_image" />
                    :
                    <span>{ size?.width } x { size?.height }</span>
                }
                { 
                    isCurrentSelected && isSocialMediaActive && (
                        <div className="platform-format-social-media-active-indicator rounded-circle bg-nw-primary border border-3 border-white"></div>
                    ) 
                }
			</Button>
			<span className="w-100 d-flex justify-content-center nw-responsive-font-size-sm nw-font-open-sans">{ title }</span>
		</div>
	)
}

const PlatformFormatTabItem = ({
	title = '',
	icon = null,
	isSocialMediaActive = false,
	postTypes = [],
	onPlatformFormatClick = null,
	slotProps = {}
}) => {

	const handleOnPlatformFormatClick = (isSelected, postType) => {
		if (onPlatformFormatClick) onPlatformFormatClick(isSelected, postType);
	}

	return (
		<div className="platform-format-tab-item mb-3">
			<div className="platform-format-tab-item-wrapper">
				<div className={`platform-format-tab-item-header mb-1 border-bottom border-2 ${ slotProps?.header?.className ?? '' }`}>
					<div className="platform-format-tab-item-header-wrapper text-white d-flex align-items-center column-gap-2 w-100 px-2 py-1">
						<div className="platform-format-tab-item-icon-wrapper d-flex align-items-center">
							{ icon ? cloneElement(icon, { className: 'platform-format-tab-item-icon', size: 15 }) : ''}
						</div>
						<div className="platform-format-tab-item-title-wrapper text-capitalize fw-bold">
							<span className="platform-format-tab-item-title">{ title }</span>
						</div>
					</div>
				</div>
				<div className="platform-format-tab-item-formats-container">
					<div className="platform-format-tab-item-formats-wrapper p-2">
						<Row xs={3}>
							{ 
								postTypes?.map((postType, index) => (
									<Col key={index}>
										<PlatformFormat
											key={index}
											title={ postType?.name }
											initialSelected={ postType?.selected }
											size={ postType?.size }
											isSocialMediaActive={isSocialMediaActive}
											onFormatClick={(isSelected) => handleOnPlatformFormatClick(isSelected, postType)}
										/>
									</Col>
								)) 
							}
						</Row>
					</div>
				</div>
			</div>
		</div>
	);
}

export default function PlatformFormatTab (props) {
    const updateSocialMediaData = props.updateSocialMediaData;
    const socialMediasData = props.socialMedias
    const updatePreviewContainerActive = props.updatePreviewContainerActive;

    const handlePostTypeSelect = (isSelected, socialMedia, postType) => {
        const checked = isSelected;

        const socialMediaId = socialMedia.id;
        const postTypeId = postType.id;

        const newSocialMediasData = {...socialMediasData};
        newSocialMediasData[socialMediaId]["postTypes"][postTypeId].selected = checked;

        updateSocialMediaData(newSocialMediasData);
        updatePreviewContainerActive(socialMedia.id);
    }

    return (
        <div className="platform-format-tab">
			{
				Object.values(socialMediasData).map((socialMedia, index) => {
					return (
						<PlatformFormatTabItem
							key={index}
							title={ socialMedia?.name }
							icon={ SocialMediaIconMapping[socialMedia?.code?.toLowerCase()] }
							postTypes={Object.values(socialMedia?.postTypes)}
							isSocialMediaActive={ props?.currentActiveSocialMediaTab === socialMedia?.id }
							onPlatformFormatClick={(isSelected, postType) => handlePostTypeSelect(isSelected, socialMedia, postType)}
							slotProps={{
								header: {
									className: `${ SocialMediaPlatformHeaderBackgroundMapping?.[socialMedia?.code?.toLowerCase()]?.editor }`
								}
							}}
						/>
					)
				})
			}
        </div>
    )
}