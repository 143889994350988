import { getAuthTokenWithRefresh, getBackendApiUrl, getEasyPlvApiToken } from "../config";
import { checkApiResponse } from "./auth";

const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
}

export const filterMessages = async (options) => {
    let queryString = "";

    const page = options?.page;
    
    if (page) {
        queryString += `?page=${page}`;
    } else {
        queryString += `?page=1`;
    }

    const textSearchQuery = options?.text;
    if (textSearchQuery && textSearchQuery !== "") queryString += `&text=${textSearchQuery}`;
    const dateQuery = options?.date;

    if (dateQuery && dateQuery !== "") {
        try {
            const formattedDate = formatDate(new Date(dateQuery));
            queryString += `&date=${formattedDate}`;
        } catch (e) {
            console.log(e);
        }
    }

    queryString += "&itemsPerPage=12";

    try {  
        const authToken = await getAuthTokenWithRefresh();
        const apiKey = getEasyPlvApiToken();

        let filterMessagesUrl = getBackendApiUrl('messages');
        if (queryString !== "") filterMessagesUrl += `${queryString}`;

        const filteredMessagesPromise = await fetch(filterMessagesUrl, {
            method : "GET",
            headers: {
                Authorization: `Bearer ${authToken}`,
			    "X-EasyPLV-Api-Token": apiKey,
            }
        });

        checkApiResponse(filteredMessagesPromise);

        if (filteredMessagesPromise.status === 200) {
            return await filteredMessagesPromise.json();
        } else {
            // maybe display alert of failed filtering
            return [];
        }
    } catch (e) {
        console.log(e);
    }
}