import ToastContainer from 'react-bootstrap/ToastContainer';
import SimpleNotification from "../Notification/SimpleNotification";
import PdfGenerationStart from "../Notification/Type/PdfGenerationStart/PdfGenerationStart";
import PdfPrintGenerationSuccess from "../Notification/Type/PdfPrintGenerationSuccess/PdfPrintGenerationSuccess";
import MessageNotif from "../Notification/Type/Message/MessageNotif";
import ZipGenerationStart from "../Notification/Type/ZipGenerationStart/ZipGenerationStart";
import ZipGenerationSuccess from "../Notification/Type/ZipGenerationSuccess/ZipGenerationSuccess";
import SocialMediaZipGenerationSuccess from "../Notification/Type/SocialMediaZipGenerationSuccess/SocialMediaZipGenerationSuccess";
import PdfDownloadGenerationSuccess from "../Notification/Type/PdfDownloadGenerationSuccess/PdfDownloadGenerationSuccess";

function NotificationList(props) {
    const notificationsJsonData = props.notificationsJsonData;
    const notificationIds = notificationsJsonData ? Object.keys(notificationsJsonData) : [];

    return (
        <>
        <ToastContainer
            className="p-3"
            position='top-end'
            style={{ zIndex: 9999 }}
        >
            {
                notificationIds.map((id, index) => {
                    const notification = notificationsJsonData[id];
                   return (
                    {
                        'pdf_download_generation_success': <SimpleNotification variant={'light'} key={id} notificationId={notification.id}>
                            <PdfDownloadGenerationSuccess notification={notification}/>
                        </SimpleNotification>,
                        'pdf_generation_started' : <SimpleNotification variant={'light'} key={id} notificationId={notification.id}>
                            <PdfGenerationStart notification={notification}/>
                        </SimpleNotification>,
                        'pdf_print_generation_success' : <SimpleNotification variant={'light'} key={id} notificationId={notification.id}>
                            <PdfPrintGenerationSuccess notification={notification}/>
                        </SimpleNotification>,
                        'zip_generation_started' : <SimpleNotification variant={'light'} key={id} notificationId={notification.id}>
                            <ZipGenerationStart notification={notification}/>
                        </SimpleNotification>,
                        'zip_generation_success' : <SimpleNotification variant={'light'} key={id} notificationId={notification.id}>
                            <ZipGenerationSuccess notification={notification}/>
                        </SimpleNotification>,
                        'store_message' : <SimpleNotification variant={'light'} key={id} notificationId={notification.id}>
                            <MessageNotif notification={notification}/>
                        </SimpleNotification>,
                        'company_message': <SimpleNotification variant={'light'} key={id} notificationId={notification.id}>
                            <MessageNotif notification={notification}/>
                        </SimpleNotification>,
                        'system_message': <SimpleNotification variant={'light'} key={id} notificationId={notification.id}>
                            <MessageNotif notification={notification} />
                        </SimpleNotification>,
                        'social_media_zip_generation_success' : <SimpleNotification variant={'light'} key={id} notificationId={notification.id}>
                            <SocialMediaZipGenerationSuccess notification={notification}/>
                        </SimpleNotification>,
                    }[notificationsJsonData[id].type]
                   )
                })
            }
            </ToastContainer>
        </>
    )
}

export default NotificationList;