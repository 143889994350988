import React, { useState, useEffect } from 'react';
import SidebarItem from './SidebarItem';

const SidebarHeader = ({
    collapsedSideBar,
    headerIcon,
    headerTitle,
    getBadgeValue,
    translationFunction,
    location,
    handleSideItemClick,
    sidebarMenuItems = [],
    onClick,
    headerIndex,
    currentFocusBlock,
    additionalHeaderData = {},
    hideBlock = false
}) => {
    const [renderKey, setRenderKey] = useState(0);
    const [showHeader, setShowHeader] = useState(true);
    useEffect(() => {
        setRenderKey(prevKey => prevKey + 1); // Increment key to force re-render
    }, [collapsedSideBar]); // Dependency array ensures this runs only when collapsedSideBar changes

    const onHeaderClick = () => {
        if (onClick)
        {
            onClick({
                headerIndex,
                headerTitle,
                headerIcon,
                sidebarMenuItems
            });
        }        
    }

    // Helper function to determine if the header should be shown or not
    const updateHeaderVisibility = () => {
        const allItemsHidden = sidebarMenuItems?.every((route, index) =>
            route.id === currentFocusBlock?.blockIdentifier
        );
        setShowHeader(!allItemsHidden);
    };

    // Update header visibility whenever dependencies change
    useEffect(() => {
        updateHeaderVisibility();
    }, [sidebarMenuItems, currentFocusBlock]);

    return (
        <>
            {!hideBlock && (
                <div onClick={onHeaderClick}>
                    {showHeader && (
                        <div className={`nw-sidebar-header d-flex justify-content-center align-items-center ${collapsedSideBar ? 'header-collapsed' : ''} theme-${additionalHeaderData?.theme ?? ''}`}>
                            {
                                collapsedSideBar ? (
                                    <div key={renderKey} className="header-line"></div>
                                ) : (
                                    <div key={renderKey}  className="header-title-wrapper">
                                        <span className="header-title">{headerTitle}</span>
                                    </div>
                                )
                            }
                        </div>
                    )}
        
                    {/* HANDLE SIDEBAR MENU ITEMS */}
                    {
                        sidebarMenuItems && sidebarMenuItems.map((route, index) => {
                            return <SidebarItem
                                key={index}
                                id={route.id}
                                onItemClick={handleSideItemClick}
                                collapsedSideBar={collapsedSideBar} 
                                itemIcon={React.cloneElement(route.icon, { size: '100%', className: `${route.icon.props.className ?? ''} sidebar-item-icon` })} 
                                itemTitle={translationFunction(route.translationKey)} 
                                linkTo={route.path}
                                badgeValue={getBadgeValue(route.path)}
                                currentLocation={location.pathname}
                                menuItems={route.subMenu}
                                sidebarItemIndex={index}
                                hideItem={route.id === currentFocusBlock?.blockIdentifier}
                                itemTheme={additionalHeaderData?.theme}
                            />
                        })
                    }
                </div>
            )}
        </>
    );
}

export default SidebarHeader;