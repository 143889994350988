import { useEffect, useState } from "react";
import { useNavigate,useParams } from "react-router-dom";
import ResetPassword from "../../components/User/ResetPassword";

function ResetPasswordPage() {

    const [pagesList, setPagesList] = useState([]);
    const navigate = useNavigate();
   
    const [token,setToken] = useState(useParams().token);

    return (
      
        <ResetPassword token={token}/>
    )


}

export default ResetPasswordPage;