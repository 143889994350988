import { ListGroup, Row, Col, Button } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { useState } from "react";
import { removeCartItem } from "../../services/cart";
import { getCart } from "../../services/cart";
import { 
	XCircleFill, 
	ChevronDoubleLeft, 
	ChevronDoubleRight,
	PlusLg,
	DashLg
} from "react-bootstrap-icons";
import { updateCartItemQuantity as updateQuantity } from "../../services/cart";

function CartItem (props) {
    const [cartItemData, setCartItemData] = useState(props.cartItemData);
    const updateCart = props.updateCart;
    // State to track button disabled state
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const removeCartItemAndUpdateCart = (cartItemId) => {
        // Disable the button
        setIsButtonDisabled(true);

        removeCartItem(cartItemId).then(async ()=> {
            const fetchedCartData = await getCart();
            if (fetchedCartData) updateCart(fetchedCartData);

        })
    }

    const updateCartItemQuantity = async (e) => {
		console.log("Cart Item Quantity Updated");
        const prevQuantity = cartItemData.quantity;
        setCartItemData(prevCarItemData => {
            const newCartItemData = {...prevCarItemData};
            newCartItemData.quantity = e.target.value
            return newCartItemData;
        })
        updateQuantity(prevQuantity, parseInt(e.target.value), cartItemData.id).then(response => {
            setCartItemData(prevCarItemData => {
                const newCartItemData = {...prevCarItemData};
                newCartItemData.quantity = response['updatedQuantity'];
                return newCartItemData;
            })
        }).catch(e=>{});
        
    }

	const incrementCartQuantity = (incrementAmount) => {
		const prevQuantity = cartItemData.quantity;
		const preferredAmount = prevQuantity + (incrementAmount);

		setCartItemData(prevCarItemData => {
            const newCartItemData = {...prevCarItemData};
            newCartItemData.quantity = preferredAmount
            return newCartItemData;
        })
        updateQuantity(prevQuantity, parseInt(preferredAmount), cartItemData.id).then(response => {
            setCartItemData(prevCarItemData => {
                const newCartItemData = {...prevCarItemData};
                newCartItemData.quantity = response['updatedQuantity'];
                return newCartItemData;
            })
        }).catch(e=>{});
	}
	
	if (props.isDebug) {
		console.log("Cart Item:", cartItemData);
	}

    return (
        <Row className={`nw-cart-item border-top border-black w-100 py-2 ${isButtonDisabled ? 'nw-cart-item-disabled' : ''}`}>
			{/* <Col xs={2} className="nw-cart-item-img-container p-2">
				<div className="nw-cart-item-img-wrapper">
					{
						cartItemData?.imgSrc && (
							<img src="test" className="nw-cart-item-img" alt="" />
						)
					}
				</div>
			</Col> */}
            <Col xs={4} className="d-flex flex-column justify-content-center nw-cart-item-designation-container ps-5">
				<div className="nw-cart-item-designation-name-brand-container">
					<span className="nw-cart-item-designation-name-wrapper">
						{cartItemData.type === "poster" ? cartItemData.product_name : cartItemData.freePosterTitle}
					</span>
					{ cartItemData?.product_brand ?
							(
								<span className="nw-cart-item-designation-brand-wrapper ms-1">
									<ChevronDoubleLeft size={10}/>
										{cartItemData.product_brand}
									<ChevronDoubleRight size={10} />
								</span>
							) 
						: "" 
					}
				</div>
				<span className="nw-cart-item-designation-ean-wrapper">
					{ cartItemData?.product_ean ? `EAN : ${cartItemData.product_ean}` : "" }
				</span>
			</Col>
            <Col xs={4} className="d-flex align-items-center">{cartItemData?.productOperationName}</Col>
            <Col xs={2} className="d-flex justify-content-center nw-cart-item-quantity-container align-items-center p-2">
				<div className="nw-cart-item-quantity-wrapper d-flex align-items-center">
					<Button className="nw-cart-item-quantity-btn p-1 rounded-0 d-flex align-items-center justify-content-center" onClick={() => incrementCartQuantity(1)}>
						<PlusLg size={24}/>
					</Button>
					<Form.Control 
						className="nw-cart-item-quantity rounded-0 py-2" 
						min="1" 
						type="number" 
						disabled={isButtonDisabled} 
						value={cartItemData.quantity} 
						onChange={(e) => {updateCartItemQuantity(e)}}
					/>
					<Button className="nw-cart-item-quantity-btn p-1 rounded-0 d-flex align-items-center justify-content-center" onClick={() => incrementCartQuantity(-1)}>
						<DashLg size={24}/>
					</Button>
				</div>
            </Col>
            <Col xs={2} className="d-flex justify-content-center align-items-center p-1">
                <button className="btn border border-0" onClick={() =>{removeCartItemAndUpdateCart(cartItemData.id)}} disabled={isButtonDisabled}>
                    <XCircleFill className="text-danger" size={40}/>
                </button>
            </Col>   
        </Row>
    )
}



export default CartItem;