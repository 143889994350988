import { Button } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import NwImage from '../../../../components/_utilityComponents/NwImage/NwImage';
import { useTranslation } from 'react-i18next';
import DefaultImage from '../../../../assets/images/no-image-md.png';

const TemplateGroupModel = ({ 
    model, 
    handleModelSelection,
    platform
} : { 
    model:any, 
    handleModelSelection:any,
    platform: any
}) => {
    const { t } = useTranslation();
    return (
        <div className="social-media-model-container w-100 d-flex justify-content-center align-items-center">
            <Card className="social-media-model-wrapper rounded-0 shadow px-2 py-3 border-0">
                <div className="social-media-model-img">
                    <NwImage src={model?.thumbnailPreviewUrl !== "" ? model.thumbnailPreviewUrl : DefaultImage} imgClassName='object-fit-contain w-100 h-100'/>
                </div>
                <div className="social-media-model-title-and-size d-flex flex-column justify-content-center align-items-center">
                    <span className='social-media-model-title'>{model?.postType?.name}</span>
                    <span className='social-media-model-szie'>{model?.postType?.size?.width} x {model?.postType?.size?.height}px</span>
                </div>
                <div className="social-media-model-btn-container d-flex justify-content-center align-items-end">
                    <Button className='social-media-model-btn rounded-pill border-0 px-4 nw-bg-accent-1' onClick={() => {handleModelSelection(model, platform)}}>
                        { t('freeEditorSocialMedia.tabs.templateGroupModels.chooseModel') }
                    </Button>
                </div>
            </Card>
        </div>
       
    )
}

export default TemplateGroupModel;