import React from 'react';
import { CheckCircleFill } from 'react-bootstrap-icons';

const PreviewListItem = (props) => {
    return (
        <div onClick={props.onClick} className={`preview-list-item-container`}>
            <div className={`preview-list-item-wrapper nw-responsive-font-size-xl ${props.isSelected ? 'is-selected' : ''}`}>
                <img className={`preview-list-item-image`} src={props.imageSrc} alt="" loading="lazy" />
                {props.isSelected && <CheckCircleFill className="preview-list-item-checked" size={'1em'}/>}
            </div>
            <div className={`preview-list-item-text ${props.textCenter ? 'center' : ''}`}>
                { props.text }
            </div>
        </div>
    );
}

export default PreviewListItem;