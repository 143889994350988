import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ForgotPassword from "../../components/User/ForgotPassword";

function ForgotPasswordPage() {

    const [pagesList, setPagesList] = useState([]);
    const navigate = useNavigate();
   

    return (
      
        <ForgotPassword/>
    )


}

export default ForgotPasswordPage;