import { Fragment } from "react";
import BooleanType from "../FormFieldTypes/BooleanType";
import CollectionType from "../FormFieldTypes/CollectionType";
import ColorPickerType from "../FormFieldTypes/ColorPickerType";
import EnergeticType from "../FormFieldTypes/EnergeticType";
import FloatType from "../FormFieldTypes/FloatType";
import HiddenType from "../FormFieldTypes/HiddenType";
import IntegerType from "../FormFieldTypes/IntegerType";
import LongTextType from "../FormFieldTypes/LongTextType";
import MultipleCollectionType from "../FormFieldTypes/MultipleCollectionType";
import StarsType from "../FormFieldTypes/StarsType";
import StringType from "../FormFieldTypes/StringType";
import ImageSingleType from "../FormFieldTypes/ImageSingleType";
import ImageMultipleType from "../FormFieldTypes/ImageMultipleType";
import EktatekType from "../FormFieldTypes/EktatekType";
import EktatekMultipleType from "../FormFieldTypes/EktatekMultipleType";
import DateTimeType from "../FormFieldTypes/DateTimeType";

function FormFieldContainer(props) {
    const groupedEntityFormFieldsJson = props.entityFormFieldsJson;
    let groupKeys = [];
    if (groupedEntityFormFieldsJson) groupKeys = Object.keys(groupedEntityFormFieldsJson);
    const entityFormValuesJson = props.entityFormValuesJson;
    const updateEntityFormValuesJson = props.updateEntityFormValuesJson;
    const productId = props.productId;
    const isDisabled = props?.isDisabled || false;

    return (
        <div style={{
            pointerEvents: isDisabled ? 'none' : 'all'
        }}>
            {
                groupedEntityFormFieldsJson && 
                groupKeys.map((groupKey, index) => {
                    const formFieldsJson = groupedEntityFormFieldsJson[groupKey];
                    return (
                        <fieldset key={index}>
                            <legend>{groupKey !== "other" && !groupKey.includes("standard_custom") && groupKey}</legend>
                            {
                                formFieldsJson.map(formFieldJson => {
                                    return (
                                        {
                                        'boolean' : <Fragment key={formFieldJson.id}>
                                            <BooleanType isDisabled={isDisabled} entityFormValuesJson={entityFormValuesJson} formFieldJson={formFieldJson} updateEntityFormValues={updateEntityFormValuesJson}/>
                                        </Fragment>,
                                        'stars' : <Fragment key={formFieldJson.id}>
                                            <StarsType isDisabled={isDisabled} entityFormValuesJson={entityFormValuesJson} formFieldJson={formFieldJson} updateEntityFormValues={updateEntityFormValuesJson}/>
                                        </Fragment>,
                                        'collection' : <Fragment key={formFieldJson.id}>
                                            <CollectionType isDisabled={isDisabled} entityFormValuesJson={entityFormValuesJson} formFieldJson={formFieldJson} updateEntityFormValues={updateEntityFormValuesJson}/>
                                        </Fragment>,
                                        'multiple_collection' : <Fragment key={formFieldJson.id}>
                                            <MultipleCollectionType isDisabled={isDisabled} entityFormValuesJson={entityFormValuesJson} formFieldJson={formFieldJson} updateEntityFormValues={updateEntityFormValuesJson}/>
                                        </Fragment>,
                                        'float' : <Fragment key={formFieldJson.id}>
                                            <FloatType isDisabled={isDisabled} entityFormValuesJson={entityFormValuesJson} formFieldJson={formFieldJson} updateEntityFormValues={updateEntityFormValuesJson}/>
                                        </Fragment>,
                                        'integer' : <Fragment key={formFieldJson.id}>
                                            <IntegerType isDisabled={isDisabled} entityFormValuesJson={entityFormValuesJson} formFieldJson={formFieldJson} updateEntityFormValues={updateEntityFormValuesJson}/>
                                        </Fragment>,
                                        'string' : <Fragment key={formFieldJson.id}>
                                            <StringType isDisabled={isDisabled} entityFormValuesJson={entityFormValuesJson} formFieldJson={formFieldJson} updateEntityFormValues={updateEntityFormValuesJson}/>
                                        </Fragment>,
                                        'color_picker' : <Fragment key={formFieldJson.id}>
                                            <ColorPickerType isDisabled={isDisabled} entityFormValuesJson={entityFormValuesJson} formFieldJson={formFieldJson} updateEntityFormValues={updateEntityFormValuesJson}/>
                                        </Fragment>,
                                        'long_text' : <Fragment key={formFieldJson.id}>
                                            <LongTextType isDisabled={isDisabled} entityFormValuesJson={entityFormValuesJson} formFieldJson={formFieldJson} updateEntityFormValues={updateEntityFormValuesJson}/>    
                                        </Fragment>,
                                        'hidden' : <Fragment key={formFieldJson.id}>
                                            <HiddenType isDisabled={isDisabled} entityFormValuesJson={entityFormValuesJson} formFieldJson={formFieldJson} updateEntityFormValues={updateEntityFormValuesJson}/>    
                                        </Fragment>,
                                        'energetic_value' : <Fragment key={formFieldJson.id}>
                                            <EnergeticType isDisabled={isDisabled} entityFormValuesJson={entityFormValuesJson} formFieldJson={formFieldJson} updateEntityFormValues={updateEntityFormValuesJson} />
                                        </Fragment>,
                                        'image_single' : <Fragment key={formFieldJson.id}>
                                            <ImageSingleType isDisabled={isDisabled} productId={productId} entityFormValuesJson={entityFormValuesJson} formFieldJson={formFieldJson} updateEntityFormValues={updateEntityFormValuesJson} />
                                        </Fragment>,
                                        'image_multiple' : <Fragment key={formFieldJson.id}>
                                            <ImageMultipleType isDisabled={isDisabled} productId={productId} entityFormValuesJson={entityFormValuesJson} formFieldJson={formFieldJson} updateEntityFormValues={updateEntityFormValuesJson} />
                                        </Fragment>,
                                        'ektatek_single' : <Fragment key={formFieldJson.id}>
                                            <EktatekType isDisabled={isDisabled} entityFormValuesJson={entityFormValuesJson} formFieldJson={formFieldJson} updateEntityFormValues={updateEntityFormValuesJson} />
                                        </Fragment>,
                                        'ektatek_multiple': <Fragment key={formFieldJson.id}>
                                            <EktatekMultipleType isDisabled={isDisabled} entityFormValuesJson={entityFormValuesJson} formFieldJson={formFieldJson} updateEntityFormValues={updateEntityFormValuesJson} />
                                        </Fragment>,
                                        'datetime':<Fragment key={formFieldJson.id}>
                                            <DateTimeType isDisabled={isDisabled} entityFormValuesJson={entityFormValuesJson} formFieldJson={formFieldJson} updateEntityFormValues={updateEntityFormValuesJson} />
                                        </Fragment>,
                
                                        }[formFieldJson.type]
                                    )
                                })
                            }
                        </fieldset>
                    )
                })
            }
        </div>
    )
}

export default FormFieldContainer;