import { getBackendApiUrl, getEasyPlvApiToken, getAuthToken, getAuthTokenWithRefresh } from "../config";
import { checkApiResponse } from "./auth";

export const getFreePosters = async (page = 1) => {
  const res = await fetch(getBackendApiUrl(`freePoster/byStoreAndFilters?options[itemsPerPage]=24&options[page]=${page}`), {
    method: "GET",
    headers: {
      Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
      "X-EasyPLV-Api-Token": getEasyPlvApiToken(),
    },
  });
  checkApiResponse(res);
  const data = await res.json();
  return data;
};

export const deleteFreePostersById = async (freePosterId) => {
  const res = await fetch(getBackendApiUrl(`free_posters/${freePosterId}/deleteUserFreePoster`), {
    method: "POST",
    headers: {
      Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
      "X-EasyPLV-Api-Token": getEasyPlvApiToken(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify([]),
  });
  checkApiResponse(res);
  const data = await res.json();
  return data;
};

export const duplicateFreePosterById = async (freePosterId) => {
  const res = await fetch(getBackendApiUrl(`free_posters/${freePosterId}/duplicate`), {
    method: "POST",
    headers: {
      Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
      "X-EasyPLV-Api-Token": getEasyPlvApiToken(),
      "Content-Type": "application/json",
    },
    body: JSON.stringify([]),
  });
  checkApiResponse(res);
  const data = await res.json();
  return data;
};

export const highlightAddedToCart = async (posts) => {
  const cartItems = JSON.parse(localStorage.getItem("updatedCartItems"));

  if (Array.isArray(cartItems)) {
    const freePostersIdsInCart = cartItems.map((item) => item.freePosterId);

    posts?.forEach((item) => {
      const freePosterCartData = cartItems.find((cartItem) => cartItem.freePosterId === item.id);
      console.log(freePosterCartData);

      let freePosterIdMatches = false;

      const cardElement = document.getElementById(`product-card-${item.id}`);
      // console.log(cardElement);
      if (cardElement !== null) {
        if (freePosterCartData) {
          console.log(freePosterCartData);

          // isInCart = productIdsInCart.includes(item.id);
          if (parseInt(freePosterCartData.freePosterId) === parseInt(item.id)) {
            freePosterIdMatches = true;
            console.log(freePosterIdMatches);
          }

          if (cardElement && freePosterIdMatches) {
            console.log(cardElement);
            cardElement.style.backgroundColor = "#A6FF96";
          } else {
            cardElement.style.backgroundColor = "white";
          }
        }
      }
    });
  }
};

export const getFreePostersFiltered = async (page = 1, filter) => {
  const res = await fetch(getBackendApiUrl(`freePoster/byStoreAndFilters?options[itemsPerPage]=24&options[page]=${page}${filter}`), {
    method: "GET",
    headers: {
      Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
      "X-EasyPLV-Api-Token": getEasyPlvApiToken(),
    },
  });
  checkApiResponse(res);
  const data = await res.json();
  return data;
};
