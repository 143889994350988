import { useState } from "react";
import { getProductsByUserFiltered } from "../../services/posters";
import { useTranslation } from "react-i18next";

//NwCustomFilters
import { NwCommonFilter } from "../NwCustomFilters/NwCustomFilters";

export default function MainPosterFilter({ getFilteredProduct, queryFilter, departmentData,resetPaginationNumber }) {
    const { t } = useTranslation();
    const [posterName, setPosterName] = useState();

    const handleFilterSubmit = async (e, sortBy = 'ASC') => {
        e.preventDefault();
        const rayon = e.target.par_rayon?.value ?? "";
        let queryString = "";
        let name = e.target.search?.value;

        if (name) {
            queryString += `&filters[name]=${name}`;
        }

        if (rayon) {
            queryString += `&filters[rayonId]=${rayon}`;
        }
        const filteredProduct = await getProductsByUserFiltered(1, queryString);
        queryFilter(queryString);
        getFilteredProduct(filteredProduct);
    };

    return (
        <NwCommonFilter
            onSubmit={handleFilterSubmit}
            className= "w-100"
            autoSubmitOnReset
            filterWrapperProps={{
                className: "w-100 justify-content-around",
            }}
            filterButtonProps={{
                type: 'submit',
                children: t('validateButtonLabel')
            }}
            resetButtonProps={{
                children: t('resetFilterButton')
            }}
            filterModalProps={{
                headerContent: t('postersPage.filterModalHeader')
            }}
            formFields={[
                {
                    type: 'input',
                    wrapperProps: {
                        xs: 4,
                        md: 5,
                        lg: 5
                    },
                    props: {
                        controlId: "search-by-name-filter",
                        name: 'search',
                        placeholder: t("postersPage.topBox.searchBarLabel"),
                        hideLabel: true,
                        onInput: (e) => setPosterName(e.target.value)
                    }
                },
                {
                    type: 'select',
                    wrapperProps: {
                        xs: 3,
                        lg: 3
                    },
                    props: {
                        id: "department-select",
                        options: JSON.parse(departmentData ?? '[]'),
                        isDisabled: !(JSON.parse(departmentData ?? '[]').count > 1),
                        name: "par_rayon",
                        placeholder: t("viewProductsPage.topSection.byDepartmentDropdown"),
                        isClearable: true,
                    }
                }
            ]}
        />
    );
}
