import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { Row, Col} from 'react-bootstrap';

const EditTabItem = (props) => {
    return (
        <ListGroup.Item className="p-0 bg-transparent nw-tab-item " action href={props.href}>
            <div className="p-3 d-flex align-content-center" style={{height: '4.5rem'}}>
                <Row className='w-100 m-0'>
                    <Col xs={3} className='d-flex justify-content-end align-items-center '>
                        {props.tabIcon}
                    </Col>
                    <Col className='d-flex align-items-center '>
                        {props.tabTitle}
                    </Col>
                </Row>
            </div>
        </ListGroup.Item>
    );
}

export default EditTabItem;