import { Instagram, Facebook, Google } from "react-bootstrap-icons";
import { NwEleclercIcon } from "../components/_utilityComponents/NwIcons/NwIcons";

export const SocialMediaIconMapping = {
	ig: <Instagram/>,
	fb: <Facebook/>,
	gmb: <Google/>,
	eleclerc: <NwEleclercIcon/>
};

export const SocialMediaPlatformHeaderBackgroundMapping = {
	ig: {
		index: 'nw-bg-gradient-instagram',
		editor: 'nw-bg-gradient-instagram'
	},
	fb: {
		index: 'nw-bg-gradient-facebook',
		editor: 'nw-bg-gradient-facebook'
	},
	gmb: {
		index: 'nw-bg-gradient-google',
		editor: 'nw-bg-gradient-google'
	},
	eleclerc: {
		index: 'nw-bg-gradient-eleclerc',
		editor: 'nw-bg-gradient-eleclerc'
	},
}