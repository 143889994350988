import SocialMediaEditor from "../../components/SocialMedia/Editor/SocialMediaEditor";
import { useParams } from "react-router-dom";
import { SocialMediaEditorProvider } from "../../contexts/SocialMediaEditor";

export default function Editor() {
    const params = useParams();
    const mode = params.mode;
    const id = params.id;
    const entity = params.entity;
    
    return (
        <SocialMediaEditorProvider mode={mode} entity={entity} id={id}>
            <SocialMediaEditor />
        </SocialMediaEditorProvider>
    )
}