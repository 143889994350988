import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
  useMutation,
} from "react-query";
import { getAuthTokenWithRefresh, getBackendApiUrl, getEasyPlvApiToken } from "../../config";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <EditUser />
    </QueryClientProvider>
  );
}
const authToken = localStorage.getItem("auth_token");
// const getEasyPlvApiToken() = getEasyPlvApiToken();

function EditUser() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const { t } = useTranslation();

  const [passwordData, setPasswordData] = useState({
    newPassword: "",
    confirmNewPassword: "",
    existingPassword: "",
  });

  const handleUserDataChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
    console.log(userData);
  };

  const handleLanguageChange = (e) => {
    const { value } = e.target;
    setUserData((prevUserData) => ({
      ...prevUserData,
      locale: value,
    }));
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData((prevPasswordData) => ({
      ...prevPasswordData,
      [name]: value,
    }));
  };

  const { isLoading, error, data } = useQuery("editUser", async () => {
    const authToken = await getAuthTokenWithRefresh();
    const response = await fetch(getBackendApiUrl("user/getUserData"), {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "X-EasyPLV-Api-Token": getEasyPlvApiToken(),
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    } else {
      return response.json();
    }
  });

  useEffect(() => {
    // Assuming data structure matches your user data
    setUserData(data);
  }, [isLoading, error, data]);

  if (isLoading) return "Loading...";

  if (error) return "An error has occurred: " + error.message;

  const handleSubmit = async (e) => {
    e.preventDefault();

    
    // console.log("User Data:", userData);
    // console.log("Password Data:", passwordData);

    const editedData = { userData: userData, passwordData: passwordData };
    // console.log(editedData);
    const authToken = await getAuthTokenWithRefresh();
    fetch(getBackendApiUrl("user/editUserData"), {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "X-EasyPLV-Api-Token": getEasyPlvApiToken(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(editedData), 
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        Swal.fire("User Updated", " ", "success");
        navigate("/user/edit");
      })
      .catch((error) => {
        Swal.fire("Password Error", " ", "error");
        // console.error("There was a problem with the PUT request:", error);
      });
  };

  if (!userData) return null;

  return (
    <Container>
      <Row>
        <Form onSubmit={handleSubmit}>
          {/* User Data Column */}
          <Col md={6}>
            <Form.Group controlId="firstName">
              <Form.Label>{t('editProfile.firstName')}</Form.Label>
              <Form.Control
                type="text"
                name="firstName"
                value={userData.firstName}
                onChange={handleUserDataChange}
              />
            </Form.Group>
            <Form.Group controlId="lastName">
              <Form.Label>{t('editProfile.lastName')}</Form.Label>
              <Form.Control
                type="text"
                name="lastName"
                value={userData.lastName}
                onChange={handleUserDataChange}
              />
            </Form.Group>
            <Form.Group controlId="email">
              <Form.Label>{t('editProfile.email')}</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={userData.email}
                onChange={handleUserDataChange}
              />
            </Form.Group>
            <Form.Group controlId="locale">
              <Form.Label>{t('editProfile.language')}</Form.Label>
              <Form.Select
                value={userData.locale}
                onChange={handleUserDataChange}
                className="form-control"
                name="locale"
              >
                <option value="fr">🇫🇷 French</option>
                <option value="es">🇪🇸 Español</option>
                <option value="en">🇬🇧 English</option>
                <option value="pt">🇵🇹 Portuguese</option>
              </Form.Select>
            </Form.Group>
          </Col>
          {/* Password Column */}
          <Col md={6}>
            <Form.Group controlId="newPassword">
              <Form.Label>{t('editProfile.newPassword')}</Form.Label>
              <Form.Control
                type="password"
                name="newPassword"
                value={passwordData.newPassword}
                onChange={handlePasswordChange}
              />
            </Form.Group>
            <Form.Group controlId="confirmNewPassword">
              <Form.Label>{t('editProfile.confirmNewPassword')}</Form.Label>
              <Form.Control
                type="password"
                name="confirmNewPassword"
                value={passwordData.confirmNewPassword}
                onChange={handlePasswordChange}
              />
            </Form.Group>
            <Form.Group controlId="existingPassword">
              <Form.Label>{t('editProfile.existingPassword')}</Form.Label>
              <Form.Control
                type="password"
                name="existingPassword"
                value={passwordData.existingPassword}
                onChange={handlePasswordChange}
              />
            </Form.Group>
          </Col>
          <br></br>
          <Button type="submit">{t('editProfile.save')}</Button>
        </Form>
      </Row>
    </Container>
  );
}

export default App;
