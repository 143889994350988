import { useState } from 'react';

/**
 * @typedef {Object} FocusBlockData
 * @property {string} title - The title of the focus block.
 * @property {JSX.Element} icon - The icon URL or name associated with the focus block.
 * @property {Array<Object>} menuItems - The list of menu items related to the focus block.
 * @property {string} blockIdentifier - The unique identifier for the block.
 */


/**
 * Hook to manage the focus block data state.
 * 
 * @returns {[FocusBlockData|null, function]} The current focus block data and a function to update it.
 */
const useFocusBlockData = () => {
    const [focusBlockData, setFocusBlockData] = useState(/** @type {FocusBlockData|null} */ (null));

    /**
     * Updates the focus block data.
     * 
     * @param {FocusBlockData} data - The new focus block data.
     */
    const updateFocusBlockData = (data) => {
        setFocusBlockData(data);
    };

    return [focusBlockData, updateFocusBlockData];
};

export default useFocusBlockData;
