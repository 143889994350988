import { getBackendApiUrl, getEasyPlvApiToken } from "../config"
import { checkApiResponse } from "./auth";


export const fetchUserStoreData = async (jwtToken) => {
    try {
        const apiKey = getEasyPlvApiToken();
        const userStoreDataPromise = await fetch(getBackendApiUrl('stores/byUser'), {
            method: "GET",
            headers : {
                'Content-Type' : 'application/json',
                'Authorization' : `Bearer ${jwtToken}`,
                'X-EasyPLV-Api-Token' : apiKey
            }
        }).catch(e => {console.log(e)}); 
        checkApiResponse(userStoreDataPromise);
        if (userStoreDataPromise?.status === 500) return 500;
        return await userStoreDataPromise.json();
    } catch (e) {console.log(e); return []};
}

export const getStoreDataFromLocalStorage = () => {
    const storeData = localStorage.getItem('store_details') ? localStorage.getItem('store_details') : '{}';
    return JSON.parse(storeData);
}
