import { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { getProductsByUser, getProductsByUserFiltered,getFormatsByCompany,deleteProductById,getUserCategory } from "../../services/posters";
import MainPosterCard from "./MainPosterCard/MainPosterCard";
import "./CustomStyles.css";
import MainPosterFilter from "./MainPosterFilter";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { useNavigate, Link } from "react-router-dom";
import { addProductToCart } from "../../services/cart";
import { highlightProductCards } from "../../services/operation";
import { Plus } from "react-bootstrap-icons";
import { useAuth } from "../../contexts/AuthContext";
import { NwLoaderSpinner } from "../_utilityComponents/NwIcons/NwIcons";
import NwPageLoader from "../_utilityComponents/NwPageLoader/NwPageLoader";
import MainPosterSideFilter from "./MainPosterSideFilter";

const MainPoster = () => {
	const [posts, setPosts] = useState([]);
	const [ isMainPosterInit, setIsMainPosterInit ] = useState(false);
	const { userNw, isAuthenticated } = useAuth();
	const [totalItems, setTotalItems] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const { i18n, t } = useTranslation();
	const [pageCount, setPageCount] = useState(0);
	const [paginateCurrentPage, setCurrentPagePaginate] = useState(1);
	const [reduceValue, setReduceValue] = useState(0);
	const [postsPerPage, setPostsPerPage] = useState(20);
	const [filterString,setFilterString] = useState('');
	const [formatSelect,setFormatSelect] = useState([]);
	const [selectedFormatValues, setSelectedFormatValues] = useState(0);
	const [selectedOrientationValues, setSelectedOrientationValues] = useState("");
	const [OrientationSelectDataFinal, setOrientationSelectDataFinal] = useState([]);
	const [customData, setCustomData] = useState({});
	const [isChecked, setIsChecked] = useState(false);
	const [addToCartLoading, setAddToCartLoading] = useState(false);
	const navigate = useNavigate();
	const [companyId, setCompanyId] = useState(null);
	const [changePageLoading, setChangePageLoading] = useState(false);
	const [departmentData,setDepartmentData] = useState(false);
	const [userCategory, setUserCategory] = useState();
	const [userCategorySelect, setUserCategorySelect] = useState();
	const [fetchCategoryAgain,setFetchCategoryAgain] = useState(0);
	const [fetchAllPostersAgain,setFetchAllPostersAgain] = useState(0);
	const [totalPostersItem,setTotalPostersItem] = useState(0);

	const [showModal, setShowModal] = useState(false);
	const [isProductDelete, setProductDelete] = useState(0);

	let actualSelectedFormat = null;

	const fetchData = async () => {
		const response = await getProductsByUser();
		setPosts(response.items);
		setTotalItems(response.totalItems);
		setTotalPostersItem(response.totalItems);
		setDepartmentData(response.departmentData);    
	};

	const fetchDataDeleteOrDuplicate = async (paginateCurrentPage) => {
		const response = await getProductsByUser(paginateCurrentPage);
		setCurrentPagePaginate(paginateCurrentPage);
		setPosts(response.items);
		setTotalItems(response.totalItems);
		setTotalPostersItem(response.totalItems);
		setDepartmentData(response.departmentData);
		
	};

	const resetPaginationNumber = (data) => {
		setCurrentPagePaginate(data);
	};
	
	const getUserCategoryList = async () => {
		const CategoryResponse = await getUserCategory();
		setUserCategory(CategoryResponse.items);
		setUserCategorySelect(CategoryResponse.categorySelect);
	}

	const getFormatsByComp = async () => {
		let formats = await getFormatsByCompany();
		let formatSelect = formats?.formats_select;
		setFormatSelect(formatSelect);
	}
	
	useEffect(() => {
		const initMainPoster = async () => {
			await fetchData();
			await getFormatsByComp();    
			await getUserCategoryList();
		};
		
		initMainPoster().then(() => setIsMainPosterInit(true));
	}, []);

	useEffect(() => {
		setIsMainPosterInit(false);
		fetchDataDeleteOrDuplicate(paginateCurrentPage).then(() => setIsMainPosterInit(true));
	}, [isProductDelete]);

	useEffect(() => {
		if (isAuthenticated && userNw)
		{
			setCompanyId(userNw.companyId);
		}
	}, [isAuthenticated, userNw])

	useEffect(() => {
		document.title = "Affiches";
		setPageCount(Math.ceil(totalItems / postsPerPage));
	}, [posts]);

	useEffect(() => {
		highlightAddedToCartPoster(posts);
	}, []);


	useEffect(() => {
		setIsMainPosterInit(false);
		fetchDataDeleteOrDuplicate(paginateCurrentPage).then(() => setIsMainPosterInit(true));
		getUserCategoryList();
	}, [fetchCategoryAgain]);

	useEffect(() => {
		fetchData();
		setFetchAllPostersAgain(false);
	}, [fetchAllPostersAgain]);

	useEffect(() => {
		if (isMainPosterInit) handlePageClick(paginateCurrentPage);
	}, [paginateCurrentPage]);


	const handlePageClick = async (data) => {
		if(data === 0){
			return;
		}
		window.scrollTo(0, 0);
		let currentPage = data;
		setChangePageLoading(true);
		setCurrentPagePaginate(data);
		let posters = '';
		if(filterString){
			posters = await getProductsByUserFiltered(currentPage,filterString);
		}else{
			posters = await getProductsByUser(currentPage);
		}
		setChangePageLoading(false);
		setPosts(posters.items);
		setTotalItems(posters.totalItems);
		setReduceValue(reduceValue + 1);
		setDepartmentData(posters.departmentData);
		// scroll to the top
		window.scrollTo(0, 0);
	};  

	useEffect(() => {
		if (isMainPosterInit) highlightAddedToCartPoster(posts);
	}, [posts, isMainPosterInit]);


	const getFilteredProduct = (data) => {
		setPosts(data.items);
		setTotalItems(data.totalItems);
		setDepartmentData(data.departmentData);
	}

	const queryFilter = (data) => {
		setFilterString(data);
	}

	const addItemToCart = async (newItem) => {    
		const addToCart = await addProductToCart(newItem);
		return addToCart;
	};
	let orientationWarning = t("please_Select_an_orientation");
	let formatWarning = t("please_Select_a_format");
	const handleSubmit = (e, item) => {
		e.preventDefault();
		const itemId = item?.id;

		const customPosterData = item.customPosterData;
		let conditionalPosterData = {};
		conditionalPosterData['showHeaderName'] = customPosterData?.showHeaderName?.value;
		conditionalPosterData['showHeaderDate'] = customPosterData?.showHeaderDate?.value;
		conditionalPosterData['withImage'] = customPosterData?.showProductImage;

		customData['conditionalPosterData'] = conditionalPosterData;
		const itemData = {
			itemID: item.id,
			radioValue: isChecked ? 1 : 0, //with or without image
			format: item?.customPosterData?.formatId,
			orientation:item?.customPosterData.orientation,
			customData: JSON.stringify(customData),
		};

		if (itemData.format === "" || itemData.format === null) {
			Swal.fire(formatWarning , " ", "error");
			return false;
		} else if (itemData.orientation === "" || itemData.orientation === null) {
			Swal.fire(orientationWarning, " ", "error");
			return false;
		}

		setAddToCartLoading((prevState) => ({
			...prevState,
			[itemId]: true,
		}));

		addItemToCart(itemData);
		
		setAddToCartLoading((prevState) => ({
			...prevState,
			[itemId]: false,t
		}));
		
		let cardElement = document.getElementById(`product-card-`+itemId);
		cardElement.style.backgroundColor = "#A6FF96";

		highlightProductCards(
			posts,
			0,
			actualSelectedFormat,
			selectedOrientationValues,
			"poster"
		);
		setReduceValue(reduceValue + 1);
	}

	const handleNewSubmit = (item) => {
		const itemId = item?.id;

		const customPosterData = item.customPosterData;
		let conditionalPosterData = {};
		conditionalPosterData['showHeaderName'] = customPosterData?.showHeaderName?.value;
		conditionalPosterData['showHeaderDate'] = customPosterData?.showHeaderDate?.value;
		conditionalPosterData['withImage'] = customPosterData?.showProductImage;

		customData['conditionalPosterData'] = conditionalPosterData;
		const itemData = {
			itemID: item.id,
			radioValue: isChecked ? 1 : 0, //with or without image
			format: item?.customPosterData?.formatId,
			orientation:item?.customPosterData.orientation,
			customData: JSON.stringify(customData),
		};

		if (itemData.format === "" || itemData.format === null) {
			Swal.fire(formatWarning , " ", "error");
			return false;
		} else if (itemData.orientation === "" || itemData.orientation === null) {
			Swal.fire(orientationWarning, " ", "error");
			return false;
		}

		setAddToCartLoading((prevState) => ({
			...prevState,
			[itemId]: true,
		}));

		addItemToCart(itemData);
		
		setAddToCartLoading((prevState) => ({
			...prevState,
			[itemId]: false,t
		}));

		let addToCartButton = document.getElementById("addToCart-"+itemId);
		addToCartButton.style.backgroundColor = "#A6FF96";
		

		highlightProductCards(
			posts,
			actualSelectedFormat,
			selectedOrientationValues
		);
		setReduceValue(reduceValue + 1);
	}

	const handleInputChange = (e, itemId, other = "") => {
		const { name, value } = e.target;

		if (name === "format") {
			setSelectedFormatValues(value);
			highlightProductCards(posts, value);
			const formatId = value;

			let foundData = null;
			const orientationSelectData = JSON.parse(
				localStorage.getItem("orientationSelectData")
			);

			for (const key in orientationSelectData) {
				if (parseInt(key) === parseInt(formatId)) {
					foundData = {
						key: key,
						data: orientationSelectData[key],
					};
					break;
				}
			}

			if (foundData) {
				// set selection select to zero
				setSelectedOrientationValues("");

				setOrientationSelectDataFinal(foundData.data);
			} else {
			}
		} else if (name === "orientation") {
			setSelectedOrientationValues(value);
		
			highlightProductCards(
				posts,
				actualSelectedFormat,
				selectedOrientationValues
			);
		} else if (name === "customData") {
			setCustomData({
				...customData,
				[itemId]: value,
			});
		}
	};

	const handleRadioChange = (e, itemId) => {
		const { name, value, checked } = e.target;

		setIsChecked(!isChecked);
	};


	const redirectToPreviewPage = (poster_id, format, orientation) => {
		if (format === "" || format === undefined) {
			Swal.fire(formatWarning , " ", "error");
			return false;
		}

		if (orientation === "" || orientation === undefined) {
			Swal.fire(orientationWarning, " ", "error");
			return false;
		}

		const url = `/posters/preview?poster_id=${poster_id}${
			format ? `&format=${format}` : ""
		}${orientation ? `&orientation=${orientation}` : ""}`;

		navigate(url);
	};

	const redirectToEditPage = (endOn,operationId,startOn,product_id,format,orientation,showImageProduct) => {
		
		if (format === "" || format === undefined) {
			Swal.fire(formatWarning , " ", "error");
			return false;
		}

		if (orientation === "" || orientation === undefined) {
			Swal.fire(orientationWarning, " ", "error");
			return false;
		}

		const url = `/posters/edit?from=main_posters&end_on=${endOn}&operation_id=${operationId}&product_id=${product_id}&start_on=${startOn}${
			format ? `&format=${format}` : ""
		}${orientation ? `&orientation=${orientation}` : ""}${companyId ? `&company_id=${companyId}` : ""}&showImageProduct=${showImageProduct}&onSave=EDIT`;

		navigate(url);
	};

const deleteProduct = async (productId) => {
	Swal.fire({
		title: t("freePosters.freePosterSure"),
		text: t("freePosters.freePosterSureText"),
		icon: "warning",
		showCancelButton: true,
		confirmButtonColor: "#3085d6",
		cancelButtonColor: "#d33",
		confirmButtonText: t("freePosters.freePosterDeleteConfirm"),
		cancelButtonText: t("freePosters.freePosterDuplicateCancel"),
		showLoaderOnConfirm: true,
		returnFocus: false,
		preConfirm: () => {
			return deleteProductById(productId)
				.then(response => {
					// Handle the response from your deleteProductById function
					// You might want to check if the response indicates success and then return something meaningful or throw an error
					return response; // This value will be passed to the next then() block
				})
				.catch(error => {
					Swal.showValidationMessage(
						`Request failed: ${error}` // Show error message within the Swal
					);
				});
		}
	}).then((result) => {
		if (result.isConfirmed && result.value) {
			// The promise from preConfirm resolved successfully and the Swal was confirmed
			setProductDelete(isProductDelete + 1);
			Swal.fire({
				title: t("freePosters.deleted"),
				confirmButtonText: t("freePosters.PosterDeleteConfirm"),
				icon: 'success'
			});
		}
	});
};

	const isDuplicate = (data) =>{
	fetchDataDeleteOrDuplicate(paginateCurrentPage).then(() => setIsMainPosterInit(true));
	}

	const sideFilterSelectedValue = async (data) => {
		let queryString = '';
		if (data) {
			queryString = `&filters[category]=${data}`;
		}
		
		setIsLoading(true);
		const filteredProduct = await getProductsByUserFiltered(1, queryString);
		setIsLoading(false);
		setFilterString(queryString);
		setChangePageLoading(false);
		 
		
		setPosts(filteredProduct.items);
		setTotalItems(filteredProduct.totalItems);
		setDepartmentData(filteredProduct.departmentData);

	}

	const addToCategory = (data) =>{
		setShowModal(true);
		
	}

	const getCategoryAgain = (data) => {
		
		setFetchCategoryAgain(fetchCategoryAgain+1);
	}


	const getAllPosters = (data) => {
		setFetchAllPostersAgain(true);
	}

	const highlightAddedToCartPoster = (posts) => {
		const cartItems = JSON.parse(localStorage.getItem('updatedCartItems'));
		let productIdMatches = false;

		if (Array.isArray(cartItems)) {
			posts?.forEach((item) => {
				const productCartData = cartItems.find((cartItem) => cartItem.productId === item.id);
				const cardElement = document.getElementById(`product-card-${item.id}`);

				if(productCartData){
					if (productCartData.type === 'poster'){
						if(parseInt(productCartData.productId) === parseInt(item.id)){
							productIdMatches = true;
						};

						if (cardElement && productIdMatches) {
							cardElement.style.backgroundColor = "#A6FF96";
						}else{
							cardElement.style.backgroundColor = "white";
						}
					}
				}
			})
		}
	}

 

	return (
		<div className="nw-main-poster-list-container poster-list-container px-3 position-relative">
			{changePageLoading ? (
				<NwPageLoader/>
			) : null}
			<div className="position-relative pt-5">
				<div className="d-flex justify-content-between h-100 mb-5">
					<div className="w-75">
						<MainPosterFilter getFilteredProduct={getFilteredProduct} queryFilter = {queryFilter} departmentData = {departmentData} resetPaginationNumber={resetPaginationNumber}/>
					</div>   
					<Link 
						className="text-nowrap"
						to={`/posters/new${companyId ? `?company_id=${companyId}` : ''}&onSave=DUPLICATE&from=posters`}
					>
						<Button variant="success" className="new-poster-btn h-100 px-3 rounded-1" >
							<Plus size={25}/>
							<span className="new-poster-button">
									{ t('postersPage.topButtonLabel') }
							</span>
						</Button>
					</Link>
				</div>       
					<Row className="mb-3"
						style={{
							minHeight: '70svh'
						}}
					>     
						<Col xs={0} md={4} lg={3} xl={2}>
							<MainPosterSideFilter totalItems={totalPostersItem} sideFilterSelectedValue={sideFilterSelectedValue} fetchCategoryAgain={fetchCategoryAgain} getAllPosters={getAllPosters} getCategoryAgain = {getCategoryAgain}></MainPosterSideFilter>
						</Col>
						<Col xs={12} md={8} lg={9} xl={10}>
							{ 
							!isMainPosterInit ? (
								<div className="w-100 h-100 d-flex justify-content-center align-items-center nw-responsive-font-size-xl">
									<NwLoaderSpinner isSpinner size={'1em'}/>
								</div>
								) : (
									<Row xs={1} sm={2} md={2} lg={3} xl={4} xxl={5} className="gy-4">
										{
											posts?.map((post) => (
												<MainPosterCard
													key={post?.id}
													data={post}
													formatSelect={formatSelect}
													handleSubmit={handleSubmit}
													handleNewSubmit={handleNewSubmit}
													handleRadioChange={handleRadioChange}
													isChecked={isChecked}
													selectedFormatValues={post?.customPosterData?.formatId}
													handleInputChange={handleInputChange}
													OrientationSelectDataFinal={OrientationSelectDataFinal}
													selectedOrientationValues={post?.customPosterData?.orientation}
													addToCartLoading={addToCartLoading}
													redirectToEditPage={redirectToEditPage}
													redirectToPreviewPage={redirectToPreviewPage}
													editPosterData={{}}
													deleteProduct={deleteProduct}
													disableFormatSelect={true}
													disableOrientationSelect={true}
													addToCategory={addToCategory}
													userCategory = {userCategory}
													userCategorySelect = {userCategorySelect}
													getCategoryAgain = {getCategoryAgain}
													isDuplicate = {isDuplicate}
												/>
											))
										}
									</Row>
								)
							}
						</Col>
					</Row>
					<ResponsivePagination
						previousLabel={t("back")} 
						nextLabel={t("next")} 
						current={paginateCurrentPage}
						total={pageCount}
						onPageChange={setCurrentPagePaginate}
					/>
			</div>
		</div>
	);
};

export default MainPoster;
