import React, { useState, useEffect } from 'react';
import { Popover, OverlayTrigger, Badge } from "react-bootstrap";
import { Bell } from 'react-bootstrap-icons';
import { Link } from "react-router-dom";
import NotificationList from '../../../NotificationList/NotificationList';
import { getNotifications } from '../../../../services/notification';
import NotificationBoxList from './NotificationBoxList';

//NwIcon
import { NwBellIcon } from '../../../_utilityComponents/NwIcons/NwIcons';

// Nw Utility Components
import NwBadgeWithCount from '../../../_utilityComponents/NwBadgeWithCount/NwBadgeWithCount';

const NotificationBox = ({isMobile}) => {

    // const [notifications, setNotifications] = useState({});
    // useEffect(() => {
    //     const fetchAndSetNotfications = async () => {
    //         try {
    //             const notificationsData = await getNotifications();
    //             if (notificationsData !== undefined) {
    //                 setNotifications(notificationsData);
    //             }
    //         } catch (e) {console.error(e)}
    //     }
    //     const interval = setInterval(() => {
    //         fetchAndSetNotfications();
    //     }, 5000);
    //     return ()=>clearInterval(interval);
    // }, [notifications]);
    // console.log("notif...",notifications);
    // const notificationCount = notifications.length;
    //
    // console.log("Notification Count:", notificationCount);

    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        const fetchAndSetNotfications = async () => {
            try {
                const notificationsData = await getNotifications();
                if (notificationsData !== undefined) {
                    // Convert object to array
                    const notificationsArray = Object.values(notificationsData);
                    setNotifications(notificationsArray);
                }
            } catch (e) {console.error(e)}
        }
        const interval = setInterval(() => {
            fetchAndSetNotfications();
        }, 5000);
        return ()=>clearInterval(interval);
    }, []);

    // console.log("notif...",notifications);
    const notificationCount = notifications.length;
    //
    // console.log("Notification Count:", notificationCount);
    
    const popover = (
        <Popover id="popover-basic" className='w-100 h-75 rounded-4 overflow-hidden mt-3 shadow'>
            <Popover.Header as="h3">Notification List</Popover.Header>
            <Popover.Body>
                {/* <NotificationList notificationsJsonData={notifications}/> --uncomment to show notif list to_do_nw */}
                <NotificationBoxList/>
            </Popover.Body>
        </Popover>
    );

    return (
        // <OverlayTrigger trigger="focus" placement="auto" overlay={popover}>
            <Link className={`nav-link nw-main-navbar-menu-item border-0 position-relative d-flex align-items-center ${isMobile ? 'mx-1' : 'mx-3'} p-2`} to={"/notifications"}>
                <NwBellIcon size={24}/>
                <NwBadgeWithCount
                    bg="accent-1"
                    countValue={notificationCount}
                />
            </Link>
        // {/* </OverlayTrigger> */}
    );
}

export default NotificationBox;