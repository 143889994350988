import {Fragment} from 'react';
import {PlatformFormat} from "../PlatformFormatTab/PlatformFormatTab";
import {Row, Col} from 'react-bootstrap';

export default function CoverTab({covers, updateCovers, updatePreviewContainerActive}) 
{
    const handleCoverCheck = (coverKey, checked) => {
        covers[coverKey].selected = checked;
        updateCovers({...covers});
        updatePreviewContainerActive('covers');
    }

    const coverSizes = {
        "cover_carre" : {
            "height" : 1080,
            "width" : 1080,
        },
        "cover_portrait" : {
            "height" : 1920,
            "width" : 1080,
        },
        "cover_landscape" : {
            "height" : 1080,
            "width" : 1920,
        }
    }

    return (
        <>

            <Row xs={3} className="covers-row">
            {
                Object.keys(covers).map(coverKey => {
                    const cover = covers[coverKey];
                    return (
                        <>
                        {
                            cover?.images?.thumbnailPreviewUrl !== "" &&
                            <Col>
                                <Fragment key={coverKey}>
                                    {
                                        <>
                                        <PlatformFormat 
                                            imgUrl={cover?.images?.thumbnailPreviewUrl}
                                            onFormatClick={(isSelected) => {handleCoverCheck(coverKey, isSelected)}}
                                            size={coverSizes[coverKey]}
                                            initialSelected={cover.selected}
                                            isSocialMediaActive={true}
                                        />
                                        </>
                                    }
                                </Fragment>
                            </Col>
                        }
                        </>
                    )
                })
            }
            </Row>
        </>
    )
}