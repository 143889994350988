import { ListGroup, Row, Col, OverlayTrigger, Tooltip  } from "react-bootstrap";
import { useEffect, useState, forwardRef } from "react";
import { Link, useMatch, useNavigate } from "react-router-dom";
import NwBadgeWithCount from "../_utilityComponents/NwBadgeWithCount/NwBadgeWithCount";
import { Popover } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./SidebarItem.css";

const SidebarPopover = forwardRef(({
    title,
    menuItems = [],
    id,
    ...props
}, ref) => {

    const { t } = useTranslation();

    return (
        <Popover className="shadow" ref={ref} {...props} id={`${title}-${id}`}>
            <Popover.Header className="nw-responsive-font-size">{title}</Popover.Header>
            <Popover.Body>
                {
                    menuItems?.map((menuItem) => (
                        <Link className="text-primary nav-link nw-responsive-font-size-sm" to={menuItem?.path}>{t(menuItem?.translationKey)}</Link>
                    ))
                }
            </Popover.Body>
        </Popover>
    )
})

const SidebarItem = ({
    id,
    linkTo,
    collapsedSideBar,
    itemTitle,
    itemIcon,
    badgeValue,
    onItemClick,
    sidebarItemIndex,
    menuItems = [],
    additionalData = {},
    isFocusBlockItem = false,
    useTrailIcon = false,
    hideItem = false,
    itemTheme = null
}) => {
    let isFocusButton = false;
    let isExpandedItem = false;
    let trailIcon = null;
    const [ renderKey, setRenderKey ] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        setRenderKey(prevKey => prevKey + 1); // Increment key to force re-render
    }, [collapsedSideBar]);

    const match = useMatch({
        path: `${additionalData?.useForceCustomLink ?? linkTo}${additionalData?.useExactPath ? '' : '/*'}`,
    });
    const isActive = match ? true : false;

    const handleSidebarItemClick = () => {
        if (onItemClick && !additionalData?.useSidebarPopover) {
            onItemClick({
                itemIdentifier: id,
                itemTitle,
                itemIcon,
                menuItems
            })
        }
    }

    if (isFocusBlockItem) {
        if (additionalData?.focusBlockData?.isFocusButton){
            isFocusButton = true;
        }

        if (additionalData?.focusBlockData?.isExpandedItem){
            isExpandedItem = true
        }

        if (useTrailIcon && additionalData?.focusBlockData?.trailIcon){
            trailIcon = additionalData?.focusBlockData?.trailIcon
        }
    }

    const navigateTo = () => {
        if (additionalData?.useSidebarPopover){
            console.log("Using popover")
        } else {
            navigate(additionalData?.useForceCustomLink ?? linkTo)
        }
    }

    return (
        <>
            {!hideItem && (
                <OverlayTrigger
                    key={renderKey}
                    placement="auto"
                    trigger={ additionalData?.useSidebarPopover ? ['click', 'focus'] : ['hover', 'focus'] }
                    overlay={
                        collapsedSideBar ? 
                            additionalData?.useSidebarPopover ? 
                                <SidebarPopover 
                                    title={itemTitle} 
                                    menuItems={menuItems}
                                    id={id}
                                /> 
                                : <Tooltip className="nw-tooltip">{itemTitle}</Tooltip> 
                        : <></>
                    }
                >
                    <ListGroup.Item className="p-0 bg-transparent border-0" onClick={handleSidebarItemClick}>
                        <button className="nav-link d-flex w-100 h-100" onClick={() => navigateTo()}>
                            <Row className={
                                `w-100 m-0 sidebar-items-container column-gap-2 ${isActive ? 'sidebar-item-active' : ''} ${collapsedSideBar ? 'collapsed' : ''}
                                ${isFocusButton ? 'is-focus-button': ''} ${isExpandedItem ? 'is-expanded-item' : ''} theme-${itemTheme ?? 'accent-1'}`}
                            >
                                <Col xs={collapsedSideBar ? 0 : 2} className={`sidebar-item-icon-container position-relative d-flex ${collapsedSideBar ? 'justify-content-center' : 'px-2 ms-3 justify-content-end'}`}>
                                    {itemIcon}
                                    {collapsedSideBar && (
                                        <NwBadgeWithCount
                                            countValue={badgeValue}
                                            bg="accent-1"
                                            overrideVerticalValue={4}
                                            overrideHorizontalValues={{
                                                oneCharacter: isActive ? '15%' : '20%',   
                                                twoCharacter: isActive ? '1ch' : '1.5ch',
                                                threeCharacter: isActive ? '.1ch' : '1.5ch'  
                                            }}
                                        />
                                    )}
                                </Col>
                                {!collapsedSideBar && (
                                    <>
                                        <Col xs={6} className="sidebar-items-title p-0 d-flex justify-content-start align-items-center">
                                            {itemTitle}
                                        </Col>
                                        {
                                            isExpandedItem ? (
                                                <div className="expanded-item-icon-container pb-3">
                                                    { trailIcon && (
                                                        <div className="expanded-item-trail-icon ms-2">
                                                            { trailIcon }
                                                        </div>
                                                    ) }
                                                    <div className="expanded-item-icon">
                                                        {itemIcon}
                                                        <div className="expanded-item-badge">
                                                            <NwBadgeWithCount
                                                                bg="accent-inverse-1"
                                                                countValue={badgeValue}
                                                                isDefault
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <Col className="d-flex justify-content-end align-items-center">
                                                    <NwBadgeWithCount
                                                        bg={isFocusButton ? "accent-inverse-1" : "accent-1"}
                                                        countValue={badgeValue}
                                                        isDefault
                                                    />
                                                </Col>
                                            )
                                        }
                                    </>
                                )}
                            </Row>
                        </button>
                    </ListGroup.Item>
                </OverlayTrigger>
            )}
        </>
    );
}

export default SidebarItem;