import React from "react"
import { useEditor, useObjects } from "@layerhub-io/react"
import { Block } from "baseui/block"
import AngleDoubleLeft from "../../../../../components/Icons/AngleDoubleLeft"
import Scrollable from "../../../../../components/Scrollable"
import { ILayer } from "@layerhub-io/types"
import Locked from "../../../../../components/Icons/Locked"
import Unlocked from "../../../../../components/Icons/Unlocked"
import Eye from "../../../../../components/Icons/Eye"
import EyeCrossed from "../../../../../components/Icons/EyeCrossed"
import Delete from "../../../../../components/Icons/Delete"
import { Button, KIND, SIZE } from "baseui/button"
import useIsSidebarOpen from "../../../hooks/useIsSidebarOpen"
import { useTranslation } from "react-i18next"
import { toBase64 } from "../../../../../utils/data"
import { CloudArrowUp } from "react-bootstrap-icons";
import useEditorUseContext from '../../../hooks/useEditorUseContext';
import { useActiveObject } from "@layerhub-io/react"
import { Input } from 'baseui/input';


const Layers = () => {
	const editor = useEditor()
	const objects = useObjects() as ILayer[]
	const [layerObjects, setLayerObjects] = React.useState<any[]>([])
	const isSidebarItemOpen = useIsSidebarOpen();
	const {t} = useTranslation();
	const inputFileRef = React.useRef<HTMLInputElement>(null)
	const {use} = useEditorUseContext();
	const activeObject: any = useActiveObject();

	const handleDropFiles = async (files: FileList) => {
		const file = files[0]
		const base64 = (await toBase64(file)) as string
		let preview = base64

		const isVideo = file.type.includes("video")
		const type = isVideo ? "StaticVideo" : "StaticImage"

		const upload = {
		src: base64
		}

		updateImage(upload);
	}

	const handleInputFileRefClick = () => {
		inputFileRef.current?.click()
	}

	const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		handleDropFiles(e.target.files!)
	}

	const updateImage = (options : any) => {
		editor.objects.update(options)
	}

	React.useEffect(() => {
		if (objects) {
		setLayerObjects(objects)
		}
	}, [objects])

	React.useEffect(() => {
		let watcher = async () => {
		if (objects) {
			setLayerObjects([...objects])
		}
		}
		if (editor) {
		editor.on("history:changed", watcher)
		}
		return () => {
		if (editor) {
			editor.off("history:changed", watcher)
		}
		}
	}, [editor, objects])

  return (
    <Block className="pt-4 px-3" $style={{ flex: 1, display: "flex", height: '100%', flexDirection: "column" }}>
      <Block
        className="nw-font-open-sans mb-3"
        $style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: isSidebarItemOpen ? ".1rem" : "1.5rem",
        }}
      >
        <Block>{t('editor.panels.panelsList.layers')}</Block>
      </Block>
      <Scrollable>
        <Block className="text-white p-2 px-3 border border-1">
          {layerObjects.map((object) => (
            <Block
              className="py-1 px-2 my-2 rounded-1"
              $style={{
                display: "grid",
                gridTemplateColumns: "1fr 90px",
                borderRadius: "2px",
                alignItems: "center",
                transition: "all 150ms ease-in-out",
				backgroundColor: object.id === activeObject?.id ? 'var(--nw-accent-color-1)' : '',
                ":hover": {
                  background: "var(--nw-accent-color-1)",
                },
              }}
              key={object.id}
            >
			{
				use === "tg_model" ?
				<Block $style={{ display: "flex", justifyContent: "flex-start", cursor: "pointer" }} onClick={() => editor.objects.select(object.id)}>
					<Input type="text" value={object.name} onChange={(e) => {
						const value = e.target.value;
						editor.objects.update({name: value});
					}}/>
				</Block>
				:
				<Block $style={{ display: "flex", justifyContent: "flex-start", cursor: "pointer" }} onClick={() => editor.objects.select(object.id)}>
				{object.name}
				</Block>

			}
              
              <Block $style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                {object.locked ? (
                  <Button
                    kind={KIND.tertiary}
                    size={SIZE.mini}
                    onClick={() => editor.objects.unlock(object.id)}
                    overrides={{
                      Root: {
                        style: {
                          color: "white",
                          paddingLeft: "4px",
                          paddingRight: "4px",
                          ':hover': {
                            color: 'black'
                          }
                        },
                      },
                    }}
                  >
                    <Locked size={24} />
                  </Button>
                ) : (
                  <Button
                    kind={KIND.tertiary}
                    size={SIZE.mini}
                    onClick={() => editor.objects.lock(object.id)}
                    overrides={{
                      Root: {
                        style: {
                          color: "white",
                          paddingLeft: "4px",
                          paddingRight: "4px",
                          ':hover': {
                            color: 'black'
                          }
                        },
                      },
                    }}
                  >
                    <Unlocked size={24} />
                  </Button>
                )}

                {object.visible ? (
                  <Button
                    kind={KIND.tertiary}
                    size={SIZE.mini}
                    onClick={() => editor.objects.update({ visible: false }, object.id)}
                    overrides={{
                      Root: {
                        style: {
                          color: "white",
                          paddingLeft: "4px",
                          paddingRight: "4px",
                          ':hover': {
                            color: 'black'
                          }
                        },
                      },
                    }}
                  >
                    <Eye size={24} />
                  </Button>
                ) : (
                  <Button
                    kind={KIND.tertiary}
                    size={SIZE.mini}
                    onClick={() => editor.objects.update({ visible: true }, object.id)}
                    overrides={{
                      Root: {
                        style: {
                          color: "white",
                          paddingLeft: "4px",
                          paddingRight: "4px",
                          ':hover': {
                            color: 'black'
                          }
                        },
                      },
                    }}
                  >
                    <EyeCrossed size={24} />
                  </Button>
                )}
                <Button
                  kind={KIND.tertiary}
                  size={SIZE.mini}
                  onClick={() => editor.objects.remove(object.id)}
                  overrides={{
                    Root: {
                      style: {
                        color: "white",
                        paddingLeft: "4px",
                        paddingRight: "4px",
                        ':hover': {
                          background: "white",
                          color: "var(--nw-accent-color-1)",
                        }
                      },
                    },
                  }}
                >
                  <Delete size={24} />
                </Button>
                {
                  object.type === "StaticImage" && 
                  <Block>
                    <Button
                  kind={KIND.tertiary}
                  size={SIZE.mini}
                  onClick={() => handleInputFileRefClick()}
                  overrides={{
                    Root: {
                      style: {
                        color: "white",
                        paddingLeft: "4px",
                        paddingRight: "4px",
                        ':hover': {
                          background: "white",
                          color: "black"
                        }
                      },
                    },
                  }}
                >
                  <CloudArrowUp size={24} />
                </Button>
                    <input onChange={handleFileInput} type="file" id="file" ref={inputFileRef} style={{ display: "none" }} />
                  </Block>
                }
              </Block>
            </Block>
          ))}
        </Block>
      </Scrollable>
    </Block>
  )
}

export default Layers
