import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { loginUser } from "../../../services/auth";
import { useAuth } from "../../../contexts/AuthContext";
import { useTranslation, Trans } from "react-i18next";
import { useMediaQuery } from "@uidotdev/usehooks";
import { Form, FloatingLabel, Button, Spinner } from "react-bootstrap";
import { getRequestAccessMail } from "../../../config";
import { Envelope, Key } from "react-bootstrap-icons";
import { NwLoaderSpinner } from "../../_utilityComponents/NwIcons/NwIcons";

function LoginForm () {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginResponseText, setLoginResponseText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const isMobile = useMediaQuery("only screen and (max-width : 768px)");
    const { isAuthenticated, Authenticate } = useAuth();

	const emailAddressLabel = (
		<>
			<Envelope className="mx-2"/>
			<span>{ t('loginPage.formSection.form.emailFieldLabel') }</span>
		</>
	)
	const passwordLabel = (
		<>
			<Key className="mx-2"/>
			<span>{ t('loginPage.formSection.form.passwordFieldLabel') }</span>
		</>
	)

    async function handleLoginSubmit (e) {
		e.preventDefault();
        setIsLoading(true);
        const loginResponse = await loginUser({email: email, password: password});
        if (loginResponse){
            await Authenticate(loginResponse);
            setIsLoading(false);
        } else {
            setLoginResponseText("Identifiants invalides.");
            setIsLoading(false);
        }
        return;
    }

    useEffect(() => {
        if (isAuthenticated !== null && isAuthenticated == true ){
            navigate('/');
        }
    }, [isAuthenticated])

    return (
        <>
            { !isAuthenticated && (
				<div className={`login-form-main-container-main-wrapper ${ isMobile ? 'p-4' : 'px-4 py-4 p-xxl-5' }`}>
					<span className="text-white fs-2 fw-bold mb-1 nw-responsive-font-size-xl mb-xxl-4">{ t('loginPage.formSection.form.formTitle') }</span>
					<Form className="border-0 lg login-form-elem my-1 my-xxl-2">
						<FloatingLabel controlId="floatingEmailAddress" label={emailAddressLabel} className="mb-2 mb-xxl-3 nw-responsive-font-size" style={{ color: 'var(--nw-text-placeholder-color)' }}>
							<Form.Control type="email" className="rounded-1 rounded-1 border-0 shadow-none nw-responsive-font-size" placeholder="Email Address" onChange={(event) => setEmail(event.target.value)}/>
						</FloatingLabel>
						<FloatingLabel controlId="floatingPassword" label={passwordLabel} className="mb-3 mb-xxl-4 nw-responsive-font-size" style={{ color: 'var(--nw-text-placeholder-color)' }}>
							<Form.Control type="password" className="rounded-1 border-0 shadow-none nw-responsive-font-size-lg" placeholder="Password" onChange={(event) => setPassword(event.target.value)} />
						</FloatingLabel>
						<div className="text-white text-bg-danger d-flex justify-content-center nw-responsive-font-size mb-2">{loginResponseText}</div>
						<Button 
							type="submit" 
							variant="light" 
							className="w-100 rounded-1 login-form-button fw-semibold d-flex justify-content-center align-items-center" 
							disabled={isLoading} onClick={(e) => handleLoginSubmit(e)}
						>
							{isLoading ? (                     
									<span>
										<NwLoaderSpinner isSpinner size={20}/>
									</span>
								) : (
									<span className="me-2">{ t('loginPage.formSection.form.loginButtonLabel') }</span>
								)
							}
                        </Button>
					</Form>
                    <div className="d-flex flex-column" to={`mailto:${getRequestAccessMail()}`}>
                        <small className="request-mail-access-text text-white mt-3" style={{fontSize: '0.6rem'}}>
							<Trans
								i18nKey={'loginPage.formSection.form.accessRequestLabel'}
								components={{
									Link: <Link className="link-underline link-underline-opacity-0 request-mail-access-text text-white" to={`mailto:${getRequestAccessMail()}`}/>
								}}
							/>
						</small>
						<small><a className="request-mail-access-text text-white mt-3" style={{fontSize: '0.6rem'}} href="/forgot-password">{ t('loginPage.formSection.form.forgotPasswordLabel') }</a></small>       
                    </div>
				</div>
            )}
        </>
    )
}

export default LoginForm;