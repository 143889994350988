import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";
import ProtectedRoute from "./ProtectedRoute";
import MainLayout from "../_layouts/MainLayout";
import React from 'react'

import { getAllRoutes } from "../config";

// Recursive function to render routes and their nested routes
function renderRoutes(routes) {
    return routes.map((route, index) => {
        // Check if the route has sub-menu items
        if (route.subMenu && route.subMenu.length > 0) {
            return (
                <React.Fragment key={index}>
                    {!route.isHeader && (
                        <>
                            {route.isProtected && route.element ? (
                                <Route key={index} path={route.path} element={
                                    <ProtectedRoute>
                                        {route.element}
                                    </ProtectedRoute>
                                } />
                            ) : (
                                <Route key={index} path={route.path} element={route.element} />
                            )}
                        </>
                    )}
                    {renderRoutes(route.subMenu)}  // Recursively render sub-routes
                </React.Fragment>
            );
        } else {
            return route.isProtected && route.element ? (
                <Route key={index} path={route.path} element={
                    <ProtectedRoute>
                        {route.element}
                    </ProtectedRoute>
                } />
            ) : (
                <Route key={index} path={route.path} element={route.element} />
            );
        }
    });
}


const AppRoutes = () => {
    const { userRights } = useAuth();
    return (
        <BrowserRouter>
            <MainLayout>
                <Routes>
                    { renderRoutes(getAllRoutes()) }
                    {/* Page Not Found -- TODO*/}
                    {/* <Route path="*" element={>NotFound404/>} /> */}
                </Routes>
            </MainLayout>
        </BrowserRouter>
    );
}

export default AppRoutes