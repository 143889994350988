import React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Row, Col, Container } from "react-bootstrap";
import {fetchPageByLocaleAndSlug} from "../../services/pages"
import { useTranslation } from 'react-i18next';
import Pages from '../Pages/Pages';


const PageSlug = () => {

    const [posts, setPosts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [locale,setLocale] = useState(useParams().locale);
    const [slug,setSlug] = useState(useParams().slug);

    const { i18n } = useTranslation();


    const fetchData = async (locale,slug) => {
        
        setIsLoading(true);
        const response = await fetchPageByLocaleAndSlug(locale,slug);
        setPosts(response);
        setIsLoading(false);   
    };


    useEffect(() => {
        fetchData(i18n.language ?? 'fr',slug);
      }, []);

    return(
        <>
        
        <Container>
        {isLoading ? 
                <span 
                style={{ marginLeft: "10px" }} 
                className="spinner-border spinner-border-sm" 
                role="status" 
                aria-hidden="true"></span> : null}
        {posts ? (
        <>
          <h1>{posts.titleTranslated}</h1>
          <div dangerouslySetInnerHTML={{ __html: posts.bodyTranslated }} />
        </>
      ) : (
        <p>Page not found</p>
      )}
        </Container>    


        
        </>
    )
};

export default PageSlug;