import React, {Fragment} from "react";
import { useEffect, useState, useRef } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { Link } from 'react-router-dom';
import {fetchAllPages} from "../../services/pages"
import { useTranslation } from 'react-i18next';
import { NwLoaderSpinner } from "../_utilityComponents/NwIcons/NwIcons";

const Pages = () => {

	const [posts, setPosts] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const { i18n } = useTranslation();

	const fetchData = async (locale) => {
		setIsLoading(true);
		const response = await fetchAllPages(locale);
		setPosts(response);
		setIsLoading(false);   
	};
	

	useEffect(() => {
		fetchData(i18n.language ?? 'fr');
	}, []);

	useEffect(() => {
		fetchData(i18n.language ?? 'fr');
	}, [i18n.language]);


	return (
		<div className="pages align-items-center d-flex nw-responsive-font-size" style={{ float: 'right'}}>
			{Object.keys(posts).length > 0 ? (
				<>
					{Object.keys(posts)?.map((key, index) => (
						<Fragment key={index}>
							<span key={key} className="d-flex align-items-center">
								<small>
									<Link to={`/pages/${i18n.language}/${posts?.[key].slug}`} className='nav-link'>{posts?.[key].titleTranslated}</Link>
								</small>
							</span>
							{index < Object.keys(posts).length - 1 && (
								<span className="px-1 d-flex align-items-center">-</span>
							)}
						</Fragment>
					))}
				</>
			) : (
				<NwLoaderSpinner isSpinner size={'1em'} className="nw-responsive-font-size"/>
			)}
		</div>
	);
};

export default Pages;