import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import {
  BrowserRouter as Router,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import MainLogo from "../_utilityComponents/MainLogo";
import { NwLoaderSpinner } from "../_utilityComponents/NwIcons/NwIcons";
import { getAuthTokenWithRefresh, getBackendApiUrl, getEasyPlvApiToken,getBackendUrl } from "../../config";

export default function ResetPassword(props) {
  const [emailAddress, setEmailAddress] = useState(null);
  const { t } = useTranslation();
  const [collapsedSideBar, setCollapsedSideBar] = useState(true);
  const [notificationMessage, setNotificationMessage] = useState(null);
  const [token,setToken] = useState(props.token);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const formToken = formData.get('token');
    const newPassword = formData.get('newPassword');
    const confirmNewPassword = formData.get('confirmNewPassword');

    const editedData = { token: formToken, newPassword: newPassword,confirmNewPassword: confirmNewPassword };
    fetch(getBackendUrl("api-user-reset-password"), {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(editedData), 
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        Swal.fire("User Updated", " ", "success");
        navigate("/");
      })
      .catch((error) => {
        Swal.fire("Password cannot be changed", " ", "error");
        // console.error("There was a problem with the PUT request:", error);
      });
    
  };

  return (
    <Container>
        {token ? (
      <Row>
        <div className="logo d-flex justify-content-center" style={{ marginTop: '20px',color: '#0D6DFD' }}>
          <MainLogo size={collapsedSideBar ? 40 : 30} />
        </div>

        <div className="fpCard d-flex justify-content-center" style={{ marginTop: '20px' }}>
          <Card style={{ width: '30rem' }}>
            <Card.Body>
              <Card.Title>
                {t("loginPage.formSection.form.forgotPasswordText")}
              </Card.Title>
            
              <Card.Text>
                {t("loginPage.formSection.form.enterNewPassword")}
              </Card.Text>
              <Form id="trouble-signing-form" onSubmit={handleSubmit}>
                <input type="hidden" value={token} name="token"></input>
              <Form.Group controlId="newPassword">
              <Form.Label>{t('editProfile.newPassword')}</Form.Label>
              <Form.Control
                type="password"
                name="newPassword"
              />
            </Form.Group>
            <Form.Group controlId="confirmNewPassword">
              <Form.Label>{t('editProfile.confirmNewPassword')}</Form.Label>
              <Form.Control
                type="password"
                name="confirmNewPassword"
              />
            </Form.Group>
                <br></br>
                <Button type="submit">
                  {t("loginPage.formSection.form.forgotPasswordSubmitButton")}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </Row>
      ) : (
        <Row>
        <h1>{t("loginPage.formSection.form.forgotPasswordText")}</h1>
        </Row>
      )}
    </Container>
  );
}
