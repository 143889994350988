import React, { useState } from 'react';
import CartPlaceholder from '../../components/Cart/CartPlaceholder';
const Test = () => {

	return (
		<>
			<div className='w-100'>
				<CartPlaceholder/>
			</div>
		</>
	);
}

export default Test;