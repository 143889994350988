import Toast from 'react-bootstrap/Toast';
import { seeNotification } from '../../services/notification';
import { useState } from 'react';
import React from 'react';


function SimpleNotification (props) {
    const [show, setShow] = useState(true);
    let children = props.children;
    const variant = props.variant;
    const notificationId = props.notificationId;

    async function handleCloseNotification(notificationId) {
        try {
            setShow(prevShow => false); // set on front first
            const response = await seeNotification(notificationId);
            setShow(prevShow => !response['seen']);
        } catch (e) {console.error(e)}
    }

    return (
        <Toast bg={variant} show={show} animation={true} delay={3000} onClose={() => {handleCloseNotification(notificationId)}}>
            {React.cloneElement(children, {handleCloseNotification})}
        </Toast>
    )
}
export default SimpleNotification;