import { useState, useEffect } from "react";
import { 
    getSocialMediaPackGroupsByUserStoreAndFilters,
    deleteSocialMediaPackGroupById,
    downloadSocialMediaPackGroupById,
    createSocialMediaPackGroupCategory,
    editSocialMediaPackGroupCategory,
    deleteSocialMediaPackGroupCategory,
    getSocialMediaPackGroupCategoriesByFilters,
    assignSocialMediaPackGroupToCategory,
} from "../../services/social_media";
import CategorySideFilter from '../../components/SocialMedia/CategorySideFilter/CategorySideFilter';
import NwCustomModal from "../../components/_utilityComponents/NwCustomModal/NwCustomModal";
import { Row, Col } from 'react-bootstrap';
import ListCard from './ListCard/ListCard';
import { useTranslation } from 'react-i18next';
import { 
    EyeFill,
    Download,
    XLg,
    Folder,
} from 'react-bootstrap-icons';
import ResponsivePagination from 'react-responsive-pagination';
import { 
    Form, 
    Button,
} from "react-bootstrap";
import {useSearchParams} from "react-router-dom";
import {triggerToast} from "../../helpers/helpers";


export default function CampaignsPage() {
    const { t } = useTranslation();
    const [socialMediaPackGroups, setSocialMediaPackGroups] = useState([]);
    const [currentPaginationPage, setCurrentPaginationPage] = useState(1);
    const [filters, setFilters] = useState({});
    const [pageCount, setPageCount] = useState(0);
    const itemsPerPage = 20;

    const [categories, setCategories] = useState([]);
    const [categoriesLoading, setCategoriesLoading] = useState(true);
    const [selectedCampaign, setSelectedCampaign] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [initialFilterSet, setInitalFilterSet] = useState(false);

    useEffect(() => {
        const paramCategoryId = searchParams.get("categoryId");
        if (paramCategoryId) {
            setFilters(prevFilters => {
                const newFilters = {...prevFilters};
                newFilters['category'] = paramCategoryId;
                return newFilters;
            });

            setCurrentPaginationPage(1);
        }
        setInitalFilterSet(true);
    }, [searchParams]);

    useEffect(() => {
        if (initialFilterSet) {
            getSocialMediaPackGroupsByUserStoreAndFilters(currentPaginationPage, filters).then(response => {
                setSocialMediaPackGroups(response?.items);
                setPageCount(Math.ceil(response?.totalItems / itemsPerPage));
            })
        }
    }, [currentPaginationPage, filters, initialFilterSet])

    const handleDeletePack = async (id) => {
        const success = await deleteSocialMediaPackGroupById(id);
        if (success) {
            getSocialMediaPackGroupsByUserStoreAndFilters(currentPaginationPage, filters).then(response => {
                setSocialMediaPackGroups(response?.items);
                setPageCount(Math.ceil(response?.totalItems / itemsPerPage));
            });
        }
    }

    const handleDownloadZip = async (id) => {
        const response = await downloadSocialMediaPackGroupById(id);
		if (response) {
			const url = URL.createObjectURL(response);
			const a = document.createElement("a");
			a.href = url;
	
			a.download = "campaign.zip";
			document.body.appendChild(a);
			a.click();
	
			document.body.removeChild(a);
			URL.revokeObjectURL(url);
		}

		return;
    }

    const handleCategorySelection = (categoryId) => {
        setFilters(prevFilters => {
            const newFilters = {...prevFilters};
            newFilters['category'] = categoryId;
            return newFilters;
        });
        setCurrentPaginationPage(1);
    }

    const updateCategories = async () => {
        setCategoriesLoading(true);
        getSocialMediaPackGroupCategoriesByFilters().then(async response => {
            setCategories(response?.items)
            setCategoriesLoading(false);
        });
    } 
    
    const getAllPackGroups = () => {
        setCurrentPaginationPage(1);
        setFilters({});
    }

    const swapCategory = async (packGroupCategoryId, packGroupId) => {
        const success = await assignSocialMediaPackGroupToCategory(packGroupCategoryId, packGroupId);
        updateCategories();
        return success;
    }

    useEffect(() => {
        updateCategories()
    }, []);

    return (
        <div className="nw-social-media-campaign-list-page">
            <div className="nw-social-media-campaign-list-page-wrapper d-flex mb-3 px-4 py-3 flex-grow-1 column-gap-3">
                <CategorySideFilter 
                    categories={categories} 
                    createNewCategory={createSocialMediaPackGroupCategory} 
                    updateCategories={updateCategories} 
                    categoriesLoading={categoriesLoading} 
                    handleCategorySelection={handleCategorySelection}
                    deleteCategory={deleteSocialMediaPackGroupCategory}
                    editCategory={editSocialMediaPackGroupCategory}
                    getAllData={getAllPackGroups}
                />
                <Row className='w-75 gx-2'>     
                    {
                        socialMediaPackGroups.map((socialMediaPackGroup, index) => {
                            const swapFolderModal = <SwapFolderModal submitAction={async (categoryId) => {
                                const success = await swapCategory(categoryId, socialMediaPackGroup.id);
                                return success;
                            }} options={categories} />
                            return (
                                <Col className='flex-grow-0' key={socialMediaPackGroup.id}>
                                    <ListCard 
                                        key={socialMediaPackGroup.id}
                                        title={socialMediaPackGroup?.name} 
                                        imgSrc={socialMediaPackGroup?.imgSrc}
                                        actions={[
                                            {
                                                title: 'Download',
                                                type: "button",
                                                icon: <Download/>,
                                                onClick: () => handleDownloadZip(socialMediaPackGroup.id)
                                            },
                                            {
                                                title: "View",
                                                icon: <EyeFill/>,
                                                type: "link",
                                                href: `/social-media/campaigns/${socialMediaPackGroup.id}/posters`
                                                // onClick: () => setSelectedCampaign(socialMediaPackGroup.id)
                                            },
                                            {
                                                title: "Delete",
                                                type: "button",
                                                icon: <XLg/>,
                                                onClick: () => handleDeletePack(socialMediaPackGroup.id)
                                            },
                                            {
                                                type: "custom",
                                                component: swapFolderModal
                                            }
                                        ]}    
                                    />
                                </Col>
                            )
                        })
                    }
                </Row>
            </div>
            <ResponsivePagination
                previousLabel={t("back")} 
                nextLabel={t("next")} 
                current={currentPaginationPage}
                total={pageCount}
                onPageChange={setCurrentPaginationPage}
            />
        </div>
    )
}

function SwapFolderModal({options = [], submitAction}) {
    const { t } = useTranslation();
    const [closeModal, setCloseModal] = useState(true);
    const [isAssigning, setIsAssigning] = useState(false);
    const [selectedOption, setSelectedOption] = useState(undefined);
    const categoriesById = {};

    for (const category of options) {
        categoriesById[category.id] = category;
    }

    return (
        <NwCustomModal
            customButton={
                <Button className='list-card-btn rounded-1 nw-responsive-font-size-sm'>
                    <Folder
                        title={t("pageTitle.addToDoc")}
						className="w-100 h-100"
                    />
                </Button>
            }
            closeModal={closeModal}
        >
            <div>
                <br></br>
                <p style={{ textAlign: "left" }}>
                    {t("pageTitle.modalTitleDocument")}
                </p>
                <Form onSubmit={async (e) => {
                    setIsAssigning(true);
                    e.preventDefault();
                    submitAction(selectedOption).then(response => {
                            setIsAssigning(false);
                            setCloseModal(!closeModal)

                            let successMessage = t('campaignsPage.alerts.campaign_remove_folder');
                            if (parseInt(selectedOption) !== 0) {
                                console.log('here');
                                successMessage = t('campaignsPage.alerts.campaign_move_folder_success') + ` ${categoriesById[selectedOption]?.name}`;
                            }   

                            if (response) {
                                triggerToast(null, {
                                    "type": "success",
                                    "title": successMessage,
                                    "closeButton" : true
                                })
                            } else {
                                triggerToast(null, {
                                    "type": "error",
                                    "title": t('campaignsPage.alerts.campaign_move_folder_error'),
                                    "closeButton" : true
                                })
                            }
                        }
                    );
                }}>
                    <select
                        name="category"
                        id="prod-category"
                        className="form-select modal-select"
                        onChange={(e) => {
                            setSelectedOption(e.target.value);
                        }}
                        style={{ marginTop: "8px" }}
                        value={selectedOption}
                    >
                        <option value="0">{t("pageTitle.selectDocument")}</option>
                        {options?.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.name}
                            </option>
                        ))}
                    </select>
                    <br />
                    <div
                        className="buttons-custom"
                        style={{ backgroundColor: "#e6f0ff", height: "65px" }}
                    >
                    <div
                        className="buttons-actions"
                        style={{ float: "right", marginRight: "5px" }}
                    >
                        <Button
                            variant="outline-primary"
                            id="close-button"
                            style={{ marginRight: "10px" }}
                            onClick={() => setCloseModal(!closeModal)}
                        >
                            {t("pageTitle.cancel")}
                        </Button>

                        <Button style={{}} type="submit">
                            {t("pageTitle.move")}
                            {isAssigning ? (
                                <span
                                    style={{ marginLeft: "10px" }}
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            ) : null}
                        </Button>
                    </div>
                    </div>
                </Form>
            </div>
        </NwCustomModal>
    );
}