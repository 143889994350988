import React, { useEffect, useState } from 'react';
import { Parser } from 'html-to-react';
import { getTranslationsByApi } from '../../helpers/helpers';
import { useTranslation } from 'react-i18next';

const PrivacyPolicy = () => {
    const HtmlToReactParser = Parser;

    const [ currentTranslatedData, setCurrentTranslatedData ] = useState(null);
	const { i18n } = useTranslation();

	useEffect(() => {
	  
		getTranslationsByApi(i18n.language, 'privacypolicy')
			.then((translatedData) => {
                const htmlInput = translatedData?.bodyTranslated;
                const htmlToReactParser = new HtmlToReactParser();
                const reactElement = htmlToReactParser.parse(htmlInput);
                setCurrentTranslatedData(reactElement);
			})
			.catch((error) => {
				console.error("An error occurred:", error);
			})

	}, [i18n.language])

    return (
        <>
            <div className='p-5'>
                {currentTranslatedData}
            </div>
            
        </>
    );
}

export default PrivacyPolicy;