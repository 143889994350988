import {useState} from 'react';
import { XLg } from "react-bootstrap-icons";
import { Button } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import NotFoundImg from '../../../assets/images/no-image-md.png'

const NwDropzonePreviewItem = ({ fileUploaded, clearProductImage, isDisabled = false, isDebug, allowDelete = true }) => {
    const { t } = useTranslation();

    const [isDeleteBtnDisabled, setIsDeleteBtnDisabled] = useState(false);
    const [isDeleteSuccess, setIsDeleteSuccess] = useState(true);

    if (isDebug){
        console.log("Nw Dropzone Preview Item", fileUploaded);
    }

    const handleImageError = (e) => {
        e.target.src = NotFoundImg; // Set the fallback image if there's an error loading the original
    };

    const handlePreviewItemDelete = () => {
        setIsDeleteBtnDisabled(true);
        clearProductImage(fileUploaded).then((response) => {
            setIsDeleteBtnDisabled(false);
            if (!response.success){
                setIsDeleteSuccess(false);
            } else {
                setIsDeleteSuccess(true);
            }
        });
    }

    return (
        <div className="nw-dropzone-preview-container position-relative">
            <img src={fileUploaded?.uploadedInfo?.imageCdnUrl || fileUploaded?.preview || NotFoundImg} onError={handleImageError} alt="Not Found" style={{
                width: '100%',
                objectFit: 'contain'
            }}/>
            {
                allowDelete && 
                <Button disabled={isDeleteBtnDisabled || isDisabled} className='rounded-circle p-1 d-flex justify-content-center align-items-center position-absolute' onClick={() => handlePreviewItemDelete()} style={{
                    top: '2%',
                    right: '2%'
                }}>
                    <XLg size={15}/>
                </Button>
            }
            <div className={`text-white w-100 bg-danger p-2 ${isDeleteSuccess ? 'd-none' : ''}`}>
                {t('postersPage.newPosterPage.productImageDropzone.errorImageDelete')}
            </div>
        </div>
    );
}

export default NwDropzonePreviewItem;