import { 
	Placeholder,
	Row,
	Button,
	Col
} from "react-bootstrap";
import { EmojiFrown } from "react-bootstrap-icons";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

//Nw Custom Icons
import { NwCartIcon } from "../_utilityComponents/NwIcons/NwIcons";

const CartPlaceholderGlobalActions = () => {
	return (
		<div className="cart-placeholder-actions-container d-flex justify-content-end w-100 column-gap-2 my-3 py-3">
			<Placeholder.Button style={{ minWidth: '100px', backgroundColor: '#E6F0FF' }} className="rounded-1 border-0" xs={1} aria-hidden="true" />
			<Placeholder.Button style={{minWidth: '100px'}} className="rounded-1" xs={1} aria-hidden="true" />
		</div>
	)
}

const CartItemPlaceholder = () => {
	return (
		<Row
			className="cart-item-placeholder d-flex align-items-center border-top w-100 m-0"
			style={{
				height: '6rem'
			}}
		>
			<Col xs={2} className="p-0">
				<Placeholder className="m-0 w-100 d-flex align-items-center justify-content-center" as="p" animation="wave">
					<Placeholder bg="secondary" className="h-100" xs={11} />
				</Placeholder>
			</Col>
			<Col xs={4} className="p-0">
				<Placeholder className="m-0 w-100 d-flex align-items-center justify-content-center" as="p" animation="wave">
					<Placeholder bg="secondary" className="h-100" xs={11} />
				</Placeholder>
			</Col>
			<Col xs={4} className="p-0">
				<Placeholder className="m-0 w-100 d-flex align-items-center justify-content-center" as="p" animation="wave">
					<Placeholder bg="secondary" className="h-100" xs={11} />
				</Placeholder>
			</Col>
			<Col xs={2} className="p-0">
				<Placeholder className="m-0 w-100 d-flex align-items-center justify-content-center" as="p" animation="wave">
					<Placeholder bg="secondary" className="h-100" xs={11} />
				</Placeholder>
			</Col>
		</Row>
	);
}

const CartGroupPlaceHolder = ({
	cartItemsPlaceholderCount = 5,
	isCartEmpty = false
}) => {

	const placeholderItems = Array.from({ length: cartItemsPlaceholderCount }, (_, index) => index);
	const { t } = useTranslation();

	return (
		<div className="cart-group-format-placeholder-container d-flex flex-column h-100 w-100">
			<div 
				className="placeholder-format-header d-flex justify-content-between align-items-center px-3 w-100"
				style={{
					height: '4rem',
					backgroundColor: 'rgb(43, 43, 43)'
				}}
			>
				<Placeholder xs={3} className="m-0 d-flex align-items-center" as="p" animation="wave">
					<Placeholder bg="secondary" className="h-100" style={{ opacity: 1 }} xs={12} />
				</Placeholder>
				<Placeholder xs={7} className="m-0 d-flex align-items-center" as="p" animation="wave">
					<Placeholder bg="secondary" style={{ opacity: 1 }} xs={12} />
				</Placeholder>
			</div>

			<div className={`cart-group-format-cart-items border border-1 ${isCartEmpty ? 'position-relative': ''}`}>
				{placeholderItems.map((_, index) => (
					<CartItemPlaceholder key={index}/>
				))}

				{isCartEmpty && (
					<div className="cart-is-empty-overlay position-absolute w-100 h-100 top-0 fs-normal">
						<div className="cart-is-empty-container w-100 h-100 d-flex justify-content-center align-items-center">
							<div className="cart-is-empty-wrapper bg-secondary w-25 h-75 d-flex flex-column align-items-center rounded-2 p-3">
								<NwCartIcon className="text-white" size={150}/>
								<span style={{ textAlign: 'center' }} className="cart-is-empty-text-container text-white fw-bold fs-3">
									<Trans 
										i18nKey={'cartPage.cartTable.cartIsEmpty'}
										components={[<EmojiFrown />]}
									/>
								</span>
								<Link to={'/posters'}>
									<Button className="rounded-1 px-5 py-2 border-0 mt-3 d-flex align-items-center fw-semibold text-center"  style={{ minWidth: '100px' }}>
										{t("cartPage.cartTable.createPoster")}
									</Button>
								</Link>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}


const CartPlaceholder = ({
	isCartEmpty = false
}) => {

	return (
		<div className="cart-placeholder-container w-100">
			<CartPlaceholderGlobalActions/>
			<CartGroupPlaceHolder isCartEmpty={isCartEmpty}/>
			<CartPlaceholderGlobalActions/>
		</div>
	);	
}

export default CartPlaceholder;
