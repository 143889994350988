import React from "react"
import { IScene } from "@layerhub-io/types"
import { Block } from "baseui/block"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import useDesignEditorContext from "../../../hooks/useDesignEditorContext"
import useOnClickOutside from "../../../hooks/useOnClickOutside"
import { 
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
	ModalButton
} from "baseui/modal"
import { Button } from "react-bootstrap"
import { Upload } from "baseui/icon";
import { XLg } from "react-bootstrap-icons"
import useEditorUseContext from "../../../hooks/useEditorUseContext"

interface Props {
  isCurrentScene: boolean
  scene: IScene
  preview: string
  index: number
  changePage: (p: IScene) => void
}

const SceneItem = ({ isCurrentScene, scene, preview, index, changePage }: Props) => {
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: scene.id })
	const sceneRef = React.useRef<HTMLDivElement>(null)
	const { setContextMenuTimelineRequest } = useDesignEditorContext()
	const [isOpen, setIsOpen] = React.useState(false);
	const { scenes, setScenes } = useDesignEditorContext();
	const {use} = useEditorUseContext();


	function close() {
		setIsOpen(false);
	}

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
		cursor: "pointer",
	}

	React.useEffect(() => {
		const timeLineItemDiv = sceneRef.current
		const handleContextMenu = (event: MouseEvent) => {
		event.preventDefault()
		setContextMenuTimelineRequest({
			id: scene.id,
			left: event.pageX,
			top: event.pageY,
			visible: true,
		})
		}
		if (timeLineItemDiv) {
		timeLineItemDiv.addEventListener("contextmenu", handleContextMenu)
		}
		return () => {
		if (timeLineItemDiv) {
			timeLineItemDiv.removeEventListener("contextmenu", handleContextMenu)
		}
		}
	}, [sceneRef, scene])


	const deleteScene = () => {
		const sceneIds = scenes.map(scene => scene.id);
		const indexToDelete = sceneIds.indexOf(scene.id);
		const newScenes = [...scenes];
		newScenes.splice(indexToDelete, 1);
		setScenes(newScenes);
		close();
	}

	return (
		<Block
			ref={setNodeRef}
			{...attributes}
			{...listeners}
			$style={{
				backgroundColor: isCurrentScene ? "rgb(243,244,246)" : "#ffffff",
				padding: "1rem 0.5rem",
				position: "relative",
				...style,
			}}
		>
			{
				use === "social_media" ? 
				<>
					<Button 
						onClick={() => setIsOpen(true)}
						className=" rounded-circle position-absolute nw-bg-accent-1 border-0 z-3 top-0 end-0 p-1 d-flex justify-content-center nw-responsive-font-size align-items-center"
						style={{
							height: "fit-content",
							width: "fit-content"
						}}
					>
						<XLg/>
					</Button>
					<Modal onClose={close} isOpen={isOpen}>
						<ModalHeader>Delete Poster</ModalHeader>
						<ModalBody>
							Are you sure you want to delete this poster
						</ModalBody>
						<ModalFooter>
						<ModalButton kind="tertiary" onClick={close}>
							Cancel
						</ModalButton>
						<ModalButton onClick={deleteScene}>Yes</ModalButton>
						</ModalFooter>
					</Modal>
				</>
				:
				<></>
			}
				
			<Block
				ref={sceneRef}
				onClick={() => changePage(scene)}
				$style={{
				cursor: "pointer",
				position: "relative",
				border: isCurrentScene ? "2px solid #7158e2" : "2px solid rgba(0,0,0,.15)",
				}}
			>
				<img style={{ maxWidth: "90px", maxHeight: "80px", display: "flex" }} src={preview} />
				<Block
					$style={{
						position: "absolute",
						bottom: "4px",
						right: "4px",
						background: "rgba(0,0,0,0.4)",
						color: "#fff",
						fontSize: "10px",
						borderRadius: "2px",
						height: "16px",
						width: "16px",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					{index + 1}
				</Block>
			</Block>
		</Block>
	)
}

export default SceneItem
