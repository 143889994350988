import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Messages from "../../components/Messages/Messages";

function MessagePage() {

    const [pagesList, setPagesList] = useState([]);
    const navigate = useNavigate();
   

    return (
      
        <Messages/>
    )


}

export default MessagePage;