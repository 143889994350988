import { useEffect, useState } from "react";
import { NwCommonFilter } from "../NwCustomFilters/NwCustomFilters";
import { useAuth } from "../../contexts/AuthContext";
import { getAuthTokenWithRefresh, getBackendApiUrl, getEasyPlvApiToken } from "../../config";
import {
    Table,
  } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";
import { filterMessages } from "../../services/messages";
import { current } from "@reduxjs/toolkit";

function Messages() {
  const { i18n, t } = useTranslation();
  const apiKey = getEasyPlvApiToken();
  const { isAuthenticated, username, logoutUser } = useAuth();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPage] = useState(12);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPagePaginate, setCurrentPagePaginate] = useState(0);
  const [filterOptions, setFilterOptions] = useState({
    text: null,
    date: null,
    page: currentPagePaginate
  });

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;

    const newFilterQuery ={...filterOptions, page: currentPage};
    const filteredMessages = await filterMessages(newFilterQuery);
    setData(filteredMessages?.items ?? []);
    setFilterOptions(newFilterQuery);
    window.scrollTo(0, 0);
  };

  const handleFilterSubmit = async(e) => {
    e.preventDefault();

    const queryOptions = {
      "text" : null, // this is used to search messages by subject or content
      "date" : null,
    }

    const textSearchQuery = e.target.text_search.value;
    // const dateQuery = e.target.date_search.value;
    queryOptions.text = textSearchQuery;
    // queryOptions.date = dateQuery;

    setFilterOptions(queryOptions);

    const filteredMessages = await filterMessages(queryOptions);
    setData(filteredMessages?.items ?? []);
    return;
  }

  useEffect(() => {
    async function getMessages() {
      try {
        const authToken = await getAuthTokenWithRefresh();
        const response = await fetch(getBackendApiUrl("messages"), {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
            "X-EasyPLV-Api-Token": apiKey,
          },
        });
        if (response.status === 401) {
          logoutUser();
          return false;
        }
        const data = await response.json();
      
        setData(data.items);
        setTotalItems(data.totalMessages);
        setPageCount(Math.ceil(data.totalMessages / itemsPerPage));

        setLoading(false);

      } catch (error) {
        console.error("Error fetching data: ", error);
        // Set loading state to false in case of an error
        setLoading(false);
      }
    }

    getMessages();
  }, []);

  useEffect(() => {
    if(currentPagePaginate > 0) {
      handlePageClick(currentPagePaginate);
    }
  }, [currentPagePaginate])

  return (
    <div className="px-5 pt-3">
      <div className="container">
      <NwCommonFilter
					onSubmit={handleFilterSubmit}
					className= "w-100"
          filterContainerProps={{
            className: 'mt-3 mb-3'
          }}
					autoSubmitOnReset
					filterWrapperProps={{
						className: "w-100 justify-content-around"
					}}
					filterButtonProps={{
						type: 'submit',
						children: t('validateButtonLabel')
					}}
					resetButtonProps={{
						children: t('resetFilterButton')
					}}
          filterModalProps={{
            headerContent: t('messagesPage.filterModalHeader')
          }}
					formFields={[
						{
							type: 'input',
							props: {
								id: 'search-by-subject-content-filter',
								name: 'text_search',
								placeholder: t('searchBarLabel'),
								hideLabel: true
							}
						},
            // {
            //   type: 'date',
						// 	props: {
						// 		id: 'search-by-date-filter',
						// 		name: 'date_search',
						// 		placeholder: t('searchBarLabel'),
						// 		hideLabel: true
						// 	}
            // }
					]}
				/>
        <br></br>
        {data?.length > 0 ? (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Date</th>
                <th>{t("messagesPage.messagesTable.subjectTableHeading")}</th>
                <th>{t("messagesPage.messagesTable.contentHeading")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => (
                <tr key={item.id}>
                  <td>{item?.createdAt ? (new Date(item.createdAt)).toLocaleString() : "-"}</td>
                  <td>{item.subject}</td>
                  <td>{item.body}</td>
                  <td>
                    <a
                      className="btn btn-info"
                      href={`/view/message/${item.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("messagesPage.messagesTable.viewMessageButtonLabel")}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <p>{t("messagesPage.No_messages_available")}</p>
        )}
  
        <br></br>
        <br></br>
        {totalItems > itemsPerPage && (
          <ReactPaginate
            previousLabel={t("back")}
            nextLabel={t("next")}
            breakLabel={"..."}
            pageCount={pageCount}
            forcePage={currentPagePaginate}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination justify-content-center"}
            pageClassName={"page-item"}
            pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            breakLinkClassName={"page-link"}
            activeClassName={"active"}
          />
        )}
      </div>
    </div>
  );
}

export default Messages;
