import React from 'react';
import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import NwCustomInput from '../_utilityComponents/NwFormsUi/CustomInput/NwCustomInput';

const FloatType = (props) => {
	const isDisabled = props?.isDisabled || false;
	const formFieldJson = props.formFieldJson;
	const entityFormValuesJson = props.entityFormValuesJson;
	const updateEntityFormValues = props.updateEntityFormValues;
	const configValues = formFieldJson.configValues;
	const metadata = configValues?.values?.metadata;
    const floatValueKey = `${configValues.frontUniqueKeys.number1}_${formFieldJson.id}`;
    const floatValueJson = `${floatValueKey}` in entityFormValuesJson 
	? entityFormValuesJson[`${floatValueKey}`]
	: {
		name: formFieldJson.name,
		type: 'float',
		value: "",
		metadata: metadata,
	}

	const updateFloatValue = (newFloatValue) => {
		updateEntityFormValues(prevFieldValuesJson => {
			const newFieldValuesJson = {...prevFieldValuesJson};
            floatValueJson.value = parseFloat(newFloatValue);
            newFieldValuesJson[`${floatValueKey}`] = floatValueJson;
            return newFieldValuesJson;
		})
	}

	useEffect(() => {
		updateFloatValue(floatValueJson.value);
	}, []);

	return (
		<div key={props.id} className={`mb-3 ${props.elementClasses}`}>
			<Form.Label>{props.formFieldJson.title}</Form.Label>
        	<NwCustomInput disabled={isDisabled} hideLabel onChange={(e) => updateFloatValue(e.target.value)} placeholder={floatValueJson?.value} value={floatValueJson.value} id={props.id} type="number" step={0.01} name={props.formFieldJson.name + "_" + props.formFieldJson.type}/>
		</div>
	);
}

export default FloatType;
