import { useEffect, useState } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import { getAuthTokenWithRefresh, getBackendApiUrl, getEasyPlvApiToken } from "../../config";
import { logoutUser } from "../../services/auth";
import { useTranslation } from "react-i18next";

function MessageDetail() {
  const { i18n, t } = useTranslation();

    const [messageId, setId] = useState(useParams().id);
    const [data, setData] = useState([]);
  const apiKey = getEasyPlvApiToken();
  const [loading, setLoading] = useState(true);
   
    useEffect(() => {
        async function getMessage() {
          try {
            const authToken = await getAuthTokenWithRefresh();
            const response = await fetch(getBackendApiUrl(`messages/${messageId}`),
              {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${authToken}`,
                  "X-EasyPLV-Api-Token": apiKey,
                },
              }
            );
            if (response.status === 401) {
                logoutUser();
              return false;
            }
            const data = await response.json();
    
            console.log("Data received from the API: ", data);
            // console.log("Total Data: ", data.itemsCount);
            setData(data);
            document.title = `${data.subject}`;
            setLoading(false);
          } catch (error) {
            console.error("Error fetching data: ", error);
            // Set loading state to false in case of an error
            setLoading(false);
          }
        }
    
        getMessage();
      }, []);





    return (
      
        <>
        
        <div className="container">

        <a href="/messages">{t("messagesPage.messagesTable.viewMessagePage.backToMessagesLabel")}</a>

        <br></br>
        

          <br></br>
          <h3>{t("messagesPage.messagesTable.viewMessagePage.subjectLabel")}{data.subject}</h3>

          <br></br>
          <label>{t("messagesPage.messagesTable.viewMessagePage.bodyLabel")}</label>
          <p>{data.body}</p>


        </div>

        </>
    )


}

export default MessageDetail;