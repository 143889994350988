import UKFlagIcon from '../../../assets/images/flags/union-jack-flag.webp';
import SpainFlagIcon from '../../../assets/images/flags/spain.png';
import FranceFlagIcon from '../../../assets/images/flags/france.png';
import PortugalFlagIcon from '../../../assets/images/flags/portugal.png';
import textIcon from '../../../assets/images/social media/social-media-text-icon.png';
import uploadIcon from '../../../assets/images/social media/social-media-upload-icon.png';
import eleclercIcon from '../../../assets/images/logos/logo-Leclerc.svg';

export const NwLockIcon = ({ size, className='' }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} fill="currentColor" width={size} height={size} viewBox="0 0 25.653 31.5">
            <path d="M4.552,31.5A4.5,4.5,0,0,1,0,27.057V17.366a4.5,4.5,0,0,1,4.552-4.443h.413V7.673A7.769,7.769,0,0,1,12.827,0a7.778,7.778,0,0,1,7.853,7.321l.009.352v5.25H21.1a4.5,4.5,0,0,1,4.551,4.443v9.691A4.5,4.5,0,0,1,21.1,31.5ZM2.483,17.366v9.691a2.047,2.047,0,0,0,2.069,2.019H21.1a2.047,2.047,0,0,0,2.069-2.019V17.366A2.047,2.047,0,0,0,21.1,15.346H4.552A2.047,2.047,0,0,0,2.483,17.366Zm15.722-4.443V7.673a5.384,5.384,0,0,0-10.749-.3l-.009.3v5.25ZM9.931,22.21a2.9,2.9,0,1,1,2.9,2.828A2.865,2.865,0,0,1,9.931,22.21Zm2.483,0a.413.413,0,1,0,.413-.4A.408.408,0,0,0,12.414,22.21Z"/>
        </svg>
    )
}

export const NwLoaderSpinner = ({ size, className = '', isSpinner }) => {
	return (
		<svg 
			xmlns="http://www.w3.org/2000/svg" 
			viewBox="0 0 512 512"
			fill="currentColor"
			className={`${isSpinner ? 'nw-logo-spinner' : ''} ${ className }`}
			width={size} 
			height={size}
		>
			<path 
				className='fa-secondary'
				opacity=".4" 
				d="M65.6 414.4c0 8.2 3.1 16.4 9.4 22.6c12.5 12.5 32.8 12.5 45.3 0l45.3-45.3c12.5-12.5 
				12.5-32.8 0-45.3s-32.8-12.5-45.3 0L75 391.8c-6.2 6.2-9.4 14.4-9.4 22.6zM224 416l0 64c0 17.7 14.3 32 32 32s32-14.3 32-32l0-64c0-17.7-14.3-32-32-32s-32 
				14.3-32 32zM346.5 120.2c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L437 120.2c12.5-12.5 12.5-32.8 0-45.3c-6.2-6.2-14.4-9.4-22.6-9.4s-16.4 3.1-22.6 
				9.4l-45.3 45.3zm0 226.3c-12.5 12.5-12.5 32.8 0 45.3L391.8 437c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-45.3-45.3c-12.5-12.5-32.8-12.5-45.3 0zM384 
				256c0 17.7 14.3 32 32 32l64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32 14.3-32 32z"/>
			<path 
				className="fa-primary" 
				d="M256 0c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32s32-14.3 32-32l0-64c0-17.7-14.3-32-32-32zM0 256c0 17.7 14.3 32 32 32l64 0c17.7 
				0 32-14.3 32-32s-14.3-32-32-32l-64 0c-17.7 0-32 14.3-32 32zM120.2 75C107.7 62.5 87.5 62.5 75 75s-12.5 32.8 0 45.3l45.3 45.3c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L120.2 75z"
			/>
		</svg>
	);
}

export const NwCartIcon = ({size, className=''}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} fill="currentColor" width={size} height={size} viewBox="0 0 17.333 19.5">
            <path d="M10.667,17.056A2.444,2.444,0,1,1,13.111,19.5,2.447,2.447,0,0,1,10.667,17.056Zm1.333,0a1.111,1.111,0,1,0,1.111-1.111A1.113,1.113,0,0,0,12,17.056Zm-8.444,0A2.444,2.444,0,1,1,6,19.5,2.447,2.447,0,0,1,3.556,17.056Zm1.333,0A1.112,1.112,0,1,0,6,15.944,1.113,1.113,0,0,0,4.889,17.056Zm8.4-3.273-.173,0-6.185,0c-.063,0-.128.005-.191.005A3.319,3.319,0,0,1,3.657,11.72l-.063-.166-.029-.112L2.558,5.4,2.542,5.37A.671.671,0,0,1,2.495,5.2l-.006-.091a.7.7,0,0,1,.006-.091L1.879,1.333H.667A.668.668,0,0,1,.006.757L0,.667A.669.669,0,0,1,.576.006L.667,0H2.444A.665.665,0,0,1,3.08.464L3.1.557l.647,3.887H16.667a.665.665,0,0,1,.666.67l-.006.092-.889,6.222-.031.127a3.316,3.316,0,0,1-3.112,2.228Zm-.135-1.337c.039,0,.076,0,.114,0a2,2,0,0,0,1.828-1.192l.037-.094L15.9,5.778H3.972l.9,5.377.041.1a2,2,0,0,0,1.818,1.19l.162,0Z" fillRule="evenodd"/>
        </svg>
    );
}

export const NwBellIcon = ({size, className = null}) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="3.248 2.25 17.503 19.5" width={size} height={size}>
            <path d="M8.255 18.199 8.25 18v-.25H4a.733.733 0 0 1-.725-.56c-.09-.338.05-.67.355-.842.821-.466 1.415-1.274 1.59-2.161L5.25 14l.001-3.035a7.703 7.703 0 0 1 3.922-6.38l.12-.066.001-.014a2.74 2.74 0 0 1 2.538-2.25L12 2.25c1.27 0 2.367.86 2.67 2.09l.037.179.12.066a7.766 7.766 0 0 1 3.904 6.127l.02.288-.006 2.91a3.258 3.258 0 0 0 1.624 2.438c.349.197.418.54.367.796a.734.734 0 0 1-.634.6L20 17.75h-4.25V18a3.75 3.75 0 0 1-7.495.199ZM9.75 18a2.25 2.25 0 0 0 4.495.154L14.25 18v-.25h-4.5V18Zm1.007-13.127-.01.192-.004.037-.02.097-.032.093-.043.086-.03.045-.059.077-.028.03-.042.038-.079.06-.089.05a6.251 6.251 0 0 0-3.554 5.07L6.75 11l-.006 3.09a4.76 4.76 0 0 1-.763 2.064l-.068.096H18.09l-.007-.01a4.877 4.877 0 0 1-.798-1.991L17.25 14l.001-2.965a6.239 6.239 0 0 0-3.571-5.357.75.75 0 0 1-.422-.57L13.25 5c0-.689-.561-1.25-1.25-1.25-.644 0-1.179.483-1.243 1.123Z" fillRule="evenodd"/>
        </svg>
    );
}

export const NwPersonIcon = ({size}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" width={size} height={size}>
            <path d="M17.007 20.851 17 20.75v-2a3.25 3.25 0 0 0-3.066-3.245l-.184-.005h-4a3.25 3.25 0 0 0-3.245 3.066l-.005.184v2a.75.75 0 0 1-1.493.101L5 20.75v-2a4.75 4.75 0 0 1 4.532-4.745L9.75 14h4c2.55 0 4.631 2.01 4.746 4.532l.004.218v2a.75.75 0 0 1-1.493.101ZM7 7.751A4.756 4.756 0 0 1 11.75 3c2.62 0 4.75 2.131 4.75 4.75 0 2.62-2.13 4.75-4.75 4.75A4.756 4.756 0 0 1 7 7.75Zm1.5 0A3.254 3.254 0 0 0 11.75 11 3.254 3.254 0 0 0 15 7.75a3.254 3.254 0 0 0-3.25-3.25A3.254 3.254 0 0 0 8.5 7.75Z" fillRule="evenodd"/>
        </svg>
    );
}

export const NwGalleryIcon = ({size}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" width={size} height={size}>
            <path d="M7 20.75A3.754 3.754 0 0 1 3.25 17V7A3.754 3.754 0 0 1 7 3.25h10A3.754 3.754 0 0 1 20.75 7v10A3.754 3.754 0 0 1 17 20.75H7ZM4.75 17c0 1.241 1.01 2.25 2.25 2.25h10A2.252 2.252 0 0 0 19.25 17v-1.69l-1.77-1.77c-.598-.575-1.237-.61-1.832-.115l-.117.106-.47.47 1.47 1.468a.751.751 0 0 1-.977 1.134l-.085-.073-2-2-2.99-2.99c-.597-.575-1.236-.611-1.83-.116l-.119.106-3.78 3.78V17Zm13.63-4.666.15.136.72.72V7c0-1.24-1.009-2.25-2.25-2.25H7C5.76 4.75 4.75 5.76 4.75 7v6.189l2.73-2.73c1.17-1.125 2.694-1.17 3.902-.126l.148.137L14 12.94l.48-.48c.608-.585 1.312-.879 2.017-.879.651 0 1.304.25 1.884.753ZM14.25 8a.75.75 0 0 1 .648-.743l.112-.007a.75.75 0 0 1 .102 1.494L15 8.75a.75.75 0 0 1-.75-.75Z" fillRule="evenodd"/>
        </svg>
    );
}

export const NwCampaignIcon = ({size, className = null}) => {
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" fill="currentColor" width={size} height={size} viewBox="3.25 2.25 17.5 19.499">
            <path d="M6 21.75A2.753 2.753 0 0 1 3.25 19V7A2.754 2.754 0 0 1 6 4.25h1.25V3a.75.75 0 0 1 1.494-.102L8.75 3v1.25h6.5V3a.75.75 0 0 1 1.493-.102L16.75 3v1.25H18A2.753 2.753 0 0 1 20.75 7v12A2.752 2.752 0 0 1 18 21.75H6ZM4.75 19c0 .69.561 1.25 1.25 1.25h12c.69 0 1.25-.56 1.25-1.25v-7.25H4.75V19Zm14.5-8.75V7c0-.69-.56-1.25-1.25-1.25h-1.25V7a.75.75 0 0 1-1.493.102L15.25 7V5.75h-6.5V7a.75.75 0 0 1-1.493.102L7.25 7V5.75H6c-.689 0-1.25.56-1.25 1.25v3.25h14.5ZM8 17.75a.75.75 0 0 1-.75-.75v-2a.75.75 0 0 1 .75-.75h2a.75.75 0 0 1 .75.75v2a.75.75 0 0 1-.75.75H8Zm.75-1.5h.5v-.5h-.5v.5Z" fillRule="evenodd"/>
        </svg>
    );
}

export const NwModulePlv = ({ size, className = null }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} fill="currentColor" width={size} height={size} viewBox="0 0 17.501 17.499">
            <path d="M8.523,17.464l-.108-.044-8-4a.75.75,0,0,1,.577-1.381l.094.04L8.75,15.91l7.665-3.832a.75.75,0,0,1,.954.248l.052.088a.752.752,0,0,1-.248.955l-.088.051-8,4a.75.75,0,0,1-.562.044Zm0-4-.108-.044-8-4A.75.75,0,0,1,.992,8.039l.094.04L8.75,11.91l7.665-3.831a.749.749,0,0,1,.954.247l.052.087a.751.751,0,0,1-.248.955l-.088.051-8,4a.75.75,0,0,1-.562.044Zm0-4-.108-.044-8-4a.751.751,0,0,1-.1-1.284l.1-.058,8.009-4L8.509.039,8.6.013,8.7,0h.1l.1.013.1.026.094.04,8,4a.75.75,0,0,1,.1,1.284l-.1.058-8,4A.754.754,0,0,1,8.75,9.5.746.746,0,0,1,8.523,9.464Zm-6.1-4.714L8.75,7.91l6.323-3.161L8.75,1.587Z" transform="translate(0)"/>
        </svg>
    )
}

export const NwSocialMedia = ({ size, className = null }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} fill="currentColor" width={size} height={size} viewBox="0 0 19.5 17.479">
            <path d="M5.75,17.479a1.75,1.75,0,0,1-1.744-1.607L4,15.728v-4.25H1.75A1.759,1.759,0,0,1,.005,9.872L0,9.728v-4A1.758,1.758,0,0,1,1.607,3.984l.143-.005H9.477l4.317-3.6a1.649,1.649,0,0,1,2.7,1.121l.006.147v2.4a3.751,3.751,0,0,1,0,7.35v2.4a1.655,1.655,0,0,1-.95,1.495,1.647,1.647,0,0,1-1.63-.13l-.126-.1-4.316-3.6H8.5v4.25a1.75,1.75,0,0,1-1.607,1.744l-.143.006ZM5.5,15.728a.25.25,0,0,0,.193.244l.058.006h1a.25.25,0,0,0,.243-.193L7,15.728v-4.25H5.5Zm4.25-5.75a.754.754,0,0,1,.4.113l.085.061,4.523,3.77a.151.151,0,0,0,.1.035.161.161,0,0,0,.064-.014.153.153,0,0,0,.08-.094L15,13.807V1.65a.148.148,0,0,0-.15-.149.149.149,0,0,0-.059.012l-.037.022L10.209,5.323l-.08.053-.086.044-.1.033-.112.02-.081.005H8.5v4.5ZM7,9.978v-4.5H1.75a.252.252,0,0,0-.243.193L1.5,5.729v4a.25.25,0,0,0,.193.244l.057.006H7Zm9.5-.128a2.251,2.251,0,0,0,0-4.244Z"/>
        </svg>
    )
}

export const NwBaseProductsModuleList = ({ size, className = null }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} fill="currentColor" width={size} height={size} viewBox="0 0 58.891 56.706">
            <g transform="matrix(1, 0, 0, 1, 0, 0)">
                <path d="M6.684,16.564a40.957,40.957,0,0,0,15.262,2.641,40.956,40.956,0,0,0,15.262-2.641c4.31-1.8,6.684-4.268,6.684-6.962S41.517,4.436,37.207,2.64A40.956,40.956,0,0,0,21.945,0,40.957,40.957,0,0,0,6.684,2.64C2.374,4.436,0,6.908,0,9.6s2.374,5.167,6.684,6.962M7.294,4.1A39.358,39.358,0,0,1,21.945,1.585,39.358,39.358,0,0,1,36.6,4.1C40.224,5.616,42.3,7.619,42.3,9.6s-2.08,3.987-5.707,5.5a39.357,39.357,0,0,1-14.652,2.519A39.358,39.358,0,0,1,7.294,15.1c-3.627-1.511-5.707-3.514-5.707-5.5s2.08-3.987,5.707-5.5M43.185,18.874a.793.793,0,0,0-1.1.2c-1.163,1.661-3.735,3.18-7.243,4.275a44.038,44.038,0,0,1-12.892,1.775A44.038,44.038,0,0,1,9.054,23.344c-3.508-1.1-6.081-2.614-7.243-4.275a.793.793,0,0,0-1.3.909c1.383,1.976,4.173,3.663,8.07,4.88a45.632,45.632,0,0,0,13.365,1.847A45.632,45.632,0,0,0,35.31,24.858c3.9-1.217,6.688-2.9,8.07-4.88a.793.793,0,0,0-.2-1.1m0,7.5a.793.793,0,0,0-1.1.2c-1.163,1.661-3.735,3.18-7.243,4.275a44.038,44.038,0,0,1-12.892,1.775A44.038,44.038,0,0,1,9.054,30.844c-3.508-1.1-6.081-2.614-7.243-4.275a.793.793,0,1,0-1.3.91c1.383,1.976,4.173,3.663,8.07,4.88a45.632,45.632,0,0,0,13.365,1.847A45.632,45.632,0,0,0,35.31,32.358c3.9-1.217,6.688-2.9,8.07-4.88a.793.793,0,0,0-.2-1.1m0,7.5a.793.793,0,0,0-1.1.2c-1.163,1.661-3.735,3.18-7.243,4.275a44.036,44.036,0,0,1-12.892,1.775A44.035,44.035,0,0,1,9.054,38.344c-3.508-1.1-6.081-2.614-7.243-4.275a.793.793,0,0,0-1.3.91c1.383,1.976,4.173,3.663,8.07,4.88a45.631,45.631,0,0,0,13.365,1.847A45.631,45.631,0,0,0,35.31,39.858c3.9-1.217,6.688-2.9,8.07-4.88a.793.793,0,0,0-.2-1.1" transform="translate(4.5 4.5)"/>
            </g>
        </svg>
    )
}

export const NwPlvModuleList = ({ size, className = null }) => {
    return (
		<svg xmlns="http://www.w3.org/2000/svg" className={className} fill="currentColor" width={size} height={size} viewBox="0 0 17.501 17.499">
			<path strokeWidth={3} d="M8.523,17.464l-.108-.044-8-4a.75.75,0,0,1,.577-1.381l.094.04L8.75,15.91l7.665-3.832a.75.75,0,0,1,.954.248l.052.088a.752.752,0,0,1-.248.955l-.088.051-8,4a.75.75,0,0,1-.562.044Zm0-4-.108-.044-8-4A.75.75,0,0,1,.992,8.039l.094.04L8.75,11.91l7.665-3.831a.749.749,0,0,1,.954.247l.052.087a.751.751,0,0,1-.248.955l-.088.051-8,4a.75.75,0,0,1-.562.044Zm0-4-.108-.044-8-4a.751.751,0,0,1-.1-1.284l.1-.058,8.009-4L8.509.039,8.6.013,8.7,0h.1l.1.013.1.026.094.04,8,4a.75.75,0,0,1,.1,1.284l-.1.058-8,4A.754.754,0,0,1,8.75,9.5.746.746,0,0,1,8.523,9.464Zm-6.1-4.714L8.75,7.91l6.323-3.161L8.75,1.587Z" transform="translate(0)"/>
		</svg>
    )
}

export const NwECatalogModuleList = ({ size, className = null }) => {
    return (
		<svg xmlns="http://www.w3.org/2000/svg" className={className} fill="currentColor" width={size} height={size} viewBox="0 0 68.696 60.308">
			<g transform="matrix(1, 0, 0, 1, 0, 0)">
				<path d="M42.07,36.047a18.775,18.775,0,0,0-18.818,0,1.708,1.708,0,0,1-1.811,0,18.775,18.775,0,0,0-18.818,0A1.708,1.708,0,0,1,.6,35.891l-.007-.006q-.04-.035-.077-.072l0,0a1.734,1.734,0,0,1-.469-.85l0-.016,0-.02L.029,34.89v0A1.74,1.74,0,0,1,0,34.58V4.521a1.735,1.735,0,0,1,.3-.975l.011-.016.011-.016a1.724,1.724,0,0,1,.541-.5,22.181,22.181,0,0,1,20.99-.728l.5.256.5-.256a22.181,22.181,0,0,1,20.315.352l.675.377a1.724,1.724,0,0,1,.652.675l0,.008.013.025a1.728,1.728,0,0,1,.16.467v0l.005.031,0,.015,0,.016,0,.029h0a1.751,1.751,0,0,1,.016.228v30.06a1.726,1.726,0,0,1-1.72,1.733A1.7,1.7,0,0,1,42.07,36.047Zm-.813-4.261V5.552a18.762,18.762,0,0,0-17.19,0V31.785a22.172,22.172,0,0,1,17.19,0Zm-37.819,0a22.179,22.179,0,0,1,17.19,0V5.552a18.762,18.762,0,0,0-17.19,0Z" transform="translate(12 9)"/>
			</g>
		</svg>
    )
}

export const NwSocialMediaCoverTab = ({ size, className = null }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} className={className} fill="currentColor" viewBox="0 0 18.5 18.51">
            <path fill="currentColor" d="M16.921,17.824l-.007-.118a.793.793,0,0,1,1.578-.108l.007.118a.793.793,0,0,1-1.578.108Zm-16.914,0L0,17.706A.793.793,0,0,1,1.578,17.6l.008.118a.793.793,0,0,1-1.578.108Zm6.07.675a1.852,1.852,0,0,1-1.85-1.85V1.85A1.852,1.852,0,0,1,6.078,0h6.343a1.852,1.852,0,0,1,1.851,1.85v14.8a1.852,1.852,0,0,1-1.851,1.85ZM5.814,1.85v14.8a.265.265,0,0,0,.264.264h6.343a.265.265,0,0,0,.264-.264V1.85a.264.264,0,0,0-.264-.264H6.078A.264.264,0,0,0,5.814,1.85ZM16.921,13.6l-.007-.118a.793.793,0,0,1,1.578-.108l.007.118a.793.793,0,0,1-1.578.108ZM.008,13.6,0,13.478a.793.793,0,0,1,1.578-.108l.008.118A.793.793,0,0,1,.008,13.6ZM16.921,9.367l-.007-.118a.793.793,0,0,1,1.578-.108l.007.118a.793.793,0,0,1-1.578.108Zm-16.914,0L0,9.249a.793.793,0,0,1,1.578-.108l.008.118a.793.793,0,0,1-1.578.108ZM16.921,5.139l-.007-.118a.793.793,0,0,1,1.578-.108l.007.118a.793.793,0,0,1-1.578.108Zm-16.914,0L0,5.021a.793.793,0,0,1,1.578-.108l.008.118a.793.793,0,0,1-1.578.108ZM16.921.911,16.915.793A.793.793,0,0,1,18.493.685L18.5.8a.793.793,0,0,1-1.578.108ZM.008.911,0,.793A.793.793,0,0,1,1.578.685L1.586.8A.793.793,0,0,1,.008.911Z" />
        </svg>
    )
}

export const NwShareBoxModuleList = ({ size, className = null }) => {
    return (
		<svg xmlns="http://www.w3.org/2000/svg" className={className} fill="currentColor" width={size} height={size} viewBox="0 0 256 256">
			<path opacity="1.000000" stroke="none" 
				d="
				M138.000000,207.000000 
					C105.336395,207.000000 73.172539,206.927505 41.009315,207.037888 
					C29.157932,207.078552 18.907316,196.848907 18.946478,184.769135 
					C19.084242,142.273621 18.988914,99.777390 19.006918,57.281445 
					C19.012157,44.911087 27.922733,34.889313 41.343010,34.975174 
					C57.674118,35.079655 74.007339,35.101585 90.337746,34.952061 
					C97.270233,34.888588 102.866173,37.313019 107.727325,42.179131 
					C123.171806,57.639374 117.986610,54.805573 138.643494,54.973320 
					C154.974197,55.105934 171.324234,55.465828 187.633255,54.856590 
					C201.346664,54.344311 213.094254,65.657471 212.044357,79.258965 
					C211.753204,83.030777 212.000000,86.844124 212.000000,91.005081 
					C219.506638,90.676132 226.130722,91.744339 231.756287,96.894760 
					C237.304550,101.974419 240.817062,111.860794 238.585876,118.921158 
					C230.765488,143.668137 222.721802,168.346008 215.112946,193.157440 
					C212.902893,200.364182 202.245255,207.165543 195.993546,207.087265 
					C176.831665,206.847321 157.664886,207.000000 138.000000,207.000000 
				M65.748352,100.000000 
					C57.530647,100.085892 53.333828,102.936966 50.895744,110.532028 
					C43.548885,133.418823 36.494896,156.401718 28.924255,179.213593 
					C25.555660,189.363846 32.834526,198.169510 42.858616,198.117996 
					C93.006409,197.860229 143.156250,198.001770 193.305359,197.998032 
					C200.533737,197.997498 204.799744,194.938293 207.011932,187.999176 
					C214.361176,164.946259 221.655457,141.875809 228.988281,118.817642 
					C232.170654,108.810677 225.714233,100.002800 215.142395,100.001892 
					C165.659714,99.997643 116.177032,100.000000 65.748352,100.000000 
				M162.500000,64.000000 
					C150.171371,64.000000 137.831741,64.293228 125.520508,63.830402 
					C121.565613,63.681725 117.141159,62.334858 113.899368,60.119740 
					C109.055099,56.809635 105.250862,52.017887 100.640312,48.308743 
					C98.234695,46.373444 95.076714,44.252869 92.214684,44.189236 
					C75.062851,43.807884 57.897472,43.904945 40.738350,44.047558 
					C33.969532,44.103813 28.075684,49.921089 28.050390,56.676861 
					C27.935808,87.282806 28.000263,117.889420 28.000263,149.952682 
					C29.044781,147.575974 29.631123,146.520737 29.985558,145.392654 
					C34.019360,132.553909 37.948887,119.682022 42.048473,106.864479 
					C45.062016,97.442490 54.168068,90.953613 64.068069,90.969292 
					C108.551483,91.039749 153.035065,91.000000 197.518585,91.000000 
					C199.281326,91.000000 201.044067,91.000000 203.000000,91.000000 
					C203.000000,86.600319 203.002579,82.787964 202.999496,78.975609 
					C202.992371,70.128777 196.928177,64.050995 187.990875,64.008278 
					C179.827438,63.969257 171.663635,64.000000 162.500000,64.000000 
				z"
			/>
		</svg>
    )
}

export const NwSocialMediaModuleList = ({ size, className = null }) => {
    return (
		<svg xmlns="http://www.w3.org/2000/svg" className={className} fill="currentColor" width={size} height={size} viewBox="0 0 256 256">
		<path opacity="1.000000" stroke="none" 
			d="
			M0.999999,154.454102 
				C1.654114,153.679688 2.842822,153.418396 2.885639,153.030594 
				C4.987053,133.997681 16.976332,123.094246 32.836380,114.368149 
				C47.664619,106.209740 63.202118,98.885429 74.846077,85.779030 
				C83.652664,75.866356 90.118385,64.590614 96.018448,52.877350 
				C99.629440,45.708530 103.399376,38.618454 106.927711,31.409698 
				C110.156555,24.812859 114.854073,20.527613 122.529289,19.968952 
				C131.806442,19.293690 138.937775,22.010677 143.499146,30.829123 
				C147.927200,39.389839 153.022736,47.616261 158.111832,55.811466 
				C158.820633,56.952888 161.450211,57.944557 162.715576,57.535622 
				C175.044449,53.551216 186.201324,56.310329 195.086060,64.901711 
				C205.767197,75.230179 207.305603,91.608849 198.862930,104.518761 
				C196.861008,107.579956 194.103851,110.147247 191.239822,113.468521 
				C195.198074,120.301193 199.514008,127.960159 204.035034,135.496078 
				C208.464935,142.880096 212.191147,150.271912 209.039307,159.282135 
				C206.479813,166.599014 199.285370,172.202698 191.526871,171.951309 
				C176.017426,171.448746 160.511780,170.057785 145.014511,170.190887 
				C132.408646,170.299149 119.915054,172.528854 108.258591,178.321320 
				C115.413506,190.646759 123.102852,202.689606 129.555634,215.362091 
				C135.485275,227.007156 131.571793,239.970398 120.838615,247.015259 
				C108.656586,255.011093 93.044334,249.927444 86.575195,239.046906 
				C82.961632,232.969238 79.614418,226.733841 76.087997,220.603561 
				C72.568848,214.485931 68.975807,208.410797 65.336861,202.183029 
				C50.263062,207.206345 35.647842,206.259216 22.094856,196.992920 
				C11.123325,189.491608 4.512996,179.246292 2.954770,165.896973 
				C2.853338,165.028000 2.140517,164.230423 1.356046,163.699814 
				C1.000000,160.969406 1.000000,157.938812 0.999999,154.454102 
			M170.965775,105.618134 
				C164.326996,94.225105 157.679901,82.836922 151.051758,71.437706 
				C144.352905,59.916882 137.779831,48.321102 130.936508,36.886925 
				C127.819725,31.679230 121.938652,31.623323 119.091133,36.687298 
				C116.495110,41.304016 114.156158,46.092197 112.059853,50.958149 
				C104.528061,68.440987 95.289268,84.845718 81.859505,98.532341 
				C77.886497,102.581329 73.259224,105.988312 68.736206,109.856743 
				C79.640465,128.694748 90.487801,147.434418 101.306038,166.123795 
				C115.276344,160.364380 129.241501,156.485535 144.086136,157.027481 
				C158.917007,157.568909 173.735397,158.471573 188.567978,158.938385 
				C195.405716,159.153580 198.735214,153.577591 195.446915,147.953018 
				C187.335175,134.078033 179.174606,120.231598 170.965775,105.618134 
			M76.484932,150.989105 
				C69.941757,139.579254 63.398582,128.169403 56.748501,116.573120 
				C47.694996,121.889053 39.033051,126.767151 30.579065,131.982361 
				C13.091645,142.770233 10.367414,168.986877 26.646629,183.025848 
				C33.905041,189.285400 42.175545,192.398575 52.316151,191.235550 
				C61.179260,190.219025 67.676102,184.929047 75.087585,181.291214 
				C79.957993,178.900650 84.432152,175.702805 89.165382,172.816711 
				C84.943733,165.469208 80.953377,158.524277 76.484932,150.989105 
			M95.664230,185.037567 
				C89.760414,188.566101 83.856598,192.094620 77.694885,195.777283 
				C81.033859,201.638382 84.244621,207.369217 87.547737,213.046310 
				C90.939789,218.876297 94.473419,224.624146 97.845184,230.465607 
				C99.797470,233.847855 102.155693,236.626785 106.328995,236.980225 
				C110.708908,237.351135 114.551758,235.925308 116.904045,232.189697 
				C119.876633,227.469009 118.284508,222.776031 115.749535,218.375381 
				C109.340294,207.249100 102.873352,196.156067 95.664230,185.037567 
			M175.966232,86.624413 
				C178.507736,91.167366 181.049255,95.710312 183.635834,100.333801 
				C190.394043,94.737450 192.162903,87.397095 189.086777,80.279068 
				C186.048828,73.249443 179.008621,67.955681 166.524567,70.553101 
				C169.585190,75.814598 172.551163,80.913383 175.966232,86.624413 
			z"/>
		<path opacity="1.000000" stroke="none" 
			d="
			M257.000000,103.600006 
				C256.877167,103.887550 256.754303,103.775101 256.631470,103.662651 
				C252.873596,109.582947 246.922485,107.919434 241.507812,107.982719 
				C235.180115,108.056671 228.829315,107.680183 222.529526,108.105820 
				C217.875656,108.420250 216.347855,105.156723 216.318268,102.248199 
				C216.286484,99.126076 217.794785,95.173973 222.105057,95.069977 
				C231.425888,94.845100 240.778534,94.653664 250.065689,95.256744 
				C252.280136,95.400543 254.288437,98.718910 256.695770,100.288574 
				C257.000000,101.066673 257.000000,102.133339 257.000000,103.600006 
			z"/>
		<path opacity="1.000000" stroke="none" 
			d="
			M209.215393,58.286964 
				C216.684296,53.813892 223.691864,49.211979 231.197189,45.662632 
				C233.527008,44.560848 238.133194,45.277851 240.058395,46.997215 
				C243.166824,49.773293 242.622162,54.124096 238.862259,56.491249 
				C230.865234,61.526020 222.744965,66.433861 214.310104,70.666084 
				C211.450745,72.100777 207.482071,71.520187 205.769119,67.328346 
				C204.152634,63.372543 204.982544,60.433174 209.215393,58.286964 
			z"/>
		<path opacity="1.000000" stroke="none" 
			d="
			M182.998962,26.985939 
				C186.017670,21.460735 189.005844,16.320192 191.983734,11.173687 
				C194.347137,7.089116 197.940247,5.905313 201.751480,7.970637 
				C205.063049,9.765185 206.135529,13.776462 204.057526,17.606337 
				C200.080414,24.936440 196.148224,32.304272 191.802399,39.415035 
				C189.792633,42.703453 186.680084,43.950005 182.524002,42.181240 
				C177.774078,40.159760 178.599365,36.548092 179.498367,33.128185 
				C180.045151,31.048191 181.768936,29.277601 182.998962,26.985939 
			z"/>
		</svg>
    )
}

export const NwTrailIcon = ({ size, className = null }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" className={className} fill="currentColor" width={size} height={size} viewBox="0 0 51.142 28">
            <g transform="translate(-43.359 -413.5)">
                <line x1="27" transform="translate(63.5 415.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="4"/>
                <line x1="16" transform="translate(76.5 423.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="4"/>
                <line x1="33" transform="translate(47.359 431.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="4"/>
                <line x1="12" transform="translate(53.558 439.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="4"/>
                <line x1="7" transform="translate(45.359 423.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="4"/>
                <line x1="15" transform="translate(77.5 439.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="4"/>
                <line x1="4" transform="translate(61.253 423.5)" fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth="4"/>
            </g>
        </svg>
    );
}

export const NwSocialMediaCreateEditorIcon = ({ size, className = null }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className={className} fill="currentColor" width={size} height={size} viewBox="0 0 115 115">
			<g id="Group_595" transform="translate(-622 -223)">
				<g id="Rectangle_206" transform="translate(622 223)" fill="none" stroke="currentColor" strokeWidth="6">
					<rect width="115" height="115" rx="15" stroke="none"/>
					<rect x="3" y="3" width="109" height="109" rx="12"/>
				</g>
				<g id="Group_594" transform="translate(2 1)">
				<line id="Line_76" y1="15" x2="15" transform="translate(634.5 236.5)" stroke="currentColor" strokeWidth="1"/>
				<line id="Line_84" y1="16" x2="15" transform="translate(704.5 307.25)" stroke="currentColor" strokeWidth="1"/>
				<line id="Line_77" y1="33" x2="33" transform="translate(634.5 233.5)" stroke="currentColor" strokeWidth="1"/>
				<line id="Line_83" y1="7" x2="7" transform="translate(712.5 290.938)" stroke="currentColor" strokeWidth="1"/>
				<line id="Line_78" y1="51" x2="49" transform="translate(634.5 233.5)" stroke="currentColor" strokeWidth="1"/>
				<line id="Line_82" y1="17" x2="17" transform="translate(702.5 273.27)" stroke="currentColor" strokeWidth="1"/>
				<line id="Line_79" y1="15" x2="14" transform="translate(686.5 233.5)" stroke="currentColor" strokeWidth="1"/>
				<line id="Line_81" y1="29" x2="28" transform="translate(691.5 253.75)" stroke="currentColor" strokeWidth="1"/>
				<line id="Line_80" y1="28" x2="26" transform="translate(693.5 233.5)" stroke="currentColor" strokeWidth="1"/>
				<line id="Line_85" y1="29" x2="28" transform="translate(634.5 272.071)" stroke="currentColor" strokeWidth="1"/>
				<line id="Line_86" y1="10" x2="9" transform="translate(634.5 313.25)" stroke="currentColor" strokeWidth="1"/>
				<line id="Line_87" y1="10" x2="9" transform="translate(653.5 313.25)" stroke="currentColor" strokeWidth="1"/>
				<line id="Line_88" y1="10" x2="10" transform="translate(670.5 313.25)" stroke="currentColor" strokeWidth="1"/>
				<line id="Line_89" y1="10" x2="9" transform="translate(686.5 313.25)" stroke="currentColor" strokeWidth="1"/>
				</g>
				<g id="Icon_hanger" transform="translate(637.5 245.119)">
				<path id="Icon_Color" d="M75.422,70.763H6.6A6.649,6.649,0,0,1,.26,65.911,6.83,6.83,0,0,1,3.053,58.35l34.8-22.6V31.84l-.908-.212A15.961,15.961,0,0,1,25.264,16.937l-.023-.853a15.773,15.773,0,1,1,31.539,0,3.24,3.24,0,0,1-.45,1.652L45.29,36.491,78.964,58.35a6.79,6.79,0,0,1,3.024,6.184l-.064.648-.163.73A6.649,6.649,0,0,1,75.422,70.763Zm.2-6.467h0l.02.008a.049.049,0,0,1,0,.04l.068-.274a.307.307,0,0,0-.136-.293L41.007,41.336,6.44,63.777a.306.306,0,0,0-.125.34.3.3,0,0,0,.284.216H75.422a.524.524,0,0,0,.136-.023A.293.293,0,0,1,75.623,64.3ZM41.01,6.434a9.568,9.568,0,0,0-9.461,9.65,9.568,9.568,0,0,0,9.461,9.65A3.134,3.134,0,0,1,43.519,27l6.91-11.749-.045-.483a9.567,9.567,0,0,0-8.727-8.318Z"/>
				</g>
				<g id="Icon_hanger-2" transform="translate(641.248 247.98)">
				<path id="Icon_Color-2" d="M68.528,63.037H6A6,6,0,0,1,2.774,51.979L34.4,31.85V28.364l-.825-.189A14.28,14.28,0,0,1,22.954,15.088l-.021-.76a14.328,14.328,0,1,1,28.656,0,2.844,2.844,0,0,1-.409,1.472L41.151,32.507l30.6,19.472a6.011,6.011,0,0,1,2.747,5.508l-.058.578-.148.65A6.024,6.024,0,0,1,68.528,63.037Zm.183-5.761h0l.019.007a.043.043,0,0,1,0,.035l.062-.244a.272.272,0,0,0-.124-.261L37.258,36.822,5.851,56.814a.269.269,0,0,0,.144.5H68.528a.485.485,0,0,0,.124-.021A.271.271,0,0,1,68.71,57.277ZM37.262,5.732a8.6,8.6,0,0,0,0,17.192,2.866,2.866,0,0,1,2.28,1.131L45.82,13.589l-.041-.43a8.62,8.62,0,0,0-7.929-7.41Z" transform="translate(0)"/>
				</g>
				<rect id="Rectangle_207" width="79" height="7" rx="3" transform="translate(641 328)"/>
			</g>
		</svg>

	)
}

export const NwSocialMediaCreateFreeEditorIcon = ({ size, className = null }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className={className} fill="currentColor" width={size} height={size} viewBox="0 0 115 115">
			<g transform="translate(-1400 -223)">
				<g transform="translate(1432.249 300.668)">
					<path d="M1.558,13.833a1.585,1.585,0,0,1-.212-3.152l.212-.014H34.795a1.585,1.585,0,0,1,.212,3.152l-.212.014ZM2.164,3.167C.969,3.167,0,2.458,0,1.583,0,.782.815.12,1.87.014L2.164,0H48.336c1.2,0,2.165.709,2.165,1.583,0,.8-.814,1.464-1.87,1.569l-.294.015Z"/>
				</g>
				<g transform="translate(1432.249 243.168)">
					<path d="M10.823,50.5A10.833,10.833,0,0,1,0,39.68V33.963c0-.036,0-.072,0-.108V10.823A10.834,10.834,0,0,1,10.823,0H39.68A10.833,10.833,0,0,1,50.5,10.823V33.837c0,.047,0,.094,0,.141v5.7A10.833,10.833,0,0,1,39.68,50.5ZM4.33,39.68a6.5,6.5,0,0,0,6.493,6.493H39.68a6.5,6.5,0,0,0,6.493-6.493V34.805L41.064,29.7c-1.725-1.66-3.568-1.763-5.285-.335l-.34.306-1.355,1.355,4.24,4.24a2.165,2.165,0,0,1-2.818,3.272l-.244-.21-5.771-5.771-8.628-8.628c-1.725-1.662-3.568-1.766-5.285-.338l-.34.306L4.33,34.8ZM43.664,26.214l.431.392,2.078,2.078V10.823A6.5,6.5,0,0,0,39.68,4.33H10.823A6.5,6.5,0,0,0,4.33,10.823V28.682l7.877-7.876a7.928,7.928,0,0,1,11.259-.366l.429.395,7.128,7.127,1.386-1.384a8.385,8.385,0,0,1,5.819-2.535A8.285,8.285,0,0,1,43.664,26.214ZM31.743,13.708a2.166,2.166,0,0,1,1.87-2.145l.325-.021a2.166,2.166,0,0,1,.293,4.311l-.322.018A2.164,2.164,0,0,1,31.743,13.708Z" transform="translate(0 0)"/>
				</g>
				<g transform="translate(1400 223)" fill="none" stroke="currentColor" strokeWidth="6">
					<rect width={size} height={size} rx="15" stroke="none"/>
					<rect x="3" y="3" width="109" height="109" rx="12" fill="none"/>
				</g>
				<rect width="79" height="7" rx="3" transform="translate(1419 328)" fill="currentColor"/>
			</g>
		</svg>
	)
	
}

export const NwBrowserIcon = ({ size, className = null }) => {
	return (
		<svg className={className} fill="currentColor" width={size} height={size} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.5 17.5">
			<path d="M1.75,17.5A1.751,1.751,0,0,1,0,15.75v-14A1.751,1.751,0,0,1,1.75,0h14A1.751,1.751,0,0,1,17.5,1.75v14a1.751,1.751,0,0,1-1.75,1.75ZM1.5,15.75A.25.25,0,0,0,1.75,16h14a.251.251,0,0,0,.25-.25V5.5H1.5ZM16,4V1.75a.25.25,0,0,0-.25-.249H5.5V4ZM4,4V1.5H1.75A.249.249,0,0,0,1.5,1.75V4Z"/>
		</svg>
	)
}

export const NwAdjustmensIcon = ({ size, className = null }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" className={className} fill="currentColor" width={size} height={size} viewBox="0 0 16.347 16.347">
			<path d="M10.372,14.479H.7a.7.7,0,0,1-.095-1.395L.7,13.078h9.672a2.569,2.569,0,0,1,4.944,0h.331a.7.7,0,0,1,.095,1.395l-.095.006h-.331a2.569,2.569,0,0,1-4.944,0Zm1.3-.7a1.167,1.167,0,1,0,1.168-1.168A1.168,1.168,0,0,0,11.677,13.778Zm-9.711-4.9H.7a.7.7,0,0,1-.095-1.4L.7,7.473H1.965a2.569,2.569,0,0,1,4.943,0h8.738a.7.7,0,0,1,.095,1.395l-.095.007H6.909a2.569,2.569,0,0,1-4.943,0Zm1.3-.7A1.167,1.167,0,1,0,4.437,7.006,1.169,1.169,0,0,0,3.27,8.174Zm4.3-4.9H.7A.7.7,0,0,1,.605,1.875L.7,1.868H7.57a2.569,2.569,0,0,1,4.943,0h3.133a.7.7,0,0,1,.095,1.395l-.095.007H12.514a2.569,2.569,0,0,1-4.943,0Zm1.3-.7A1.167,1.167,0,1,0,10.042,1.4,1.169,1.169,0,0,0,8.874,2.569Z"/>
		</svg>
	)
}

export const NwTextIcon = ({ size, className = null }) => {
	return (
		<img width={size} height={size} className={ `object-fit-contain ${className}` } src={textIcon}/>
	)
}

export const NwUploadIcon = ({ size, className = null }) => {
	return (
		<img width={size} height={size} className={ `object-fit-contain ${className}` } src={uploadIcon}/>
	)
}

export const NwEleclercIcon = ({ size, className = '' }) => {
	return (
		<img width={size} height={size} className={ `object-fit-contain ${className}` } src={eleclercIcon}/>
	)
}

// Flags
export const NwFrenchFlag = ({size}) => {
    return (
        <img 
            width={size ?? '100%'} 
            height={size ?? '100%'}
            src={FranceFlagIcon}
            style={{
                objectFit: 'contain'
            }}
        />
    );
}

export const NwSpainFlag = ({size}) => {
    return (
        <img 
            width={size ?? '100%'} 
            height={size ?? '100%'}
            src={SpainFlagIcon}
            style={{
                objectFit: 'contain'
            }}
        />
    )
}

export const NwPortugalFlag = ({size}) => {
    return (
        <img 
            width={size ?? '100%'} 
            height={size ?? '100%'}
            src={PortugalFlagIcon}
            style={{
                objectFit: 'contain'
            }}
        />
    )
}

export const NwUkFlag = ({size}) => {
    return (
        <img 
            width={size ?? '100%'} 
            height={size ?? '100%'}
            src={UKFlagIcon}
            style={{
                objectFit: 'contain'
            }}
        />
    )
}

