import { useState, useEffect, useContext } from "react";
import { Row, Button, Form } from "react-bootstrap";
import { getAuthTokenWithRefresh, getBackendApiUrl, getEasyPlvApiToken } from "../../config";
import ReactPaginate from "react-paginate";
import {
  filterOperation,
  fetchOperations,
  formatDateRange,
} from "../../services/operation";
import ListOperationItem from "./ListOperationItem";
import { useTranslation } from "react-i18next";
import { Border, Search, SortDown } from "react-bootstrap-icons";

// Contexts
import MainLayoutContext from "../../contexts/MainLayoutContext";

// Nw Custom Filters
import { NwCommonFilter } from "../NwCustomFilters/NwCustomFilters";

function ListOperation() {
	const apiKey = getEasyPlvApiToken();
	const { locale } = useContext(MainLayoutContext);

	const { t } = useTranslation();
	const [posts, setPosts] = useState([]);
	const [loading, setLoading] = useState(true);
	const [pageCount, setPageCount] = useState(0);
	const [totalItems,setTotalItems] = useState(0);
	const [newIsLoadingReset, setNewIsLoadingReset] = useState();

	const [postsPerPage] = useState(12);
  
	let itemsPerPage = 0;
	useEffect(() => {
		async function getOperations() {
			try {
				const authToken = await getAuthTokenWithRefresh();
				const response = await fetch(getBackendApiUrl("operation/byUser"), {
					method: "GET",
					headers: {
						Authorization: `Bearer ${authToken}`,
						"X-EasyPLV-Api-Token": apiKey,
						"nw-front-locale": locale || 'fr'
					},
				});
				
				const data = await response.json();
				if(data === undefined){
					return <p>No Operation...</p>;
				}
				// Set the state with the data
				setPosts(data.items);
				setTotalItems(data.totalItems);
				// console.log(data);

				setPageCount(Math.ceil(totalItems / postsPerPage));

				// Set loading state to false after fetching
			} catch (error) {
				console.error("Error fetching data: ", error);

				// Set loading state to false in case of an error
				// setLoading(false);
			}
		}

		if (loading == false){
			setLoading(true);
		}
		
		getOperations().then(() => {
			setLoading(false);
		});
	}, [locale]);

	const handleFilterSubmit = async (e) => {
		e.preventDefault();

		let queryString = "";
		// const searchValue = e.target.search.value;
		
		const operationState = e.target.operation_status.value;
		const operationType = e.target.operation_type.value;
		const operationName = e.target.search_ope_name.value;

		if (operationState) {
			if (queryString) {
			  queryString += `&state=${operationState}`;
			} else {
			  queryString += `state=${operationState}`;
			}
		  }
		  
		  if (operationType) {
			if (queryString) {
			  queryString += `&type=${operationType}`;
			} else {
			  queryString += `type=${operationType}`;
			}
		  }
		  
		  if (operationName) {
			if (queryString) {
			  queryString += `&name=${operationName}`;
			} else {
			  queryString += `name=${operationName}`;
			}
		  }
		  
		//   console.log(queryString);

		// if (searchValue) {
		// 	queryString += `name=${searchValue}`;
		// }

		const filteredOperation = await filterOperation(queryString, locale);
		setPosts(filteredOperation.items);

		
		setTotalItems(filteredOperation.totalItems);
		itemsPerPage = postsPerPage;

		setPageCount(Math.ceil(totalItems / postsPerPage));
		
	};

	const handleResetFilter = async (e) => {
		//TO_DO_NW
		e.preventDefault();
    setNewIsLoadingReset(true);
    const res = await fetchOperations(1);
    setPosts(res.items);
    setTotalItems(res.totalItems);
    setNewIsLoadingReset(false);

    document.getElementById('search-by-name-filter').value = "";
    

	};

	const handlePageClick = async (data) => {
		let currentPage = data.selected + 1;
		const operations = await fetchOperations(currentPage);
		setPosts(operations.items);
		window.scrollTo(0, 0);
	};

	const handleDownloadPDF = (item) => {
		console.log("Download PDF clicked for:", item.id);
	};

	// ----------Only used for Loading Phase of Page----------------

	// Define the number of times you want to repeat the component
	const numberOfRepeatsPlaceholderItems = 12; // for example

	// Create an array of that length and map over it to render components
	const placeholderItems = Array.from({ length: numberOfRepeatsPlaceholderItems }, (_, index) => index);

	// ----------Only used for Loading Phase of Page----------------

	const statusOptions = [
		{ value: 'frontstate_published', label: 'Disponible' },
		{ value: 'frontstate_in_progress', label: 'Traitement' },
		{ value: 'frontstate_coming_soon', label: 'A venir' }
	  ];

	return (
		
		<div className="p-5">
			<div>
				<NwCommonFilter
					onSubmit={handleFilterSubmit}
					className= "w-100"
					filterContainerProps={{
						className: 'mb-3'
					}}
					autoSubmitOnReset
					filterWrapperProps={{
						className: "w-100 justify-content-around"
					}}
					filterButtonProps={{
						type: 'submit',
						children: t('validateButtonLabel')
					}}
					resetButtonProps={{
						children: t('resetFilterButton')
					}}
					filterModalProps={{
					headerContent: t('productLocalPage.filterModalHeader')
					}}
					formFields={[
						{
							type: 'select',
							wrapperProps: {
								sm: 4
							},
							props: {
								controlId: "search-by-operation-status",
								name: "operation_status",
								placeholder: t('operationsPage.operationsCard1.sortByState'),
								options: statusOptions,
								hideLabel: true
							}
						},
						{
							type: 'select',
							wrapperProps: {
								className: 'd-none',
								sm: 3
							},
							props: {
								controlId: "search-by-operation-type",
								name: "operation_type",
								placeholder: t('operationsPage.operationsCard1.operationType'),
								hideLabel: true
							}
						},
						{
							type: 'input',
							wrapperProps: {
								sm: 4
							},
							props: {
								controlId: "search-by-operation-name",
								name: "search_ope_name",
								placeholder: t('operationsPage.operationsCard1.searchOperation'),
								hideLabel: true
							}
						},
					]}
				/>
				<div id="nw-operation-list-container" className="nw-operation-list-container">
					<Row className="gy-4" xs={1} sm={2} md={3} lg={4} xl={5} xxl={6}>
						{loading ? (
							<>
								{placeholderItems.map((_, index) => (
									<ListOperationItem key={index} isPlaceholder />
								))}
							</>
						) : (
							<>
								{posts?.map((item, index) => (
									<ListOperationItem key={index} item={item} formatDateRange={formatDateRange} handleDownloadPDF={handleDownloadPDF}/>
								))}
							</>
						)}
					</Row>
				</div>

				<div className="paginationDebug" style = {{display : 'none'}}>
					<p>totalItems: {totalItems}</p>
					<p>postsPerPage: {postsPerPage}</p>
					<p>pageCount: {pageCount}</p>
				</div>
				{totalItems > postsPerPage && (
					<ReactPaginate
						previousLabel={ t('back') }
						nextLabel={ t('next') }
						breakLabel={"..."}
						pageCount={pageCount}
						marginPagesDisplayed={2}
						pageRangeDisplayed={3}
						onPageChange={handlePageClick}
						containerClassName={"pagination justify-content-center"}
						pageClassName={"page-item"}
						pageLinkClassName={"page-link"}
						previousClassName={"page-item"}
						previousLinkClassName={"page-link"}
						nextClassName={"page-item"}
						nextLinkClassName={"page-link"}
						breakClassName={"page-item"}
						breakLinkClassName={"page-link"}
						activeClassName={"active"}
					/>
				)} 
			</div>
		</div>
  	);
}
export default ListOperation;
