import { getBackendApiUrl, getAuthTokenWithRefresh, getEasyPlvApiToken } from "../config";
import { checkApiResponse, ApiResponseStatus } from "./auth";
import { setUpdatedCartItemsAndCount } from "./cart";
import { nwFetch } from '../lib/functions';
import { objectToQueryString, getFiltersQueryParam, getOptionsQueryParam } from "../helpers/helpers";

export const getUserSocialMedias = async () => {
    const socialMediasPromise = await fetch(getBackendApiUrl(`social_medias/user`), {
        method: "GET",
        headers: {
          Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
          "X-EasyPLV-Api-Token": getEasyPlvApiToken(),
        },
    });

    checkApiResponse(socialMediasPromise);

    if (socialMediasPromise.status === 200) {
        return await socialMediasPromise.json();
    } else {
        return [];
    }
}

export const getUserSocialMediaById = async (platformId) => {
    const socialMediasPromise = await fetch(getBackendApiUrl(`social_medias/user/${platformId}`), {
        method: "GET",
        headers: {
          Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
          "X-EasyPLV-Api-Token": getEasyPlvApiToken(),
        },
    });

    checkApiResponse(socialMediasPromise);

    if (socialMediasPromise.status === 200) {
        return await socialMediasPromise.json();
    } else {
        return [];
    }
}

export const getDisplayOnlySocialMediaTemplateGroupModelsByUser = async () => {
    const displayOnlyTemplateGroupModelsPromise = await fetch(getBackendApiUrl(`social_media_campaigns/template_group_models/display_only`), {
        method: "GET",
        headers: {
          Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
          "X-EasyPLV-Api-Token": getEasyPlvApiToken(),
        },
    });

    checkApiResponse(displayOnlyTemplateGroupModelsPromise);

    if (displayOnlyTemplateGroupModelsPromise.status === 200) {
        return await displayOnlyTemplateGroupModelsPromise.json();
    } else {
        return [];
    }
}

export const saveSocialMediaProduct = async (socialMediaProductDetails) => {
    try {
        const saveProductPromise = await fetch(getBackendApiUrl('social_media_products/saveFrontSocialMediaProduct'), {
			method: "PUT",
			headers: {
				Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
				"Content-Type": 'application/json'
			},
			body: JSON.stringify(socialMediaProductDetails)
        });
        checkApiResponse(saveProductPromise);
        if (saveProductPromise.status !== 200) return;
        const response = await saveProductPromise.json();

        return response;
      } catch (e) {console.log(e)}
}

export const getSocialMediaProductById = async (id) => {
	const locale = localStorage.getItem('lang');
	const response = await fetch(getBackendApiUrl(`social_media_products/data/${id}?locale=${locale}`), {
		method: "GET",
		headers: {
			Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
			"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
		}
	});
  
	checkApiResponse(response);
	const socialMediaProductData = response.json();
	return socialMediaProductData;
  }

export const getSocialMediaThemeTypes = async () => {
	const url = getBackendApiUrl('social_media_theme_types');
	try {
		const response = await nwFetch(url, {
			method: "GET",
		});

		if (response.status === 200) {
			return await response.json();
		}
	} catch (e) {
		return [];
	}
}

export const getUserSocialMediaCampaignsByThemeType = async (themeType) => {
	const url = getBackendApiUrl(`social_media_campaigns/byTheme/${themeType}`);
	try {
		const response = await nwFetch(url, {
			method: "GET",
		});

		if (response.status === 200) {
			return await response.json();
		}
	} catch (e) {
		return [];
	}
}

export const getSocialMediaTemplateGroups = async (templateGroupBodyData = null) => {
	
	let queryParamStringOptions = '';
	let queryParamStringFilters = '';
	let campaignId = 0;
	if (templateGroupBodyData)
	{
		const options = templateGroupBodyData.options;
		const filters = templateGroupBodyData.filters;

		queryParamStringOptions = Object.entries(options).map(([key, option]) => {
			return getOptionsQueryParam(key, option);
		}).join('&');

		queryParamStringFilters = Object.entries(filters).map(([key, filter]) => {
			return getFiltersQueryParam(key, filter);
		}).join('&')

		campaignId = templateGroupBodyData?.campaignId
	}

	const url = getBackendApiUrl(`social_media/template_groups/byCampaign/${campaignId}?${queryParamStringOptions}&${queryParamStringFilters}`);

	try {
		const response = await nwFetch(url, {
			method: "GET",
		});

		if (response.status === 200) {
			return await response.json();
		}
	} catch (e) {
		return [];
	}
}

export const getTemplateGroupModelsByTemplateGroupId = async (id) => {
	const url = getBackendApiUrl(`social_media/template_group_models/${id}`);
	try {
		const response = await nwFetch(url, {
			method: "GET",
		});

		if (response.status === 200) {
			return await response.json();
		}
	} catch (e) {
		return [];
	}
}

export const saveSocialMediaFreePack = async (data) => {
	const url = getBackendApiUrl("social_media_free_packs/save");
	try {
		const response = await nwFetch(url, {
			method: "PUT",
			headers: {
				"Content-Type" : "application/json",
				"Authorization" : `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
			},
			body : JSON.stringify(data),
		});

		if (response.status === 200) {
			return await response.json();
		}
	} catch (e) {
		return null;
	}
}

export const editSocialMediaFreePack = async (data, socialMediaFreePackId) => {

	const url = getBackendApiUrl("social_media_free_packs/edit");
	data['socialMediaFreePackId'] = socialMediaFreePackId;
	try {
		const response = await nwFetch(url, {
			method: "PUT",
			headers: {
				"Content-Type" : "application/json",
				"Authorization" : `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
			},
			body : JSON.stringify(data),
		});

		if (response.status === 200) {
			return await response.json();
		}
	} catch (e) {
		return null;
	}
}

export const getSocialMediaFreePackById = async (id) => {
	const url = getBackendApiUrl(`social_media_free_packs/${id}`);
	try {
		const response = await nwFetch(url, {
			method: "GET",
		});

		if (response.status === 200) {
			return await response.json();
		}
	} catch (e) {
		return null;
	}
}

export const downloadSocialMediaFreePack = async (data) => {
	const url = getBackendApiUrl(`social_media_free_packs/download/from_front_data`);
	try {
		const response = await nwFetch(url, {
			method: "POST",
			headers: {
				"Content-Type" : "application/json",
				"Authorization" : `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
			},
			body : JSON.stringify(data)
		});

		if (response.status === 200) return response.blob();

		return null;
	} catch (e) {
		return null;
	}
}

export const downloadSocialMediaFreePackById = async (id) => {
	const url = getBackendApiUrl(`social_media_free_packs/download/${id}`);
	try {
		const response = await nwFetch(url, {
			method: "GET",
		});

		if (response.status === 200) return response.blob();

		return null;
	} catch (e) {
		return null;
	}
}

export const downloadSocialMediaFreePosterById = async (socialMediaFreePosterId) => {
	const url = getBackendApiUrl(`social_media_free_posters/download/by_id`);
	try {
		const response = await nwFetch(url, {
			method: "POST",
			headers: {
				"Content-Type" : "application/json",
				"Authorization" : `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
			},
			body : JSON.stringify({
				socialMediaFreePosterId : socialMediaFreePosterId
			})
		});

		if (response.status === 200) {
			return response.blob()
		}
		return null;
	} catch (e) {
		return null;
	}
}

export const deleteSocialMediaFreePosterById = async (socialMediaFreePosterId) => {
	const url = getBackendApiUrl(`social_media_free_posters/delete/by_id`);
	try {
		const response = await nwFetch(url, {
			method: "POST",
			headers: {
				"Content-Type" : "application/json",
				"Authorization" : `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
			},
			body : JSON.stringify({
				socialMediaFreePosterId : socialMediaFreePosterId
			})
		});

		if (response.status === 200) {
			const responseData = await response.json();
			return responseData.success;
		}

		return false;
	} catch (e) {
		return false;
	}
}

export const deleteSocialMediaFreePackById = async (socialMediaFreePackId) => {
	const url = getBackendApiUrl(`social_media_free_packs/delete/by_id`);
	try {
		const response = await nwFetch(url, {
			method: "POST",
			headers: {
				"Content-Type" : "application/json",
				"Authorization" : `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
			},
			body : JSON.stringify({
				socialMediaFreePackId : socialMediaFreePackId
			})
		});

		if (response.status === 200) {
			const responseData = await response.json();
			return responseData.success;
		}

		return false;
	} catch (e) {
		return false;
	}
}

export const downloadSocialMediaPosterPack = async (data) => {
	const url = getBackendApiUrl(`social_media_products/download/from_front_data`);
	try {
		const response = await nwFetch(url, {
			method: "POST",
			headers: {
				"Content-Type" : "application/json",
				"Authorization" : `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
			},
			body : JSON.stringify(data)
		});

		return response.blob();
	} catch (e) {
		return null;
	}
}

export const downloadSocialMediaPackById = async (socialMediaProductId) => {
	const url = getBackendApiUrl(`social_media_products/download/by_id`);
	try {
		const response = await nwFetch(url, {
			method: "POST",
			headers: {
				"Content-Type" : "application/json",
				"Authorization" : `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
			},
			body: JSON.stringify({
				socialMediaProductId : socialMediaProductId
			})
		});

		if (response.status === 200) return response.blob();
		else return null;
	} catch (e) {
		return null;
	}
}

export const downloadSocialMediaPosterPackByQueue = async (data) => {
	const url = getBackendApiUrl(`social_media_products/download/from_front_data/by_queue`);
	try {
		const responsePromise = await nwFetch(url, {
			method: "POST",
			headers: {
				"Content-Type" : "application/json",
				"Authorization" : `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
			},
			body : JSON.stringify(data)
		});

        if (responsePromise.status !== 200) return null;
        const response = await responsePromise.json();
        return response;
	} catch (e) {
		return null;
	}
}

export const deleteSocialMediaProductById = async (socialMediaProductId) => {
	const url = getBackendApiUrl(`social_media_products/delete/by_id`);
	try {
		const response = await nwFetch(url, {
			method: "POST",
			headers: {
				"Content-Type" : "application/json",
				"Authorization" : `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
			},
			body : JSON.stringify({
				socialMediaProductId : socialMediaProductId
			})
		});

		if (response.status === 200) {
			const responseData = await response.json();
			return responseData.success;
		}

		return false;
	} catch (e) {
		return false;
	}
}

export const downloadSocialMediaPosterImageById = async (socialMediaPosterId) => {
	const url = getBackendApiUrl(`social_media_posters/download/by_id`);
	try {
		const response = await nwFetch(url, {
			method: "POST",
			headers: {
				"Content-Type" : "application/json",
				"Authorization" : `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
			},
			body : JSON.stringify({
				socialMediaPosterId : socialMediaPosterId
			})
		});

		if (response.status === 200) {
			return response.blob()
		}
		return null;
	} catch (e) {
		return null;
	}
}

export const deleteSocialMediaPosterById = async (socialMediaPosterId) => {
	const url = getBackendApiUrl(`social_media_posters/delete/by_id`);
	try {
		const response = await nwFetch(url, {
			method: "POST",
			headers: {
				"Content-Type" : "application/json",
				"Authorization" : `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
			},
			body : JSON.stringify({
				socialMediaPosterId : socialMediaPosterId
			})
		});

		if (response.status === 200) {
			const responseData = await response.json();
			return responseData.success;
		}

		return false;
	} catch (e) {
		return false;
	}
}

export const formatSocialMediasToEditorUseFormat = (socialMedias) => {
	const newSocialMediasData = {};
	socialMedias.forEach(socialMedia => {
		const postTypes = socialMedia.postTypes;
		newSocialMediasData[`${socialMedia.id}`] = {
			id: socialMedia.id,
			name: socialMedia.name,
			code: socialMedia.code,
			postTypes : {},
		}
		postTypes.forEach(postType => {
			postType.selected = false;
			newSocialMediasData[`${socialMedia.id}`]["postTypes"][`${postType.id}`] = postType;
		})
	})

	return newSocialMediasData;
}

export const getSocialMediaTemplateGroupModelByUuid = async (uuid) => {
	const url = getBackendApiUrl(`social_media/template_group_model/${uuid}`);
	try {
		const response = await nwFetch(url, {
			method: "GET",
		});

		if (response.status === 200) return response.json();

		return null;

	} catch (e) {
		return null;
	}
} 

export const updateSocialMediaTemplateGroupModelSceneJson = async (sceneJson, imageDataUrl, uuid) => {
	const url = getBackendApiUrl(`social_media/template_group_model/update_scene_json`);
	const payload = {
		uuid: uuid,
		sceneJson: sceneJson,
		imageDataUrl: imageDataUrl
	}
	try {
		const response = await nwFetch(url, {
			method: "POST",
			headers: {
				"Content-Type" : "application/json",
				"Authorization" : `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
			},
			body : JSON.stringify(payload)
		});

		if (response.status === 200) return response.json();

		return null;

	} catch (e) {
		return null;
	}
}

export const getSocialMediaFreePacksByUser = async (page=1, filters) => {
	const filterQueryString = objectToQueryString(filters);
	filters['itemsPerPage'] = 20;
	const url = getBackendApiUrl(`social_media_free_packs/byUser?page=${page}&${filterQueryString}`);
	const response = await nwFetch(url, {
		method: "GET",
	})

	if (response.status === 200) return await response.json();

	return [];
} 

export const getSocialMediaProductsByUser = async (page=1, filters) => {
	const filterQueryString = objectToQueryString(filters);
	filters['itemsPerPage'] = 20;
	const url = getBackendApiUrl(`social_media_products/byUser?page=${page}&${filterQueryString}`);
	const response = await nwFetch(url, {
		method: "GET",
	})

	if (response.status === 200) return await response.json();

	return [];
}

export const getUserSocialMediaFreePostersByFreePack = async (freePackId) => {
	const url = getBackendApiUrl(`social_media_free_posters/pack/${freePackId}`);
	const response = await nwFetch(url, {
		method: "GET",
	})

	if (response.status === 200) return await response.json();

	return [];
}

export const getUserFreePackCategories = async () => {
	const url = getBackendApiUrl(`user_social_media_free_pack_categories/byUser`);
	const response = await nwFetch(url, {
		method: "GET",
	})

	if (response.status === 200) return await response.json();

	return [];
}

export const getUserFreePacksCategoriesByFilters = async () => {
	const url = getBackendApiUrl(`user_social_media_free_pack_categories/byFilters`);
	const response = await nwFetch(url, {
		method: "GET",
	})

	if (response.status === 200) return await response.json();

	return [];
}

export const createUserSocialMediaFreePackCategory = async (data) => {
	const url = getBackendApiUrl(`user_social_media_free_pack_categories/create`);
	try {
		const response = await nwFetch(url, {
			method: "POST",
			headers: {
				"Content-Type" : "application/json",
				"Authorization" : `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
			},
			body : JSON.stringify(data)
		});

		if (response.status === 200) return response.json();

		return null;

	} catch (e) {
		return null;
	}
}

export const deleteUserSocialMediaFreePacksCategory = async (categoryId) => {
	const url = getBackendApiUrl(`user_social_media_free_pack_categories/${categoryId}/delete`);
	const response = await nwFetch(url, {
		method: "DELETE",
		headers: {
			"Content-Type" : "application/json",
			"Authorization" : `Bearer ${await getAuthTokenWithRefresh()}`,
			"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
		},
	})

	if (response.status === 204) return true;

	return null;
}

export const editUserSocialMediaFreePacksCategory = async (categoryId, data) => {
	const url = getBackendApiUrl(`user_social_media_free_pack_categories/${categoryId}/edit`);
	const response = await nwFetch(url, {
		method: "PUT",
		headers: {
			"Content-Type" : "application/json",
			"Authorization" : `Bearer ${await getAuthTokenWithRefresh()}`,
			"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
		},
		body: JSON.stringify(data)
	})

	if (response.status === 200) return true;

	return null;
}

export const getSocialMediaTemplateGroupBodyData = (campaignId = 0) => {
	return {
		campaignId : campaignId,
		options: {
			page: 1,
			itemsPerPage: 10,
			sort: [
				{
					direction: 'ASC',
					orderBy: 'name'
				}
			]
		},
		filters: {
			name: ''
		}	
	};
}

export const getUserSocialMediaProductCategoryByFilters = async () => {
	const url = getBackendApiUrl(`user_social_media_product_categories/byFilters`);
	const response = await nwFetch(url, {
		method: "GET",
	})

	if (response.status === 200) return await response.json();

	return [];
}

export const createUserSocialMediaProductCategory = async (data) => {
	const url = getBackendApiUrl(`user_social_media_product_categories/create`);
	try {
		const response = await nwFetch(url, {
			method: "POST",
			headers: {
				"Content-Type" : "application/json",
				"Authorization" : `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
			},
			body : JSON.stringify(data)
		});

		if (response.status === 200) return response.json();

		return null;

	} catch (e) {
		return null;
	}
}

export const editUserSocialMediaProductCategory = async (categoryId, data) => {
	const url = getBackendApiUrl(`user_social_media_product_categories/${categoryId}/edit`);
	const response = await nwFetch(url, {
		method: "PUT",
		headers: {
			"Content-Type" : "application/json",
			"Authorization" : `Bearer ${await getAuthTokenWithRefresh()}`,
			"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
		},
		body: JSON.stringify(data)
	})

	if (response.status === 200) return true;

	return null;
}

export const deleteUserSocialMediaProductCategory = async (categoryId) => {
	const url = getBackendApiUrl(`user_social_media_product_categories/${categoryId}/delete`);
	const response = await nwFetch(url, {
		method: "DELETE",
		headers: {
			"Content-Type" : "application/json",
			"Authorization" : `Bearer ${await getAuthTokenWithRefresh()}`,
			"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
		},
	})

	if (response.status === 204) return true;

	return null;
}

export const getUserSocialMediaProductCategories = async () => {
	const url = getBackendApiUrl(`user_social_media_product_categories/byUser`);
	const response = await nwFetch(url, {
		method: "GET",
	})

	if (response.status === 200) return await response.json();

	return [];
}

export const getUserSocialMediaPostersByPack = async (packId) => {
	const url = getBackendApiUrl(`social_media_posters/pack/${packId}`);
	const response = await nwFetch(url, {
		method: "GET",
	})

	if (response.status === 200) return await response.json();

	return [];
}

////////////////// GET DISCOUNT(PRICE MECHANIC) WITH FILTER AND OPTIONS /////////////////////////////
export const getSocialMediaDiscounts = async (discountBodyData = null) => {
	let queryParamStringOptions = '';
	let queryParamStringFilters = '';
	if (discountBodyData)
	{
		const options = discountBodyData.options;
		const filters = discountBodyData.filters;
		filters['module'] = 'social_media';

		queryParamStringOptions = Object.entries(options).map(([key, option]) => {
			return getOptionsQueryParam(key, option);
		}).join('&');

		queryParamStringFilters = Object.entries(filters).map(([key, filter]) => {
			return getFiltersQueryParam(key, filter);
		}).join('&')
	}

	const response = await fetch(getBackendApiUrl(`discounts/byStoreAndFilters?${queryParamStringOptions}&${queryParamStringFilters}`), {
		method: "GET",
		headers: {
		  Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
		  "X-EasyPLV-Api-Token": getEasyPlvApiToken(),
		},
	});

	checkApiResponse(response);

	const data = await response.json();
	return data;
}

export const getTemplateGroupCoversByTemplateGroup = async (templateGroupId) => {
    const url = getBackendApiUrl(`social_media/template_group_covers/byTemplateGroup/${templateGroupId}`);
    const response = await nwFetch(url);

    if (response.status === 200) return await response.json();

    return {};
}

export const getSocialMediaCoversBySocialMediaProduct = async (socialMediaProductId) => {
    const url = getBackendApiUrl(`social_media_covers/bySocialMediaProduct/${socialMediaProductId}`);

    const response = await nwFetch(url);

    if (response.status === 200) return await response.json();

    return {};
}

export const getSocialMediaPackGroupsByUserStore = async () => {
    const url = getBackendApiUrl(`social_media_pack_groups/by_user_stores`);

    const response = await nwFetch(url);

    if (response.status === 200) return await response.json();

    return [];
}

export const getSocialMediaPackGroupsByUserStoreAndFilters = async (page= 1, filters = {}) => {
    const filterQueryString = objectToQueryString(filters);
	filters['itemsPerPage'] = 20;
    const url = getBackendApiUrl(`social_media_pack_groups/by_user_stores_and_filters?page=${page}&${filterQueryString}`);

    const response = await nwFetch(url);

    if (response.status === 200) return await response.json();

    return [];
}

export const getSocialMediaPackGroupPosters = async (packGroupId) => {
    const url = getBackendApiUrl(`social_media_pack_groups/${packGroupId}/posters`);

    const response = await nwFetch(url);

    if (response.status === 200) return await response.json();

    return null;
}

export const deleteSocialMediaPackGroupById = async (id) => {
    const url = getBackendApiUrl(`social_media_pack_groups/delete/by_id`);

	try {
		const response = await nwFetch(url, {
			method: "POST",
			headers: {
				"Content-Type" : "application/json",
				"Authorization" : `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
			},
			body : JSON.stringify({
				socialMediaPackGroupId : id
			})
		});

		if (response.status === 200) {
			const responseData = await response.json();
			return responseData.success;
		}

		return false;
	} catch (e) {
		return false;
	}
} 

export const downloadSocialMediaPackGroupById = async (id) => {
    const url = getBackendApiUrl(`social_media_pack_groups/download/by_id`);

    try {
		const response = await nwFetch(url, {
			method: "POST",
			headers: {
				"Content-Type" : "application/json",
				"Authorization" : `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
			},
			body : JSON.stringify({
				socialMediaPackGroupId : id
			})
		});

		if (response.status === 200) return response.blob();

		return null;
	} catch (e) {
		return null;
	}
}

export const createSocialMediaPackGroupCategory = async (data) => {
	const url = getBackendApiUrl(`social_media_pack_group_categories/create`);
	try {
		const response = await nwFetch(url, {
			method: "POST",
			headers: {
				"Content-Type" : "application/json",
				"Authorization" : `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
			},
			body : JSON.stringify(data)
		});

		if (response.status === 200) return response.json();

		return null;

	} catch (e) {
		return null;
	}
}

export const editSocialMediaPackGroupCategory = async (categoryId, data) => {
	const url = getBackendApiUrl(`social_media_pack_group_categories/${categoryId}/edit`);
	const response = await nwFetch(url, {
		method: "PUT",
		headers: {
			"Content-Type" : "application/json",
			"Authorization" : `Bearer ${await getAuthTokenWithRefresh()}`,
			"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
		},
		body: JSON.stringify(data)
	})

	if (response.status === 200) return true;

	return null;
}

export const deleteSocialMediaPackGroupCategory = async (categoryId) => {
    const url = getBackendApiUrl(`social_media_pack_group_categories/delete/by_id`);
	try {
		const response = await nwFetch(url, {
			method: "POST",
			headers: {
				"Content-Type" : "application/json",
				"Authorization" : `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
			},
			body : JSON.stringify({
                socialMediaPackGroupCategoryId : categoryId
            })
		});

		if (response.status === 200) {
            const responseData = await response.json();
            return responseData?.success;
        }

		return false;

	} catch (e) {
		return false;
	}
}

export const getSocialMediaPackGroupCategoriesByFilters = async () => {
    const url = getBackendApiUrl(`social_media_pack_group_categories/byFilters`);
	const response = await nwFetch(url, {
		method: "GET",
	})

	if (response.status === 200) return await response.json();

	return [];
}

export const assignSocialMediaPackGroupToCategory = async (categoryId, socialMediaPackGroupId) => {
    const url = getBackendApiUrl(`social_media_pack_groups/swap_category`);
	try {
		const response = await nwFetch(url, {
			method: "POST",
			headers: {
				"Content-Type" : "application/json",
				"Authorization" : `Bearer ${await getAuthTokenWithRefresh()}`,
				"X-EasyPLV-Api-Token": getEasyPlvApiToken(),
			},
			body : JSON.stringify({
                socialMediaPackGroupCategoryId : categoryId !== undefined ? parseInt(categoryId) : 0,
                socialMediaPackGroupId: socialMediaPackGroupId
            })
		});

		if (response.status === 200) {
            const responseData = await response.json();
            return responseData?.success;
        }

		return false;

	} catch (e) {
		return false;
	}
}

export const getSocialMediaTemplateGroupModelById = async (id) => {
    const url = getBackendApiUrl(`social_media/template_group_model/by_id/${id}`);
	const response = await nwFetch(url, {
		method: "GET",
	})

	if (response.status === 200) return await response.json();

	return [];
}

export const getSocialMediaTemplateGroupById = async (id) => {
	const url = getBackendApiUrl(`social_media_template_groups/${id}`);
	const templateGroupResponse = await nwFetch(url)

	if (checkApiResponse(templateGroupResponse) === ApiResponseStatus.OK) {
		return await templateGroupResponse.json();
	}

	return null;
}




