import { getAuthToken, getAuthTokenWithRefresh } from "../config";
import { getEasyPlvApiToken } from "../config";


export async function getPagesListing () {
    const backendPagesApiUrl = `${process.env.REACT_APP_BACKEND_URL}/api/formats`;
    // const authToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImN0eSI6IkpXVCJ9.eyJpYXQiOjE2OTEwNDY5NDIsImV4cCI6MTY5MTA1MDU0Miwicm9sZXMiOlsiUk9MRV9VU0VSIl0sImVtYWlsIjoidGVzdEBudW1lcmljd2F5Lm5ldCIsImlwIjoiOjoxIiwiY3VzdG9tTXNnIjoiU2hhbmlzaCJ9.I1QROyZ9gQIdRKUQoSR1UZtVuZ60UWOy_SCd8d7WptYP8LD3v28Jp457qkpK1Oz-7b35VSKBK61LAaLvTBQOdHzTxSnhfrLYcp37--5Wiff6xltHzD3Jdq_L7XUfc-KUPxqd9IOWwX2YI8mB30gF7eVqExT5NtRMC-0tzrLhSuf_nrg4AkO3lKE8QR0Sypxn3KAitFtURJlPqLGqt7K9aTwiI_wmnr4inrhU7W7PJKA7EbbTlV51NzlVFs40ZQ8CAzH5CO_CGsPn-E2RHHX5NJSn0AFm_zUeqs44jlWyNorxRoZvvpJML5f4kUScgX1l_y-OpTfpPobqjBXmkmGtEw";
    const authToken = await getAuthTokenWithRefresh();
    const pagesListingPromiseResponse = await fetch(backendPagesApiUrl, {
        method : "GET",
        headers : {
            "Content-Type" : "application/json",
            "Authorization" : `Bearer ${authToken}`,
            "X-EasyPLV-Api-Token" : `${process.env.REACT_APP_EASY_PLV_API_TOKEN}`
        }
    })
    
    return pagesListingPromiseResponse;
}

export async function login (email, password) {
    const backendLoginApiUrl = `${process.env.REACT_APP_BACKEND_URL}/auth`;
    const loginPromiseReponse = await fetch(backendLoginApiUrl, {
        method : "POST",
        headers: {
            "Content-Type" : "application/json"
        },
        body : JSON.stringify({email : email, password: password})
    })

    if (loginPromiseReponse.status === 200) {
        const response = await loginPromiseReponse.json();
        if (response.token) {
            localStorage.setItem('auth_token', response.token);
            localStorage.setItem('refresh_token', response.refresh_token);
            return true;
        } else {
            return false;
        }
    } 
}

/**
 * The `compareKeys` function in JavaScript compares the keys of two objects to determine if they are
 * the same.
 * @param obj1 - obj1 is an object that contains information about a user, such as their name, age, and
 * email.
 * @param obj2 - It seems like you have not provided the `obj2` parameter for the `compareKey`
 * function. Could you please provide the `obj2` object so that I can assist you further with the
 * comparison?
 * @returns The `compareKeys` function is returning `true` if both objects `obj1` and `obj2` have the
 * same number of keys and all keys in `obj1` exist in `obj2`. Otherwise, it returns `false`.
 */
export const compareKeys = (obj1, obj2) => {
	const obj1Keys = Object.keys(obj1);
	const obj2Keys = Object.keys(obj2);

	// Check if both objects have the same number of keys
	if (obj1Keys.length !== obj2Keys.length) {
		return false;
	}

	// Check if all keys in obj1 exist in obj2
	for (const key of obj1Keys) {
		if (!obj2.hasOwnProperty(key)) {
		return false;
		}
	}

	// If we've made it this far, the objects have the same number of keys and the same keys
	return true;
}

/**
 * The function `compareAtLeastOneKey` checks if at least one key from one object exists in another
 * object.
 * @param obj1 - obj1 is the first object that will be compared with obj2.
 * @param obj2 - obj2 is an object that will be compared with obj1 to check if at least one key in obj1
 * exists in obj2.
 * @returns The `compareAtLeastOneKey` function returns `true` if at least one key in `obj1` exists in
 * `obj2`, and `false` if none of the keys in `obj1` are found in `obj2`.
 */
export const compareAtLeastOneKey = (obj1, obj2) => {
    const obj1Keys = Object.keys(obj1);

    // Check if at least one key in obj1 exists in obj2
    for (const key of obj1Keys) {
        if (obj2.hasOwnProperty(key)) {
            return true; // Found at least one key that exists in both objects
        }
    }

    // No keys in obj1 were found in obj2
    return false;
}

/**
 * The `logout` function removes the 'auth_token' from the localStorage.
 */
export async function logout () {
    localStorage.removeItem('auth_token');
}


/**
 * The function `nwFetch` is an asynchronous function that performs a fetch request with additional
 * options and handles authentication headers.
 * @param url - The `url` parameter in the `nwFetch` function is the URL to which the fetch request
 * will be made. It specifies the location from which to retrieve data.
 * @param [additionalOptions] - The `additionalOptions` parameter in the `nwFetch` function is an
 * object that allows you to provide additional configuration options for the fetch request. It has the
 * following properties:
 * @returns The `nwFetch` function returns a Promise that resolves to the response object from the
 * `fetch` call.
 */
export async function nwFetch(url, additionalOptions = {}) {
    // Ensure getAuthToken is called if it's a function
    const authToken = typeof getAuthToken === 'function' ? await getAuthTokenWithRefresh() : getAuthToken;
    const easyPlvToken = getEasyPlvApiToken(); // Assuming this is always a function call
    // Extract headers and method from additionalOptions
    const { headers: additionalHeaders = {}, method: additionalMethod, ...restOptions } = additionalOptions;

    // Prepare headers
    const headers = {
        'Authorization': `Bearer ${authToken}`,
        'X-EasyPLV-Api-Token': easyPlvToken,
        ...additionalHeaders
    };

    // Prepare the fetch options
    const options = {
        method: additionalMethod || "GET", // Default to GET if no method specified
        headers,
        ...restOptions
    };

    // Conditionally add body if not GET or HEAD
    if (!['GET', 'HEAD'].includes(options.method.toUpperCase()) && additionalOptions.body) {
        options.body = additionalOptions.body;
    }

    // Execute fetch
    const nwResponse = await fetch(url, options);

    // Optional: Check if the response was successful
    if (!nwResponse.ok) {
        // Handle HTTP errors
        throw new Error(`HTTP error! Status: ${nwResponse.status}`);
    }

    return nwResponse;
}
