import { getAuthTokenWithRefresh, getBackendApiUrl, getEasyPlvApiToken } from "../config";
import { checkApiResponse } from "./auth";

export const getTemplateGroupMetadata = async (templateGroupId = 0) => {
    const authToken = await getAuthTokenWithRefresh();
	const apiKey = getEasyPlvApiToken();
    try {
        const getTemplateGroupMetadataPromise = await fetch(getBackendApiUrl(`templateGroups/metadata/${templateGroupId}`), {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authToken}`,
				"X-EasyPLV-Api-Token": apiKey,
				"Content-Type": "application/json",
            }
        });

        checkApiResponse(getTemplateGroupMetadataPromise);

        if (getTemplateGroupMetadataPromise.status === 200) {
            return await getTemplateGroupMetadataPromise.json();
        } else {
            return null;
        }
    } catch (e) {}
}   