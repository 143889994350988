import {React, useState, useEffect} from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { NwSocialMediaCreateEditorIcon, NwSocialMediaCreateFreeEditorIcon } from '../../components/_utilityComponents/NwIcons/NwIcons';
import NwImage from '../../components/_utilityComponents/NwImage/NwImage';
import LeclercLogo from '../../assets/images/logos/logo-Leclerc.svg'
import { useCurrentPlatform } from '../../hooks/hooks';
import NwCarousel from '../../components/_utilityComponents/NwCarousel/NwCarousel';
import { Link } from 'react-router-dom';
import './SocialMedia.css';
import { useAuth } from "../../contexts/AuthContext";
import {
    getUserSocialMedias,
    getDisplayOnlySocialMediaTemplateGroupModelsByUser,
} from "../../services/social_media";
import { SocialMediaPlatformHeaderBackgroundMapping } from '../../helpers/socialMediaHelper';
import { formatLabelforFormatPreview, isEmptyObject } from '../../helpers/helpers';

const SocialMediaDashboardBlock = ({ innerDivClass = '', ...props }) => {
    return (
        <Col { ...props } className={`p-2 mt-0 ${props.className}`} >
            <div className={`social-media-dashboard-block w-100 h-100 border border-4 rounded-4 border-white ${innerDivClass}`}>
                { props.children }
            </div>
        </Col>
    )
}

const SocialMediaDashboardShortcutBlock = ({
    id,
    name,
    background,
    logo,
    code,
    url
}) => {
    return (
        <Col className='p-2 h-50'>
            <div className={`shortcut-block ${ SocialMediaPlatformHeaderBackgroundMapping?.[code]?.index } d-flex w-100 h-100 rounded-3 overflow-hidden position-relative justify-content-center align-items-center`}>
                <NwImage
                    src={logo}
                    wrapperClassName='d-flex justify-content-center align-items-center'
                    wrapperStyles={{
                        padding: 'var(--nw-responsive-padding-lg)',
                    }}
                    imgStyles={{
                        height: '50%'
                    }}
                    loadingBgInherit
                    imgClassName='object-fit-contain'
                />
                <div className="shortcut-block-actions-container position-absolute bottom-0">
                    <div className="shortcut-block-actions-wrapper">
                        <Link to={url} className='btn shortcut-block-action bg-white text-black border-0 fw-bold nw-responsive-font-size-sm'>
                            Visuel Produit
                        </Link>
                    </div>
                </div>
            </div>
        </Col>
    )
}

const SocialMediaExampleCarouselItem = ({
    templateGroupModel
}) => {

    const itemAspectRatio = isEmptyObject(templateGroupModel?.postType?.size) ? 1 : formatLabelforFormatPreview(templateGroupModel?.postType?.size?.width / templateGroupModel?.postType?.size?.height > 1 ? 'landscape' : 'portrait', templateGroupModel?.postType?.size).aspectRatio;

    return (
        <Link to={`/social-media/free-editor/new/social_media_product/0?template_group_model_id=${templateGroupModel.id}`} className='w-100 h-100 d-flex justify-content-center align-items-center'>
            <div className={`nw-social-media-example-item-wrapper mw-100 mh-100 overflow-hidden position-relative d-flex flex-column ${itemAspectRatio > 1 ? 'w-100' : 'h-100'}`}
                style={{
                    aspectRatio: itemAspectRatio
                }}
            >
                {
                    templateGroupModel?.postType?.otherConfiguration?.header?.url && (
                        <img 
                            className={`w-100 object-fit-fill ${templateGroupModel?.postType?.otherConfiguration?.header?.isOverlay ? '' : ''}`} 
                            src={templateGroupModel?.postType?.otherConfiguration?.header?.url ?? ''}
                        />
                    )
                }
                <NwImage imgClassName='object-fit-fill w-100 h-100' src={templateGroupModel.images?.thumbnailPreviewUrl}/>
                {
                    templateGroupModel?.postType?.otherConfiguration?.footer?.url && (
                        <img 
                            className={`w-100 object-fit-fill ${templateGroupModel?.postType?.otherConfiguration?.footer?.isOverlay ? 'position-absolute bottom-0' : ''}`} 
                            src={templateGroupModel?.postType?.otherConfiguration?.footer?.url ?? ''}
                        />
                    )
                }
            </div>
        </Link>
    )
}

const SocialMediaExamplesCarousel = ({
    onSlideChange
}) => {
    const OPTIONS = { axis: 'y' }
    const [ templateModelExamples, setTemplateModelExamples ] = useState([]);
    const [ templateGroupModels, setTemplateGroupModels ] = useState(); // state to be used for getting data when needed from NwCarousel events

    useEffect(() => {
        getDisplayOnlySocialMediaTemplateGroupModelsByUser().then(templateGroupModels => {
            const examples = templateGroupModels.map(templateGroupModel => {
                return {
                    "id" : templateGroupModel.id,
                    "name" : templateGroupModel.name,
                    "content" : <SocialMediaExampleCarouselItem templateGroupModel={templateGroupModel}/>
                }
            });
            setTemplateGroupModels(templateGroupModels);
            setTemplateModelExamples(examples);
            onSlideChange(templateGroupModels?.[0]);
        });
    }, []);

    const OnCarouselSlideChange = (selectedSlide) => {
        if (templateGroupModels){
            // get the the current templateGroupModel based on the slide data id, which in this case is the templateGroupModel id
            const currentTemplateGroupModel = templateGroupModels.find(templateGroupModel => templateGroupModel.id == selectedSlide.id);

            if (onSlideChange) {
                onSlideChange(currentTemplateGroupModel);
            }
        }        
    }

    return (
        <NwCarousel
            slides={templateModelExamples} 
            options={OPTIONS}
            hideNavigationButtons
            onSlideChange={OnCarouselSlideChange}
            classNames={{
                viewport_container: 'flex-column'
            }}
        />
    );
}

const SocialMedia = () => {
    const [platforms, setPlatforms] = useState([]);
    const currentPlatform = useCurrentPlatform();
    const [ currentSocialMediaExamplesCarouselTemplateGroup, setCurrentSocialMediaExamplesCarouselTemplateGroup ] = useState('');
    const {userNw} = useAuth();

    useEffect(() => {
        getUserSocialMedias().then(platforms => {
            const platformsShortcut = platforms.map(platform => {
                return {
                    "id" : platform.id,
                    "background" : platform.logo_image?.thumbnailPreviewUrl,
                    "logo" : platform.logo_image?.thumbnailPreviewUrl,
                    "code" : platform.code,
                    "url": `/social-media/editor/new/social_media_product/0?platformId=${platform.id}`
                }
            });
            setPlatforms(platformsShortcut);
        });
    }, []);

    const onSocialMediaExamplesCarouselSlideChange = (currentTemplateGroupModel) => {
        setCurrentSocialMediaExamplesCarouselTemplateGroup(currentTemplateGroupModel ?? '');
    }

    return (
        <div className={`social-media-dashboard p-5 ${currentPlatform}`}>
            <Row className='me-0 ms-0 mt-0'>
                <SocialMediaDashboardBlock innerDivClass='create-using-editor'>
                    <div className='nw-block-wrapper d-flex flex-column justify-content-center h-100 w-100 align-items-center'>
                        <div className='icon-container'>
                            <NwSocialMediaCreateEditorIcon size={'100%'} />
                        </div>
                        <div className='title fw-bolder'>Visuel Produit</div>
                        <div className='description fw-bold'>
                            Mettez en avant les produits de votre magasin en utilisant notre <br/> concepteur de visuels adaptés aux supports digitaux
                        </div>
                        <Link to={`/social-media/editor/new/social_media_product/0?company_id=${userNw?.companyId}`} className="w-50">
                            <Button className='w-100 block-cta rounded-3 fw-bold'>Créer un visuel produit</Button>
                        </Link>
                    </div>
                </SocialMediaDashboardBlock>
                <SocialMediaDashboardBlock innerDivClass='create-using-editor free-editor'>
                    <div className='nw-block-wrapper d-flex flex-column justify-content-center h-100 w-100 align-items-center'>
                        <div className='icon-container'>
                            <NwSocialMediaCreateFreeEditorIcon size={'100%'} />
                        </div>
                        <div className='title fw-bolder'>Visuel libre</div>
                        <div className='description fw-bold'>
                            Mettez en avant des photos du magasin, une opération, des  <br/> collaborateurs. Soyez créatifs avec nos modèles prédéfinis !
                        </div>
                        <Link to="/social-media/free-editor/new/social_media_product/0" className="w-50">
                            <Button className='w-100 block-cta rounded-3 fw-bold'>Créer un visuel libre</Button>
                        </Link>
                    </div>
                </SocialMediaDashboardBlock>
            </Row>
            <Row className='me-0 ms-0 mt-0'>
                <SocialMediaDashboardBlock className='h-100' innerDivClass='shortcut-list-block h-100' xs={9}>
                    <div className="nw-block-wrapper d-flex flex-column w-100 h-100 justify-content-around p-3 px-4">
                        <div className="title fw-bolder">
                            Raccourci vers la création d'un visuel par support
                        </div>
                        <div className="shortcut-list-wrapper w-100">
                            <Row xs={2} className='me-0 ms-0 mt-0 h-100'>
                                {
                                    platforms && platforms?.map((item, index) => (
                                        <SocialMediaDashboardShortcutBlock key={index} { ...item }/>
                                    ))
                                }   
                            </Row>
                        </div>
                    </div>
                </SocialMediaDashboardBlock>
                <SocialMediaDashboardBlock className='h-100' xs={3}>
                    <div className="nw-block-wrapper w-100 h-100 d-flex flex-column align-items-center justify-content-around row-gap-2 p-3">
                        <div className='h-15'>
                            <div className="title fw-bolder nw-responsive-font-size">
                                Exemple de réalisations
                            </div>
                            <div className="current-template-group-model-campaign-title nw-responsive-font-size-sm nw-text-placeholder-color nw-text-center">
                                { currentSocialMediaExamplesCarouselTemplateGroup?.campaign?.name }
                            </div>
                        </div>
                        <div className="nw-social-media-carousel-block-wrapper w-100 h-85 overflow-y-hidden">
                            <SocialMediaExamplesCarousel
                                onSlideChange={onSocialMediaExamplesCarouselSlideChange}
                            />
                        </div>
                    </div>
                </SocialMediaDashboardBlock>
            </Row>
        </div>
    )
}

export default SocialMedia;