import { useState, useContext } from "react";
import { 
    Form, 
    Badge, 
    Col, 
    Button,
    OverlayTrigger,
    Tooltip,
    Card
} from "react-bootstrap";
import {
  assignProductToCategory,
  duplicateProductById,
} from "../../../services/posters";
import { useTranslation } from "react-i18next";
import NwCustomModal from "../../_utilityComponents/NwCustomModal/NwCustomModal";
import NwImage from "../../_utilityComponents/NwImage/NwImage";
import {
  Cart,
  Folder,
  PencilSquare,
  Trash,
  Files,
  Eye
} from "react-bootstrap-icons";
import Swal from "sweetalert2";
import Alert from "react-bootstrap/Alert";
import { triggerToast } from "../../../helpers/helpers";

//Context
import MainLayoutContext from "../../../contexts/MainLayoutContext";

import './MainPosterCard.css';

export default function MainPosterCard(props) {
    const [isHover, setIsHover] = useState(false);
    const { isTouchScreen } = useContext(MainLayoutContext);
    const [ onDelete, setOnDelete ] = useState(false);
    const { t } = useTranslation();
    const [isAssigning, setIsAssigning] = useState();
    const [isDuplicating, setIsDuplicating] = useState(false);
    const [duplicatingId, setDuplicatingId] = useState(0);
    const [closeModalFlag, setCloseModalFlag] = useState(false);
    const isDebug = props.isDebug || false;
    const [displayPreview, setDisplayPreview] = useState(false);

    const selectedFormatValues = props.selectedFormatValues;
    const [selectedCategoryId, setSelectedCategoryId] = useState(
        props.data.userProductCategory[0]?.id || "0"
    );
    const categories = props.userCategory ?? [];
    const categoriesById = {};
    for (const category of categories) {
        categoriesById[category.id] = category;
    }

	const handleMouseOver = () => {
		setIsHover(true);  // Set hover state to true when mouse is over the component
	};
	
	const handleMouseOut = () => {
		setIsHover(false);  // Set hover state to false when mouse leaves the component
	};

    const onClick = () => {
        if (isTouchScreen){
            setIsHover(!isHover)
        }
    }

	const handleDuplicate = (productId) => {
		setIsHover(false);
		setDuplicatingId(true);
		Swal.fire({
			title: t("freePosters.freePosterSure"),
			text: t("freePosters.freePosterSureText"),
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: t("freePosters.freePosterDuplicateConfirm"),
			cancelButtonText: t("freePosters.freePosterDuplicateCancel"),
			showLoaderOnConfirm: true,
			returnFocus: false,
			preConfirm: () => {
				return duplicateProductById(productId)
					.then(response => {
						// Handle the response from your duplicateProductById function
						// You might want to check if the response indicates success and then return something meaningful or throw an error
						return response; // This value will be passed to the next then() block
					})
					.catch(error => {
						Swal.showValidationMessage(
							`Request failed: ${error}` // Show error message within the Swal
						);
					});
			}
		}).then((result) => {
			if (result.isConfirmed && result.value) {
				// The promise from preConfirm resolved successfully and the Swal was confirmed
				setIsDuplicating(false);
				props.isDuplicate(true);
				Swal.fire({
					title: t("postersPage.duplicated"),
					text: t("postersPage.productDuplicatedMsg"),
					icon: "success",
					returnFocus: false
				});
			}
		});
	};

    const handleSelectChange = (e) => {
        e.preventDefault();
    };

    const handleSubmitSaveDoc = async (e, productId) => {
        e.preventDefault();

        const selectedCategory = e.target.elements.category.value;
        setIsAssigning(true);
        const assignProdResponse = await assignProductToCategory(
            productId,
            selectedCategory
        );
        setIsAssigning(false);

        const category = categoriesById[selectedCategory];
        let successMessage = t('postersPage.alerts.poster_folder_remove');
        if (category) {
            successMessage = t('postersPage.alerts.poster_folder_change_success') +  ` ${category?.name}`;
        }

        if (assignProdResponse.status !== 200) {
            triggerToast(null, {
                "type": "error",
                "title": t('postersPage.alerts.poster_folder_change_error'),
                "closeButton" : true
            });
        } else {
            triggerToast(null, {
                "type": "success",
                "title": successMessage,
                "closeButton" : true
            });
        }
        props.getCategoryAgain(true);
    };

    function getLabelByFormatId(formatId) {
        const formatSelectDataString = localStorage.getItem("formatSelectData");
        try {
            const formatSelectData = JSON.parse(formatSelectDataString);
            const selectedFormat = formatSelectData?.find(
                (item) => item.value === formatId
            );
    
            return selectedFormat ? selectedFormat.label : "";
        } catch (error) {
            return "";
        }
    }

    const handleMainPosterCardDelete = (id) => {
        // setOnDelete(true)
        setIsHover(false);
        props.deleteProduct(id);
    }

    const closeModalAction = (data) => {
        setCloseModalFlag(closeModalFlag + 1);
    };

    return (
        <Col key={props.data.id} className={`list-poster-item ${onDelete ? 'list-poster-item-deleted': ''}`}>
            <Card
                className={`list-poster-card border-0 ${isHover ? ' shadow-lg onhover' : 'shadow'} position-relative d-flex justify-content-center align-items-center mb-0 rounded-1`}
                id={`product-card-${props.data.id}`}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                onClick={onClick}
            >
                <div className="position-absolute">
                    {isDuplicating && duplicatingId === props.data.id && (
                        <Alert variant="primary" >
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            {t("postersPage.duplicating")}
                        </Alert>
                    )}
                </div>

                <div className="list-poster-item-badge-container">
                    <OverlayTrigger overlay={<Tooltip >Category</Tooltip>}>
                        <Badge bg="success" className='list-poster-item-badge list-poster-item-category-badge shadow'>{props.data?.userProductCategory[0]?.name}</Badge>
                    </OverlayTrigger>

                    {
                        isDebug && (
                            <>
                                <OverlayTrigger overlay={<Tooltip >Created At</Tooltip>}>
                                    <Badge bg="success" className='list-poster-item-createdAt-badge shadow'>{props.data?.createdAt}</Badge>
                                </OverlayTrigger>
                                <OverlayTrigger overlay={<Tooltip >Updated At</Tooltip>}>
                                    <Badge bg="success" className='list-poster-item-updatedAt-badge shadow'>{props.data?.updatedAt}</Badge>
                                </OverlayTrigger>
                            </>
                        )
                    }
                </div>
                <div className="list-poster-item-img-wrapper">
                    <Card.Img 
                        variant="top" 
                        className="list-poster-item-img rounded-2" 
                        src={props.data.defaultDisplayImage}
                        alt={props.data.name}
                    />
                </div>
                <div className={`list-poster-on-hover-items-container px-4 py-3`}>
                    <div className="list-poster-on-hover-items-wrapper">
                        <div className="header-items">
                            <div className="list-poster-item-name text-white fw-bold">
                                {props.data.name}
                            </div>
                            <div className="list-poster-item-format text-white d-flex">
                                <span className="me-1">{getLabelByFormatId(props.data.customPosterData?.formatId)}</span>
                                <span className="list-poster-item-orientation">{t(`orientationTitle.${props.data.customPosterData?.orientation}`)}</span>
                            </div>
                        </div>
                        <div className="list-poster-item-action-buttons">
                            <Button onClick={(e) => props.handleSubmit(e, props.data)}>
                                <Cart/>
                            </Button>
                            <NwCustomModal
                                closeModal={closeModalFlag}
                                customButton={
                                    <Button>
                                        <Folder
                                            title={t("pageTitle.addToDoc")}
                                        />
                                    </Button>
                                }
                                customHeaderContent={
                                    <div>
                                        <h2 style={{ color: "#0D6DFD", textAlign: "center" }}>
                                            {t("pageTitle.addToDoc")}
                                        </h2>
                                    </div>
                                }
                            >
                                <div>
                                    <br></br>
                                    <p style={{ textAlign: "left" }}>
                                        {t("pageTitle.modalTitleDocument")}
                                    </p>
                                    <Form onSubmit={(e) => handleSubmitSaveDoc(e, props.data.id)}>
                                        <select
                                            name="category"
                                            id="prod-category"
                                            className="form-select modal-select"
                                            onChange={(e) => {
                                                handleSelectChange(e);
                                                setSelectedCategoryId(e.target.value);
                                            }}
                                            style={{ marginTop: "8px" }}
                                            value={selectedCategoryId}
                                        >
                                            <option value="0">{t("pageTitle.selectDocument")}</option>
                                            {props.userCategory?.map((option) => (
                                                <option key={option.id} value={option.id}>
                                                    {option.name}
                                                </option>
                                            ))}
                                        </select>
                                        <br />
                                        <div
                                            className="buttons-custom"
                                            style={{ backgroundColor: "#e6f0ff", height: "65px" }}
                                        >
                                        <div
                                            className="buttons-actions"
                                            style={{ float: "right", marginRight: "5px" }}
                                        >
                                            <Button
                                                variant="outline-primary"
                                                id="close-button"
                                                style={{ marginRight: "10px" }}
                                                onClick={closeModalAction}
                                            >
                                                {t("pageTitle.cancel")}
                                            </Button>

                                            <Button style={{}} type="submit">
                                                {t("pageTitle.move")}
                                                {isAssigning ? (
                                                    <span
                                                        style={{ marginLeft: "10px" }}
                                                        className="spinner-border spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    ></span>
                                                ) : null}
                                            </Button>
                                        </div>
                                        </div>
                                    </Form>
                                </div>
                            </NwCustomModal>
                            <NwCustomModal
                                onShow={() => setDisplayPreview(true)}
                                onClose={() => setDisplayPreview(false)}
                                customButton={
                                    <Button>
                                        <Eye/>
                                    </Button>
                                }
                                modalProps={{
									className: 'bg-transparent',
									style: {
										backdropFilter: 'blur(15px)'
									},
                                    body: {
                                        className: 'd-flex bg-transparent'
                                    }
                                }}
                            >
                                <div className="d-flex">
                                    {<NwImage imgClassName="w-100 h-100 object-fit-contain " src={props.data?.mainImageUrl ?? ""} />}
                                </div>
                            </NwCustomModal>
                            <Button>
                                <PencilSquare
                                    title={t("pageTitle.mod")}
                                    onClick={() => {
										props.redirectToEditPage(
											props.data?.customPosterData?.endDate,
											props.data?.customPosterData?.operationId || 0,
											props.data?.customPosterData?.startDate,
											props.data.id,
											selectedFormatValues,
											props.selectedOrientationValues,
											props.data?.customPosterData.showProductImage
										);
                                    }}
                                />
                            </Button>
                            <Button
								onClick={() => {
									handleDuplicate(props.data.id);
								}}
							>
                                <Files
                                    title={t("pageTitle.duplicate")}
                                />
                            </Button>
                            <Button
								onClick={() => {
									handleMainPosterCardDelete(props.data.id);
								}}
							>
                                <Trash
                                    title={t("pageTitle.delete")}
                                />
                            </Button>
                        </div>
                    </div>
                </div>
            </Card>
        </Col>
    );
}
