import useSocialMediaContext from "../../hooks/useSocialMediaContext"
import useDesignEditorContext from '../../hooks/useDesignEditorContext';
import { useEditor } from '@layerhub-io/react';
import { nanoid } from 'nanoid';
import { getDefaultTemplate } from '../../../../constants/design-editor';
import useAppContext from '../../hooks/useAppContext';
import { PanelType } from '../../../../constants/app-options';
import SocialMediaPlatform from "./SocialMediaPlatform";
import { Accordion } from "react-bootstrap";
import { useState } from 'react'
import './model-selection.css';

export default function ModelSelection(props: any) {
    const editor = useEditor();
    const { templateGroupModelsGroupedByPlatform } = useSocialMediaContext();
    const { scenes, setScenes, setCurrentScene } = useDesignEditorContext();
    const { setActivePanel, activePanelAdditionalData }  = useAppContext();

    const [ accordionActiveKeys, setAccordionActiveKeys ] = useState<string | string[] | null>([]);

    const contentTitle = activePanelAdditionalData?.panelContentTitle;
    const contentDescription = activePanelAdditionalData?.panelContentDescription;

    const handleModelSelection = async (model : any, platform: any) => {
        console.log("Model Name: ", model);
        let sceneJson = model?.sceneJson;
        if (!sceneJson) return;
        
        const updatedTemplate = editor.scene.exportToJSON()
        const updatedPreview = await editor.renderer.render(updatedTemplate)

        const updatedPages = scenes.map((p : any) => {
            if (p.id === updatedTemplate.id) {
                return { ...p, ...updatedTemplate, preview: updatedPreview }
            }
            return p
        })

        const sceneLayers = sceneJson?.scenes[0]?.layers;
        sceneJson = {
            ...sceneJson, 
            'layers' : sceneLayers, 
            'postType' : model?.postType, 
            'modelName': model?.name, 
            'platform': platform
        };
        const newTemplate = getDefaultTemplate(sceneJson.frame)
        const newPreview = await editor.renderer.render(newTemplate)
        const newPage = { ...sceneJson, id: nanoid(), preview: newPreview } as any
        const newPages = [...updatedPages, newPage] as any[]
        setScenes(newPages)
        setCurrentScene(newPage)
        setActivePanel(PanelType.LAYERS);
    }

    return (
        <div className="social-media-main-container d-flex flex-column align-items-center pt-3 pb-3">
            <div className="my-3">{contentTitle}</div>
            <Accordion 
                className="px-4 border-0 social-media-accordion shadow py-3"
                onSelect={(e: any) => setAccordionActiveKeys(e)}
                alwaysOpen
                defaultActiveKey={['0', '1']} 
            >
                <div className="mt-3 mb-4 ms-2">{contentDescription}</div>
                {
                    Object.values(templateGroupModelsGroupedByPlatform).map((platform: any, index: any) => (
                        <Accordion.Item 
                            className='rounded-0 border-0 social-media-accordion-item my-4' 
                            key={index} 
                            eventKey={index.toString()}
                        >
                            <SocialMediaPlatform key={index} platform={platform} isActive={accordionActiveKeys?.includes(index.toString())} title={platform?.title} handleModelSelection={handleModelSelection} models={platform?.models}/>
                        </Accordion.Item> 
                    ))
                }
            </Accordion>
        </div>
    )
}