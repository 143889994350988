import React, { useState, useContext, useEffect, useRef } from "react";
import MainLayoutContext from "../../contexts/MainLayoutContext";
import { getRouteDataFromRouteId } from "../../helpers/helpers";
import { Link, useNavigate } from "react-router-dom";
import { NwLockIcon } from "../../components/_utilityComponents/NwIcons/NwIcons";
import Chart from "chart.js/auto";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Image from "react-bootstrap/Image";
import { useTranslation } from "react-i18next";
import { getDashboardStats } from "../../services/dashboard";
import Table from "react-bootstrap/Table";
import { getAuthTokenWithRefresh, getBackendApiUrl, getEasyPlvApiToken } from "../../config";
import ListGroup from 'react-bootstrap/ListGroup';
import { ArrowRight } from "react-bootstrap-icons";

const DashboardMessagesList = ({}) => {
  const { i18n, t } = useTranslation();
  const chartRef = useRef(null);

  const [posts, setPosts] = useState([]);
  const [isChartloading, setIsChartLoading] = useState(false);

 
	const [pageCount, setPageCount] = useState(0);
	const [totalItems,setTotalItems] = useState(0);
	const [isloading, setisloading] = useState(true);
  const [loading, setLoading] = useState(true);
    const [postsPerPage] = useState(5);

    const apiKey = getEasyPlvApiToken();
	const { locale } = useContext(MainLayoutContext);


async function getMessages() {
  try {
    const authToken = await getAuthTokenWithRefresh();
    const response = await fetch(getBackendApiUrl("messages?viewDashboard=1"), {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "X-EasyPLV-Api-Token": apiKey,
      },
    });
    if (response.status === 401) {
      return false;
    }
    const data = await response.json();
    setPosts(data.items);
    setTotalItems(data.totalMessages);
    setPageCount(Math.ceil(data.totalMessages / 5));

    setLoading(false);

  } catch (error) {
    console.error("Error fetching data: ", error);
    // Set loading state to false in case of an error
    setLoading(false);
  }
}

const getStateText = (state) => {
    switch (state) {
      case 'new':
        return t(`dashboard.operationState.${state}`);
      case 'in_progress_validation':
        return t(`dashboard.operationState.${state}`);
      case 'finish':
        return t(`dashboard.operationState.${state}`);
      default:
        return state;
    }
  };

    
    useEffect(() => {

      getMessages();
    
      },[]);


  return (
    <div className="rounded-4 shadow-lg h-100 bg-white p-3 d-flex flex-column">
		<div
			style={{
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
			}}
		>
			<h6>
				{t("sideBarLabels.messages")}
				{loading ? (
					<span
					style={{ marginLeft: "10px" }}
					className="spinner-border spinner-border-sm"
					role="status"
					aria-hidden="true"
					></span>
				) : null}
			</h6>
			<Link
					to={'/messages'}
					className="btn btn-primary nw-responsive-font-size rounded-pill d-flex align-items-center fw-semibold"
					style={{
						width: 'fit-content',
					}}
				>
					{t("dashboard.messages.viewAll")}<ArrowRight className="ms-1"/>
			</Link>
		</div>
      
		<ListGroup className="d-flex flex-column flex-grow-1 overflow-scroll" variant="flush">
			{posts?.map((post, index) => (
				<ListGroup.Item key={index} className="d-flex align-items-center">
					<Link to={`view/message/${post.id}`} className="w-100 d-flex align-items-center text-decoration-none text-dark">
						<Image
							className="d-none"
							src="https://bootdey.com/img/Content/avatar/avatar6.png"
							roundedCircle
							style={{
							width: '54px',
							height: '54px',
							borderRadius: '50%',
							marginRight: '15px'
							}}
						/>
						<div style={{ flex: 1 }}>
							<div className="d-flex justify-content-between align-items-center">
								<div>{post.subject}</div>
								<div style={{ float: 'right' }}>{post.createdAtHumanReadable}</div>
							</div>
							<div>{post.body}</div>
						</div>
					</Link>
				</ListGroup.Item>
			))}
		</ListGroup>
  
    </div>
  );
};

export default DashboardMessagesList;
