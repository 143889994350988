import React, { useState, useEffect, useTransition } from 'react';
import { Button } from 'react-bootstrap';
import ReactCalendar from 'react-calendar'
import { Calendar4Event } from 'react-bootstrap-icons';
import { useTranslation } from 'react-i18next';
import './NwCustomDatePicker.css';

const NwCustomDatePicker = ({initialDate, onDateChange, name = '', disabled = false, debug}) => {
    const [dateValue, setDateValue] = useState(initialDate);
    const [showCalender, setShowCalendar] = useState(false);
    const [hiddenInputDateValue, setHiddenInputDateValue] = useState("");
    const { t } = useTranslation();

    useEffect(() => {
        setHiddenInputDateValue((dateValue));

        if (onDateChange)
        {
            onDateChange(dateValue, name);
        }
    }, [dateValue])

    useEffect(() => {
        if (initialDate){
            setDateValue(initialDate);
        }
    }, [initialDate])

    const toggleShowCalender = () => {
        setShowCalendar(prevShowCalendar => !prevShowCalendar);
    }
    
    // Function to format the date
    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    return (
        <div id={name} className={`nw-custom-datepicker w-100 position-relative ${debug ? 'border border-black border-2' : ''}`}>
            <div className={`nw-custom-datepicker-input-wrapper bg-white overflow-hidden rounded-1 w-100 d-flex ${debug ? 'border border-2' : ''}`}>
                <div className={`nw-custom-datepicker-input-container w-75 d-flex align-items-center px-3 py-3 ${debug ? 'border border-primary border-2' : ''}`}>
                    <div className={`nw-custom-datepicker-input-wrapper w-100 h-100 border-start border-2 nw-border-accent-1 px-2 ${debug ? 'border border-success border-2' : ''}`}>
                        <div className={`nw-custom-datepicker-input-value w-100 h-100 d-flex align-items-center ps-2 text-black ${debug ? 'border border-danger border-2' : ''}`}>
                            {dateValue ? (
                                    <span className="date-value-container">{formatDate(dateValue)}</span>
                                ):(
                                    <span className="date-placeholder-container text-secondary">
                                        {t('nwDatePicker.placeholder')}
                                    </span>
                                )
                            }
                                
                        </div>
                    </div>
                </div>
                <div className={`nw-custom-datepicker-btn-container w-25 ${debug ? 'border border-warning border-2' : ''}`}>
                    <div className={`nw-custom-datepicker-open-calendar-btn-wrapper w-100 h-100 d-flex justify-content-end align-items-center ${debug ? 'border border-info border-2' : ''}`}>
                        <Button className={`nw-custom-datepicker-btn nw-bg-accent-1 border-0 h-100 py-3 px-4 d-flex align-items-center rounded-0`} disabled={disabled} onClick={toggleShowCalender}>
                            <Calendar4Event size={20}/>
                        </Button>
                    </div>
                </div>
            </div>
            <input type="hidden" name={name} value={hiddenInputDateValue}></input>
            <ReactCalendar className={`nw-custom-datepicker-calendar position-absolute mt-3 rounded-3 overflow-hidden shadow border-0 w-100 ${showCalender ? '' : 'd-none'}`} onChange={setDateValue} value={dateValue} />
        </div> 
    );
}

export default NwCustomDatePicker;