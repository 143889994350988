import { createFormFromJson, generateConfigFormValuesJson } from "./front_form_creation";
import { getBackendApiUrl, getEasyPlvApiToken, getAuthToken, getAuthTokenWithRefresh } from "../../config";
import { checkApiResponse, ApiResponseStatus } from "../../services/auth";

const getDiscountByIdApiUrl = (discountId) => {
    return getBackendApiUrl(`poster/discountData/${discountId}`);
}

const getTemplateExtensionByIdApiUrl = (templateExtensionId) => {
    return getBackendApiUrl(`/poster/templateExtensionData/${templateExtensionId}`);
}

export const getCharteGraphiqueAndMechaniquePrixByCompanyId = async (companyId) => {
    let companyMetaData = {
        priceMechanics: null,
        templateExtensions: null,
        chartesGraphiques: null,
    }

    try {
        const response = await fetch(
            getBackendApiUrl(`poster/getCharteGraphiqueAndMechaniquePrixByCompanyId/${companyId}`),
            {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
                  "X-EasyPLV-Api-Token": getEasyPlvApiToken(),
                  "Content-Type": "application/json",
                },
            }
        );
        
        if (checkApiResponse(response) == ApiResponseStatus.OK) {
            const data = await response.json();
            companyMetaData.priceMechanics = data.mechaniquePrix;
            companyMetaData.templateExtensions = data.templateExtension;
            companyMetaData.chartesGraphiques = data.charteGraphic;
        }
            
    } catch (error) {
        
    }

    return companyMetaData;
}

export const getAndCreateDiscountFormFields = async (discountId, originalDiscountData, setDiscountFormFieldContainerItems) => {
    let chosenDiscountId = discountId;

    let originalDiscountId = originalDiscountData.discountId;
    let originalDiscountValuesJson = originalDiscountData.formValues;

    // if(document.getElementById('__js_original_discount_id')){
    //     originalDiscountId = document.getElementById('__js_original_discount_id').value;
    // }
    // if(document.getElementById('__js_original_discount_values')){
    //     originalDiscountValuesJson = document.getElementById('__js_original_discount_values').value;
    // }

    try {
        let discountJson = await fetchDiscountJsonById(chosenDiscountId);
        await createFormFromJson(discountJson, document.getElementById('form_field_container'), setDiscountFormFieldContainerItems);
        let generatedConfigFormValuesJson = generateConfigFormValuesJson(discountJson);
		// let configFormValuesJsonInputElement = document.getElementById('review_product_discountConfigFormValues');

        // if (originalDiscountId != chosenDiscountId){
        //     configFormValuesJsonInputElement.value = JSON.stringify(generatedConfigFormValuesJson);
        // } else {
        //     configFormValuesJsonInputElement.value = originalDiscountValuesJson;
        // }
        
        // let submitBtnElement = document.getElementById('review_product_save');
        // submitBtnElement.type = 'button';
        // submitBtnElement.addEventListener('n9click', ()=>{handleFormSubmission(discountJson, configFormValuesJsonInputElement)})
        
    } catch (e) {
        console.log(e);
    }

    // document.querySelectorAll('.__js_tom_select').forEach((el)=>{
    //     let settings = {
    //         plugins: [
    //             'dropdown_input',
    //             'remove_button'
    //         ],
    //     };
    //     new TomSelect(el,settings);
    // });
}
export const getAndCreateTemplateExtensionFormFields = async (templateExtensionId) => {
    let chosenTemplateExtensionId = templateExtensionId;

    let originalTemplateExtensionId = null;
    let originaltemplateExtensionValuesJson = null;

    if(document.getElementById('__js_original_template_extension_id')){
        originalTemplateExtensionId = document.getElementById('__js_original_template_extension_id').value;
    }
    if(document.getElementById('__js_original_template_extension_values')){
        originaltemplateExtensionValuesJson = document.getElementById('__js_original_template_extension_values').value;
    }

    try {
        let templateExtensionJson = await fetchTemplateExtensionJsonById(chosenTemplateExtensionId);
        await createFormFromJson(templateExtensionJson, document.getElementById('form_field_template_extension_container'), 'template-extension-child-elem');
        console.log("Template Extension: ");
        console.log(templateExtensionJson);
        let generatedConfigFormValuesJson =  generateConfigFormValuesJson(templateExtensionJson);
		let configFormValuesJsonInputElement = document.getElementById('review_product_templateExtensionConfigFormValues');

        if (originalTemplateExtensionId != chosenTemplateExtensionId){
            configFormValuesJsonInputElement.value = JSON.stringify(generatedConfigFormValuesJson);
        } else {
            configFormValuesJsonInputElement.value = originaltemplateExtensionValuesJson;
        }
        
        let submitBtnElement = document.getElementById('review_product_save');
        submitBtnElement.type = 'button';
        submitBtnElement.addEventListener('click', ()=>{handleFormSubmission(templateExtensionJson, configFormValuesJsonInputElement)})
        
    } catch (e) {
        console.log(e);
    }
}

export const handleFormSubmission = (entityJson, entityConfigFormValuesJsonInputElement) => {
    try {
        let generatedConfigFormValuesJson = generateConfigFormValuesJson(entityJson);
        let configFormValuesJsonInputElement = entityConfigFormValuesJsonInputElement;
        configFormValuesJsonInputElement.value = JSON.stringify(generatedConfigFormValuesJson);
    } catch (e) {console.log(e)}

    let reviewProductForm = document.getElementsByName('review_product')[0];
    // let nameInput = document.getElementById('discount_operation_template_group_name');
    // if (nameInput.value === '') 
    // {
    //     discountOperationTemplateGroupForm.reportValidity();
    //     return false;
    // }
    reviewProductForm.submit();
}

export const fetchDiscountJsonById = async (discountId) => {

    if (discountId){
        const discountPromise = await fetch(getDiscountByIdApiUrl(discountId), {
            "method" : "GET",
            "headers" : {
                "Content-Type" : "application/json",
                Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
                "X-EasyPLV-Api-Token": getEasyPlvApiToken(),
            }
        })

        const discountJson = await discountPromise.json();
        return discountJson;
    }

    return null;
}

export const fetchTemplateExtensionJsonById = async (templateExtensionId) => {

    if (templateExtensionId)
    {
        const templateExtensionPromise = await fetch(getTemplateExtensionByIdApiUrl(templateExtensionId), {
            "method" : "GET",
            "headers" : {
                "Content-Type" : "application/json",
                Authorization: `Bearer ${await getAuthTokenWithRefresh()}`,
                "X-EasyPLV-Api-Token": getEasyPlvApiToken(),
            }
        })

        const templateExtensionJson = await templateExtensionPromise.json();
        return templateExtensionJson;
    }

    return null;
}