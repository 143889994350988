import { useEffect } from "react";
import { Form } from "react-bootstrap";

function HiddenType(props) {
    const formFieldJson = props.formFieldJson;
	const entityFormValuesJson = props.entityFormValuesJson;
	const updateEntityFormValues = props.updateEntityFormValues;
	const configValues = formFieldJson.configValues;
    const defaultValue = configValues?.values?.default ?? "";
    const metadata = configValues?.values?.metadata;
    const hiddenValueKey = `${configValues.frontUniqueKeys.hidden1}_${formFieldJson.id}`;
    const hiddenValueJson = `${hiddenValueKey}` in entityFormValuesJson
    ? entityFormValuesJson[`${hiddenValueKey}`]
    : {
        name: formFieldJson.name,
        value : defaultValue,
        type : 'hidden',
        metadata: metadata,
    }

    useEffect(() => {
        updateHiddenValue(hiddenValueJson.value);
    }, []);

    const updateHiddenValue = (newValue) => {
		updateEntityFormValues(prevFieldValuesJson => {
            const newFieldValuesJson = {...prevFieldValuesJson};
            hiddenValueJson.value = newValue;
            newFieldValuesJson[`${hiddenValueKey}`] = hiddenValueJson;
            return newFieldValuesJson;
        });
    }
    return (
        <>
        	<Form.Control onChange={(e) => updateHiddenValue(e.target.value)} value={hiddenValueJson.value} id={props.id} type="hidden" name={props.formFieldJson.name + "_" + props.formFieldJson.type}/>
        </>
    )
}

export default HiddenType;