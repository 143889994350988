import { createContext, useEffect, useState } from "react";
import useDesignEditorContext from '../hooks/useDesignEditorContext';
import { 
    getSocialMediaTemplateGroups,
    getTemplateGroupModelsByTemplateGroupId, 
    getSocialMediaFreePackById, 
    getSocialMediaThemeTypes,
    getUserSocialMediaCampaignsByThemeType,
    getSocialMediaTemplateGroupBodyData,
    getSocialMediaPackGroupsByUserStore,
    getSocialMediaTemplateGroupModelById,
    getSocialMediaTemplateGroupById
} from "../../../services/social_media";
import { loadVideoEditorAssets } from '../../../utils/video';
import { loadTemplateFonts } from '../../../utils/fonts';
import useEditorUseContext from '../hooks/useEditorUseContext';
import useAppContext from "../hooks/useAppContext";
import { PanelType } from "../../../constants/app-options";
import { SOCIAL_MEDIA_ITEMS } from "../../../constants/app-options";
import { useSearchParams } from "react-router-dom";

import { useEditor } from '@layerhub-io/react';
import { getDefaultTemplate } from '../../../constants/design-editor';
import { nanoid } from 'nanoid';
import { getRouteDataFromRouteId } from "../../../helpers/helpers";
import { useMainLayoutContext } from "../../../hooks/useMainLayoutContext";


export const SocialMediaContext = createContext<any>(null);

export const SocialMediaProvider = (props : any) => {
    const editor = useEditor();

    const children = props.children;
    const mode = props.mode;
    const id = props.id;
    const entity = props.entity // entity can be either a pack or a single free poster out of a pack
    const {use} = useEditorUseContext();
    const { setFocusBlockData } = useMainLayoutContext();
    const { setActivePanel, setActivePanelAdditionalData } = useAppContext();
    const [ socialMediaThemeTypes, setSocialMediaThemeTypes ] = useState([]);
    const [ socialMediaCampaigns, setSocialMediaCampaigns] = useState([]);
    const [ socialMediaTemplateGroups, setSocialMediaTemplateGroups ] = useState([]);
    const [ templateGroupModelsGroupedByPlatform, setTemplateGroupModelsGroupedByPlatform ] = useState([]);
    const [ currentSelectedTemplateGroup, setCurrentSelectedTemplateGroup ] = useState<any>(null);
    const [ currentSelectedThemeType, setCurrentSelectedThemeType ] = useState<any>(null);
    const { scenes, setScenes, setCurrentDesign, setCurrentScene } = useDesignEditorContext();
    const [ existingCategories, setExistingCategories ] = useState([]);
    const [ currentSelectedSocialMediaCampaign, setCurrentSelectedSocialMediaCampaign ] = useState<any>(null);
    const [ templateGroupBodyData, setTemplateGroupBodyData ] = useState<any>(null)
    const [ searchParams, setSearchParams ] = useSearchParams();
    const [ packGroups, setPackGroups ] = useState([]);
    const focusSceneId = searchParams.get('focus_scene_id');
    const templateGroupModelId = searchParams.get('template_group_model_id');
    const templateGroupQueryParamId = searchParams.get('template_group');
    

    // get all the social media campaigns for selection
    useEffect(() => {
        if (use !== "social_media") return;
        setActivePanel(PanelType.TEMPLATE_GROUP_MODELS);
        setActivePanelAdditionalData(SOCIAL_MEDIA_ITEMS?.[0]?.additionalData);
        getSocialMediaThemeTypes().then((socialMediaThemeTypes : any) => {
            socialMediaThemeTypes = socialMediaThemeTypes.map((type : any) => {
                return {
                    'id' : type.key,
                    'value' : type.key,
                    'label' : type.key
                }
            })

            setSocialMediaThemeTypes(socialMediaThemeTypes);
        })

        getSocialMediaPackGroupsByUserStore().then(packGroups => {
            setPackGroups(packGroups);
        }) 
    }, [use, setActivePanel, setActivePanelAdditionalData])
    
    useEffect(() => {
        if (use !== "social_media") return;

        const currentRoute = getRouteDataFromRouteId('social_media');
        setFocusBlockData({
            blockIdentifier: currentRoute?.id,
            title: currentRoute?.title,
            icon: currentRoute?.icon,
            menuItems: currentRoute?.subMenu
        })

        if (mode === "edit" && id !== 0) {
            // get all the data from the pack or a single free poster depending on the entity
            if (entity === "pack") {
                setActivePanel(PanelType.LAYERS);
                const socialMediaFreePackId = id;
                getSocialMediaFreePackById(socialMediaFreePackId).then((socialMediaFreePackData : any) => {
                    if (!socialMediaFreePackData) return;
                    const loadGraphicTemplate = async (payload: any) => {
                        const scenes = []
                        const { scenes: scns, ...design } = payload
                        let focusScene = null;
                        for (const scn of scns) {
                            const loadedScene = await loadVideoEditorAssets(scn)
                            await loadTemplateFonts(loadedScene)
                            // const preview = (await editor.renderer.render(loadedScene)) as string
                            scenes.push({ ...loadedScene })
                            if (scn.id === focusSceneId) focusScene = scn
                        }
                    
                        return { scenes, design, focusScene }
                    }
                    const handleImportTemplate = async (data: any) => {
                        let template : any = {};
                        if (data.type === "GRAPHIC") {
                            template = await loadGraphicTemplate(data)
                        }
                        //   @ts-ignore
                        setScenes(template.scenes)
                        //   @ts-ignore
                        setCurrentDesign(template.design)
                        if (template.focusScene) {
                            setCurrentScene(template.focusScene);
                        }
                        else if (template.scenes.length > 0) {
                            setCurrentScene(template.scenes[0]);
                        }
                    }
            
                    handleImportTemplate(socialMediaFreePackData.scenesJson);
                })
            }
        } else if (mode === "new") {
            if (templateGroupModelId) {
                const handleModelSelection = async (model : any) => {
                    let sceneJson = model?.sceneJson;
                    if (!sceneJson) return;
                    
                    const updatedTemplate = editor.scene.exportToJSON()
                    const updatedPreview = await editor.renderer.render(updatedTemplate)
            
                    const updatedPages = scenes.map((p : any) => {
                        if (p.id === updatedTemplate.id) {
                            return { ...p, ...updatedTemplate, preview: updatedPreview }
                        }
                        return p
                    })
            
                    const sceneLayers = sceneJson?.scenes[0]?.layers;
                    sceneJson = {...sceneJson, 'layers' : sceneLayers, 'postType' : model?.postType, 'modelName': model?.name};
                    const newTemplate = getDefaultTemplate(sceneJson.frame)
                    const newPreview = await editor.renderer.render(newTemplate)
                    const newPage = { ...sceneJson, id: nanoid(), preview: newPreview } as any
                    const newPages = [...updatedPages, newPage] as any[]
                    setScenes(newPages)
                    setCurrentScene(newPage)
                    setActivePanel(PanelType.LAYERS);
                }

                getSocialMediaTemplateGroupModelById(templateGroupModelId).then(model => handleModelSelection(model));
                
            }

            if (templateGroupQueryParamId){
                getSocialMediaTemplateGroupById(templateGroupQueryParamId).then(templateGroup => {
                    setCurrentSelectedThemeType({
                        id: templateGroup?.socialMediaCampaign?.themeType,
                        name: templateGroup?.socialMediaCampaign?.themeType,
                        value: templateGroup?.socialMediaCampaign?.themeType,
                        label: templateGroup?.socialMediaCampaign?.themeType,
                    })

                    setCurrentSelectedSocialMediaCampaign({
                        id: templateGroup?.socialMediaCampaign?.id,
                        name: templateGroup?.socialMediaCampaign?.name,
                        label: templateGroup?.socialMediaCampaign?.name
                    })

                    setCurrentSelectedTemplateGroup({
                        id: templateGroup?.id,
                        name: templateGroup?.name,
                        label: templateGroup?.name,
                        thumbailPreviewUrl: ""
                    })
                })
            }
        }
    }, [id, mode, entity, setCurrentDesign, setScenes, setCurrentScene, use, templateGroupModelId, templateGroupQueryParamId]);
    

    useEffect(() => {
        getTemplateGroupModelsByTemplateGroupId(currentSelectedTemplateGroup?.id).then(templateGroupModels => {
            setTemplateGroupModelsGroupedByPlatform(templateGroupModels);
        });
    }, [currentSelectedTemplateGroup]);

    useEffect(() => {
        getUserSocialMediaCampaignsByThemeType(currentSelectedThemeType?.id).then((socialMediaCampaigns : any) => {
            socialMediaCampaigns = socialMediaCampaigns.map((campaign : any) => {
                return {
                    ...campaign,
                    'label' : campaign.name
                }
            })
            setSocialMediaCampaigns(socialMediaCampaigns)
        })

        // get the relevant social media campaigns
    }, [currentSelectedThemeType])

    useEffect(() => {
        if (currentSelectedSocialMediaCampaign) {
            const newTemplateGroupData = getSocialMediaTemplateGroupBodyData(currentSelectedSocialMediaCampaign?.id);
            setTemplateGroupBodyData(newTemplateGroupData);
        }
    }, [currentSelectedSocialMediaCampaign]);

    useEffect(() => {
        if (templateGroupBodyData) {
            getSocialMediaTemplateGroups(templateGroupBodyData).then((socialMediaTemplateGroups : any) => {
                socialMediaTemplateGroups = socialMediaTemplateGroups?.items.map((templateGroup : any) => {
                    return {
                        ...templateGroup,
                        'label' : templateGroup?.name,
                    }
                })
                setSocialMediaTemplateGroups(socialMediaTemplateGroups);
            });
        }
    }, [templateGroupBodyData])


    const context = {
        entity,
        socialMediaThemeTypes,
        setSocialMediaThemeTypes,
        socialMediaCampaigns,
        setSocialMediaCampaigns,
        socialMediaTemplateGroups,
        setSocialMediaTemplateGroups,
        templateGroupBodyData,
        setTemplateGroupBodyData,
        templateGroupModelsGroupedByPlatform,
        setTemplateGroupModelsGroupedByPlatform,
        currentSelectedTemplateGroup,
        setCurrentSelectedTemplateGroup,
        existingCategories,
        currentSelectedThemeType,
        setCurrentSelectedThemeType,
        currentSelectedSocialMediaCampaign,
        setCurrentSelectedSocialMediaCampaign,
        packGroups,
        setPackGroups
    }

    return <SocialMediaContext.Provider value={context}>{children}</SocialMediaContext.Provider>
}