import React from "react"
import useAppContext from "../../hooks/useAppContext"
import panelItems from "./panelItems"
import useIsSidebarOpen from "../../hooks/useIsSidebarOpen"
import useSetIsSidebarOpen from "../../hooks/useSetIsSidebarOpen"
import { Block } from "baseui/block"
import useEditorUseContext from '../../hooks/useEditorUseContext';
import { Button } from "react-bootstrap"
import { ChevronDoubleLeft } from "react-bootstrap-icons"
import { TAB_EDITOR_CONTENT } from '../../../../constants/app-options';

interface State {
  panel: string
}

const PanelCloseButton = ({ isSidebarOpen} : { isSidebarOpen: any}) => {
  const setIsSidebarOpen = useSetIsSidebarOpen();

  return (
    <Button
      onClick={() => setIsSidebarOpen(prev => !prev)}
      className={`p-0 rounded-0 border-0 d-flex justify-content-center align-items-center position-absolute bottom-50 ${ isSidebarOpen ? '' : 'shadow' }`}
      style={{
        backgroundColor: "#3D465C",
        zIndex: 999,
        right: '-2.5vw',
        width: '2.5vw',
        aspectRatio: 1
      }}
    >
      <ChevronDoubleLeft
        size={20}
        style={{
          rotate: isSidebarOpen ? '0deg' : '180deg'
        }}
      />
    </Button>
  )
}

const PanelsList = () => {
  const [state, setState] = React.useState<State>({ panel: "Text" })
  const isSidebarOpen = useIsSidebarOpen();
  const { activePanel, activeSubMenu } = useAppContext();
  const {setCurrentEditorContent} = useEditorUseContext();

  React.useEffect(() => {
    setState({ panel: activePanel })
    const panel: string  = activePanel;
    const tabs = Object.keys(TAB_EDITOR_CONTENT);

    if (tabs.includes(panel)) setCurrentEditorContent({"content": TAB_EDITOR_CONTENT[panel], "hideCanvas" : true});
    else setCurrentEditorContent({"hideCanvas" : false, "content": "canvas"})
  }, [activePanel])

  React.useEffect(() => {
    if (activeSubMenu) {
      setState({ panel: activeSubMenu })
    } else {
      setState({ panel: activePanel })
    }
  }, [activeSubMenu])


  // @ts-ignore
  const Component = panelItems[state.panel]

  return (
    <Block
      id="EditorPanelItem"
      className={`${isSidebarOpen ? 'px-0' : 'px-0'} `}
      $style={{
        background: "#3C475C",
        color: "white",
        width: isSidebarOpen ? "25vw" : "0",
        flex: "none",
        borderRight: "0px solid #d7d8e3",
        display: "flex",
        transition: "ease-in-out width 250ms",
        position: 'relative'
      }}
    >
      <PanelCloseButton isSidebarOpen={isSidebarOpen} />
      <div className={`panel-item-component-wrapper w-100 overflow-hidden h-100 ${isSidebarOpen ? 'p-2 px-4' : ''}`}>
        {Component && isSidebarOpen && <Component />}
      </div>
      
    </Block>
  )
}

export default PanelsList
