import NwCustomDatePicker from "../_utilityComponents/NwFormsUi/CustomDatePicker/NwCustomDatePicker";
import { useEffect, useMemo } from "react";
import { Form } from "react-bootstrap";
function DateTimeType(props) {
    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    }
    const isDisabled = props?.isDisabled || false;
    const formFieldJson = props.formFieldJson;
	const entityFormValuesJson = props.entityFormValuesJson;
	const updateEntityFormValues = props.updateEntityFormValues;
	const configValues = formFieldJson.configValues;
	const metadata = configValues?.values?.metadata;
    const datetimeValueKey = `${configValues.frontUniqueKeys.datetime1}_${formFieldJson.id}`;
    const datetimeValueJson = `${datetimeValueKey}` in entityFormValuesJson 
	? entityFormValuesJson[`${datetimeValueKey}`]
	: {
		name: formFieldJson.name,
		type: 'string',
		value: '',
		metadata: metadata,
	}

    const initialDate = useMemo(() => { return datetimeValueJson.value !== "" ? new Date(datetimeValueJson.value) : ""}, [datetimeValueJson.value]);
    const updateDateTimeValue = (newDate) => {
        if (newDate) {
            updateEntityFormValues(prevFieldValuesJson => {
                const newFieldValuesJson = {...prevFieldValuesJson};
                datetimeValueJson.value = formatDate(newDate);
                newFieldValuesJson[`${datetimeValueKey}`] = datetimeValueJson;
                return newFieldValuesJson;
            })
        }
    }

    useEffect(() => {
        updateDateTimeValue(initialDate);
	}, []);

    return (
        <>
            <Form.Label>{formFieldJson.title}</Form.Label>
            {
                
                <NwCustomDatePicker disabled={isDisabled} label={formFieldJson.title} initialDate={initialDate} onDateChange={(newDate) => updateDateTimeValue(newDate)}/>
            }
        </>
    )
}

export default DateTimeType;