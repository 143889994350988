import  {createContext} from 'react';

/* This code snippet is creating a context in React using the `createContext` function. The
`MainLayoutContext` context is being initialized with an object that contains three properties:
`setPageTitle`, `setPageTitleAdditionalContent`, and `currentPlatform`. */
const MainLayoutContext = createContext({
    setPageTitle: () => {}, // Default empty implementation
    setPageTitleAdditionalContent: () => {},
    setShowFooter: () => {},
    currentPlatform: null,
    isTouchScreen: false,
    setFocusBlockData: () => {},
    locale: null
});

export default MainLayoutContext;