import { Container, Button, Row } from "react-bootstrap";
import { XLg } from "react-bootstrap-icons";
function ChosenImagesPreview({images, handleRemoveImage, isDisabled = false}) {
    return (
        <>
            <Container>
                <Row 
                    xs={2}
                    className="row-gap-3 column-gap-3 justify-content-between "    
                >
                    {
                        images.map((image, index) => {
                            return (
                                <div className="bg-white position-relative p-3"
                                    style={{
                                        width: '45%',
                                        aspectRatio: 1
                                    }}
                                >
                                    <Button 
                                        onClick={() => handleRemoveImage(index)}
                                        disabled = {isDisabled}
                                        variant="danger"
                                        className="rounded-circle p-1 d-flex justify-content-center align-items-center position-absolute"
                                        style={{
                                            width: '20%',
                                            aspectRatio: 1,
                                            top: '4%',
                                            right: '4%'
                                        }}
                                    >
                                        <XLg/>
                                    </Button>
                                    <img 
                                        src={image.imageMainUrl}
                                        alt='ektatek_preview'
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain'
                                        }}
                                    />
                                </div>
                            )
                        })
                    }
                </Row>
            </Container>
        </>
    )
}

export default ChosenImagesPreview;