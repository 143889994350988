import { convertCartToOrder } from "../../services/cart";
import { useState } from 'react';
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { NwLoaderSpinner } from "../_utilityComponents/NwIcons/NwIcons";

// Icons
import { ThreeDotsVertical } from "react-bootstrap-icons";

// Nw Custom Utility Components
import NwCustomDropdown from "../_utilityComponents/NwFormsUi/CustomDropdown/NwCustomDropdown";

import { useCart } from "../../contexts/CartContext";

function Actions(props) {
    const isOrderingGeneralState = props.isOrderingGeneralState
    const { allowPrint, allowPrintFromSubscription } = useCart();
    const { t } = useTranslation();
    const orderAction = props.orderAction;
    const resetCart = props.resetCart;
    const [isOrdering, setIsOrdering] = useState({'zip' : false, 'print': false, 'pdf': false});

    async function order(type) {
        isOrderingGeneralState.update(true);
        setIsOrdering(prevIsOrdering => {
            const newIsOrdering = {...prevIsOrdering};
            newIsOrdering[type] = true;
            return newIsOrdering;
        });
        await orderAction(convertCartToOrder, type);
        setIsOrdering(prevIsOrdering => {
            const newIsOrdering = {...prevIsOrdering};
            newIsOrdering[type] = true;
            return newIsOrdering;
        });
        return;
    }

	const cartMoreOptions = [
		{
			displayText: t("cartPage.cartTable.zip"),
			eventKey: 0,
			onClick: ()=>order('zip')
		},
		{
			displayText: t("cartPage.cartTable.pdf"),
			eventKey: 1,
			onClick: ()=>order('pdf'),
            isVisible: allowPrintFromSubscription
		},
		{
			displayText: t("cartPage.cartTable.resetCart"),
			eventKey: 2,
			onClick: resetCart
		}
	]

    return (
        <>
            <div className={`d-flex flex-wrap w-100 justify-content-end column-gap-2 align-items-center ${props.className}`}>
                <Link to={'/operation'}>
                    <Button className="rounded-1 px-5 py-2 text-primary border-0 nw-responsive-font-size"  style={{ minWidth: '100px', backgroundColor: '#E6F0FF' }}>
						 {t("cartPage.cartTable.continueShopping")}
                    </Button>
                </Link>
                <Button 
                    disabled={isOrderingGeneralState.value || allowPrintFromSubscription ? !allowPrint : false} 
                    variant="primary" 
                    className="rounded-1 mx-1 px-5 py-2 nw-responsive-font-size"  
                    style={{ minWidth: '100px' }} 
                    onClick={()=>order(allowPrintFromSubscription ? 'print' : 'pdf')}
                >
                    {                        
                        allowPrintFromSubscription ? (
                            isOrdering['print'] ? <NwLoaderSpinner isSpinner size={'1em'} /> :  <>{t("cartPage.cartTable.orderbuttonLabel")}</>
                        ) : (
                            isOrdering['pdf'] ? <NwLoaderSpinner isSpinner size={'1em'} /> :  <>{t("cartPage.cartTable.pdf")}</>
                        )
                    }
                </Button>
				<NwCustomDropdown
					hideDropdownIcon
					title={t("cartPage.cartTable.moreOptions")}
					dropdownItems={cartMoreOptions}
				>
					<ThreeDotsVertical size={24}/>
				</NwCustomDropdown>
			</div>
        </>
    )
}

export default Actions;