import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ChevronDown } from 'react-bootstrap-icons';
import { Trans } from 'react-i18next';
import './NwCustomDropdown.css';

/**
 * `NwCustomDropdown` component for rendering a customizable dropdown menu.
 * 
 * @param {Object[]} dropdownItems - The items to display in the dropdown menu. Each item can have the following properties:
 * @param {string} dropdownItem.displayText - The text to display for the dropdown item.
 * @param {string} [dropdownItem.eventKey] - The key that uniquely identifies the dropdown item. Used in the `onSelect` callback.
 * @param {Function} [dropdownItem.onClick] - The callback function to be called when the dropdown item is clicked.
 * @param { Boolean } [dropdownItem.isDisabled] - Whether the dropdown item should be disabled or not
 * @param {string} [dropdownItem.href] - The URL path to which the dropdown item should link to. If provided, the dropdown item will be wrapped in a `Link` component, making it a navigable link.
 * @param {function} [onSelect] - Callback function triggered on selecting a dropdown item. Receives the `eventKey` of the selected item as an argument.
 * @param {string} [className=''] - Handled the className of the nw-custom-dropdown
 * @param {Object} [currentValue] - The currently selected value in the dropdown. Should have `displayText` and `eventKey` properties.
 * @param {React.ReactNode} [children] - Custom children to render inside the dropdown toggle, used when no `currentValue` is provided.
 * @param {boolean} [hideDropdownIcon=false] - Determines whether the dropdown icon should be hidden.
 * @param {Object} [dropdownToggleProps={}] - Additional props for the dropdown toggle component.
 * @param {Object} [dropdownMenuProps={}] - Additional props for the dropdown menu component.
 * @param {Boolan} [useTranslationOnDisplayText=false] - Will use translation for all, dropdown items and current value, this will work properly only if the label is a translation key
 * @param {Object} [translationComponentsObject={}] - The object to be used in the Trans Component prop : `components`
 * @param {boolean} [debug=false] - Whether component in debug mode or not, if in debug mode, show designated console logs 
 * @example
 * <NwCustomDropdown
 *     dropdownItems={[
 *         { displayText: 'Item 1', eventKey: '1', onClick: () => console.log('Clicked Item 1') },
 *         { displayText: 'Item 2', eventKey: '2', href: '/path-to-item-2' }
 *     ]}
 *     onSelect={(eventKey) => console.log(`Selected: ${eventKey}`)}
 * />
 */
const NwCustomDropdown = ({
	onSelect = null, 
	currentValue = null, 
	children = null, 
	dropdownItems = [],
	className = '',
	hideDropdownIcon = false,
	title = null,
	dropdownToggleProps={}, 
	dropdownMenuProps={},
	useTranslationOnDisplayText = false,
	translationComponentsObject = {},
	debug = false
}) => {
    const [isMenuHidden, setIsMenuHidden] = useState(false);
	const dropdownMenuOnToggle = (nextShow, meta) => {
		setIsMenuHidden(!nextShow);
	}

	if (debug){
		console.log("Dropdown Items: ", dropdownItems);
	}

	return (
        <div className={`nw-custom-dropdown ${ className }`}>
            <Dropdown onSelect={onSelect} onToggle={dropdownMenuOnToggle}>
				<OverlayTrigger 
					placement="top"
					overlay={ 
						title ? 
						<Tooltip 
							className='nw-tooltip nw-responsive-font-size-sm'
						>
							{title}
						</Tooltip>
						: <></>
					}
				>
					<Dropdown.Toggle 
						className={`nw-custom-dropdown-toggle bg-transparent border-0 d-flex align-items-center ${dropdownToggleProps.className || ''}`}
						style={{
							...dropdownToggleProps.style
						}}
					>
						<span className='nw-custom-dropdown-toggle-text'>
							{currentValue ? 
								(useTranslationOnDisplayText ? (
									<Trans
										i18nKey={currentValue.displayText}
										components={translationComponentsObject}
									/>
								) : currentValue.displayText )
								: children
							} 
						</span>
						<ChevronDown className={hideDropdownIcon ? 'd-none' : ''} size={dropdownToggleProps?.iconSize ?? '1em'} />
					</Dropdown.Toggle>
				</OverlayTrigger>

                <Dropdown.Menu
					className={`rounded-3 border-0 shadow nw-custom-dropdown-menu py-0 ${dropdownMenuProps.className || ''}`}
					renderOnMount
					style={{
						...dropdownMenuProps.style
					}}
				>
                    {
						dropdownItems?.map((item, index) => {
							// Conditionally wrap Dropdown.Item with Link if item.href is present
							// item can have onClick, eventKey, displayText, href (if present will wrap in Link)
							const showItem = item?.isVisible ?? true;

							if (!showItem){
								return;
							}

							if (debug){
								console.log(item?.displayText);
								console.log(item?.isDisabled);
							}

							const dropdownContent = (
								<Dropdown.Item
									onClick={item?.onClick}
									disabled={item?.isDisabled}
									type='button'
									as="button"
									className='py-2 nw-custom-dropdown-item'
									key={index}
									eventKey={item?.eventKey}
									active={currentValue?.eventKey === item?.eventKey}
								>
									{
										(useTranslationOnDisplayText ? (
											<Trans
												i18nKey={item?.displayText}
												components={translationComponentsObject}
											/>
										) : item?.displayText)
									}
								</Dropdown.Item>
							);

							return item?.href ? (
								<Link disabled={item?.isDisabled} to={item.href} className="nav-link" key={index}>
									{dropdownContent}
								</Link>
							) : (
								<React.Fragment key={index}>{dropdownContent}</React.Fragment>
							);
						})
                    }
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
}

export default NwCustomDropdown;