import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MessageDetail from "../../components/Messages/MessageDetail";

function MessageDetailPage() {

    const [pagesList, setPagesList] = useState([]);
   

    return (
      
        <MessageDetail/>
    )


}

export default MessageDetailPage;