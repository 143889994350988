import { getAuthTokenWithRefresh, getBackendApiUrl, getEasyPlvApiToken } from "../config";
import { checkApiResponse } from "./auth";
import { Spinner } from "react-bootstrap";
import { CheckCircleFill, SlashCircle, XCircle } from "react-bootstrap-icons";

export const uploadCustomImages = async (chosenImages, productId, toDelete=null, type=null, onProgress, onComplete, onError) => {
    const authToken = await getAuthTokenWithRefresh();
    const apiKey = getEasyPlvApiToken();
    const data = new FormData();
    for (const chosenImage of chosenImages) {
        data.append('files[]', chosenImage);
    }
    if (toDelete !== null) {
        data.append('toDelete', JSON.stringify(toDelete));
    }
    if (productId) {
        data.append('productId', productId);
    }
    if (type) {
        data.append('type', type);
    }

    const xhr = new XMLHttpRequest();
    xhr.open('POST', getBackendApiUrl('products/customImages'), true);
  
    xhr.upload.onprogress = function(event) {
        // console.log(`Loaded: ${event.loaded}, Total: ${event.total}`);
        if (event.lengthComputable) {
            const percentage = (event.loaded / event.total) * 100;
            onProgress(percentage, chosenImages);
        }
    };
  
    xhr.onload = function() {
        if (xhr.status === 200) {
            onComplete(JSON.parse(xhr.responseText), chosenImages);
        } else {
            onError('Upload failed with status: ' + xhr.status, chosenImages);
        }
    };
  
    xhr.onerror = function() {
        onError('XMLHttpRequest failed', chosenImages);
    };
  
    xhr.setRequestHeader('Authorization', `Bearer ${authToken}`);
    xhr.setRequestHeader("X-EasyPLV-Api-Token", apiKey);
    // Don't set content type for FormData
    // xhr.setRequestHeader('Content-Type', 'multipart/form-data');
  
    xhr.send(data);
}

export const deleteSingleCustomImage = async (savedImageInfo) => {
    const authToken = await getAuthTokenWithRefresh();
    const apiKey = getEasyPlvApiToken();
    const deleteSingleImagePromise = await fetch(getBackendApiUrl('products/deleteCustomImage'), {
        method: "POST",
        headers: {
            Authorization : `Bearer ${authToken}`,
            "X-EasyPLV-Api-Token": apiKey,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({'toDelete' : savedImageInfo})
    })

    checkApiResponse(deleteSingleImagePromise);
    if (deleteSingleImagePromise.status === 500) return {'success' : false};
    return await deleteSingleImagePromise.json();
}

export const UPLOAD_STATES = {
	PROCESSING: {
        VALUE: 'processing',
        ICON: <Spinner animation="border" variant="dark" size="sm"/>
    },
	IN_PROGRESS: {
        VALUE: 'in_progress',
        ICON: <XCircle size={20} />
    },
    SUCCESS: {
        VALUE: 'success',
        ICON: <CheckCircleFill size={20} className="text-success" />
    },
    ERROR: {
        VALUE: 'error',
        ICON: <SlashCircle size={20} className="text-danger" />
    }
}