import { useEffect, useState } from "react";
import SimpleListing from "../../components/Navbar/Listing/SimpleListing";
import { getPagesListing } from "../../lib/functions";
import { useNavigate } from "react-router-dom";
import EditUser from "../../components/User/EditUser";


function EditUserProfile(){
    const [pagesList, setPagesList] = useState([]);
    const navigate = useNavigate();
   

    return (
      
        <EditUser/>
    )



}


export default EditUserProfile;