import React from 'react'
import ProductLocalForm from '../../components/ProductLocal/ProductLocalForm';

function ProductLocalEditFormPage() {
  return (
    <>
      <ProductLocalForm/>
    </>
  )
}


export default  ProductLocalEditFormPage;