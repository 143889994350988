import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from './assets/translations/en.json';
import es from './assets/translations/es.json';
import fr from './assets/translations/fr.json';
import pt from './assets/translations/pt.json';

const languagePref = localStorage.getItem('lang') || 'fr';

i18n 
    .use(initReactI18next)
    .init({
        fallbackLng: "fr",
        resources: {
            en: { translation: en },
            es: { translation: es },
            fr: { translation: fr },
            pt: { translation: pt }
        },
        lng: languagePref,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;