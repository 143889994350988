import { useEffect, useState } from "react";
import NotificationList from "../../NotificationList/NotificationList";
import { getNotifications } from "../../../services/notification";
import { getAfterTokenExpiryLimit, getDecodedTokenData } from "../../../helpers/helpers";
import { getAuthToken } from "../../../config";

function Notifications () {

    const [notifications, setNotifications] = useState({});
    useEffect(() => {
        const fetchAndSetNotfications = async () => {
            try {
                const authToken = getAuthToken();
                const jwtTokenData = await getDecodedTokenData(authToken);
                const timestampDifference = jwtTokenData.timestampDifference;
                const minutesDifference = Math.abs(Math.floor(timestampDifference/1000/60));
                
                // do not send any notifications after being expired but only after the set time after the user should log out after expiry
                if (jwtTokenData.expired) {
                    const afterTokenExpiryLimit = getAfterTokenExpiryLimit();
                    if (minutesDifference < afterTokenExpiryLimit) {
                        setNotifications({});
                        return;
                    }
                } else if (minutesDifference <= 1 && minutesDifference > 0) { // do not send notifications 1 minutes before token expires to prevent logout even before token expires
                    setNotifications({});
                    return;
                } else {
                    const notificationsData = await getNotifications();
                    if (notificationsData !== undefined) {
                        setNotifications(notificationsData);
                    }
                }

            } catch (e) {console.error(e)}
        }
        const interval = setInterval(() => {
            fetchAndSetNotfications();
        }, 5000);
        return ()=>clearInterval(interval);
    }, [notifications]);
    return (
        <>
                <NotificationList notificationsJsonData={notifications}/>
        </>
    )
}

export default Notifications;