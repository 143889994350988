import React from "react"
import { useStyletron } from "baseui"
import useDesignEditorPages from "../../../hooks/useDesignEditorScenes"
import { DesignEditorContext } from "../../../contexts/DesignEditor"
import { nanoid } from "nanoid"
import { getDefaultTemplate } from "../../../../../constants/design-editor"
import { useEditor, useFrame } from "@layerhub-io/react"
import { IScene } from "@layerhub-io/types"
import { DndContext, closestCenter, PointerSensor, useSensor, DragOverlay } from "@dnd-kit/core"
import { arrayMove, SortableContext, horizontalListSortingStrategy } from "@dnd-kit/sortable"
import { restrictToFirstScrollableAncestor, restrictToHorizontalAxis } from "@dnd-kit/modifiers"
import SceneItem from "./SceneItem"
import { Block } from "baseui/block"
import useContextMenuTimelineRequest from "../../../hooks/useContextMenuTimelineRequest"
import SceneContextMenu from "./SceneContextMenu"
import useEditorUseContext from '../../../hooks/useEditorUseContext';
import Add from '../../../../../components/Icons/Add';
import { PanelType } from '../../../../../constants/app-options';
import useAppContext from '../../../hooks/useAppContext';
import TemplateGroupModels from '../../Panels/panelItems/TemplateGroupModels';

const Scenes = () => {
  const scenes = useDesignEditorPages()
  const { setScenes, setCurrentScene, currentScene, setCurrentDesign, currentDesign, setCurrentPreview, currentPreview } =
    React.useContext(DesignEditorContext)
  const editor = useEditor()
  const [css] = useStyletron()
  const frame = useFrame()
  const [draggedScene, setDraggedScene] = React.useState<IScene | null>(null)
  const contextMenuTimelineRequest = useContextMenuTimelineRequest()
  const {use} = useEditorUseContext();
  const { setActivePanel } = useAppContext();

  const sensors = [
    useSensor(PointerSensor, {
		activationConstraint: {
			distance: 5,
		},
    }),
  ]

  React.useEffect(() => {
    if (editor && scenes && currentScene) {
		const isCurrentSceneLoaded = scenes.find((s) => s.id === currentScene?.id)
		if (!isCurrentSceneLoaded) {
			setCurrentScene(scenes[0])
		}
    }
  }, [editor, scenes, currentScene])

  React.useEffect(() => {
    let watcher = async () => {
		const updatedTemplate = editor.scene.exportToJSON()
		const updatedPreview = (await editor.renderer.render(updatedTemplate)) as string
		setCurrentPreview(updatedPreview)
    }
    if (editor) {
      	editor.on("history:changed", watcher)
    }
    return () => {
		if (editor) {
			editor.off("history:changed", watcher)
		}
    }
  }, [editor])

  React.useEffect(() => {
	// INITIAL SCENES SETUP
	// ALSO DEPENDS ON EDITOR USE TYPE
	// do not include any initial scenes if editor is used for social media purposes
    if (editor) {
      if (currentScene) {
        updateCurrentScene(currentScene)
      } 
	  else 
	  {
        const defaultTemplate = getDefaultTemplate({
			width: 794,
			height: 1123,
        })
        setCurrentDesign({
			id: nanoid(),
			frame: defaultTemplate.frame,
			metadata: {},
			name: "Untitled Design",
			preview: "",
			scenes: [],
			type: "PRESENTATION",
        })
		if (use !== 'social_media') {
			editor.scene
			.importFromJSON(defaultTemplate)
			.then(() => {
					const initialDesign = editor.scene.exportToJSON() as any
					editor.renderer.render(initialDesign).then((data) => {
					setCurrentScene({ ...initialDesign, preview: data })
					setScenes([{ ...initialDesign, preview: data }])
				})
			})
			.catch(console.log)
		} 
      }
    }
  }, [editor, currentScene])

  const updateCurrentScene = React.useCallback(
    async (design: IScene) => {
      await editor.scene.importFromJSON(design)
      const updatedPreview = (await editor.renderer.render(design)) as string
      setCurrentPreview(updatedPreview)
    },
    [editor, currentScene]
  )

  const addNewScene = React.useCallback(async () => {
    setCurrentPreview("")
    const updatedTemplate = editor.scene.exportToJSON()
    const updatedPreview = await editor.renderer.render(updatedTemplate)

    const updatedPages = scenes.map((p) => {
		if (p.id === updatedTemplate.id) {
			return { ...updatedTemplate, preview: updatedPreview }
		}
		return p
    })

    const defaultTemplate = getDefaultTemplate(currentDesign.frame)
    const newPreview = await editor.renderer.render(defaultTemplate)
    const newPage = { ...defaultTemplate, id: nanoid(), preview: newPreview } as any
    const newPages = [...updatedPages, newPage] as any[]
    setScenes(newPages)
    setCurrentScene(newPage)
  }, [scenes, currentDesign])

  const changePage = React.useCallback(
    async (page: any) => {
      setCurrentPreview("")
      if (editor) {
          const updatedTemplate = editor.scene.exportToJSON()
          const updatedPreview = await editor.renderer.render(updatedTemplate)

          const updatedPages = scenes.map((p) => {
            if (p.id === updatedTemplate.id) {
                return { ...p, preview: updatedPreview,}
            }
            return p
          }) as any[]

          setScenes(updatedPages)
          setCurrentScene(page)
      }
    },
    [editor, setCurrentScene, scenes, setScenes]
  )

  const handleDragStart = (event: any) => {
	const draggedScene = scenes.find((s) => s.id === event.active.id)
	if (draggedScene) {
		setDraggedScene(draggedScene)
	}
  }

  const handleDragEnd = (event: any) => {
    const { active, over } = event

    if (active.id !== over.id) {
      setScenes((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id)
        const newIndex = items.findIndex((item) => item.id === over.id)

        return arrayMove(items, oldIndex, newIndex)
      })
    }
    setDraggedScene(null)
  }

  const switchTab = (tab: PanelType) => {
	console.log(tab)
	setActivePanel(tab);
  }

  return (
    <DndContext
      modifiers={[restrictToFirstScrollableAncestor, restrictToHorizontalAxis]}
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <Block
        id="TimelineItemsContainer"
        $style={{ padding: "0.25rem 0.75rem", background: "#ffffff", position: "relative" }}
      >
        <div className={css({ display: "flex", alignItems: "center" })}>
          {contextMenuTimelineRequest.visible && <SceneContextMenu />}

		<SortableContext items={scenes} strategy={horizontalListSortingStrategy}>
			{scenes.map((page, index) => (
				<SceneItem
					key={index}
					isCurrentScene={page.id === currentScene?.id}
					scene={page}
					index={index}
					changePage={changePage}
					preview={
					currentPreview && page.id === currentScene?.id ? currentPreview : page.preview ? page.preview : ""
					}
				/>
			))}
			<div
				style={{
				background: "#ffffff",
				padding: "1rem 1rem 1rem 0.5rem",
				}}
			>
			{
				use === "social_media" &&
				<div
					onClick={() => switchTab(PanelType.TEMPLATE_GROUP_MODELS)}
					className={css({
						width: "100px",
						height: "56px",
						background: "rgb(243,244,246)",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						cursor: "pointer",
					})}
				>
					<Add size={20} />
				</div>
			}
				
			</div>
		</SortableContext>
		<DragOverlay>
		{draggedScene ? (
			<Block
				$style={{
					backgroundImage: `url(${draggedScene.preview})`,
					backgroundSize: `${frame ? (frame.width * 70) / frame.height : 70}px 70px`,
					height: "80px",
					opacity: 0.75,
				}}
				/>
			) : null}
		</DragOverlay>
        </div>
      </Block>
    </DndContext>
  )
}

export default Scenes
