import React, {createContext, useContext} from 'react';

/**
 * @typedef CartContextValue
 * @property {boolean} allowPrint - Whether to allow print or not
 * @property {boolean} allowPrintFromSubscription - Allow print from subscription data
 */

export const CartContext = createContext();

/**
 * Custom hook to access CART context.
 *
 * @returns {CartContextValue} Cart context values and actions.
 */
export const useCart = () => {
    return useContext(CartContext);
}