import React, { useEffect } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import { DotButton, useDotButton } from './NwCarouselDotButton';
import {   
    PrevButton,
    NextButton,
    usePrevNextButtons 
} from './NwCarouselArrowButtons';
import './embla.css';

/**
 * @typedef {Object} Slide
 * @property {number|string} id - The unique identifier for the slide.
 * @property {string} name - The name of the slide.
 * @property {string|JSX.Element} content - The content of the slide, either a string or JSX element.
 */

/**
 * A carousel component utilizing Embla Carousel.
 * 
 * @param {Object} props - The props for the component.
 * @param {Slide[]} props.slides - An array of slides to be displayed in the carousel.
 * @param {Object} props.options - Options for the Embla carousel.
 * @param {boolean} [props.hideNavigationButtons=false] - Flag to hide navigation buttons.
 * @param {Object} [props.classNames={}] - Custom class names for various parts of the component.
 * @param {string} [props.classNames.root] - Class name for the root element.
 * @param {string} [props.classNames.viewport] - Class name for the viewport element.
 * @param {string} [props.classNames.viewport_container] - Class name for the viewport container element.
 * @param {string} [props.classNames.slide] - Class name for the slide element.
 * @param {string} [props.classNames.slide_number] - Class name for the slide number element.
 * @param {string} [props.classNames.controls] - Class name for the controls container element.
 * @param {string} [props.classNames.controls_wrapper] - Class name for the controls wrapper element.
 * @param {string} [props.classNames.buttons] - Class name for the buttons container element.
 * @param {string} [props.classNames.dots] - Class name for the dots container element.
 * @param {string} [props.classNames.prev_btn] - Class name for the previous button element.
 * @param {string} [props.classNames.next_btn] - Class name for the next button element.
 * @param {Object} [props.styles={}] - Custom Styles for various parts of the component.
 * @param {string} [props.styles.root] - Styles for the root element.
 * @param {string} [props.styles.viewport] - Styles for the viewport element.
 * @param {string} [props.styles.viewport_container] - Styles for the viewport container element.
 * @param {string} [props.styles.slide] - Styles for the slide element.
 * @param {string} [props.styles.slide_number] - Styles for the slide number element.
 * @param {string} [props.styles.controls] - Styles for the controls container element.
 * @param {string} [props.styles.controls_wrapper] - Styles for the controls wrapper element.
 * @param {string} [props.styles.buttons] - Styles for the buttons container element.
 * @param {string} [props.styles.dots] - Styles for the dots container element.
 * @param {string} [props.styles.prev_btn] - Styles for the previous button element.
 * @param {string} [props.styles.next_btn] - Styles for the next button element.
 * @param {Function} [props.onSlideChange] - Callback function that is called every time the slide changes.
 * @returns {JSX.Element} The rendered carousel component.
 */
const NwCarousel = ({
    slides,
    options,
    hideNavigationButtons = false,
    classNames = {},
    styles = {},
    onSlideChange
}) => {
    const [emblaRef, emblaApi] = useEmblaCarousel(options)

    const { selectedIndex, scrollSnaps, onDotButtonClick } =  useDotButton(emblaApi);

    useEffect(() => {
        if (emblaApi) {
            if (onSlideChange) {
                // If there is slide change function passed, then call it with current data
                onSlideChange(slides?.[selectedIndex] ?? null);
            }
        }
    }, [emblaApi, selectedIndex]);

    const {
        prevBtnDisabled,
        nextBtnDisabled,
        onPrevButtonClick,
        onNextButtonClick
    } = usePrevNextButtons(emblaApi)
    return (
        <section className={`embla ${ classNames?.root ?? ''}`}>
            <div className={`embla__viewport ${ classNames?.viewport ?? ''}`} ref={emblaRef}>
                <div className={`embla__container ${ classNames?.viewport_container ?? ''}`}>
                    {slides.map((item, index) => (
                        <div className={`embla__slide ${ classNames?.slide ?? ''}`} style={styles?.slide} key={index}>
                            <div className={`embla__slide__number ${ classNames?.slide_number ?? ''}`}>{ item?.content ?? index + 1 }</div>
                        </div>
                    ))}
                </div>
            </div>
    
            <div className={`embla__controls ${classNames?.controls ?? ''}`}>
                <div className={`embla__controls_wrapper ${classNames?.controls_wrapper ?? ''}`}>
                    <div className={`embla__buttons ${classNames?.buttons ?? ''} ${classNames?.prev_btn ?? ''} ${ hideNavigationButtons ? 'd-none' : '' }`}>
                        <PrevButton onClick={onPrevButtonClick} disabled={prevBtnDisabled} />
                    </div>        
                    <div className={`embla__dots ${classNames?.dots ?? ''}`}>
                        {scrollSnaps.map((_, index) => (
                            <DotButton
                                key={index}
                                onClick={() => onDotButtonClick(index)}
                                className={'embla__dot'.concat(
                                index === selectedIndex ? ' embla__dot--selected' : ''
                                )}
                            />
                        ))}
                    </div>
                    <div className={`embla__buttons ${classNames?.buttons ?? ''} ${classNames?.next_btn ?? ''} ${ hideNavigationButtons ? 'd-none' : '' }`}>
                        <NextButton onClick={onNextButtonClick} disabled={nextBtnDisabled} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default NwCarousel;