import { getBackendApiUrl, getEasyPlvApiToken, getBackendUrl, synchroniseUserData } from "../config";
import jwtDecode from 'jwt-decode';
import {updateUserFrontSession} from "./userFrontSession";

export const loginUser = async (userData) => {
    let jwtToken = null;
    try {
        const loginPromiseReponse = await fetch(getBackendUrl('auth'), {
            method : "POST",
            headers: {
                "Content-Type" : "application/json"
            },
            body : JSON.stringify({email : userData.email, password: userData.password})
        })
    
        if (loginPromiseReponse.status === 200) {
            const response = await loginPromiseReponse.json();
            if (response.token) {
                jwtToken = response.token;
            }
            localStorage.setItem('impersonateSession', false);

            if (response?.refresh_token) {
                localStorage.setItem('refresh_token', response.refresh_token);
            }
        }
    } catch (error) {
        console.error(error);
    }

    return jwtToken;
}

export const logoutUser = (doRequest = false) => {
    if (doRequest) {
        // Run API logic and logout
        // synchroniseUserData('action', 'value');
    }
    localStorage.removeItem('auth_token');
    localStorage.removeItem('plv_username');
    localStorage.removeItem('store_details');
    localStorage.removeItem('impersonateSession');
};

export const fetchUserData = async (jwtToken, toSave = true) => {    
    let dataToBeSaved = {
        jwtToken: null,
        userData: null,
        isError: true,
        isExpired: false
    }
    try {
        const response = await fetch(getBackendApiUrl(`user/getUserData`), {
            method: 'GET',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${jwtToken}`,
                'X-EasyPLV-Api-Token': `${getEasyPlvApiToken()}`                       
            },
        });

        const userData = await response.json();
        if (response.status === 200){
            //if authorised, i.e tokens are good
            dataToBeSaved = {
                jwtToken: jwtToken,
                userData: userData,
                isError: false,
                isExpired: false
            }

            if (toSave)
            {
                saveUserDataInLocalStorage(jwtToken, userData, null);
            }
        } else {
            if (checkApiResponse(response) === ApiResponseStatus.UNAUTHORISED)
            {
                dataToBeSaved.isExpired = true;
            }
        }
        
    } catch (error) {
        console.error(error);
    }

    return dataToBeSaved;
}


export const UpdateUserFrontSession = async (data) => {
    //fetchUserData
    const responseUpdateUserFrontSession = await updateUserFrontSession(data);
    if (responseUpdateUserFrontSession.status === 200){
        return (responseUpdateUserFrontSession.json());
    }else{
        console.log("UserFrontSessionFailed");
    }

    return false;
}

export const checkApiResponse = (response) => {
    if (response && response.status === ApiResponseStatus.UNAUTHORISED) {
            // Dispatch a custom event to signal an expired token
            window.dispatchEvent(new CustomEvent('apiResponseEvent', {
                detail: { message: ApiResponseStatus.UNAUTHORISED }
            }));
        return ApiResponseStatus.UNAUTHORISED
    }

    return ApiResponseStatus.OK;
}

export const saveUserDataInLocalStorage = async (token, userData, userStoreData) => {
    console.log(userData);
    try {
        localStorage.setItem('auth_token' , token);
        localStorage.setItem('plv_username', userData.firstName);
        localStorage.setItem('store_details', userStoreData ? JSON.stringify(userStoreData) : null);
    } catch (error) {
        return false;
    }

    return true;
}

export const ApiResponseStatus = {
    OK: 200,
    UNAUTHORISED:  401
}