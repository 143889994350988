import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { 
    getUserSocialMediaFreePostersByFreePack,
    downloadSocialMediaFreePosterById,
    deleteSocialMediaFreePosterById
} from "../../services/social_media";
import ListCard from './ListCard/ListCard';
import { Row, Col } from 'react-bootstrap';
import { 
    PencilSquare,
    EyeFill,
    Download,
    XLg
} from 'react-bootstrap-icons';

export default function FreePosters() {
    const params = useParams();
    const freePackId = params.id;
    const [ socialMediaFreePosters, setSocialMediaFreePosters ] = useState([]);

    const handleDownloadSocialMediaFreePoster = async (freePosterId) => {
        const responseData = await downloadSocialMediaFreePosterById(freePosterId);
        if (responseData) {
            const url = URL.createObjectURL(responseData);
			const a = document.createElement("a");
			a.href = url;
	
			a.download = "media.png";
			document.body.appendChild(a);
			a.click();
	
			document.body.removeChild(a);
			URL.revokeObjectURL(url);
        }
    }

    const handleDeleteSocialFreeMediaPoster = async (freePosterId) => {
        const success = await deleteSocialMediaFreePosterById(freePosterId);
        if (success) {
            getUserSocialMediaFreePostersByFreePack(freePackId).then(freePosters => {
                setSocialMediaFreePosters(freePosters);
            });
        }
    }

    useEffect(() => {
        if (!freePackId) return; 
        getUserSocialMediaFreePostersByFreePack(freePackId).then(freePosters => {
            setSocialMediaFreePosters(freePosters);
        });
    }, [freePackId])

    return (
        <>
            <div className="d-flex mb-3 px-3">
                <Row className='w-75 gx-2'> 
                {
                    socialMediaFreePosters.map(freePoster => {
                        return (
                            <Col className='flex-grow-0'>
                            <ListCard 
                                key={freePoster.id}
                                title={freePoster.title}
                                imgSrc={freePoster.thumbnailPreviewUrl}
                                actions={[
                                    {
                                        title: 'Download',
                                        type: "button",
                                        onClick: () => handleDownloadSocialMediaFreePoster(freePoster.id),
                                        icon: <Download/>,
                                    },
                                    {
                                        title: "Edit",
                                        type: "link",
                                        href: `/social-media/free-editor/edit/pack/${freePackId}?focus_scene_id=${freePoster.sceneId}`,
                                        icon: <PencilSquare/>,
                                    },
                                    {
                                        title: "View",
                                        href: `#`,
                                        type: "link",
                                        icon: <EyeFill/>,
                                    },
                                    {
                                        title: "Delete",
                                        type: "button",
                                        onClick: () => handleDeleteSocialFreeMediaPoster(freePoster.id),
                                        icon: <XLg/>,
                                    }
                                ]}   
                            />
                            </Col>
                        )
                    })
                }
                </Row>
            </div>
        </>
    )
}