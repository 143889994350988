import Toast from "react-bootstrap/Toast";
import { useNavigate } from "react-router-dom";

function MessageNotif ({notification, handleCloseNotification}) {
    const navigate = useNavigate();
    const body = notification.body ? notification.body : "";
    const otherData = notification.otherDataJson ? notification.otherDataJson : {};
    const subject = notification.subject ? notification.subject : "";
    const messageId = otherData.messageId ? otherData.messageId : undefined;

    const handleViewMessage = () => {
        handleCloseNotification(notification.id);
        if (messageId) {
            navigate(`view/message/${messageId}/`);
        }
    }

    return (
        <>
            <Toast.Header closeButton={true}>
                <img
                    src="holder.js/20x20?text=%20"
                    className="rounded me-2"
                    alt=""
                />
                <strong className="me-auto">{subject}</strong>
            </Toast.Header>
            <Toast.Body>
                <span>{body}</span>
                <button className="btn btn-success" onClick={handleViewMessage}>View</button>
            </Toast.Body>
        </>
    )
}

export default MessageNotif;