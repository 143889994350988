import { getBackendApiUrl, getEasyPlvApiToken, getBackendUrl, getAuthTokenWithRefresh } from "../config";
import { checkApiResponse } from "./auth";


export const getDashboardStats = async (type,sort,locale) => {
    const authToken = await getAuthTokenWithRefresh();
    const res = await fetch(getBackendApiUrl(`activities/${type}?sort=${sort}&locale=${locale}`), {
      method: "GET",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "X-EasyPLV-Api-Token": getEasyPlvApiToken(),
      },
    });
    checkApiResponse(res);
    const data = await res.json();
    return data;
};


export const getCarouselItems = async () => {
  const authToken = await getAuthTokenWithRefresh();
  const res = await fetch(getBackendApiUrl(`advertisingBanner/listForFrontUser`), {
    method: "GET",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "X-EasyPLV-Api-Token": getEasyPlvApiToken(),
    },
  });
  checkApiResponse(res);
  if (res.status === 200) {
    const data = await res.json();
    return data;
  }
  return [];
};