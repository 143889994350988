import { useEffect } from "react";
import NwCustomImageDropzone from "../_utilityComponents/NwCustomImageDropzone/NwCustomImageDropzone";

function ImageMultipleType(props) {
    const isDisabled = props?.isDisabled || false;
    const productId = props.productId;
    const formFieldJson = props.formFieldJson;
	const entityFormValuesJson = props.entityFormValuesJson;
	const updateEntityFormValues = props.updateEntityFormValues;
	const configValues = formFieldJson.configValues;
    const metadata = configValues?.values?.metadata;
    const imageMultipleValueKey = `${configValues.frontUniqueKeys.multiplefileinput1}_${formFieldJson.id}`;
    const imageMultipleValueJson = `${imageMultipleValueKey}` in entityFormValuesJson
    ? entityFormValuesJson[`${imageMultipleValueKey}`]
    : {
        name : formFieldJson.name,
        type: 'image_multiple',
        value : [],
        metadata: metadata,
    }

    useEffect(() => {
        updateEntityFormValues(prevFieldValuesJson => {
            const newFieldValuesJson = {...prevFieldValuesJson};
            newFieldValuesJson[`${imageMultipleValueKey}`] = imageMultipleValueJson;
            return newFieldValuesJson;
        })
    }, []);

    const customHandleUploadMultipleImages = async (imagesUploaded) => {
        if (!imagesUploaded) return;
        const uploadedImages = imagesUploaded.map(imageUploaded => imageUploaded?.uploadedInfo);

        updateEntityFormValues(prevFieldValuesJson => {
            const newFieldValuesJson = {...prevFieldValuesJson};
            imageMultipleValueJson.value = [...uploadedImages];
            newFieldValuesJson[`${imageMultipleValueKey}`] = imageMultipleValueJson;
            return newFieldValuesJson;
        })
    }

    return (
        <>
            <label>{formFieldJson.title}</label>
            <NwCustomImageDropzone disabled={isDisabled} handleFilesUploaded={customHandleUploadMultipleImages} />
        </>
    )
}

export default ImageMultipleType;