// @ts-nocheck
import React, { useState } from "react"
import { Plus, Dash, FileEarmark, Eye } from "react-bootstrap-icons"
import { styled } from "baseui"
import { Theme } from "baseui/theme"
import { NwLoaderSpinner } from "../../../../../components/_utilityComponents/NwIcons/NwIcons"
import { KIND, SIZE } from "baseui/button"
import { Button } from "react-bootstrap"
import { Slider } from "baseui/slider"
import { BaseInput, Input } from "baseui/input"
import { useEditor, useZoomRatio } from "@layerhub-io/react"
import useTemplateGroupEditorContext from "../../../hooks/useTemplateGroupEditorContext"
import { saveFreePoster, editFreePoster } from "../../../../../services/free_poster"
import useDesignEditorContext from "../../../hooks/useDesignEditorContext"
import { useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { saveSocialMediaFreePack, editSocialMediaFreePack, downloadSocialMediaFreePack, updateSocialMediaTemplateGroupModelSceneJson } from "../../../../../services/social_media";
import useEditorUseContext from '../../../hooks/useEditorUseContext';
import NwCustomModal from "../../../../../components/_utilityComponents/NwCustomModal/NwCustomModal"
import NwCustomInput from "../../../../../components/_utilityComponents/NwFormsUi/CustomInput/NwCustomInput"
import useTemplateGroupModelContext from '../../../hooks/useTemplateGroupModelContext';
import useSocialMediaContext from '../../../hooks/useSocialMediaContext';
import NwCustomSelect from "../../../../../components/_utilityComponents/NwFormsUi/CustomSelect/NwCustomSelect"
import { Trans } from "react-i18next";
import Graphic from "../../Preview/Graphic"

const Container = styled<"div", {}, Theme>("div", ({ $theme }) => ({
  height: "50px",
  background: $theme.colors.white,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}))

interface Options {
  zoomRatio: number
}

const Common = () => {
	const { currentDesign, displayPreview, setDisplayPreview, currentScene, scenes } = useDesignEditorContext()
	// id = id of entity
	const {mode, freePosterId, id} = useParams();
	const [ isSaving, setIsSaving ] = useState(false);
	const [ isDownloading, setIsDownloading ] = useState(false);
	const [ isAddingToCart, setIsAddingToCart ] = useState(false);
	const {t} = useTranslation();
	const {use} = useEditorUseContext();
	const [packGroupId, setPackGroupId] = useState(null);
	const [newPackGroupName, setNewPackGroupName] = useState("");
	const {modelUuid} = useTemplateGroupModelContext();
	const { packGroups } = useSocialMediaContext();

    const packGroupOptions = packGroups.map(packGroup => {
        return {
            label: packGroup?.name,
            value: packGroup?.id,
        }
    })

	const {
		title,
		currentFormat,
		currentOrientation,
		selectedOptionChartesGraphique,
	} = useTemplateGroupEditorContext();

	const parseGraphicJSON = () => {
		const currentScene = editor.scene.exportToJSON()

		const updatedScenes = scenes.map((scn: any) => {
			if (scn.id === currentScene.id) {
				return {...scn, ...currentScene};
			}
			return scn;
		})

		if (currentDesign) {
			const graphicTemplate: any = {
				id: currentDesign.id,
				type: "GRAPHIC",
				name: currentDesign.name,
				frame: currentDesign.frame,
				scenes: updatedScenes,
				metadata: {},
				preview: "",
			}

			return graphicTemplate;
		} else {
			console.log("NO CURRENT DESIGN")
		}
	}

	const handleSaveFreePoster = async (addToCart = true) => {
		if (addToCart) {
			setIsAddingToCart(true);
		} else {
			setIsSaving(true);
		}

		const layerhubJson = parseGraphicJSON();
		const scene = editor.scene.exportToJSON();
		const dataUrl = await editor.renderer.toDataURL(
			{
				id: scene.id,
				frame: scene.frame,
				layers: scene.layers,
				metadata: scene.metadata
			}, {}, {}
		);

		const data = {
			addToCart: addToCart,
			title: title,
			orientation: currentOrientation.value,
			format_id: currentFormat.value,
			imageDataUrl: dataUrl,
			layerhubJson : layerhubJson,
			templateGroupId: selectedOptionChartesGraphique ? selectedOptionChartesGraphique.id : null
		}

		if (mode === "new") await saveFreePoster(data);
		else if (mode === "edit") await editFreePoster(data, freePosterId);

		if (addToCart) {
			setIsAddingToCart(false);
		} else {
			setIsSaving(false);
		}
	}

	const getSocialMediaFreePackForApi = async () => {
		const scenesJson = parseGraphicJSON();
		const scenes = scenesJson.scenes;

		const newScenes : any = [];

		for (const scene of scenes) {
			const dataUrl = await editor.renderer.toDataURL({...scene}, {}, {});
			scene.imageDataUrl = dataUrl;
			newScenes.push(scene);
		}

		scenesJson.scenes = newScenes;

		const data = {
			'name' : "new",
			"scenesJson" : scenesJson,
			'packGroupId' : packGroupId,
			'newPackGroupName' : newPackGroupName,
		}
		return data;
	} 

 	const handleSaveSocialMediaFreePack = async () => {
		setIsSaving(true);
		const apiData = await getSocialMediaFreePackForApi();

		if (mode === "new") {
			await saveSocialMediaFreePack(apiData);
		} else if (mode === "edit") {
			await editSocialMediaFreePack(apiData, id);
		}

		setIsSaving(false);
	} 

	const handleDownloadSocialMediaFreePack = async () => {
		setIsDownloading(true);
		const apiData = await getSocialMediaFreePackForApi();

		const response = await downloadSocialMediaFreePack(apiData);
		if (response) {
			const url = URL.createObjectURL(response);
			const a = document.createElement("a");
			a.href = url;
	
			a.download = "media.zip";
			document.body.appendChild(a);
			a.click();
	
			document.body.removeChild(a);
			URL.revokeObjectURL(url);
		}

		setIsDownloading(false);
		return;
	}

	const handleSaveTgModelDesign = async () => {
		setIsSaving(true);
		const layerhubJson = parseGraphicJSON();
		const scene = editor.scene.exportToJSON();
		const dataUrl = await editor.renderer.toDataURL(
			{...scene}, {}, {}
		);
		await updateSocialMediaTemplateGroupModelSceneJson(layerhubJson, dataUrl, modelUuid);
		setIsSaving(false);
		return;
	}

	const zoomMin = 10
	const zoomMax = 240
	const [options, setOptions] = React.useState<Options>({
		zoomRatio: 20,
	})
	const editor = useEditor()
	const zoomRatio: number = useZoomRatio()

	React.useEffect(() => {
		setOptions({ ...options, zoomRatio: Math.round(zoomRatio * 100) })
	}, [zoomRatio])

	const handleChange = (type: string, value: any) => {
		if (value < 0) {
		editor.zoom.zoomToRatio(zoomMin / 100)
		} else if (value > zoomMax) {
		editor.zoom.zoomToRatio(zoomMax / 100)
		} else {
		editor.zoom.zoomToRatio(value / 100)
		}
	}

	return (
		<Container className="mb-0" $style={
		{
			backgroundColor: "#3C475C",
			paddingBlock: "var(--nw-responsive-padding-lg)",
			height: "fit-content"

		}
		}>
		<div style={{ display: "flex", alignItems: "center", justifyContent: "space-around", width: "100%" }}>
			{/* {
				currentScene?.postType ? (
					<div>
						<NwCustomModal
							title="Preview"
							onShow={() => setDisplayPreview(true)}
							onClose={() => setDisplayPreview(false)}
							defaultButtonProps={{
								icon: <Eye size={16}/>,
								className: 'nw-responsive-font-size py-2 border-0',
								style: {
									height: 'fit-content'
								}
							}}
							modalProps={{
								body: {
									className: 'd-flex'
								}
							}}
						>
							<div className="d-flex">
								{displayPreview && <Graphic/>}
							</div>
						</NwCustomModal>
					</div>
				) : (<></>)
			} */}

			<div className="nw-free-poster-format-additional-info-container text-white">
				{ use === "social_media" ? 
					(
						<>
							{
								currentScene?.postType ? 
								(
									<span className="text-capitalize">
										{t('freeEditorSocialMedia.common.model')}: {currentScene.postType.name} {currentScene.postType.size.width} x {currentScene.postType.size.height}px
									</span>
								) : 
								t('freeEditorSocialMedia.common.noModelSelected')
							}
						</>
					)  : (
						<>
							<FileEarmark size={30} style={{
								transform: currentOrientation?.label === "landscape" ? 'rotate(-90deg)' : ''
							}}/>
							<span>: {currentFormat && currentFormat.label}</span>
							<span> {currentOrientation && t(`orientationTitle.${currentOrientation.value}`)}</span>
						</>
					)
				}

			</div>
			<div className="nw-free-poster-slider-main-container d-flex align-items-center">
			<Button 
				kind={KIND.tertiary} 
				size={SIZE.compact} 
				onClick={() => editor.zoom.zoomOut()}
				style={{
					backgroundColor: "#0D6EFD",
					borderRadius: "7px",
					padding: "var(--nw-responsive-padding-sm)",
					marginInline: "0.5rem",
					height: "fit-content",
					fontSize: "var(--nw-responsive-font-size-lg)",
					aspectRatio: 1,
					width: "fit-content",
				}}
				className="d-flex justify-content-center align-items-center"
			>
				<Dash className="text-white"/>
			</Button>
			<Slider
				overrides={{
				InnerThumb: () => null,
				ThumbValue: () => null,
				TickBar: () => null,
				Root: {
					style: { width: "140px" },
				},
				Thumb: {
					style: {
					height: "12px",
					width: "12px",
					paddingLeft: 0,
					backgroundColor: "#0D6EFD"
					},
				},
				Track: {
					style: {
					paddingLeft: 0,
					paddingRight: 0,
					},
				},
				InnerTrack: {
					style: {
					background: "#0D6EFD"
					}
				}
				}}
				value={[options.zoomRatio]}
				onChange={({ value }) => {
				handleChange("zoomRatio", value[0])
				}}
				min={zoomMin}
				max={zoomMax}
			/>
			<Button
				kind={KIND.tertiary} 
				size={SIZE.compact} 
				onClick={() => editor.zoom.zoomIn()}
				style={{
					backgroundColor: "#0D6EFD",
					borderRadius: "7px",
					padding: "var(--nw-responsive-padding-sm)",
					height: "fit-content",
					width: "fit-content",
					fontSize: "var(--nw-responsive-font-size-lg)",
					aspectRatio: 1,
					marginInline: "0.5rem"
				}}
				className="d-flex justify-content-center align-items-center"
			>
				<Plus className="text-white" />
			</Button>
			<Input
				type="number"
				value={options.zoomRatio}
				endEnhancer="%"
				overrides={{
					Root: {
						style: {
							width: "clamp(55px, 3.5vw, 5rem)",
							paddingRight: "0px",
							display: "flex"
						},
					},
					InputContainer: {
						style: {
							width: '60%'
						}
					},
					Input: {
						style: {
							paddingBlock: "5px",
							paddingLeft: "10px",
							paddingRight: "0px"
						}
					},
					EndEnhancer: {
						style: {
							padding: "0px var(--nw-responsive-padding-sm)",
							width: "40%",
							display: "flex",
							justifyContent: "center"
						}
					}
				}}
				size={SIZE.mini}
				max={zoomMax}
				min={zoomMin}
				onChange={(e: any) => handleChange("zoomRatio", e.target.value)}
			/>
			</div>
			<div className="nw-free-poster-btn-container d-flex align-items-center">
				{
					(() => {
						if (use === "social_media") {
							return (
								<>
									{
										// @ts-ignore
										<NwCustomModal 
											title={t('editor.inputs.save')} 
											customButton={
												<Button
													className="px-4 rounded-1 me-3 w-100 text-nowrap"
													style={{
														fontFamily: "Montserrat-SemiBold",
														fontSize: "var(--nw-responsive-font-size)",
														backgroundColor: "var(--nw-primary-color)",
														height: "fit-content",
														paddingBlock: "var(--nw-responsive-padding)"
													}}
												>{t('freeEditorSocialMedia.common.save')}</Button>
											}
											modalProps={{
												closeBtn: {
													className: 'bg-transparent text-black border-0',
													iconSize: 20
												}
											}}
										>
											<div className="d-flex flex-column align-items-center px-4 pb-3" style={{width: '35vw', height: "fit-content"}}>
												<span 
													className="nw-text-center nw-font-open-sans fw-bold"
													style={{
														fontSize: 'clamp(14px, 0.9vw, 23px)'
													}}
												>
													<Trans
														i18nKey={'freeEditorSocialMedia.common.save_modal_header'}
													/>
												</span>
												<div className="save-modal-inputs-container w-100 mt-4 mb-4">
													<div className="w-100 nw-font-open-sans">
														<div className="mb-2">{ t('freeEditorSocialMedia.common.addToExistingCampaign') }</div>
														<NwCustomSelect
															onChange={(params: any) => {setPackGroupId(parseInt(params?.value))}} 		
															options={packGroupOptions}
															placeholder={ t('freeEditorSocialMedia.common.chooseCampaignPlaceholder') }
															label={ t('freeEditorSocialMedia.common.chooseCampaignPlaceholder') }
															menuPortalTarget={null}
															uiOverrides={{
																background: {
																	control: '#F2F2F2'
																}
															}}
														/>
													</div>
													<div className="w-100 nw-font-open-sans mt-2">
														<NwCustomInput
															type="text"
															label={t('freeEditorSocialMedia.common.addToNewCampaign')}
															labelClassName="text-black"
															placeholder={t('freeEditorSocialMedia.common.newCampaignNamePlaceholder')}
															value={newPackGroupName}
															inputWrapperClassName="nw-bg-tertiary-color"
															inputClassName="nw-bg-tertiary-color"
															onChange={(e)=>{setNewPackGroupName(e.target.value)}}													
														/>
													</div>
												</div>

												<Button 
													onClick={handleSaveSocialMediaFreePack}
													className="px-5 rounded-1 d-flex justify-content-center align-items-center column-gap-2"
													disabled={ isSaving }
													style={{
														fontFamily: "Montserrat-SemiBold",
														fontSize: "var(--nw-responsive-font-size)",
														paddingBlock: "var(--nw-responsive-padding-lg)",
														height: "fit-content"
													}}
												>
													{t('freeEditorSocialMedia.common.save_modal_text')}{ isSaving && <NwLoaderSpinner isSpinner size={15}/> }
												</Button>
											</div>
										</NwCustomModal>
									}

									<Button 
										onClick={() => handleDownloadSocialMediaFreePack()}
										className="px-4 rounded-1 w-100"
										style={{
											fontFamily: "Montserrat-SemiBold",
											fontSize: "var(--nw-responsive-font-size)",
											height: "fit-content",
											color: "var(--nw-primary-color)",
											backgroundColor: "var(--nw-secondary-color)",
											paddingBlock: "var(--nw-responsive-padding)"
										}}
									>
										{t('editor.inputs.download')} {isDownloading && <span className="mx-2"><NwLoaderSpinner isSpinner size={20} /></span>}
									</Button>

								</>
							)
						} else if (use === "free_poster") {
							return (
								<>
									<Button 
										onClick={() => handleSaveFreePoster(false)}
										style={{
										fontFamily: "Montserrat-SemiBold",
										backgroundColor: "#0D6EFD",
										borderRadius: "2px",
										marginInline: "0.5rem",
										}}
									>
										{t('editor.inputs.save')} {isSaving && <span className="mx-2"><NwLoaderSpinner isSpinner size={20} /></span>}
									</Button>
									<Button
										onClick={() => handleSaveFreePoster(true)}
										style={{
										fontFamily: "Montserrat-SemiBold",
										backgroundColor: "#198754",
										borderRadius: "2px",
										marginInline: "0.5rem"
										}}
									>
										{t('addToCartButtonLabel')} {isAddingToCart && <span className="mx-2"><NwLoaderSpinner isSpinner size={20} /></span>}
									</Button>
								</>
							)
						} else if (use === "tg_model") {
							return (
								<Button 
									onClick={() => handleSaveTgModelDesign()}
									style={{
									fontFamily: "Montserrat-SemiBold",
									backgroundColor: "#0D6EFD",
									borderRadius: "2px",
									marginInline: "0.5rem",
									}}
								>
									{t('editor.inputs.save')} {isSaving && <span className="mx-2"><NwLoaderSpinner isSpinner size={20} /></span>}
								</Button>
							)	
						}
					})()
				}
			</div>
		</div>
		<div style={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
			{/* <Button kind={KIND.tertiary} size={SIZE.compact}>
			<Icons.Refresh size={16} />
			</Button>
			<Button kind={KIND.tertiary} size={SIZE.compact}>
			<Icons.Undo size={22} />
			</Button>
			<Button kind={KIND.tertiary} size={SIZE.compact}>
			<Icons.Redo size={22} />
			</Button>
			<Button kind={KIND.tertiary} size={SIZE.compact}>
			<Icons.TimePast size={16} />
			</Button> */}
		</div>
		</Container>
	)
}

export default Common
