import { styled } from "baseui"
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap"
import { ChevronDoubleRight, ChevronDoubleLeft } from "react-bootstrap-icons"
import React, { useState} from 'react'
import { BASE_ITEMS, VIDEO_PANEL_ITEMS, SOCIAL_MEDIA_ITEMS, TEMPLATE_GROUP_MODEL_ITEMS } from '../../../../constants/app-options';
import useAppContext from "../../hooks/useAppContext"
import { useTranslation } from "react-i18next"
import useSetIsSidebarOpen from "../../hooks/useSetIsSidebarOpen"
import useEditorType from "../../hooks/useEditorType"
import { Block } from "baseui/block"
import useEditorUseContext from "../../hooks/useEditorUseContext";

const Container = styled("div", (props) => ({
  width: "10vw",
  backgroundColor: "#262F41",
  display: "flex",
  flexDirection: "column",
}))

const PanelsList = () => {
	const { activePanel, setActivePanel } = useAppContext()
	const [ isSideCollapsed, setIsSideCollapsed ] = useState(true);
	const { t } = useTranslation()
	const editorType = useEditorType()
	let PANEL_ITEMS = editorType === "VIDEO" ? VIDEO_PANEL_ITEMS : BASE_ITEMS
	const { use } = useEditorUseContext();
	if (use === "social_media") {
		PANEL_ITEMS = SOCIAL_MEDIA_ITEMS
	}
	else if (use === "tg_model") {
		PANEL_ITEMS = TEMPLATE_GROUP_MODEL_ITEMS
	}

	const toggleCollapse = () => {
		setIsSideCollapsed(!isSideCollapsed);
	};

	return (
		<Container style={{
			transition: 'all 150ms ease-in-out',
			width: isSideCollapsed ? "5.5vw" : "15vw"
		}}>
			<Button className={`bg-transparent border-0 py-4 px-3 d-flex justify-content-center`} onClick={toggleCollapse}>
				{ isSideCollapsed ? <ChevronDoubleRight size={24}/> : <	ChevronDoubleLeft size={24}/>}
			</Button>
			<Block>
				{PANEL_ITEMS.map((panelListItem: any, index) => (
					<PanelListItem
						label={t(`editor.panels.panelsList.${panelListItem.id}`)}
						name={panelListItem.name}
						key={panelListItem.name}
						index={index}
						icon={panelListItem?.icon}
						activePanel={activePanel}
						isPanelsListCollapsed={isSideCollapsed}
						additionalData={ panelListItem?.additionalData }
					/>
				))}
			</Block>
		</Container>
  )
}

const PanelListItem = ({ label, icon, activePanel, name, isPanelsListCollapsed, index, additionalData = null }: any) => {
	const { setActivePanel, setActivePanelAdditionalData } = useAppContext()
	const setIsSidebarOpen = useSetIsSidebarOpen()
	let iconName = name;
	if (name === "TemplateGroupModels") {
		iconName = "General"
	} else if (name === "UploadsTemplateGroupModel") {
		iconName = "Uploads"
	}

	return (
		<OverlayTrigger placement="auto" overlay={ isPanelsListCollapsed ? <Tooltip className="nw-tooltip">{label}</Tooltip> : <></>}>
		<Block
			id={`EditorPanelList-${name}`}
			onClick={() => {
				setIsSidebarOpen(true)
				setActivePanel(name)
				setActivePanelAdditionalData(additionalData)
			}}
			className="p-3"
			$style={{
				width: "100%",
				aspectRatio: isPanelsListCollapsed ? "1" : "unset",
				height: "70px",
				backgroundColor: name === activePanel ? "#3D465C !important" : '',
				display: "flex",
				alignItems: "center",
				justifyContent: isPanelsListCollapsed ? "center" : '',
				fontFamily: "Montserrat-SemiBold",
				borderTop: index == 0 ? '1px solid white' : '',
				borderBottom: "1px solid white",
				fontWeight: 500,
				fontSize: "var(--nw-responsive-font-size-md)",
				userSelect: "none",
				transition: "all 200ms",
				color: name === activePanel ? "white" :"white",
				columnGap: "0.3rem",
				":hover": {
					cursor: "pointer",
					backgroundColor: "#3D465C",
				},
				"svg": {
					fontSize: "var(--nw-responsive-font-size-sm)"
				}
			}}
		>
			<div
				className={isPanelsListCollapsed ? 'w-100 h-50' :'w-25 h-50'}
				style={{
					fontSize: "var(--nw-responsive-font-size-sm)"
				}}
			>
				{icon && React.cloneElement(icon, {className: 'w-100 h-100'})}
			</div>
			{!isPanelsListCollapsed && <div className="w-75 text-nowrap overflow-hidden" style={{ lineClamp: '1', textOverflow: 'ellipsis' }}>{label}</div>}
		</Block>
		</OverlayTrigger>
  )
}

export default PanelsList
