import Toast from 'react-bootstrap/Toast';
import { useTranslation } from 'react-i18next';

function ZipGenerationStart({notification, handleCloseNotification}) {
    const { t } = useTranslation();
    const body = notification.body ? notification.body : "";
    const subject = notification.subject ? notification.subject : "";
    return (
        <>
            <Toast.Header closeButton={true}>
                <img
                    src="holder.js/20x20?text=%20"
                    className="rounded me-2"
                    alt=""
                />
                <strong className="me-auto">{t(subject)}</strong>
            </Toast.Header>
            <Toast.Body>
                <span>{t(body)}</span><br></br>
            </Toast.Body>
        </>
    )
}

export default ZipGenerationStart;