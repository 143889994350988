import React from 'react';

const LegalMentionsLogo = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 16" width={props.size} height={props.size}>
            <path d="m9.237 17.372-.006-.103v-4.333a3.743 3.743 0 0 1-.923-2.477c0-2.012 1.552-3.649 3.461-3.649 1.909 0 3.461 1.637 3.461 3.65 0 .955-.35 1.825-.923 2.477v4.332c0 .566-.576.903-1.025.64l-.082-.056-1.43-1.132-1.432 1.132a.66.66 0 0 1-.412.147.707.707 0 0 1-.689-.628Zm2.862-2.204.086.058.738.584v-1.912a3.287 3.287 0 0 1-2.308 0v1.912l.739-.584a.666.666 0 0 1 .745-.058ZM9.692 10.46c0 1.207.932 2.19 2.077 2.19s2.077-.983 2.077-2.19c0-1.207-.932-2.189-2.077-2.189s-2.077.982-2.077 2.19ZM2.54 15.08c-1.35 0-2.454-1.11-2.534-2.511L0 12.405v-9.73C0 1.257 1.058.09 2.384.006L2.54 0H15.46c1.35 0 2.454 1.111 2.534 2.512l.005.164v9.73c0 .954-.485 1.836-1.27 2.314a.673.673 0 0 1-.945-.267.757.757 0 0 1 .175-.942l.079-.056a1.22 1.22 0 0 0 .568-.908l.008-.142v-9.73c0-.63-.454-1.147-1.036-1.21l-.118-.005H2.54c-.594 0-1.088.482-1.148 1.092l-.006.124v9.729c0 .63.453 1.147 1.036 1.209l.118.007h4.615c.382 0 .692.327.692.73 0 .369-.26.674-.598.723l-.094.006H2.539Zm.923-3.89c-.383 0-.693-.327-.693-.73 0-.37.26-.675.599-.723l.094-.007h1.846c.382 0 .692.326.692.73 0 .369-.26.674-.598.722l-.094.007H3.462Zm0-2.92c-.383 0-.693-.326-.693-.73 0-.369.26-.673.599-.722l.094-.007H6.23c.382 0 .692.327.692.73 0 .37-.26.675-.598.723l-.094.006H3.46Zm0-2.918c-.383 0-.693-.327-.693-.73 0-.37.26-.675.599-.723l.094-.007h11.076c.382 0 .692.328.692.73 0 .37-.26.675-.598.724l-.094.006H3.462Z" fill="currentColor" fillRule="evenodd"/>
        </svg>
    );
}

export default LegalMentionsLogo;