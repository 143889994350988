import { useEffect, useState } from "react";
import { getAuthTokenWithRefresh, getBackendApiUrl, getEasyPlvApiToken } from "../../config";
import {
	Button,
	Table,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { Notyf } from 'notyf';
import 'notyf/notyf.min.css';
import "./download_page.css";
import { useAuth } from "../../contexts/AuthContext";
import PrintConfirmation from "../Print/PrintConfirmation";
import { useTranslation } from "react-i18next";

// Nw Custom Filters
import { NwCommonFilter } from "../NwCustomFilters/NwCustomFilters";

function PrintOrderItemDownload() {
	const { t } = useTranslation();
	const apiKey = getEasyPlvApiToken();

	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [pageCount, setPageCount] = useState(0);
	const [itemsPerPage] = useState(12);
	const [totalItems, setTotalItems] = useState(0);
	const notyf = new Notyf({
		types: [
			{
				type: 'info',
				background: 'blue',
				icon: false
			} 
		]
	});
	const [currentPagePaginate,setCurrentPagePaginate] = useState(0);
	const { logoutUser, subscriptionData } = useAuth();

	// START PRINT CONFIRMATION
	const [printDataFromNotification, setPrintDataFromNotification] = useState();
	const [showPrintConfirmation, setShowPrintConfirmation] = useState(false);

	const closePrintConfirmation = () => {
			setShowPrintConfirmation(false);
	}

	const openPrintConfirmationWithPrintData = (printerData) => {
		setPrintDataFromNotification(prevPrintData => printerData);
		setShowPrintConfirmation(true);
	}
	// END PRINT CONFIRMATION

	let formatSelectData = [];
	const storedFormatData = localStorage.getItem("formatSelectData");
	if (storedFormatData === "undefined") {
		console.log("Format Data Empty");
	} else {
		formatSelectData = JSON.parse(storedFormatData);
	}

	useEffect(() => {
		async function getPrintOrderItemDownloads() {
			try {
				const authToken = await getAuthTokenWithRefresh();
				const response = await fetch(
					getBackendApiUrl("printOrderItemDownload"),
					{
						method: "GET",
						headers: {
							Authorization: `Bearer ${authToken}`,
							"X-EasyPLV-Api-Token": apiKey,
						},
					}
				);
				if (response.status === 401) {
						logoutUser();
					return false;
				}
				const data = await response.json();
				setData(data.items);

				setTotalItems(data.itemsCount);
				setPageCount(Math.ceil(data.itemsCount / itemsPerPage));

				setLoading(false);
			} catch (error) {
				console.error("Error fetching data: ", error);
				setLoading(false);
			}
		}

		getPrintOrderItemDownloads();
	}, []);

 

	const handlePageClick = async (data) => {
		let currentPage = data.selected + 1;
		const downloads = await fetchDownloads(currentPage);

		setData(downloads.items);
		setTotalItems(downloads.itemsCount);
		setPageCount(Math.ceil(totalItems / itemsPerPage));

		window.scrollTo(0, 0);
	};

	const fetchDownloads = async (currentPage) => {
		const authToken = await getAuthTokenWithRefresh();
		const res = await fetch(
			getBackendApiUrl(
				`printOrderItemDownload?page=${currentPage}&itemsPerPage=${itemsPerPage}`
			),
			{
				method: "GET",
				headers: {
					Authorization: `Bearer ${authToken}`,
					"X-EasyPLV-Api-Token": apiKey,
				},
			}
		);

		const data = await res.json();
		return data;
	};

	const handleFilterSubmit = async (e) => {
		e.preventDefault();

		let queryString = "";

		const searchValue = e.target.search.value;
		const parVitesseValue = e.target.format.value;
		

		if (searchValue) {
			queryString += `name=${searchValue}`;
		}

		if (parVitesseValue) {
			if (queryString) {
				queryString += `&format=${parVitesseValue}`;
			} else {
				queryString += `format=${parVitesseValue}`;
			}
		}


		const filteredDownloads = await filterDownload(queryString);
		setData(filteredDownloads.items);


		setTotalItems(filteredDownloads.itemsCount);
		

		setPageCount(Math.ceil(totalItems / itemsPerPage));
	};

	const filterDownload = async (queryString) => {
		const authToken = await getAuthTokenWithRefresh();
		const res = await fetch(
				getBackendApiUrl(
					`printOrderItemDownload?${queryString}`
				),
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${authToken}`,
						"X-EasyPLV-Api-Token": apiKey,
					},
				}
			);
	
			const data = await res.json();
			return data;
	}

	const handleResetFilter = async (e) => {
		let currentPage = 1;
		setCurrentPagePaginate(0);
		// console.log(currentPage);
		const downloads = await fetchDownloads(currentPage);

		// //console.log(commentsFormServer);
		setData(downloads.items);
		setTotalItems(downloads.itemsCount);
		setPageCount(Math.ceil(downloads.itemsCount / itemsPerPage));
		// scroll to the top
		window.scrollTo(0, 0);
		notyf.success('Filter Reset');
	};

	return (
		<div className="px-5 pt-3">
			<div className="container">
				<NwCommonFilter
					onSubmit={handleFilterSubmit}
					className= "w-100 "
					autoSubmitOnReset
					filterContainerProps={{
						className: 'mt-3 mb-3'
					}}
					filterWrapperProps={{
							className: "w-100 justify-content-around"
					}}
					filterButtonProps={{
							type: 'submit',
							children: t('validateButtonLabel')
					}}
					resetButtonProps={{
							children: t('resetFilterButton')
					}}
					filterModalProps={{
						headerContent: t('downloadsPage.filterModalHeader')
					}}
					formFields={[
						{
							type: 'input',
							wrapperProps: {
									sm: 3
							},
							props: {
								controlId: "search-by-name-filter",
								name: 'search',
								placeholder: t("postersPage.topBox.searchBarLabel"),
								hideLabel: true
							}
						},
						{
							type: 'select',
							wrapperProps: {
									sm: 3
							},
							props: {
								id: "department-select",
								options: formatSelectData,
								isDisabled: !formatSelectData?.count > 1,
								name: "format",
								placeholder: t("formatDropdownLabel"),
								isClearable: true,
							}
						}
					]}
				/>
				{showPrintConfirmation ? <PrintConfirmation show={showPrintConfirmation} close={closePrintConfirmation} printData={printDataFromNotification}/> : <></> }
				<br></br>
				{data?.length > 0 ? (
				<Table striped bordered hover>
					<thead>
						<tr>
							<th>{t("messagesPage.messagesTable.idTableHeading")} </th>
							{/* <th>{t("downloadsPage.middleSection.downloadstable.operationNameTableHeading")} </th> */}
							<th>{t("downloadsPage.middleSection.downloadstable.formatTableHeading")}</th>
							<th>{t("downloadsPage.middleSection.downloadstable.printerOutFormatTableHeading")}</th>
							<th>{t("downloadsPage.middleSection.downloadstable.typeTableHeading")}</th>
							<th>{t("downloadsPage.middleSection.downloadstable.dateTabelHeading")}</th>
							<th>{t("downloadsPage.middleSection.downloadstable.downloadTableHeading")}</th>
							{
								subscriptionData?.subscriptionRights.allowPrint && (
									<th>{t("downloadsPage.middleSection.downloadstable.printTableHeading")}</th>
								)
							}
						</tr>
					</thead>
					<tbody>
						{data?.length > 0 && data.map((item) => (
							<tr key={item.id}>
								<td>{item.id}</td>
								{/* <td>{item.operationName}</td> */}
								<td>{item.format_name} <br></br><small>[{item.format_code}]</small></td>
								<td>{item?.printerOutputFormatName} <br></br><small>[{item?.printerOutputFormat}]</small></td>
								<td>{item.type}</td>
								<td>{item?.date_created ? (new Date(item.date_created)).toLocaleString() : "-"}</td>
								<td>                  
									<a
										href={
											item?.file_location && (
												typeof item.file_location === 'string' ? JSON.parse(item.file_location).cdnUrl : item.file_location.cdnUrl
											)
										}
										target="_blank"
										rel="noopener noreferrer"
									>
										<Button variant="info" disabled={item.file_location === null}>
										{t("downloadsPage.middleSection.downloadstable.downloadTableHeading")}
										</Button>
									</a>
								</td>
								{
									subscriptionData?.subscriptionRights.allowPrint && (
										<td>
											{
												item.type === "zip" ? <></> : (<Button disabled={item.file_location===null} onClick={() => openPrintConfirmationWithPrintData(item.otherDataJson)}>Print</Button>)
											}
										</td>
									)
								}								
							</tr>
						))}
					</tbody>
				</Table>
			) : (
				<p>{t("downloadsPage.middleSection.no_downloads_available")}</p>
			)}

			<br></br>
			<br></br>
			{totalItems > itemsPerPage && (
				<ReactPaginate
					previousLabel={"previous"}
					nextLabel={"next"}
					breakLabel={"..."}
					pageCount={pageCount}
					forcePage={currentPagePaginate}
					marginPagesDisplayed={2}
					pageRangeDisplayed={3}
					onPageChange={handlePageClick}
					containerClassName={"pagination justify-content-center"}
					pageClassName={"page-item"}
					pageLinkClassName={"page-link"}
					previousClassName={"page-item"}
					previousLinkClassName={"page-link"}
					nextClassName={"page-item"}
					nextLinkClassName={"page-link"}
					breakClassName={"page-item"}
					breakLinkClassName={"page-link"}
					activeClassName={"active"}
				/>
			)}
		</div>
	</div>
	);
}

export default PrintOrderItemDownload;
