import { useEffect } from 'react';
import Select from 'react-select'
import { Form } from 'react-bootstrap';
import NwCustomSelect from '../_utilityComponents/NwFormsUi/CustomSelect/NwCustomSelect';

function CollectionType(props) {
    const isDisabled = props?.isDisabled || false;
    const formFieldJson = props.formFieldJson;
	const entityFormValuesJson = props.entityFormValuesJson;
	const updateEntityFormValues = props.updateEntityFormValues;
	const configValues = formFieldJson.configValues;
    const metadata = configValues?.values?.metadata;
    const translatedConfigValues = formFieldJson.translatedConfigValues.values;
    const translatedFormattedChoices = translatedConfigValues.choices;
    const collectionValueKey = `${configValues.frontUniqueKeys.select1}_${formFieldJson.id}`;
    const collectionValueJson = `${collectionValueKey}` in entityFormValuesJson
    ? entityFormValuesJson[`${collectionValueKey}`]
    : {
        name: formFieldJson.name,
        value : configValues.values.default,
        type : 'collection',
        metadata: metadata,
    }
    const choices = configValues.values.parsedChoices;
    choices.push(configValues.values.default);
    const distinctChoices =[...new Set(choices)].map(choice => {
        return {value: choice, label: translatedFormattedChoices[`${choice}`]};
    });

    useEffect(() => {
        updateCollectionValue({value: collectionValueJson.value});
    }, []);

    const updateCollectionValue = (newValue) => {
		updateEntityFormValues(prevFieldValuesJson => {
            const newFieldValuesJson = {...prevFieldValuesJson};
            collectionValueJson.value = newValue.value;
            newFieldValuesJson[`${collectionValueKey}`] = collectionValueJson;
            return newFieldValuesJson;
        });
    }

    return (
        <>
            <Form.Label>{formFieldJson.title}</Form.Label>
            <NwCustomSelect isDisabled={isDisabled} className={'text-black'} onChange={(newValue) => updateCollectionValue(newValue)} options={distinctChoices} value={{value: collectionValueJson.value, label: translatedFormattedChoices[`${collectionValueJson.value}`]}}/>
        </>
    )
}

export default CollectionType;