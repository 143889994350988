import { Form, Button } from 'react-bootstrap';
import { Search } from 'react-bootstrap-icons';
import { NwLoaderSpinner } from '../../NwIcons/NwIcons';
import './NwCustomInput.css';

/**
 * @typedef {Object} NwCustomInputProps
 * @property {boolean} [placeholderIsLabel=false]
 * @property {string} [label='']
 * @property {string} [className='']
 * @property {string} [labelClassName='']
 * @property {number} [controlId=0]
 * @property {boolean} [hideLabel=false]
 * @property {string} [inputWrapperClassName='']
 * @property {boolean} [disabled=false]
 * @property {boolean} [isSearch=false]
 * @property {string} [as='']
 * @property {boolean} [noBar=false]
 * @property {string} [inputClassName='']
 * @property {string} [type='text']
 * @property {string} [placeholder='']
 * @property {string | number | null} [value=null]
 * @property {Object} [style={}]
 * @property {(e: any) => void | null} [onChange]
 * @property {(e: any) => void | null} [onInput=null]
 * @property {string} [name='']
 * @property {boolean} [isLoading=false]
 * @property {(e: any) => void | Promise<void> | null} [buttonOnClick=null]
 * @property {boolean} [buttonIsDisabled=false]
 */

/**
 * @param {NwCustomInputProps} props
 */
const NwCustomInput = ({
    placeholderIsLabel = false,
    label = '',
    className = '',
    labelClassName = '',
    controlId = '',
    hideLabel = false,
    inputWrapperClassName = '',
    disabled = false,
    isSearch = false,
    as = 'input',
    noBar = false,
    inputClassName = '',
    type = 'text',
    placeholder = '',
    value = null,
    style = {},
    onChange,
    onInput = null,
    name = '',
    isLoading = false,
    buttonOnClick = null,
    buttonIsDisabled = false,
    debug = false
}) => {
    if (debug) {
        console.log("Value", value);
    }

    return (
        <Form.Group className={`w-100 ${className ?? ''}`} controlId={controlId ?? ''}>
            {
                hideLabel ? (<></>) : (<Form.Label className={`px-2 pt-2 ${labelClassName ?? 'text-white'}`}>{ label ?? '' }</Form.Label>)
            }

            <div className="d-flex bg-white rounded-2 overflow-hidden">
                <div className={`nw-custom-input-main-wrapper ${ inputWrapperClassName ?? '' } ${disabled ? 'bg-body-secondary' : ''}`}>
                    <Form.Control
                        as={as}
                        className={
                            `
                            nw-custom-input
                            ${isSearch ? 'search-enabled' : ''}
                            ${as === "textarea" ? 'is-text-area' : ''}
                            ${noBar ? 'no-bar' : ''}
                            ${inputClassName ?? ''}
							${disabled && 'bg-body-secondary'}
                            `
                        }
                        type={type ?? 'text'}
                        placeholder={ 
                            placeholderIsLabel ? (label ? label : (type == "number" && isNaN(placeholder)) ? label : placeholder) : 
                                (type == "number" && isNaN(placeholder)) ? label : placeholder
                            }
                        value={
                            value ? (type == "number" && isNaN(value)) ? null : value 
                            : ''
                        }
                        style={style}
						disabled={disabled}
                        onChange={onChange}
                        onInput={onInput}
                        name={name}
                    />
                </div>

                { isSearch && (
                    <Button className={`nw-custom-input-search-button nw-bg-accent-1 border-0 ${isLoading ? 'isLoading' : ''} rounded-0`} onClick={buttonOnClick} disabled={buttonIsDisabled}>
                        {isLoading ? <NwLoaderSpinner isSpinner size={30}/> : <Search size={20}/> }
                    </Button>
                )}
            </div>
        </Form.Group>
    );
}

export default NwCustomInput;