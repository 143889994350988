import NwCustomCheckbox from "../../../_utilityComponents/NwFormsUi/CustomCheckbox/NwCustomCheckbox";

const LegalMentionsItem = ({ item, onClick, isSelected, isDisabled })=> {
	
	const handleClick = () => {
		if (!isDisabled){
			onClick(item);
		}
	};

	const handlePositionChange = (e) => {
		item.position = parseInt(e.target.value);
	}

	const handleForcedContentChange = (e) => {
		if (e.target.value !== "") {
			item.forcedContent = e.target.value;
		} else {
			if ('forcedContent' in item) delete item.forcedContent;
		}
	}
	
	return (
		<div onClick={handleClick} 
			className={`legal-mentions-item-main-container d-flex justify-content-start align-items-center ${isDisabled ? 'border-secondary' : ''} border-top border-bottom p-3`}
		>
			<NwCustomCheckbox initialIsCheck={isSelected} variant="primary" isDisabled={isDisabled}/>
			<div className={`legal-mentions-item-wrapper px-2 text-start`}> 
				{item?.content}
			</div>
			<input type="hidden" onChange={e => handlePositionChange(e)} value={item?.position} />
			<input type="hidden" onChange={e => handleForcedContentChange(e)} value={item?.forcedContent} />
		</div>
	);
}

export default LegalMentionsItem;