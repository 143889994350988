import React, { useEffect, useState, useRef } from 'react';
import NwCustomInput from '../../_utilityComponents/NwFormsUi/CustomInput/NwCustomInput';
import NwCustomDropdown from '../../_utilityComponents/NwFormsUi/CustomDropdown/NwCustomDropdown';
import PreviewListItem from './PreviewListItem';
import { Button } from 'react-bootstrap';
import { getSortingDisplayFromEventKey, mergeDataWithoutDuplicates, filterData, SORTING_TYPE } from '../../../helpers/helpers';
import { Plus } from 'react-bootstrap-icons';
import { NwLoaderSpinner } from '../../_utilityComponents/NwIcons/NwIcons';
import { useTranslation } from 'react-i18next';
import { IconCalendarDown, IconCalendarUp, IconSortAscendingLetters, IconSortDescendingLetters } from '@tabler/icons-react';
import './PreviewList.css'

/**
 * PreviewList component for displaying a list of items with filtering, sorting, and searching capabilities.
 * 
 * @component
 * 
 * @param {Object} props - The props for the component.
 * @param {Array} props.listData - The data to display in the list.
 * @param {Function} [props.getListData] - Callback function to retrieve list data.
 * @param {Function} [props.bodyDataUpdator] - Callback function to update body data.
 * @param {Function} [props.getCurrentSelected] - Callback function to get the currently selected item.
 * @param {Function} [props.updateListData] - Callback function to update the list data state in the parent component.
 * @param {Function} [props.fetchMoreData] - Function to fetch more data when the user requests more.
 * @param {Function} [props.fetchSearchData] - Function to fetch data based on the search input.
 * @param {Function} [props.handleSortData] - Function to sort the list data based on selected criteria.
 * @param {Object} [props.bodyData] - Data to send with fetch requests, including options and filters.
 * @param {Object} [props.initialSelection] - Initial selected item in the list.
 * @param {boolean} [props.hideMoreButton=false] - Flag to hide the "Load More" button.
 * @param {boolean} [props.allowDeselection=false] - Flag to allow deselection of the current item.
 * @param {boolean} [props.hasFilter=false] - Flag to show filter options.
 * @param {boolean} [props.hideSearch=false] - Flag to hide the search input field.
 * @param {boolean} [props.isDisabled=false] - Flag to disable interactions with the list.
 * @param {boolean} [props.textCenter=false] - Flag to center the text in the list items.
 * @param {boolean} [props.debug=false] - Flag to enable debug mode, showing additional information.
 * @param {string} [props.className] - Additional class names for the main container.
 * @param {Object} [props.style] - Inline styles for the main container.
 * 
 * @example
 * <PreviewList
 *   listData={[{ id: 1, name: 'Item 1' }, { id: 2, name: 'Item 2' }]}
 *   getListData={fetchListData}
 *   updateListData={updateListState}
 *   fetchMoreData={fetchMoreItems}
 *   fetchSearchData={searchItems}
 *   handleSortData={sortItems}
 *   bodyData={bodyData}
 *   initialSelection={initialItem}
 *   hideMoreButton={false}
 *   allowDeselection={true}
 *   hasFilter={true}
 *   hideSearch={false}
 *   isDisabled={false}
 *   textCenter={true}
 *   debug={false}
 *   className="custom-preview-list"
 *   style={{ backgroundColor: '#f0f0f0' }}
 * />
 * 
 * @returns {JSX.Element} The rendered PreviewList component.
*/

const PreviewList = (props) => {
    const { t } = useTranslation();
    const [currentSelected, setCurrentSelected] = useState(null);
    const [selectedSortingAlphabet, setSelectedSortingAlphabet] = useState(getSortingDisplayFromEventKey("ASC"));
    const [selectedSortingDate, setSelectedSortingDate] = useState(getSortingDisplayFromEventKey("", SORTING_TYPE.DATE));
    const [currentListData, setCurrentListData] = useState(props.listData);
    const [currentPage, setCurrentPage] = useState(1);
	const [isFetchingMore, setIsFetchingMore] = useState(false);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');
    const hideMoreButton = props?.hideMoreButton ? props.hideMoreButton : false;
    const allowDeselection = props?.allowDeselection ?? false;

	const getListData = props.getListData ?? null;
	const bodyDataUpdator = props.bodyDataUpdator ?? null;

    const isDisabled = props?.isDisabled || false;

    const childRef = useRef(null);

    useEffect(() => {
      const childElement = childRef.current;
  
      const handleWheel = (event) => {
        const { scrollTop, scrollHeight, clientHeight } = childElement;
        const delta = event.deltaY;
  
        // Prevent scrolling up when already at the top
        if (scrollTop === 0 && delta < 0) {
          event.preventDefault();
          event.stopPropagation();
        }
        // Prevent scrolling down when already at the bottom
        if (scrollTop + clientHeight >= scrollHeight && delta > 0) {
          event.preventDefault();
          event.stopPropagation();
        }
      };
  
      // Add event listener for wheel events
      childElement.addEventListener('wheel', handleWheel, { passive: false });
  
      // Clean up the event listener when the component is unmounted
      return () => {
        childElement.removeEventListener('wheel', handleWheel);
      };
    }, []);

    // Use Effect to set currentListData state
	useEffect(() => {
        setIsFetchingMore(true);
		if (props.listData) {
            setIsFetchingMore(false);
            if (searchTerm) {
                setCurrentListData(filterData(props.listData, searchTerm));
            } else {
                setCurrentListData(props.listData);
            }
		}
	}, [props.listData])

    // useEffect called when state current Selected has changed and to return value of current Select
    useEffect(() => {
        if (props.getCurrentSelected) {
            props.getCurrentSelected(currentSelected);
        }
    }, [currentSelected]);

    //If there is an initial selection, add it to the list without causing a duplicate,
    //Ensures Selected Template Group always in list
    useEffect(() => {
        if (props.initialSelection && props.listData){
            setCurrentSelected(props.initialSelection)
            const combinedData = mergeDataWithoutDuplicates(props.listData, [props.initialSelection], true);
            updateListData(combinedData);
        }
    }, [props.initialSelection])

    // useEffect called when state current page changes
    useEffect(() => {
		const fetchMoreData = async (currentPage) => {		
			if (props.fetchMoreData && props.bodyData)
			{
				const newData = await props.fetchMoreData(
					currentPage,
					props.bodyData,
					getListData,
					bodyDataUpdator
				);
				if (newData && newData.length > 0)
                {

                    const combinedData = mergeDataWithoutDuplicates(props.listData, newData);

                    // Update the parent state with the combined data
                    updateListData(combinedData);
                    return newData;
                }
			}
		}

		if (currentPage > 1 && hasMoreData) {
            // Fetch more data only if current page is > 1 
            // and state hasMoreData is true
			fetchMoreData(currentPage).then((newData) => {
                setIsFetchingMore(false);
                if (newData){
                    if (newData?.length < props.bodyData?.options?.itemsPerPage)
                    {
                        //If newData is less than the items per page when fetching means that
                        // there will be no more data
                        setHasMoreData(false);
                    }
                } else {
                    setHasMoreData(false);
                }
            });
		} else {
            setIsFetchingMore(false);
        }
    }, [currentPage])
    
    // ON Preview list item selected, set state currentSelected
    const handleItemSelect = (item) => {
        if (!isDisabled)
        {
            if (allowDeselection) {
                if (currentSelected) {
                    if (item?.id === currentSelected?.id) {
                        setCurrentSelected(null);
                        return;
                    }
                } 
            }

            setCurrentSelected(item);
        }
    }

    // Used when typing in Search bar
    const handleSearchInput = async (event) => {
        if (!isDisabled)
        {
            const inputValue = event.target.value;
            setSearchTerm(inputValue);

            if (inputValue.trim() === '') {
                setCurrentListData(props.listData);
                return;
            }

            // Filter existing data
            const filteredData = filterData(props.listData, inputValue);
            setCurrentListData(filteredData);
            
            // If a fetch function is provided, use it to fetch new data
            if (props.fetchSearchData) {
                try {
                    const newData = await props.fetchSearchData(
                        inputValue,
                        props.bodyData,
                        getListData,
                        bodyDataUpdator
                    );
                    // Combine local and fetched data without duplicates
                    
                    if (newData && newData.length > 0)
                    {
                        const combinedData = mergeDataWithoutDuplicates(props.listData, newData);

                        // Update the parent state with the combined data
                        updateListData(combinedData);
                    }
                } catch (error) {
                    console.error('Error fetching search data:', error);
                    // Optionally handle the error state
                }
            }
        }
    }

    // Used to handle when load more button is clicked
    const handleLoadMoreData = () => {
        if (hasMoreData){
            setIsFetchingMore(true);
            setCurrentPage(prevPage => prevPage + 1);
        }
        
    }

    const generateSortingDropdownItems = () => {
        return {
            name: [
                {
                    eventKey: 'ASC',
                    displayText: <IconSortAscendingLetters size={'1.5em'}/>
                },
                {
                    eventKey: 'DESC',
                    displayText: <IconSortDescendingLetters size={'1.5em'}/>
                }
            ],
            date: [
                {
                    eventKey: 'DESC',
                    displayText: <IconCalendarDown size={'1.5em'}/>
                },
                {
                    eventKey: 'ASC',
                    displayText: <IconCalendarUp size={'1.5em'}/>
                }
            ]
        };
    }

    const handleSortingSelectedAlphabet = async (eventKey) => {
		resetState('page', 1);
        setSelectedSortingAlphabet(getSortingDisplayFromEventKey(eventKey));
        const sortedListData = await props.handleSortData(
			eventKey,
			SORTING_TYPE.NAME,
			resetBodyDataValue(props.bodyData, 'page', 1),
			getListData,
			bodyDataUpdator
		);
        updateListData(sortedListData);
    }

    const handleSortingSelectedDate = async (eventKey) => {
		resetState('page', 1);
        setSelectedSortingDate(getSortingDisplayFromEventKey(eventKey, SORTING_TYPE.DATE));
        const sortedListData = await props.handleSortData(
			eventKey,
			SORTING_TYPE.DATE,
			resetBodyDataValue(props.bodyData, 'page', 1),
			getListData,
			bodyDataUpdator
		);
        updateListData(sortedListData);
    }

    // used to update list data
    const updateListData = (listData) => {
        props.updateListData(listData);
    }

    // used to reset the state of the current page
	const resetState = (state, value) => {
		if (state == 'page'){
			setCurrentPage(value);
		}
	}

    // Called with arguments, bodyData : current bodyData
    // key: the key element to change
    // value: the value for the key element to replace
	const resetBodyDataValue = (bodyData, key, value) => {
		if (bodyData.options.hasOwnProperty(key)) {
			// Reset value in options
			return {
				...bodyData,
				options: {
					...bodyData.options,
					[key]: value
				}
			};
		} else if (bodyData.filters.hasOwnProperty(key)) {
			// Reset value in filters
			return {
				...bodyData,
				filters: {
					...bodyData.filters,
					[key]: value
				}
			};
		} else {
			// Key not found, return original bodyData
			return bodyData;
		}
	}


    return (
        <div className={`preview-list-main-container ${isDisabled ? 'isDisabled' : ''} ${props.debug ? 'is-debug' : ''} ${ props.className ?? '' }`} style={props.style}>
            <div className={`preview-list-search-and-filter-container ${props?.hideSearch ? 'hide-search' : ''} ${props.hasFilter ? 'has-filter' : ''}`}>
                {props?.hideSearch ? <></> : (
                    <NwCustomInput 
                        hideLabel 
                        placeholder={'Rechercher'}
                        onChange={handleSearchInput}
                        value={searchTerm}
                    />
                )}
                {props.hasFilter && 
                    <div className="preview-list-filter-wrapper nw-responsive-font-size">
                        {/* FILTER COMPONENTS HERE */}
                        <span className='w-40 overflow-hidden nw-responsive-font-size-sm' style={{
                            color: '#414141',
                        }}>{t('previewList.sortBy')} : </span>
                        <NwCustomDropdown
                            onSelect={handleSortingSelectedAlphabet}
                            currentValue={selectedSortingAlphabet}
                            dropdownItems={generateSortingDropdownItems().name}
                            title={t('previewList.alphabeticalOrder')}
                            dropdownToggleProps={{
                                className: 'px-1'
                            }}
                        />
                        <NwCustomDropdown
                            onSelect={handleSortingSelectedDate}
                            currentValue={selectedSortingDate}
                            dropdownItems={generateSortingDropdownItems().date}
                            title={t('previewList.dateCreation')}
                            dropdownToggleProps={{
                                className: 'px-1'
                            }}
                        />
                    </div>
                }
            </div>
            <div ref={childRef} className={`preview-list-main-wrapper ${props.hasFilter ? 'has-filter' : ''}`}>
                <div className="preview-list">
                    {/* PREVIEW ITEMS HERE */}
                    {
                        currentListData?.map(item => 
                            <PreviewListItem
                                key={item?.id}
                                imageSrc={item?.thumbnailPreviewUrl}
                                onClick={() => handleItemSelect(item)}
                                isSelected={currentSelected?.id === item?.id}
                                text={item?.name}
                                textCenter={props.textCenter ?? false}
                            />
                        )
                    }
                </div>
                <div className="preview-list-buttons-container w-100 d-flex justify-content-center my-2">
                    { 
                    !hideMoreButton && 
                        <Button onClick={handleLoadMoreData} disabled={isFetchingMore || !hasMoreData || isDisabled} className="preview-list-add-more nw-bg-accent-1 border-0 rounded-2 p-1 d-flex align-items-center justify-content-center">
                            {isFetchingMore ? <NwLoaderSpinner size={30} isSpinner/> : <Plus size={30}/>}
                        </Button>
                    }
                </div>
            </div>
        </div>
    );
}

export default PreviewList;