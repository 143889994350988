import { useEffect } from 'react';
import { useState } from 'react';
import { Form, FormLabel } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { connectToQzTrayPrintServer, findAllAvailablePrinters, printPdfUsingPrinter } from '../../services/print';
import { useTranslation } from 'react-i18next';
import NwCustomInput from '../_utilityComponents/NwFormsUi/CustomInput/NwCustomInput';
import NwCustomSelect from '../_utilityComponents/NwFormsUi/CustomSelect/NwCustomSelect';

function PrintConfirmation(props) {
    const { t } = useTranslation()
    const show = props.show
    const close = props.close;
    const [printData, setPrintData] = useState();
    const [selectedPrinter, setSelectedPrinter] = useState(null);
    const [availablePrinters, setAvailablePrinters] = useState([]);
    const [printQuantity, setPrintQuantity] = useState(1);

    useEffect(() => {
        setPrintData(prevPrintData => props.printData);
        if (printData) {
            console.log(printData);
            setSelectedPrinter(props.printData.printersInfo.selectedPrinter)
        }

        async function findAndSetAvailablePrinters() {
            findAllAvailablePrinters().then(printersFound => setAvailablePrinters(printersFound ?? []));
        };

        if (props.show) findAndSetAvailablePrinters();
    }, [props.printData, printData, props.show]);

    // useEffect(() => {
    //     if (show) {
    //         if (printData) {
    //             if (!availablePrinters.includes(selectedPrinter)) {
    //                 const otherPrinters = printData.printersInfo.otherPrinters.map(otherPrinter => otherPrinter.printerName);
    //                 for (const otherPrinter of otherPrinters) {
    //                     if (availablePrinters.includes(otherPrinter)) {
    //                         setSelectedPrinter(otherPrinter);
    //                         return;
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // }, [availablePrinters, selectedPrinter, printData, show]);

    const startPrint = async () => {
        if (selectedPrinter !== "" && selectedPrinter !== undefined) {
            await connectToQzTrayPrintServer();
            printPdfUsingPrinter(printData.pdfFileInfo.cdnUrl, selectedPrinter?.printerName, printQuantity, t("print.fail"), t("print.start"));
        }
        close()
    }

    return (
        <> 
        {
            printData ? 
            <Modal
                show={show}
                onHide={close}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        {t("printConfirmation.title")}
                        {JSON.stringify(selectedPrinter)}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mb-3'>
                        <span>{t('printConfirmation.file')}: {printData.pdfFileInfo.filename}</span><br></br>
                    </div>
                    <div className='ms-2'>
                    <FormLabel>{t('printConfirmation.printer')} : </FormLabel>  
                    </div>
                    <NwCustomSelect value={selectedPrinter !== null ? {label: (selectedPrinter?.printerDisplayName ?? "") + "(" + selectedPrinter?.printerName + ")", value: selectedPrinter?.printerName} : {}} options={printData.printersInfo.otherPrinters.map((printer, index) => {
                                if (printer) {
                                    return {
                                        label: (printer?.printerDisplayName ?? "") + "(" + printer?.printerName + ")",
                                        value: (printer?.printerName ?? ""),
                                        isDisabled: !availablePrinters.includes(printer?.printerName)
                                    }
                                } else {
                                    return {}
                                }
                            }) }/>
                    
                    <NwCustomInput labelClassName="text-black" label={t('printConfirmation.quantity')} min="1" onChange={(e)=>setPrintQuantity(e.target.value)} value={printQuantity} type="number"/>
                    <button onClick={startPrint} className="btn btn-primary rounded-1 mt-4" type="submit">{t('printConfirmation.printcta')}</button>
                </Modal.Body>
            </Modal> : <></>
        }
        </>
    )
}

export default PrintConfirmation;