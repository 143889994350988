import React, { useRef, useEffect, useState } from 'react'
import { XLg, CloudDownload } from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';
import './NwCustomModal.css'

/**
 * This code defines a React functional component called `NwCustomModal`. The component takes several
 * props including `title`, `hasFooter`, `customButton`, `headerContent`, `children`, `footerContent`,
 * `customHeaderContent`, `closeModal`, `onShow`, `onClose`, `modalProps`, and `openDialogCustomCallback`.
 * 
 * @param {Object} props - The props for the component.
 * @param {string} [props.title='Importer une Image'] - The title of the modal, used as the button text if `customButton` is not provided.
 * @param {boolean} [props.hasFooter] - Flag to determine if the footer should be displayed.
 * @param {React.ReactElement} [props.customButton] - Custom button element to open the modal.
 * @param {React.ReactNode} [props.headerContent] - Content to be displayed in the modal header.
 * @param {Object} [props.defaultButtonProps] - Props for the default button if `customButton` is not provided.
 * @param {boolean} [props.defaultButtonProps.disabled] - Flag to disable the default button.
 * @param {string} [props.defaultButtonProps.className] - Additional class names for the default button.
 * @param {Object} [props.defaultButtonProps.style] - Inline styles for the default button.
 * @param {React.ReactNode} [props.defaultButtonProps.icon] - Custom icon for the default button.
 * @param {number} [props.defaultButtonProps.defaultIconSize=20] - Default icon size for the default button.
 * @param {React.ReactNode} props.children - Content to be displayed in the modal body.
 * @param {React.ReactNode} [props.footerContent] - Content to be displayed in the modal footer.
 * @param {React.ReactNode} [props.customHeaderContent] - Custom content to replace the default header.
 * @param {boolean} [props.closeModal] - Prop to externally control the closing of the modal.
 * @param {Function} [props.onShow] - Callback function to be called when the modal is shown.
 * @param {Function} [props.onClose] - Callback function to be called when the modal is closed.
 * @param {Object} [props.modalProps] - Additional props for customizing the modal.
 * @param {string} [props.modalProps.className] - Additional class names for the modal dialog.
 * @param {Object} [props.modalProps.style] - Inline styles for the modal dialog.
 * @param {Object} [props.modalProps.closeBtn] - Props for customizing the close button.
 * @param {string} [props.modalProps.closeBtn.className] - Additional class names for the close button.
 * @param {React.ReactNode} [props.modalProps.closeBtn.icon] - Custom icon for the close button.
 * @param {number} [props.modalProps.closeBtn.iconSize=15] - Icon size for the close button.
 * @param {Object} [props.modalProps.body] - Props for customizing the modal body.
 * @param {string} [props.modalProps.body.className] - Additional class names for the modal body.
 * @param {Object} [props.modalProps.body.style] - Inline styles for the modal body.
 * @param {Function} [props.openDialogCustomCallback] - Callback function to be executed when the dialog is opened.
 * 
 * @returns {JSX.Element} The rendered modal component.
 */
const NwCustomModal = ({ 
	title = 'Importer une Image', 
	hasFooter, 
	customButton, 
	headerContent,
	defaultButtonProps,
	children, 
	footerContent,
	customHeaderContent,
	closeModal,
	onShow,
	onClose,
	modalProps,
    openDialogCustomCallback = () => true

}) => {
	const nwDialogRef = useRef(null);
	const [isDialogOpen, setDialogOpen] = useState(false);
	
	const openDialog = () => {
		if(onShow)
		{
			onShow();
		}
		setDialogOpen(openDialogCustomCallback);
	}
	const closeDialog = () => 
	{
		if (onClose){
			onClose();
		}
		setDialogOpen(false);
	}
	useEffect(() => {
		if (isDialogOpen && nwDialogRef.current) {
			nwDialogRef.current.showModal();
		} else if (nwDialogRef.current) {
			nwDialogRef.current.close();
		}
	}, [isDialogOpen]);
	

	useEffect(()=>{
		closeDialog();
	},[closeModal])

	useEffect(() => {
		function handleEscKeyPress(event) {
		  if (event.keyCode === 27) { // Check if the pressed key is the Escape key
			closeDialog(); 
		  }
		}
	
		document.addEventListener('keydown', handleEscKeyPress);
	
		return () => {
		  document.removeEventListener('keydown', handleEscKeyPress);
		};
	  }, []);

	// Clone the customButton element and add the openDialog handler
	const renderCustomButton = () => {
		if (!customButton) return null;
		// Clone the element and inject the openDialog function as the onClick prop
		return React.cloneElement(customButton, { onClick: openDialog });
	};

	return (
		<>
			<dialog className={`nw-modal rounded-4 border-0 shadow-lg ${ isDialogOpen ? 'd-flex' : '' } ${modalProps?.className || ''}`} style={modalProps?.style || {}} ref={nwDialogRef}>
				{customHeaderContent ? (
					<div>
						{customHeaderContent}
					</div>
				) : (
					<div className="nw-modal-header d-flex justify-content-end pb-2">
						<div className="nw-modal-header-content-wrapper w-100 d-flex justify-content-start px-3">
							{headerContent}
						</div>
						<Button className={`nw-modal-close-btn rounded-circle p-1 d-flex justify-content-center align-items-center nw-bg-accent-1 border-0 ${modalProps?.closeBtn?.className}`} onClick={closeDialog}>
							{ modalProps?.closeBtn?.icon ?? (<XLg size={modalProps?.closeBtn?.iconSize ?? 15}/>) }
						</Button>
					</div>
				)}
				<div className={`nw-modal-body ${ modalProps?.body?.className || '' }`} style={modalProps?.body?.style || {} }>
					{children}
				</div>
				<div className={`nw-modal-footer ${hasFooter ? '' : 'd-none'}`}>
					{footerContent}
				</div>
			</dialog>

			{customButton ? renderCustomButton() : (
				<Button 
					disabled={defaultButtonProps?.disabled || false} 
					className={`nw-modal-open-btn w-100 rounded-1 text-start ${ defaultButtonProps?.className || '' } `} 
					onClick={openDialog}
					style={defaultButtonProps?.style || {}}
				>
					{ defaultButtonProps?.icon ? defaultButtonProps?.icon : <CloudDownload className='mx-2' size={ defaultButtonProps?.defaultIconSize ?? 20}/>}
					<span className='mx-2'>{title}</span>
				</Button>
			)}
		</>
	);
}

export default NwCustomModal;