import { useState, useEffect } from "react";
import { fetchEktatekMediaData } from "../../services/ektatek";
import EktatekModal from "./Modal/EktatekModal";
import ChosenImagesPreview from "./Preview/ChosenImagesPreview";

function EktatekMultipleType(props) {
    const isDisabled = props?.isDisabled || false;
    const formFieldJson = props.formFieldJson;
	const entityFormValuesJson = props.entityFormValuesJson;
	const updateEntityFormValues = props.updateEntityFormValues;
	const configValues = formFieldJson.configValues;
    const metadata = configValues?.values?.metadata;
    const galleriesChosen = configValues.values.galleries;
    const ektatekValueKey = `${configValues.frontUniqueKeys.checkboxgroup1}_${formFieldJson.id}`;
    const ektatekValueJson = `${ektatekValueKey}` in entityFormValuesJson 
	? entityFormValuesJson[`${ektatekValueKey}`]
	: {
		name: formFieldJson.name,
		type: 'ektatek_multiple',
		value: [],
        metadata: metadata,
	}

    const [ektatekMediaData, setEktatekMediaData] = useState({});
    const [chosenImages, setChosenImages] = useState([]);

    const handleImageSelection = (image) => {
        updateEntityFormValues(prevFieldValuesJson => {
            const newFieldValuesJson = {...prevFieldValuesJson};
            ektatekValueJson.value = [...(ektatekValueJson.value), image];
            newFieldValuesJson[`${ektatekValueKey}`] = ektatekValueJson;
            return newFieldValuesJson;
        });
        // setChosenImages([...chosenImages, image])
    }

    const handleRemoveImage = (index) => {
        // const newChosenImages = [...chosenImages];
        // newChosenImages.splice(index, 1);
        // setChosenImages(newChosenImages);
        let newChosenImages = [...(ektatekValueJson.value)];
        newChosenImages.splice(index, 1);

        updateEntityFormValues(prevFieldValuesJson => {
            const newFieldValuesJson = {...prevFieldValuesJson};
            ektatekValueJson.value = newChosenImages;
            newFieldValuesJson[`${ektatekValueKey}`] = ektatekValueJson;
            return newFieldValuesJson;
        });
    }

    useEffect(() => {
        if (galleriesChosen.length > 0) {
            const getEktatekImages = async () => {
                const ektatekMediaData = await fetchEktatekMediaData(galleriesChosen, 0);
                setEktatekMediaData({...ektatekMediaData});
            }
            getEktatekImages();
        }   
    }, [galleriesChosen]);

    // useEffect(() => {
    //     updateEntityFormValues(prevFieldValuesJson => {
    //         const newFieldValuesJson = {...prevFieldValuesJson};
    //         ektatekValueJson.value = [...chosenImages]
    //         newFieldValuesJson[`${ektatekValueKey}`] = ektatekValueJson;
    //         return newFieldValuesJson;
    //     });
    // }, [chosenImages]);

    useEffect(() => {
        updateEntityFormValues(prevFieldValuesJson => {
            const newFieldValuesJson = {...prevFieldValuesJson};
            newFieldValuesJson[`${ektatekValueKey}`] ={...ektatekValueJson};
            return newFieldValuesJson;
        });
    }, []);

    return (
        <div className="ektatek_multiple_main_container my-3 d-flex flex-column row-gap-3">
            <div className="form-field-json-title">
                {formFieldJson.title}
            </div>
            <div className="upload-images-btn w-100">
                <EktatekModal isDisabled={isDisabled} ektatekMediaData={ektatekMediaData} handleImageSelection={handleImageSelection} />
            </div>
            <ChosenImagesPreview isDisabled={isDisabled} images={ektatekValueJson.value} handleRemoveImage={handleRemoveImage}/>
        </div>
    )
}

export default EktatekMultipleType;