import React from 'react'
import NwCustomSelect from '../NwFormsUi/CustomSelect/NwCustomSelect';

const NwLangSwitcher = ({
    languageOptions,
    collapsedSideBar,
    wrapperProps,
    ...props

}) => {
    return (
        <div className={`language-switcher-main-wrapper ${collapsedSideBar ? 'p-2' : 'w-25'}`}>
            <NwCustomSelect
                {...props}
                options={languageOptions}
                closeMenuOnSelect={true}
                classNames={{
                    container: (state) => 'd-flex justify-content-center align-items-center',
                }}
                isDisabled={false}
                menuPlacement="top"
                hideDropdown
                isSearchable={false}
                components={{ DropdownIndicator:() => null, IndicatorSeparator:() => null }}
                uiOverrides={{
                    background: {
                        control: 'transparent'
                    },
                    width: {
                        valueContainer: '100%',
                        control: '2.5rem',
                        dropdown: '0%'
                    },
                    border: {
                        valueContainer: {
                            borderLeft: 'none'
                        },
                        control: {
                            borderBottom: 'none',
                            hover: {
                                borderBottom: 'none'
                            }
                        }
                    },
                    height: {
                        valueContainer: '100%'
                    },
                    option: {
                        padding: '12% 15%'
                    },
                    displaySpacing: {
                        value: '0rem',
                        valueContainer: '0rem'
                    }
                }}
            />
        </div>
    )
}

export default NwLangSwitcher;