import React, {useEffect, useState, useRef} from "react";
import Table from "react-bootstrap/Table";
import {getProductLocal, getProductLocalFiltered, deleteProductLocal} from "../../services/productLocal";
import ProductLocalFilter from "./ProductLocalFilter";
// import ReactPaginate from "react-paginate";
import ResponsivePagination from 'react-responsive-pagination';
import 'react-responsive-pagination/themes/classic.css';
import {useTranslation} from "react-i18next";
import {Plus} from "react-bootstrap-icons";
import {Link, useNavigate} from "react-router-dom";
import {Button} from "react-bootstrap";
import Swal from "sweetalert2";
import PlaceholderImage from "../../assets/images/no-image-md.png";
import {useAuth} from "../../contexts/AuthContext";


export default function ProductLocal() {
    const [posts, setPosts] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [postsPerPage, setPostsPerPage] = useState(25);
    const [selectedItem, setSelectedItem] = useState(null);
    // const [currentPagePaginate,setCurrentPagePaginate] = useState();
    const [changePageLoading, setChangePageLoading] = useState();
    const [reduceValue, setReduceValue] = useState();
    const [] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const {i18n, t} = useTranslation();
    const [filterQueryStr, setFilterQueryStr] = useState('');
    const [paginateCurrentPage, setCurrentPagePaginate] = useState(0);
    const {userNw, isAuthenticated} = useAuth();
    const navigate = useNavigate();

    const companyId = (isAuthenticated && userNw) ? userNw.companyId : null;

    const fetchData = async () => {
        setIsLoading(true);
        const res = await getProductLocal(1);
        setPosts(res.items);
        setTotalItems(res.totalItems);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchData();
        // setPageCount(Math.ceil(totalItems / postsPerPage));
        // console.log(pageCount);
    }, []);

    useEffect(() => {
    }, [posts]);

    useEffect(() => {
        if (paginateCurrentPage > 0) {
            handlePageClick(paginateCurrentPage);
        }
    }, [paginateCurrentPage]);

    useEffect(() => {
        //  console.log('totalItems: '+totalItems);
        setPageCount(Math.ceil(totalItems / postsPerPage));

        // console.log('pageCount: '+pageCount);
    }, [totalItems]);

    const getFilteredData = (data) => {
        // console.log(data);
        setPosts(data.items);
        setTotalItems(data.totalItems);
    };

    const getFilteredQueryString = (data) => {
        setFilterQueryStr(data);
    };

    const handlePageClick = async (data) => {
        let currentPage = data;
        setCurrentPagePaginate(data);
        setChangePageLoading(true);

        const page = `?options[page]=${currentPage}`;

        const res = await getProductLocalFiltered(page, filterQueryStr);

        setChangePageLoading(false);

        //console.log(commentsFormServer);
        setPosts(res.items);
        setReduceValue(reduceValue + 1);
        // scroll to the top
        window.scrollTo(0, 0);
    };

    const resetPaginationNumber = (data) => {
        setCurrentPagePaginate(data);
    };

    const handleDelete = (postId) => {
        Swal.fire({
            title: t("freePosters.freePosterSure"),
            text: t("freePosters.freePosterSureText"),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("freePosters.freePosterDeleteConfirm"),
            showLoaderOnConfirm: true, // Show a loader on confirm button after it's clicked
            preConfirm: () => {
                // Execute the async operation within preConfirm
                return deleteProductLocal(postId)
                    .then(response => {
                        // You can process your response here if needed

                        return response; // The response will be passed to the next then() block as result.value
                    })
                    .catch(error => {
                        // Handle errors
                        Swal.showValidationMessage(`Request failed: ${error}`);
                        // Re-throwing error here to prevent moving to the success Swal in case of failure
                        throw new Error(error);
                    });
            }
        }).then((result) => {
            // This block is executed if preConfirm resolved successfully and the user confirmed the action
            if (result.isConfirmed && result.value) {
                // Here, result.value contains the response from deleteProductLocal
                //console.log(result.value);
                Swal.fire(
                    t("freePosters.deleted"),
                    t("productLocalForm.productLocalDeleted"),
                    "success"
                ).then(() => {
                    navigate("/product-local");
                });
            }


        });
    };


    return (
        <div className="nw-main-local-product-list-container poster-list-container px-5 py-5">
            <div className="d-flex justify-content-between h-100 mb-5">
                <div className="w-75">
                    <ProductLocalFilter getFilteredData={getFilteredData}
                                        getFilteredQueryString={getFilteredQueryString}
                                        resetPaginationNumber={resetPaginationNumber}/>
                </div>
                <Link
                    to={`/product-local/form/new/0`}
                >
                    <Button variant="success" className="new-poster-btn text-nowrap h-100 px-3 rounded-1"
                            onClick={() => console.log("Primary")}>
                        <Plus size={25}/>
                        <span className="new-poster-button">
						{t('productLocalPage.newProductLocalButtonLabel')}
					</span>
                    </Button>
                </Link>
            </div>
            <br></br>
            <br></br>
            {isLoading ? (
                <div style={{display: "flex", alignItems: "center"}}>
			<span
                style={{marginRight: "10px"}} // Adjust spacing as needed
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
            />
                    <span>{t("loading")}</span>
                </div>
            ) : (
                <>

                    {posts?.length === 0 ? (
                        <p>{t("no_Posts_Available")}</p>
                    ) : (
                        <>

                            {changePageLoading ? (
                                <div id="backdrop">
                                    <div className="text-center loading">
                                        <div className="spinner-border nw-spinner" role="status"></div>
                                    </div>
                                </div>
                            ) : null}
                            <Table bordered hover>
                                <thead>
                                <tr>
                                    <th>{t("productLocalPage.middleSection.table.idTableHeading")}</th>
                                    <th>{t("productLocalPage.middleSection.table.nameTableHeading")}</th>
                                    <th>Image</th>
                                    <th>{t("productLocalForm.form.ean")}</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {posts.map((post) => (
                                    <tr key={post.id}>
                                        <td>{post.id}</td>
                                        <td>{post.name}</td>
                                        <td>
                                            <img width={150} alt="product local"
                                                 src={post.thumbnailPreviewUrl !== "" ? post.thumbnailPreviewUrl : PlaceholderImage}></img>
                                        </td>
                                        <td>{post.ean}</td>
                                        <td>
                                            <a href={`/product-local/form/edit/${post.id}`}
                                               className="btn btn-info rounded-1">
                                                {t("editButtonLabel")}
                                            </a>
                                            <a onClick={() => handleDelete(post.id)}
                                               className="btn btn-danger mx-2 rounded-1">
                                                {t("deleteButtonLabel")}
                                            </a>
                                            <Link
                                                to={`/posters/new${companyId ? `?company_id=${companyId}` : ''}&onSave=DUPLICATE&from=posters&showImageProduct=${post.imageMainUrl !== ""}&productLocalEan=${post.ean}`}
                                            >
                                                <Button variant="success"
                                                        className="new-poster-btn h-100 px-3 rounded-1">
                                                    <Plus size={25}/>
                                                    <span className="new-poster-button">
										{t('postersPage.topButtonLabel')}
									</span>
                                                </Button>
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                            <ResponsivePagination
                                previousLabel={t("back")}
                                nextLabel={t("next")}
                                current={paginateCurrentPage}
                                total={pageCount}
                                onPageChange={setCurrentPagePaginate}
                            />
                            <p>{t("productLocalPage.total")}: {totalItems}</p>


                        </>
                    )}
                </>
            )}

        </div>
    );
}
