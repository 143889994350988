
import { useEffect } from "react";
import NwCustomImageDropzone from "../_utilityComponents/NwCustomImageDropzone/NwCustomImageDropzone";

function ImageSingleType(props) {
    const isDisabled = props?.isDisabled || false;
    const productId = props.productId;
    const formFieldJson = props.formFieldJson;
	const entityFormValuesJson = props.entityFormValuesJson;
	const updateEntityFormValues = props.updateEntityFormValues;
	const configValues = formFieldJson.configValues;
    const metadata = configValues?.values?.metadata;
    const imageSingleValueKey = `${configValues.frontUniqueKeys.fileinput1}_${formFieldJson.id}`;
    const imageSingleValueJson = `${imageSingleValueKey}` in entityFormValuesJson
    ? entityFormValuesJson[`${imageSingleValueKey}`]
    : {
        name : formFieldJson.name,
        type: 'image_multiple',
        value : "",
        metadata: metadata,
    }

    useEffect(() => {
        updateEntityFormValues(prevFieldValuesJson => {
            const newFieldValuesJson = {...prevFieldValuesJson};
            newFieldValuesJson[`${imageSingleValueKey}`] = imageSingleValueJson;
            return newFieldValuesJson;
        })
    }, []);

    const customHandleUploadImage = async (imagesUploaded) => {
        if (imagesUploaded === null) {
            updateEntityFormValues(prevFieldValuesJson => {
                const newFieldValuesJson = {...prevFieldValuesJson};
                imageSingleValueJson.value = "";
                newFieldValuesJson[`${imageSingleValueKey}`] = {...imageSingleValueJson};
                return newFieldValuesJson;
            })

            return;
        }
        const uploadedImages = imagesUploaded.map(imageUploaded => imageUploaded?.uploadedInfo);
        updateEntityFormValues(prevFieldValuesJson => {
            const newFieldValuesJson = {...prevFieldValuesJson};
            imageSingleValueJson.value = uploadedImages.length > 0 ? uploadedImages[0] : "";
            newFieldValuesJson[`${imageSingleValueKey}`] = {...imageSingleValueJson};
            return newFieldValuesJson;
        })
    }

    return (
        <>
            <label>{formFieldJson.title}</label>
            <NwCustomImageDropzone disabled={isDisabled} handleFilesUploaded={customHandleUploadImage} maxFiles={1} />
        </>
    )
}

export default ImageSingleType;