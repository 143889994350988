import { useState, useEffect, useRef, useContext } from 'react';
import {
    Row,
    Col,
    Button,
    Form,
} from "react-bootstrap";
import NwCustomSelect from '../_utilityComponents/NwFormsUi/CustomSelect/NwCustomSelect';
import NwCustomInput from '../_utilityComponents/NwFormsUi/CustomInput/NwCustomInput';

//Context
import MainLayoutContext from '../../contexts/MainLayoutContext';

// Icons
import { NwLoaderSpinner } from '../_utilityComponents/NwIcons/NwIcons';
import NwCustomDatePicker from '../_utilityComponents/NwFormsUi/CustomDatePicker/NwCustomDatePicker';
import { Funnel } from 'react-bootstrap-icons';

//Nw Hooks
import { useFlexWrapStatus } from '../../hooks/hooks';

//nwComponents
import NwCustomModal from '../_utilityComponents/NwCustomModal/NwCustomModal';
import './NwCustomFilters.css';

const FilterElement = ({ type, portalTarget, ...elementProps }) => {
    let element = null;
    switch (type) {
        case 'select':
            element = <NwCustomSelect {...elementProps} menuPortalTarget={portalTarget} />;
            break;
        case 'input': 
            element = <NwCustomInput  {...elementProps} />;
            break;
        case 'date':
            element = <NwCustomDatePicker {...elementProps} />;
            break;
        default:
            break;
    }

    return element;
}

export const NwCommonFilter = ({
    onSubmit,
    formFields = [],
    className = '',
    autoSubmitOnReset = false,
    onFilterReset = null,
    filterWrapperProps = {},
    filterContainerProps = {},
    intiShowReset = false,
    filterButtonProps = {},
    resetButtonProps = {},
    modalButtonProps = {},
    filterModalProps
}) => {
    
    const { currentPlatform } = useContext(MainLayoutContext);
    const [ isFilterLoading, setIsFilterLoading ] = useState(false);
    const [isFilterModal, setIsFilterModal] = useState(false)
    const [ showReset, setShowReset ] = useState(intiShowReset);
    const filterFormRef = useRef(null); // Create the ref
    const filterWrapperRef = useRef(null); // Create the ref
    const isWrapping = useFlexWrapStatus(filterWrapperRef);

    useEffect(() => {
        if (!isWrapping && !(currentPlatform === 'is-mobile' || currentPlatform === 'is-tablet' || currentPlatform === 'is-small-desktop')){
            setIsFilterModal(false);
        } else {
            setIsFilterModal(true);
        }
    }, [isWrapping, currentPlatform ]);

    // useEffect(() => {
    //     console.log("Is Filter Modal", isFilterModal);
    // }, [isFilterModal]);

    // INIT THE FILTER FORM VALUES JSON/OBJECT
    const [formValues, setFormValues] = useState(
        formFields.reduce((acc, curr) => ({ ...acc, [curr.props.name]: '' }), {}) // Initialize with empty strings or other default values
    );

    useEffect(() => {
        // Check if any form value is different from the initial value
        const isAnyValueChanged = formFields.some(field => formValues[field.props.name] !== '');
        setShowReset(isAnyValueChanged);
    }, [formValues, formFields]);

    const handleFilterSubmit = (e) => {
        e.preventDefault(); // Prevent default form submission
        setIsFilterLoading(true)
        onSubmit(e).finally(() =>setIsFilterLoading(false));
    }

    const handleChange = (name, type, value, onChangeCallback = null) => {
        setFormValues(prevValues => ({ ...prevValues, [name]: type == 'input' ? value.target.value : value }));

        // if there is an onChangeCallBack, then call it
        if (onChangeCallback) {
            onChangeCallback({
                name: name,
                type: type,
                value: value.target.value
            });
        }
    };

    const resetForm = () => {
        setFormValues(
            formFields.reduce((acc, curr) => ({ ...acc, [curr.props.name]: '' }), {}) // Reset to initial state
        );

        // call onFilterReset for custom handling when the filter resets
        if (onFilterReset) {
            // You may need to wait for the state to update before submitting
            // or use another method to ensure the form values are reset
            setTimeout(() => {
                onFilterReset(formValues);
            }, 0);
        }
    };

    return (
        <div { ...filterContainerProps } className={`w-100 shadow nw-filter-container bg-white ${filterContainerProps?.className ?? ''}`}>
            {
                isFilterModal ? (
                    <NwCustomModal
                        customButton={
                            <Button className={`rounded-1 h-100 nw-bg-accent-1 nw-border-accent-1 ${modalButtonProps.className}`} style={{ aspectRatio: 1 }} {...modalButtonProps}>
                                <Funnel size={24} />
                            </Button>
                        }
                        headerContent={filterModalProps?.headerContent ?? ""}
                        modalProps={{
                            closeBtn: {
                                className: 'nw-bg-accent-1 nw-border-accent-1'
                            }
                        }}
                        {...filterModalProps}
                    >
                        <Form 
                            ref={filterFormRef} 
                            className={`nw-custom-common-filter d-flex justify-content-center p-3 ${currentPlatform} ${isWrapping ? 'is-wrapping' : ''} ${className}`} 
                            onSubmit={handleFilterSubmit}
                            style={{
                                minWidth: '20rem'
                            }}
                        >
                            <Row {...filterWrapperProps} ref={filterWrapperRef} style={{
                                rowGap: filterWrapperProps?.style?.rowGap ?? 'clamp(10px, 1vh, 3rem)',
                                flexDirection: isFilterModal ? 'column' : 'row'
                            }}>
                                {
                                    formValues && formFields.map((formField, index) => {
                                        const colProps = isFilterModal ? { xs: 12, sm: 12, md: 12, lg: 12 } : {};
                                        return (
                                            <Col {...formField?.wrapperProps} {...colProps} key={index}>
                                                <FilterElement
                                                    type={formField.type}
                                                    portalTarget={null}
                                                    value={formValues[formField.props.name]}
                                                    onChange={(value) => handleChange(formField.props.name, formField.type, value, formField.props?.onChangeCallback ?? null)}
                                                    {...formField.props}
                                                />
                                            </Col>
                                        )
                                    })
                                }

                                <Col className='d-flex gap-2 d-flex flex-column mt-3'>
                                    <Button 
                                        {...filterButtonProps} 
                                        className={`nw-custom-common-filter-btn h-100 w-100 rounded-1 px-5 d-flex align-items-center nw-responsive-font-size nw-bg-accent-1 nw-border-accent-1 justify-content-center ${filterButtonProps.className || ''}`}
                                    >
                                        {isFilterLoading ? (<NwLoaderSpinner size={20} isSpinner/>) : filterButtonProps.children}
                                    </Button>

                                    {showReset && (
                                        <Button 
                                            {...resetButtonProps}
                                            type={autoSubmitOnReset ? 'submit' : 'button'}
                                            className={`nw-custom-common-filter-btn nw-custom-common-filter-reset-btn h-100 w-100 rounded-1 nw-bg-accent-1 nw-border-accent-1 nw-responsive-font-size px-3 ${resetButtonProps.className || ''}`}
                                            onClick={resetForm}
                                        >
                                            {resetButtonProps.children}
                                        </Button>
                                    )}
                                </Col>
                            </Row>        
                        </Form>
                    </NwCustomModal>
                ) : (
                    <Form ref={filterFormRef} className={`nw-custom-common-filter ${currentPlatform} ${isWrapping ? 'is-wrapping' : ''} ${className}`} onSubmit={handleFilterSubmit}>
                        <Row {...filterWrapperProps} className={`mx-0 ${filterWrapperProps?.className}`} ref={filterWrapperRef} style={{
                            columnGap: filterWrapperProps?.style?.columnGap ?? 'clamp(10px, 1vw, 3rem)',
                            flexDirection: isFilterModal ? 'column' : 'row'
                        }}>
                            {
                                formValues && formFields.map((formField, index) => {
                                    const colProps = isFilterModal ? { xs: 12, sm: 12, md: 12, lg: 12 } : {};
                                    return (
                                        <Col {...formField?.wrapperProps} {...colProps} key={index}>
                                            <FilterElement
                                                type={formField.type}
                                                value={formValues[formField.props.name]}
                                                onChange={(value) => handleChange(formField.props.name, formField.type, value, formField.props?.onChangeCallback ?? null)}
                                                {...formField.props}
                                            />
                                        </Col>
                                    )
                                })
                            }
        
                            <Col className='d-flex gap-2 justify-content-end'>
                                <Button 
                                    {...filterButtonProps} 
                                    className={`nw-custom-common-filter-btn h-100 rounded-1 d-flex nw-responsive-font-size align-items-center nw-bg-accent-1 nw-border-accent-1 ${filterButtonProps.className || ''}`}
                                >
                                    {isFilterLoading ? (<NwLoaderSpinner size={20} isSpinner/>) : filterButtonProps.children}
                                </Button>
        
                                {showReset && (
                                    <Button 
                                        {...resetButtonProps}
                                        type={autoSubmitOnReset ? 'submit' : 'button'}
                                        className={`nw-custom-common-filter-btn nw-custom-common-filter-reset-btn h-100 rounded-1 nw-responsive-font-size nw-bg-accent-1 nw-border-accent-1 px-3 ${resetButtonProps.className || ''}`}
                                        onClick={resetForm}
                                    >
                                        {resetButtonProps.children}
                                    </Button>
                                )}
                            </Col>
                        </Row>        
                    </Form>
                )
            }
        </div>
    );
}