import { useEffect } from "react";
import { Form } from "react-bootstrap";
import NwCustomInput from "../_utilityComponents/NwFormsUi/CustomInput/NwCustomInput";

function StringType(props) {
	const isDisabled = props?.isDisabled || false;
    const formFieldJson = props.formFieldJson;
	const entityFormValuesJson = props.entityFormValuesJson;
	const updateEntityFormValues = props.updateEntityFormValues;
	const configValues = formFieldJson.configValues;
	const metadata = configValues?.values?.metadata;
    const stringValueKey = `${configValues.frontUniqueKeys.text1}_${formFieldJson.id}`;
    const stringValueJson = `${stringValueKey}` in entityFormValuesJson 
	? entityFormValuesJson[`${stringValueKey}`]
	: {
		name: formFieldJson.name,
		type: 'string',
		value: '',
		metadata: metadata,
	}

	const updateStringValue = (newStringValue) => {
		updateEntityFormValues(prevFieldValuesJson => {
			const newFieldValuesJson = {...prevFieldValuesJson};
            stringValueJson.value = newStringValue;
            newFieldValuesJson[`${stringValueKey}`] = stringValueJson;
            return newFieldValuesJson;
		})
	}

	useEffect(() => {
		updateStringValue(stringValueJson.value);
	}, []);

    return (
		<div key={props.id} className={`mb-3 ${props.elementClasses}`}>
			<Form.Label>{props.formFieldJson.title}</Form.Label>
        	<NwCustomInput disabled={isDisabled} hideLabel onChange={(e) => updateStringValue(e.target.value)} placeholder={stringValueJson.value} value={stringValueJson.value} id={props.id} type="text" name={props.formFieldJson.name + "_" + props.formFieldJson.type}/>
		</div>
	);
}

export default StringType;