import { createContext, useEffect, useState } from "react";
import { getProductById } from "../services/posters";
import { 
    getUserSocialMedias,
    getSocialMediaProductById, 
    formatSocialMediasToEditorUseFormat,
    getUserSocialMediaCampaignsByThemeType,
    getSocialMediaTemplateGroupBodyData,
    getSocialMediaTemplateGroups,
    getSocialMediaThemeTypes,
    getTemplateGroupCoversByTemplateGroup,
    getSocialMediaCoversBySocialMediaProduct,
    getSocialMediaPackGroupsByUserStore,
    getUserSocialMediaById,
} from "../services/social_media";
import { useSearchParams } from "react-router-dom";

export const SocialMediaEditorContext = createContext(null);

export const SocialMediaEditorProvider = (props) => {
    const children = props.children;
    const mode = props.mode;
    const id = props.id;
    const entity = props.entity // entity can be either a pack or a single free poster out of a pack
    const [initialData, setInitialData] = useState(null);
    const [socialMediasData, setSocialMediasData] = useState({});
    const [existingCategories, setExistingCategories] = useState([]);
    const [packGroups, setPackGroups] = useState([]);

    // TEMPLATE GROUP SECTION
    const [ socialMediaThemeTypes, setSocialMediaThemeTypes ] = useState([]);
    const [ socialMediaCampaigns, setSocialMediaCampaigns] = useState([]);
    const [ socialMediaTemplateGroups, setSocialMediaTemplateGroups ] = useState([]);
    const [ currentSelectedThemeType, setCurrentSelectedThemeType ] = useState(null);
    const [ currentSelectedSocialMediaCampaign, setCurrentSelectedSocialMediaCampaign ] = useState(null);
    const [ templateGroupBodyData, setTemplateGroupBodyData ] = useState(null)
    const [ currentSelectedTemplateGroup, setCurrentSelectedTemplateGroup ] = useState(null);
    const [ templateGroupCovers, setTemplateGroupCovers ] = useState({});

    // Search Params
    const [ searchParams, setSearchParams ] = useSearchParams();
    const shortcutPlatformId = searchParams.get('platformId');

    useEffect(() => {
        getSocialMediaThemeTypes().then((socialMediaThemeTypes) => {
            socialMediaThemeTypes = socialMediaThemeTypes.map((type) => {
                return {
                    'id' : type.key,
                    'value' : type.key,
                    'label' : type?.label
                }
            })

            setSocialMediaThemeTypes(socialMediaThemeTypes);
        })

        getSocialMediaPackGroupsByUserStore().then(packGroups => {
            setPackGroups(packGroups);
        })
    }, []);

    useEffect(() => {
        if (mode === "new") {
            async function getSocialMedias() {
                if (shortcutPlatformId) {
                    const socialMedias = await getUserSocialMediaById(shortcutPlatformId);
                    const newSocialMediasData = formatSocialMediasToEditorUseFormat(socialMedias);
                    setSocialMediasData(newSocialMediasData);
                    return;
                }
                const socialMedias = await getUserSocialMedias();
                const newSocialMediasData = formatSocialMediasToEditorUseFormat(socialMedias);
                setSocialMediasData(newSocialMediasData);
            }
    
            getSocialMedias();

            if (entity === "product" && id !== 0) {
                // means we are creating social media product and its relative social media posters but the data is coming from a product pertaining to an operation
                getProductById(id).then(productData => setInitialData(productData));
            }
        } else if (mode === "edit") {
            if (entity === "social_media_product") {
                getSocialMediaProductById(id).then(socialMediaProductData => {
                    setInitialData(socialMediaProductData)
                    const postTypesJson = socialMediaProductData?.postTypesJson;
                    getUserSocialMedias().then(socialMedias => {
                        const newSocialMediasData = formatSocialMediasToEditorUseFormat(socialMedias);

                        // override the post type json in new social medias data if its platform exists in post types json
                        const postTypesPlatformIds = Object.keys(postTypesJson);
                        Object.keys(newSocialMediasData).forEach(platformId => {
                            if (postTypesPlatformIds.includes(platformId)) {
                                newSocialMediasData[platformId] = postTypesJson[platformId];
                            }
                        })

                        setSocialMediasData(newSocialMediasData);
                    })
                });
            }
        }
    }, [entity, id, mode]);

    useEffect(() => {
        getUserSocialMediaCampaignsByThemeType(currentSelectedThemeType?.id).then((socialMediaCampaigns) => {
            socialMediaCampaigns = socialMediaCampaigns.map((campaign) => {
                return {
                    ...campaign,
                    'label' : campaign.name
                }
            })
            setSocialMediaCampaigns(socialMediaCampaigns)
        })

        // get the relevant social media campaigns
    }, [currentSelectedThemeType])

    useEffect(() => {
        if (currentSelectedSocialMediaCampaign) {
            const newTemplateGroupData = getSocialMediaTemplateGroupBodyData(currentSelectedSocialMediaCampaign?.id);
            setTemplateGroupBodyData(newTemplateGroupData);
        }
    }, [currentSelectedSocialMediaCampaign]);

    useEffect(() => {
        if (templateGroupBodyData) {
            getSocialMediaTemplateGroups(templateGroupBodyData).then((socialMediaTemplateGroups) => {
                socialMediaTemplateGroups = socialMediaTemplateGroups?.items.map((templateGroup) => {
                    return {
                        ...templateGroup,
                        'label' : templateGroup?.name,
                    }
                })
                setSocialMediaTemplateGroups(socialMediaTemplateGroups);
            });
        }
    }, [templateGroupBodyData])

    useEffect(() => {
        if (currentSelectedTemplateGroup) {
            getTemplateGroupCoversByTemplateGroup(currentSelectedTemplateGroup?.id).then(covers => {
                Object.keys(covers).forEach(key => covers[key]['selected'] = false);

                // need the cover poster also to preselect some covers on edit
                if (mode === "edit") {
                    getSocialMediaCoversBySocialMediaProduct(id).then(socialMediaCoverPosters => {
                        Object.keys(socialMediaCoverPosters).forEach(coverKey => {
                            const socialMediaCoverData = socialMediaCoverPosters[coverKey];
                            socialMediaCoverData.selected = true;
                            covers[coverKey] = socialMediaCoverData
                        }) 

                        setTemplateGroupCovers(covers);
                    });
                } else {
                    setTemplateGroupCovers(covers);
                }
                
            });
        }
    }, [currentSelectedTemplateGroup, id, mode])

    const context = {
        entity,
        mode,
        id,
        initialData,
        socialMediasData,
        setSocialMediasData,
        socialMediaThemeTypes,
        setSocialMediaThemeTypes,
        socialMediaCampaigns,
        setSocialMediaCampaigns,
        socialMediaTemplateGroups,
        setSocialMediaTemplateGroups,
        currentSelectedThemeType,
        setCurrentSelectedThemeType,
        currentSelectedSocialMediaCampaign,
        setCurrentSelectedSocialMediaCampaign,
        templateGroupBodyData,
        setTemplateGroupBodyData,
        existingCategories,
        setExistingCategories,
        currentSelectedTemplateGroup,
        setCurrentSelectedTemplateGroup,
        templateGroupCovers,
        setTemplateGroupCovers,
        packGroups,
        setPackGroups,
    }

    return <SocialMediaEditorContext.Provider value={context}>{children}</SocialMediaEditorContext.Provider>
}