import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Form} from "react-bootstrap";
import {ArrowLeft} from "react-bootstrap-icons";
import {Link} from "react-router-dom";
import {getProductLocalById, editProductLocal, newProductLocal} from "../../services/productLocal";
import {useTranslation} from "react-i18next";
import Swal from "sweetalert2";
import {convertDate} from "../../helpers/helpers";
import NwCustomInput from "../_utilityComponents/NwFormsUi/CustomInput/NwCustomInput";
import {deleteProductLocalProductImage} from "../../services/productLocal";

///// NW UI FORMS //////
import NwCustomDatePicker from "../_utilityComponents/NwFormsUi/CustomDatePicker/NwCustomDatePicker";
import NwCustomImageDropzone from "../_utilityComponents/NwCustomImageDropzone/NwCustomImageDropzone";
///////////////////////

export default function ProductLocalForm() {
    const [formData, setFormData] = useState({
        id: 0,
        name: "",
        ean: "",
        description: "",
        price: "",
        stockQty: "",
        stockUnit: "",
        stockDate: convertDate(new Date()),
        commercialName: "",
        commercialDescription: "",
        commercialPrice: "",
        chosenProductImage: null
    });

    const [data, setData] = useState();
    const {t} = useTranslation();
    const [productLocalId, setId] = useState(useParams().productLocalId);
    const [mode, setMode] = useState(useParams().mode);
    const [isSavingData, setIsSavingData] = useState(false);
    const [uploadedProductImage, setUploadedProductImage] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (mode !== 'edit') {
            setMode('new');
        }
    }, [mode]);
    // console.log(mode);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.name) {
            const nameInput = document.querySelector('input[name="name"]');
            if (nameInput) {
                nameInput.focus();
            }
            Swal.fire(t("productLocalForm.enterName"), " ", "error");
            return false;
        }

        if (!formData.ean) {
            const eanInput = document.querySelector('input[name="ean"]');
            if (eanInput) {
                eanInput.focus();
            }
            Swal.fire(t("productLocalForm.enterEan"), " ", "error");
            return false;
        }


        console.log(formData);
        if (mode === "edit") {
            setIsSavingData(true);
            const saveProductLocal = await editProductLocal(formData);
            console.log(saveProductLocal);
            setIsSavingData(false);
            // await fetchData();
            Swal.fire(t("productLocalForm.localProductUpdated"), " ", "success");
            navigate("/product-local");
        } else if (mode === "new") {
            setIsSavingData(true);
            const saveProductLocalNew = await newProductLocal(formData);
            setIsSavingData(false);
            Swal.fire(t("productLocalForm.newLocalProductCreated") + saveProductLocalNew.id, " ", "success");
            navigate("/product-local");
        }
    };

    const fetchData = async (productLocalId) => {
        const currentProductLocal = await getProductLocalById(productLocalId);
        const uploadedProductImage = {
            'imageCdnUrl': currentProductLocal.productImage?.images?.generated?.thumb600?.cdnUrl
        }
        setUploadedProductImage([uploadedProductImage]);
        delete currentProductLocal.productImage;
        setFormData(currentProductLocal);
    };

    useEffect(() => {
        if (mode === "edit") {
            fetchData(productLocalId);
        }
    }, []);

    const handleDataChange = (e) => {
        // e.preventDefault();
        const {name, value} = e.target;
        setFormData((prevformData) => ({
            ...prevformData,
            [name]: value,
        }));

    };

    const handleDateTimeChange = (value) => {
        setFormData(prevformData => {
            const newFormData = {...prevformData};
            newFormData.stockDate = convertDate(value);
            return newFormData;
        })
    }

    const updateChosenProductImage = (filesUpload) => {
        if (filesUpload) {
            setUploadedProductImage({imageCdnUrl: filesUpload[0]})
            setFormData(prevFormData => {
                const newFormData = {...prevFormData};
                newFormData.chosenProductImage = filesUpload[0]['file'];
                return newFormData;
            });
        }
    }

    const handleDeleteProductImage = async (fileUpload) => {
        deleteProductLocalProductImage(productLocalId).then((e) => {
        }).catch(e => console.error(e));
    }

    return (
        <div className="d-flex flex-column p-3 ps-4 row-gap-3">
            <Link
                to={'/product-local'}
                className="btn btn-primary rounded-pill d-flex align-items-center fw-semibold"
                style={{
                    width: 'fit-content',
                }}
            >
                <ArrowLeft className="me-1"/>{t("productLocalForm.back_to_product_local")}
            </Link>
            <Form className="w-50" onSubmit={handleSubmit}>
                <Form.Group controlId="name">
                    <NwCustomInput disabled={mode === "edit"} labelClassName="text-black"
                                   label={t("productLocalForm.form.name")} type="text" name="name" value={formData.name}
                                   onChange={handleDataChange}/>
                </Form.Group>
                <Form.Group controlId="ean">
                    <NwCustomInput disabled={mode === "edit"} labelClassName="text-black"
                                   label={t("productLocalForm.form.ean")} type="text" name="ean" value={formData.ean}
                                   onChange={handleDataChange}/>
                </Form.Group>
                <Form.Group controlId="description">
                    <NwCustomInput noBar disabled={mode === "edit"} labelClassName="text-black"
                                   label={t("productLocalForm.form.description")} as="textarea" type="text"
                                   style={{height: "100px"}} name="description" value={formData.description}
                                   onChange={handleDataChange}/>
                </Form.Group>
                <Form.Group controlId="price">
                    <NwCustomInput disabled={mode === "edit"} labelClassName="text-black"
                                   label={t("productLocalForm.form.price")} type="number" step="0.01" name="price"
                                   value={formData.price} onChange={handleDataChange}/>
                </Form.Group>
                <Form.Group controlId="commercialPrice">
                    <NwCustomInput labelClassName="text-black" label={t("productLocalForm.form.commercialPrice")}
                                   type="number" step="0.01" name="commercialPrice" value={formData.commercialPrice}
                                   onChange={handleDataChange}/>
                </Form.Group>
                <Form.Group controlId="stockQty">
                    <NwCustomInput labelClassName="text-black" label={t("productLocalForm.form.stock_qty")}
                                   value={formData.stockQty} type="number" name="stockQty" onChange={handleDataChange}/>
                </Form.Group>
                <Form.Group controlId="stockUnit">
                    <NwCustomInput labelClassName="text-black" label={t("productLocalForm.form.stockUnit")}
                                   value={formData.stockUnit} type="text" name="stockUnit" onChange={handleDataChange}/>
                </Form.Group>
                <Form.Group controlId="stockDate">
                    <Form.Label>{t("productLocalForm.form.stockDate")}</Form.Label>
                    <NwCustomDatePicker onChange={handleDateTimeChange} value={new Date(formData.stockDate)}/>
                </Form.Group>
                <Form.Group controlId="commercialName">
                    <NwCustomInput labelClassName="text-black" label={t("productLocalForm.form.commercialName")}
                                   type="text" name="commercialName" value={formData.commercialName}
                                   onChange={handleDataChange}/>
                </Form.Group>
                <Form.Group controlId="commercialDesc">
                    <NwCustomInput noBar labelClassName="text-black" label={t("productLocalForm.form.commercialDesc")}
                                   type="text" as="textarea" style={{height: "100px"}} name="commercialDescription"
                                   value={formData.commercialDescription} onChange={handleDataChange}/>
                </Form.Group>

                <div className="nw-product-local-image my-4">
                    <NwCustomImageDropzone showPreviewOnDrop initialUploadedInfos={uploadedProductImage} maxFiles={1}
                                           handleFilesOnDrop={updateChosenProductImage}
                                           handleFilesDelete={handleDeleteProductImage}/>
                </div>

                <Button className="rounded-1 p-3" type="submit" disabled={isSavingData}>
                    {t("productLocalForm.form.save")}
                </Button>
            </Form>
        </div>
    );
}
