import React, { useEffect, useState, useRef } from "react";
import { Form, Row, Col, ListGroup, Tab, Button } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import { Input } from "react-select/animated";
import { productByEan } from "../../../services/posters";
import { getProductLocalAssetsById, getProductLocaleBarcodeFromEan } from "../../../services/productLocal";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";

////// UTILITY COMPONENTS ////////
import NwCustomInput from "../../_utilityComponents/NwFormsUi/CustomInput/NwCustomInput";

const GenCode = ({ getGencodeData, eanValue, setEanValue, disabled }) => {
  const { t } = useTranslation();
  const [posts, setPosts] = useState(null);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalItemLeft, setTotalItemLeft] = useState(0);
  const [showResults, setShowResults] = useState(false);
  
  const handleSearch = async (e) => {
    if (eanValue)
    {
      setIsLoading(true);
      const res = await productByEan(eanValue, 1);
      console.log(res);
      setPosts(res.items);
      setTotalItems(res.totalItems);
      setIsLoading(false);
      setPageNumber(1);
      setShowResults(true);
    }
  };

  const fetchMoreData = async () => {
    if (eanValue)
    {
      const res = await productByEan(eanValue, pageNumber + 1);
      setPosts(posts.concat(res.items));
      setTotalItems(res.totalItems);
      setPageNumber(pageNumber + 1);
    }
  };

  useEffect(() => {
  }, [posts, totalItems, pageNumber]);

  const handleEanInput = (e) => {
    const inputValue = e.target.value;
    // Check if the input is empty or if it's a valid number
    if (inputValue === '' || (!isNaN(+inputValue) && inputValue.trim() !== '')) {
        // For an empty string, pass it directly; otherwise, convert to a number
        setEanValue(inputValue === '' ? '' : inputValue);
    } else {
        // Optionally handle the case where the input is not valid
        console.log('Invalid input: not a number or empty');
    }
  };

  const handleSelectedData = async (itemData) => {
    const dataValueObject = itemData;
    //Get Assets for Product Local
    const productAssetsData = await getProductLocalAssetsById(itemData.id);
    dataValueObject.productAssetsData = productAssetsData ?? null;

    const productLocalBarcode = await getProductLocaleBarcodeFromEan(eanValue);
    dataValueObject.barcodeGenerated = productLocalBarcode;
    if ('ean' in itemData) setEanValue(itemData.ean);
    getGencodeData(dataValueObject);
    setShowResults(false);
  };

  return (
    <div className="ean-search">
      <div className="justify-content-between align-items-center">
        <NwCustomInput 
          isSearch
          type="number"
          label={t('productLocalForm.form.ean')}
          placeholder={ t('productLocalForm.form.ean') }
          value={eanValue} onInput={handleEanInput}
          buttonOnClick={handleSearch}
          disabled={disabled}
          buttonIsDisabled={!eanValue || disabled}
          isLoading={isLoading}
        />
      </div>

      <div className={"search-result" + (!showResults ? " d-none" : "")} style={{ marginTop: "10px", maxHeight: "500px", overflowY: "auto" }}>
        {posts && (
          <ListGroup>
            <InfiniteScroll 
              dataLength={totalItems} 
              next={fetchMoreData} 
              hasMore={true} 
            >
              {posts.map((item) => (
                  <Col key={item?.id}>
                    <ListGroup.Item>
                      <Badge bg="primary" pill>
                        {item?.ean}
                      </Badge>
                      <br />
                      <div className="d-flex justify-content-between align-items-center">
                        <div>{item?.name}</div>
                        <div>
                          <Button variant="secondary" size="sm" onClick={() => handleSelectedData(item)}>
                            Select
                          </Button>
                        </div>
                      </div>
                    </ListGroup.Item>
                  </Col>
              ))}
            </InfiniteScroll>
            <ListGroup.Item>{totalItems}</ListGroup.Item>
          </ListGroup>
        )}
      </div>
    </div>
  );
};

export default GenCode;
