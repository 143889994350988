const MainLogoWithText = ({ size }) => {
	return (
		<svg width={size} height={size} xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 289.441 44.429">
		<defs>
			<clipPath id="clip-path">
			<rect id="Rectangle_67" width="289.441" height="44.429" transform="translate(0 0)" fill="currentColor"/>
			</clipPath>
		</defs>
		<g id="Group_492" transform="translate(0 0)">
			<g id="Group_460" transform="translate(0 0)">
			<path id="Path_13" d="M40.288,0H4.142A4.154,4.154,0,0,0,0,4.142V40.288a4.154,4.154,0,0,0,4.142,4.142H40.288a4.154,4.154,0,0,0,4.142-4.142V4.142A4.154,4.154,0,0,0,40.288,0M16.475,34.26H12.8a3.152,3.152,0,1,1,0-6.3h3.67a3.152,3.152,0,0,1,0,6.3m9.363-12.044a3.162,3.162,0,0,1-3.152,3.152H12.8a3.152,3.152,0,1,1,0-6.3h9.88A3.162,3.162,0,0,1,25.837,22.215Zm5.787-5.74H12.8a3.152,3.152,0,1,1,0-6.3h18.82a3.152,3.152,0,1,1,0,6.3" transform="translate(0 0)" fill="currentColor"/>
			<path id="Path_14" d="M64.632,4.617H88.963v8.728h-14v5.317H86.755V26.89H74.966V40.936H64.632Z" transform="translate(-5.213 -0.373)" fill="currentColor"/>
			<path id="Path_15" d="M100.414,4.617H110.6l14.7,36.319H114.059L111.5,33.512H99.06L96.4,40.936H85.716Zm4.917,11.036c-.7,2.308-1.4,4.415-2.257,6.772l-1.254,3.461h7.022l-1.2-3.461c-.852-2.458-1.5-4.515-2.207-6.772Z" transform="translate(-6.913 -0.373)" fill="currentColor"/>
			<path id="Path_16" d="M156.582,39.282a25.566,25.566,0,0,1-10.535,2.158c-12.191,0-20.366-8.127-20.366-18.662,0-10.735,8.477-18.761,20.366-18.761A24.881,24.881,0,0,1,156.08,6.074V16.206a19.423,19.423,0,0,0-9.832-3.009c-6.12,0-10.234,3.963-10.234,9.48,0,5.268,3.813,9.581,10.435,9.581a19.437,19.437,0,0,0,10.133-3.16Z" transform="translate(-10.136 -0.324)" fill="currentColor"/>
			<path id="Path_17" d="M173.3,13.345H161.758V4.617h33.409v8.728H183.629V40.936H173.3Z" transform="translate(-13.046 -0.373)" fill="currentColor"/>
			<path id="Path_18" d="M217.075,4.017c10.937,0,19.414,8.226,19.414,18.711S228.012,41.44,217.075,41.44s-19.413-8.227-19.413-18.712,8.477-18.711,19.413-18.711m0,28.142a9.159,9.159,0,0,0,9.08-9.432,9.086,9.086,0,1,0-18.16,0,9.159,9.159,0,0,0,9.079,9.432" transform="translate(-15.942 -0.324)" fill="currentColor"/>
			<path id="Path_19" d="M243.2,4.617h12.691c4.415,0,7.676.552,10.184,1.806a9.909,9.909,0,0,1,5.869,9.481c0,4.464-2.157,7.876-6.672,9.782l9.632,15.25H263.216l-8.076-13.8h-1.605v13.8H243.2Zm12.892,15.451a8.067,8.067,0,0,0,4.063-.752,3.045,3.045,0,0,0,1.455-2.86,3.063,3.063,0,0,0-1.756-3.009,10.473,10.473,0,0,0-4.415-.6h-1.906v7.223Z" transform="translate(-19.614 -0.373)" fill="currentColor"/>
			<path id="Path_20" d="M287.077,24.683l-14.1-20.066h12.04l3.31,5.116c1.355,2.058,2.76,4.264,4.014,6.321h.1c1.3-2.107,2.609-4.213,3.913-6.22l3.361-5.216h11.739L297.411,24.481V40.936H287.077Z" transform="translate(-22.016 -0.373)" fill="currentColor"/>
			</g>
		</g>
		</svg>

	);
}

export default MainLogoWithText;