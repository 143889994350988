import { useEffect, useState } from 'react';
import LogoCooper from './LogoCooper';
import { useMediaQuery } from "@uidotdev/usehooks";
import { getTranslationsByApi } from '../../helpers/helpers';
import { useTranslation } from 'react-i18next';
import Pages from '../Pages/Pages';

const Footer = () => {
	const [ currentTranslatedData, setCurrentTranslatedData ] = useState(null);
	const isMobile = useMediaQuery("only screen and (max-width : 768px)");
	const { i18n, t } = useTranslation();

	const yearNow = new Date().getFullYear(); 

	useEffect(() => {
	  
		getTranslationsByApi(i18n.language)
			.then((translatedData) => {
				setCurrentTranslatedData(translatedData);
			})
			.catch((error) => {
				console.error("An error occurred:", error);
			})

	}, [i18n.language])
	

	return (
		<div className="main-footer w-100 h-100 d-flex flex-column fw-semibold">
			{isMobile && (
				<div className="mobile-logo-container d-flex justify-content-center">
					<span className='p-2'><LogoCooper/></span>
				</div>
			)}
			<div className="d-flex justify-content-between">
				<span className="copyright-container d-flex align-items-center nw-responsive-font-size">
					<small>© {yearNow} CooperGroup Inc. {t(`footer.allRightsReserved`)}</small>
				</span>
				<span className="cooper-logo-and-text-container d-flex">
					<Pages/>
					{!isMobile && (
						<span className='mx-3'><LogoCooper/></span>
					)}
				</span>
			</div>
		</div>
	);
}

export default Footer;