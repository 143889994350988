import LoginForm from "../../components/Navbar/Form/LoginForm";
import MainLogoWithText from "../../components/_utilityComponents/MainLogoWithText";
import Footer from "../../components/_utilityComponents/Footer";
import { useMediaQuery } from "@uidotdev/usehooks";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";
import "./Login.css";

function Login () {
	const { t } = useTranslation();
	const isMobile = useMediaQuery("only screen and (max-width : 768px)");
    return (
		<div className="loginFormContainer d-flex flex-column align-items-center">
			<div className="login-form-header flex-fill">
				<div className="login-header-logo-container fw-bold me-2">
					<MainLogoWithText/>
				</div>
			</div>
			<div className={`login-form-section bg-white border flex-fill ${ isMobile ? 'p-0' : 'px-5 py-3 py-xxl-5' } shadow-lg mb-5`}>
				{
					isMobile ? (
						<></>
					) : (
						<div className={`login-form-bunny-section ${ isMobile ? 'd-none' : '' }`}>
							<img className="login-form-bunny-img" src="/assets/login/login-bunny.svg" alt="" />
							<span className="bunny-section-text">
								{ t('loginPage.formSection.introductionPanel.bunnySection') }
							</span>
						</div>
					)
				}
				
				<div className="login-form-section-main-wrapper">
					<div className={`text-container d-flex flex-column justify-content-start ${ isMobile ? 'p-3' : '' }`}>
						<Link to={'https://www.coopergroup.fr/'} target="_blank" className="nav-link">
							<span className="top-section">{ t('loginPage.formSection.introductionPanel.topSection') }</span>
						</Link>
						<span className="middle-section">
							<Trans
								i18nKey={'loginPage.formSection.introductionPanel.middleSection'}
								components={{
									span: <span style={{ color: 'var(--nw-accent-color-1)' }}/>
								}}
							/>
						</span>
					</div>
					<div className={`login-form-main-container ${ isMobile ? 'position-relative' : '' }`}>
						{
							isMobile ? (
								<div className={`login-form-bunny-section is-mobile`}>
									<img className="login-form-bunny-img" src="/assets/login/login-bunny.svg" alt="" />
									<span className="bunny-section-text d-none">
										{ t('loginPage.formSection.introductionPanel.bunnySection') }
									</span>
								</div>
							) : <></>
						}
						<LoginForm />
					</div>
				</div>
			</div>
			<div className={`login-form-footer flex-fill mb-3 ${ isMobile ? 'mt-2' : 'mt-5' }`}>
				<Footer/>
			</div>			
		</div>
    )
}

export default Login;