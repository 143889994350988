import Toast from 'react-bootstrap/Toast';
import { Button } from 'react-bootstrap';
import { NotificationPrintContext } from '../../../../contexts/NotificationPrintContext';
import { useContext } from 'react';
import { useTranslation } from "react-i18next";


function ZipGenerationSuccess({notification, handleCloseNotification}) {
    const { t } = useTranslation();
    const body = notification.body  ? notification.body : "";
    const otherData = notification.otherDataJson ? notification.otherDataJson : undefined
    const subject = notification.subject ? notification.subject : "";
    const zipFileInfo = otherData.pdfFileInfo ? otherData.pdfFileInfo : {};
    const zipFileName = zipFileInfo.filename ? zipFileInfo.filename : undefined;


    const downloadZipHandler = () => {
        handleCloseNotification(notification.id);
        if (otherData === undefined) { 
            console.log("Missing data to download zip");
            return;
        }
        window.open(otherData.pdfFileInfo.cdnUrl);
    }

    return (
        <>
            <Toast.Header closeButton={true}>
                <img
                    src="holder.js/20x20?text=%20"
                    className="rounded me-2"
                    alt=""
                />
                <strong className="me-auto">{t(subject)}</strong>
            </Toast.Header>
            <Toast.Body>
                <span>{t(body)}</span><br></br>
                { zipFileName ? <span>Filename: {zipFileName}</span> : <></>} <br></br>
                <Button
					id="previewButton"
					variant="outline-primary"
					onClick={downloadZipHandler}
				>
					{t('notification.downloadcta')}
				</Button>
            </Toast.Body>
        </>
    )
}

export default ZipGenerationSuccess;