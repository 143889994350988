import { Fragment, useEffect } from "react";
import { Form, InputGroup } from "react-bootstrap";
import './FormFieldTypesCustomCss/StarsTypeCustom.css';

function StarsType(props) {
    const isDisabled = props?.isDisabled || false;
    const formFieldJson = props.formFieldJson;
	const entityFormValuesJson = props.entityFormValuesJson;
	const updateEntityFormValues = props.updateEntityFormValues;
	const configValues = formFieldJson.configValues;
    const metadata = configValues?.values?.metadata;
    const starsValueKey = `${configValues.frontUniqueKeys.radiogroup1}_${formFieldJson.id}`;
    const numberOfStars = configValues.values.number_of_stars === "" ? 0 : configValues.values.number_of_stars;
    const starsValueJson = `${starsValueKey}` in entityFormValuesJson 
    ? entityFormValuesJson[`${starsValueKey}`] 
    : {
        type: 'stars',
        name: formFieldJson.name,
        value: 0,
        metadata: metadata,
    }

    const updateStarsValue = (starValue) => {
        updateEntityFormValues(prevFieldValuesJson => {
            const newFieldValuesJson = {...prevFieldValuesJson};
            starsValueJson.value = starValue;
            newFieldValuesJson[`${starsValueKey}`] = starsValueJson;
            return newFieldValuesJson;
        })
    }

    useEffect(() => {
        updateStarsValue(starsValueJson.value);
    }, []);

    return (
        <>
            <Form.Label>{formFieldJson.title}</Form.Label>
            <InputGroup className="star-cb-group">
                {
                    Array.from({length: numberOfStars}, (_, i) => i + 1).map((starNumber) => {
                        return (
                            <Fragment key={starNumber}>
                                <input disabled={isDisabled} id={starsValueKey + "_" + starNumber} onClick={(e)=>{updateStarsValue(e.target.value)}} className="star_input" value={starNumber} name={starsValueKey} type={'radio'}/>
                                <label htmlFor={starsValueKey + "_" + starNumber} class="star_label"></label>
                            </Fragment>
                        )
                    })
                }
            </InputGroup>
        </>
    )
}

export default StarsType;