import { useState, useEffect } from "react";
import { getBackendApiUrl } from "../../config";
import { checkApiResponse } from "../../services/auth";
import { nwFetch } from "../../lib/functions";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import NwCarousel from "../../components/_utilityComponents/NwCarousel/NwCarousel";
import NwImage from "../../components/_utilityComponents/NwImage/NwImage";

const DashboardSocialMediaItem = ({
	id = '',
	name = '',
	src = ''
}) => {
	return (
		<Link to={`/social-media/free-editor/new/social_media_product/0?template_group=${id}`} className="dashboard-social-media-item-container nw-responsive-font-size w-100 p-1" style={{ aspectRatio: 1 }}>
			<NwImage
				src={src}
				imgClassName="w-100 h-100 object-fit-cover"
			/>
		</Link>
	)
}

const DashboardSocialMediaList = ({
	dashboardSocialMediaTemplateGroups = []
}) => {
	const dashboardSocialGroupsFormatted = dashboardSocialMediaTemplateGroups?.map((templateGroup, index) => {
		return {
			id: templateGroup?.id,
			name: templateGroup?.name,
			content: (
				<DashboardSocialMediaItem
					id={ templateGroup?.id }
					name={ templateGroup?.name }
					src={ templateGroup?.thumbnailPreviewUrl?.thumbnailPreviewUrl }
				/>
			)
		}
	})

	return (
		<NwCarousel
			slides={dashboardSocialGroupsFormatted}
			options={{
				slidesToScroll: 4
			}}
			hideNavigationButtons
			classNames={{
				root: 'flex-column w-100 mw-100',
				viewport: 'w-100 flex-grow-1',
				controls: 'w-100',
				controls_wrapper: 'flex-row',
				slide: 'w-25',
				dots: 'flex-row d-none'
			}}
			styles={{
				slide: {
					flex: '0 0 25%'
				}
			}}
		/>
	)
}

const DashboardSocialMedia = ({}) => {
	const [ dashboardSocialMediaTemplateGroups, setDashboardTemplateGroup ] = useState(null);
	const { t } = useTranslation();

	useEffect(() => {
		const getDashboardTemplateGroupCovers = async() => {
			const url = getBackendApiUrl(`social_media/template_group_covers/dashboard`);
			const response = await nwFetch(url);
			checkApiResponse(response);

			const dashboardTemplateGroupCovers = await response.json();
			setDashboardTemplateGroup(dashboardTemplateGroupCovers);
		}

		getDashboardTemplateGroupCovers();
	}, []);

	return (
		<div className="rounded-4 shadow-lg h-100 bg-white p-3 d-flex flex-column">
			<div className="nw-responsive-font-size d-flex justify-content-between w-100">
				<span>{ t('dashboard.socialMedia.title') }</span>
				<span>
					<Link to={ '/social-media/' } className="link-underline link-underline-opacity-0">
						{ t('dashboard.socialMedia.create') }
					</Link>
				</span>
			</div>
			<div className="dashboard-social-media-list-container flex-grow-1 py-4 py-xxl-5">
				<div className="dashboard-social-media-list-wrapper w-100 h-100 overflow-hidden">
					{ 
						dashboardSocialMediaTemplateGroups && (<DashboardSocialMediaList dashboardSocialMediaTemplateGroups={dashboardSocialMediaTemplateGroups}/>)
					}
				</div>
			</div>
		</div>
	)
};

export default DashboardSocialMedia;
