import React, { useState, useContext, useEffect, useRef } from "react";
import MainLayoutContext from "../../contexts/MainLayoutContext";
import { getRouteDataFromRouteId } from "../../helpers/helpers";
import { Link, useNavigate } from "react-router-dom";
import { NwLockIcon } from "../../components/_utilityComponents/NwIcons/NwIcons";
import Chart from "chart.js/auto";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useTranslation } from "react-i18next";
import { getDashboardStats } from "../../services/dashboard";
import { getStateDataByFrontState } from "../../services/operation";
import Table from "react-bootstrap/Table";
import { CircleFill } from "react-bootstrap-icons";
import { getAuthTokenWithRefresh, getBackendApiUrl, getEasyPlvApiToken } from "../../config";

const DashboardOperationList = ({}) => {
	const { t } = useTranslation();

	const [posts, setPosts] = useState([]);

	const [totalItems,setTotalItems] = useState(0);
	const [isloading, setisloading] = useState(true);

	const apiKey = getEasyPlvApiToken();
	const { locale } = useContext(MainLayoutContext);


	async function getOperations() {
		try {
			const authToken = await getAuthTokenWithRefresh();
			const response = await fetch(getBackendApiUrl("operation/byUser"), {
				method: "GET",
				headers: {
						Authorization: `Bearer ${authToken}`,
						"X-EasyPLV-Api-Token": apiKey,
						"nw-front-locale": locale || 'fr'
				},
			});
			setisloading(true);
			const data = await response.json();
			setisloading(false);
			if(data === undefined){
					return <p>No Operation...</p>;
			}
			// Set the state with the data
			
			setPosts(data.items);
			setTotalItems(data.totalItems);

			// Set loading state to false after fetching
		} catch (error) {
			console.error("Error fetching data: ", error);
		}
	}

	const getFrontStateData = (frontState, published = false) => {
		const stateData = getStateDataByFrontState(frontState);
		return {
			displayText: published ? t(`dashboard.operationFrontState.published`) : t(stateData.translationKey),
			color: published ? "success" : stateData.stateColor
		};
	};
		
	useEffect(() => {
		getOperations();
	}, []);

	return (
		<div className="h-100 bg-white shadow-lg rounded-3 px-2 py-3 d-flex flex-column w-100">
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "space-between",
					height: '10%'
				}}
			>
				<span className="nw-responsive-font-size mb-2 ps-2">
					{t("dashboard.operationTitle")}
					{isloading ? (
						<span
							style={{ marginLeft: "10px" }}
							className="spinner-border spinner-border-sm"
							role="status"
							aria-hidden="true"
						></span>
					) : null}
				</span>
			</div>
			<div className="home-operations-list-container flex-fill overflow-auto">
				<Table >
					<thead className="sticky-top">
						<tr>
							<th style={{ backgroundColor: '#FAFAFB' }}>{t("dashboard.tableHeaderName.name")}</th>
							<th style={{ backgroundColor: '#FAFAFB' }}>{t("dashboard.tableHeaderName.code")}</th>
							<th style={{ backgroundColor: '#FAFAFB' }}> {t("dashboard.tableHeaderName.startDate")}</th>
							<th style={{ backgroundColor: '#FAFAFB' }}>{t("dashboard.tableHeaderName.status")}</th>
						</tr>
					</thead>
					<tbody>
						{posts?.map((post, index) => (
							<tr key={index}>
								<td>{post.published ? <Link to={`/operation/${post.id}/products`}>{post.name}</Link> : post.name}</td>
								<td>{post.code}</td>
								<td>{post.formatedFrontDateDisplay}</td>
								<td>
									<div className="d-flex align-items-center column-gap-1">
										<CircleFill 
											className={`nw-responsive-font-size-xs text-${getFrontStateData(post.stateFront, post.published).color}`}
										/>
										<span>{getFrontStateData(post.stateFront, post.published).displayText}</span>
									</div>									
								</td>
							</tr>
						))}
						
					</tbody>
				</Table>
			</div>
		</div>
	);
};

export default DashboardOperationList;
