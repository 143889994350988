import React, { useEffect, useState, useRef } from "react";
import { Form, Row, Col, ListGroup, Tab, Button } from "react-bootstrap";
import { getFreePostersFiltered,getFreePosters } from "../../services/freeposters";
import NwCustomInput from "../_utilityComponents/NwFormsUi/CustomInput/NwCustomInput";
import { useTranslation } from "react-i18next";

// Nw Custom Filters
import { NwCommonFilter } from "../NwCustomFilters/NwCustomFilters";

const FreePosterFilter = ({getFilteredFreePoster,queryFilter, resetPaginationNumber}) => {
  const { t } = useTranslation();
  const [posts, setPosts] = useState();
  const [queryStr,setQueryStr] = useState();

  const handleFilterSubmit = async (e) => {
    e.preventDefault();
    let queryString = "";
    const freePosterName = e.target.search_name.value;
    if (freePosterName) {
      queryString += `&filters[title]=${freePosterName}`;
    }

    const filteredFreePoster = await getFreePostersFiltered(1, queryString);
    queryFilter(queryString);
    getFilteredFreePoster(filteredFreePoster);
  };

  useEffect(() => {
    queryFilter(queryStr);
  }, [posts]);

  return (
    <NwCommonFilter
        onSubmit={handleFilterSubmit}
        className= "w-100"
        autoSubmitOnReset
        onFilterReset={() => resetPaginationNumber(0)}
        filterWrapperProps={{
            className: "w-100 justify-content-around",
        }}
        filterButtonProps={{
            type: 'submit',
            children: t('validateButtonLabel')
        }}
        resetButtonProps={{
            children: t('resetFilterButton')
        }}
        filterModalProps={{
            headerContent: t('freePosters.filterModalHeader')
        }}
        formFields={[
            {
                type: 'input',
                wrapperProps: {
                    sm: 6
                },
                props: {
                    controlId: "search-by-name-filter",
                    name: "search_name",
                    placeholder: t('freePosters.filter.name'),
                    hideLabel: true
                }
            },
        ]}
    />

  );
};
export default FreePosterFilter;
