import Notifications from "./Notifications/Notifications";
import { NotificationPrintContext } from "../../contexts/NotificationPrintContext";
import { useState } from "react";
import PrintConfirmation from "../Print/PrintConfirmation";
import { useEffect } from "react";


function NotificationWithPrint() {
    const [printDataFromNotification, setPrintDataFromNotification] = useState();
    const [showPrintConfirmation, setShowPrintConfirmation] = useState(false);

    const openPrintConfirmationWithPrintNotificationData = (printerData) => {
        setPrintDataFromNotification(prevPrintData => printerData);
        setShowPrintConfirmation(true);
    }
    
    const closePrintConfirmation = () => {
        setShowPrintConfirmation(false);
    }

    const notificationPrintContextValue = {
        openPrintConfirmationWithPrintData : openPrintConfirmationWithPrintNotificationData,
        isOpen: showPrintConfirmation,
    }

    return (
        <>
            {showPrintConfirmation ? <PrintConfirmation show={showPrintConfirmation} close={closePrintConfirmation} printData={printDataFromNotification}/> : <></> }
            <NotificationPrintContext.Provider value={notificationPrintContextValue}>
                <Notifications />
            </NotificationPrintContext.Provider>
        </>
    )
    
    
}

export default NotificationWithPrint;