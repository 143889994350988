import { useEffect, useState } from "react";
import { Button, Modal, Card, Container, Row, Badge, Stack, InputGroup, Form, Spinner } from "react-bootstrap";
import NwCustomModal from "../../_utilityComponents/NwCustomModal/NwCustomModal";
import { NwLoaderSpinner } from "../../_utilityComponents/NwIcons/NwIcons";
import { Folder,Plus, Search } from "react-bootstrap-icons";
import { fetchEktatekMediaData } from "../../../services/ektatek";

//NwCustomIcons
import { NwGalleryIcon } from "../../_utilityComponents/NwIcons/NwIcons";

function EktatekModal({ektatekMediaData, handleImageSelection, isDisabled = false}) {
    const [chosenImages, setChosenImages] = useState([]);
    const [categories, setCategories] = useState([]);
    const [homeCategories, setHomeCategories] = useState([]);
    const [homeImages, setHomeImages] = useState([]);
    const [activeCategory, setActiveCategory] = useState(null);
    const [images, setImages] = useState([]);
    const [isFetchingImages, setIsFetchingImages] = useState(false); 
    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState("");
    const [isSearching, setIsSearching] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [totalItems, setTotalItems] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(0);


    const fetchDataByCategory = async (categoryId) => {
        categoryId = parseInt(categoryId);
        const dataByCategory = await fetchEktatekMediaData([], categoryId);
        return dataByCategory
    }

    const handleCategoryChange = async (categoryId) => {
        categoryId = parseInt(categoryId);
        const newEktatekMediaData = await fetchDataByCategory(categoryId);
        setCategories(newEktatekMediaData?.categories ? newEktatekMediaData.categories : {});
        setImages(newEktatekMediaData?.items ? newEktatekMediaData.items : [])
        setActiveCategory(categories[categoryId]);
        setCurrentPage(1);
    }

    const handleRedirectToHome = () => {
        setCategories(homeCategories);
        setImages(homeImages);
        setActiveCategory(null);
    }

    const getMoreActiveCategoryImages = async () => {
        if (!activeCategory) return;
        setIsFetchingImages(true);
        const activeCategoryId = activeCategory['id'];
        if (!activeCategoryId) return;
        const moreEktatekMediaData = await fetchEktatekMediaData([], activeCategoryId, currentPage + 1, 20, searchQuery);
        const newImages = moreEktatekMediaData?.items ? moreEktatekMediaData.items : [];
        setImages([...images, ...newImages]);
        setCurrentPage(currentPage + 1);
        setIsFetchingImages(false);
    }

    const handleSearch = async () => {
        setIsSearching(true);
        const activeCategoryId = activeCategory?.id ? activeCategory.id : 0;
        const searchedEktatekMediaData = await fetchEktatekMediaData([], activeCategoryId, 1, 20, searchQuery);
        console.log(searchedEktatekMediaData);
        const searchedImages = searchedEktatekMediaData?.items ? searchedEktatekMediaData.items : [];
        setImages([...searchedImages]);
        setIsSearching(false);
    }

    useEffect(() => {
        setCategories(ektatekMediaData?.categories ? ektatekMediaData.categories : {});
        setImages(ektatekMediaData?.items ? ektatekMediaData.items : []);
        setHomeCategories(ektatekMediaData?.categories ? ektatekMediaData.categories : {});
        setHomeImages(ektatekMediaData?.items ? ektatekMediaData.items : []);
        setTotalItems(ektatekMediaData?.options? ektatekMediaData.itemsPerPage : []);
        setItemPerPage(ektatekMediaData?.options? ektatekMediaData.itemsPerPage : []);
    }, [ektatekMediaData]);


    return (
        <div className="w-100">
            <NwCustomModal headerContent={<EktatekModalHeader />}
                title={'Add a Logo'}
                defaultButtonProps={{
                    icon: <NwGalleryIcon size={24}/>,
                    disabled: isDisabled
                }}
            >
                    <Modal.Body>
                        <Button onClick={handleRedirectToHome}>Back To Home</Button>
                        <br></br>
                        <br></br>
                        <InputGroup className="mb-3">
                            <Form.Control
                                placeholder="Search"
                                aria-label="search"
                                aria-describedby="basic-addon1"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            <InputGroup.Text onClick={handleSearch} role="button" id="basic-addon1"><Search /></InputGroup.Text>
                        </InputGroup>
                        <CategoriesList activeCategory={activeCategory} categories={categories} handleCategoryChange={handleCategoryChange} />
                        {
                            isSearching ? <Spinner /> : <GalleryImageList images={images} handleImageSelection={handleImageSelection}/>
                        } 
                        <br></br>
                        <br></br>
                        <Button className="rounded-0" onClick={getMoreActiveCategoryImages}><NwLoaderSpinner size={30}/><Plus size={30}/>Plus</Button>
                    </Modal.Body>
            </NwCustomModal>         
        </div>
    )
}

export default EktatekModal;

function GalleryImageList({images, handleImageSelection}) {
    return (
        <>
            <Container fluid>
                <Row>
                    {
                        images.map((image) => {
                            return (
                                <div className="col-2" key={image.id}>
                                    <Card>
                                        <Card.Img onClick={() => handleImageSelection(image)} src={image.imageMainUrl} />
                                    </Card> 
                                </div>
                            )
                        })
                    }
                </Row>      
            </Container>
        </>
    )
}

function CategoriesList({categories, activeCategory, handleCategoryChange}) {
    return (
        <>
            {/* {activeCategory && <h3><Badge bg="success">{activeCategory.name}</Badge></h3>} */}
            {activeCategory && <h3>{activeCategory.name}:</h3>}
            <Stack direction="horizontal" gap={2}>
                {
                    Object.keys(categories).map((categoryId) => {
                        return (
                            // <Badge key={categoryId} role="button" onClick={() => handleCategoryChange(categoryId)}><Folder size={20}></Folder> {categories[categoryId].name}</Badge>
                            <Button key={categoryId} onClick={() => handleCategoryChange(categoryId)}><Folder size={20}></Folder> {categories[categoryId].name}</Button>
                        )
                    })
                }
            </Stack>
        </>
    )
}

function EktatekModalHeader() {
    return (
        <Modal.Header>
            
        </Modal.Header>
    )
}



