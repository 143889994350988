import React from 'react';

const TextesLogo = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width={props.size} height={props.size}>
            <path d="M3.077 12.308a.615.615 0 0 1-.083-1.225l.083-.006h12.307a.615.615 0 0 1 .084 1.225l-.084.006H3.077Zm0-3.283A.615.615 0 0 1 2.994 7.8l.083-.005h12.307a.615.615 0 0 1 .084 1.225l-.084.005H3.077Zm7.385-3.281a.615.615 0 0 1-.084-1.225l.084-.006h4.922a.615.615 0 0 1 .084 1.225l-.084.006h-4.922ZM4.928 4.426l-.005-.119v-2.05A2.26 2.26 0 0 1 7.18 0a.616.616 0 0 1 .083 1.225l-.083.006c-.529 0-.967.396-1.02.92l-.006.106v.615h.205c.742 0 1.37.579 1.43 1.318l.006.117c0 .792-.645 1.437-1.436 1.437-.742 0-1.37-.58-1.431-1.318Zm1.226-.119a.205.205 0 1 0 .252-.199l-.047-.005h-.205v.204Zm-6.15.119L0 4.307v-2.05A2.26 2.26 0 0 1 2.257 0a.616.616 0 0 1 .083 1.225l-.083.006c-.529 0-.968.396-1.02.92l-.006.106v.615h.205c.742 0 1.37.579 1.43 1.318l.006.117c0 .792-.645 1.437-1.436 1.437-.743 0-1.371-.58-1.432-1.318Zm1.227-.119a.205.205 0 1 0 .252-.199l-.047-.005H1.23v.204Zm9.23-1.846a.615.615 0 0 1-.083-1.225l.084-.005h4.922a.615.615 0 0 1 .084 1.225l-.084.005h-4.922Z" fill="currentColor" fillRule="evenodd"/>
        </svg>
    );
}

export default TextesLogo;