import { useState, useEffect } from "react";
import { fetchEktatekMediaData } from "../../services/ektatek";
import EktatekModal from "./Modal/EktatekModal";
import ChosenImagesPreview from "./Preview/ChosenImagesPreview";

function EktatekType(props) {
    const isDisabled = props?.isDisabled || false;
    const formFieldJson = props.formFieldJson;
	const entityFormValuesJson = props.entityFormValuesJson;
	const updateEntityFormValues = props.updateEntityFormValues;
	const configValues = formFieldJson.configValues;
    const metadata = configValues?.values?.metadata;
    const galleriesChosen = configValues.values.galleries;
    const ektatekValueKey = `${configValues.frontUniqueKeys.radiogroup1}_${formFieldJson.id}`;
    const ektatekValueJson = `${ektatekValueKey}` in entityFormValuesJson 
	? entityFormValuesJson[`${ektatekValueKey}`]
	: {
		name: formFieldJson.name,
		type: 'ektatek_single',
		value: "",
        metadata: metadata,
	}

    const [ektatekMediaData, setEktatekMediaData] = useState({});
    const [chosenImage, setChosenImage] = useState(null);

    const handleImageSelection = (image) => {
        // setChosenImage(image);
        updateEntityFormValues(prevFieldValuesJson => {
            const newFieldValuesJson = {...prevFieldValuesJson};
            ektatekValueJson.value = image;
            newFieldValuesJson[`${ektatekValueKey}`] = {...ektatekValueJson};
            return newFieldValuesJson;
        })
    }

    const handleRemoveImage = (index) => {
        // setChosenImage(null)
        updateEntityFormValues(prevFieldValuesJson => {
            const newFieldValuesJson = {...prevFieldValuesJson};
            ektatekValueJson.value = "";
            newFieldValuesJson[`${ektatekValueKey}`] = {...ektatekValueJson};
            return newFieldValuesJson;
        })
    }

    useEffect(() => {
        if (galleriesChosen.length > 0) {
            const getEktatekImages = async () => {
                const ektatekMediaData = await fetchEktatekMediaData(galleriesChosen, 0);
                setEktatekMediaData({...ektatekMediaData});
            }
            getEktatekImages();
        }   
    }, [galleriesChosen]);

    // useEffect(() => {
    //     updateEntityFormValues(prevFieldValuesJson => {
    //         const newFieldValuesJson = {...prevFieldValuesJson};
    //         ektatekValueJson.value = chosenImage ?? "";
    //         newFieldValuesJson[`${ektatekValueKey}`] = {...ektatekValueJson};
    //         return newFieldValuesJson;
    //     })
    // }, [chosenImage]);

    useEffect(() => {
        updateEntityFormValues(prevFieldValuesJson => {
            const newFieldValuesJson = {...prevFieldValuesJson};
            newFieldValuesJson[`${ektatekValueKey}`] = {...ektatekValueJson};
            return newFieldValuesJson;
        })
    }, []);


    return (
        <>
            <>{formFieldJson.title}</>
            <EktatekModal isDisabled={isDisabled} ektatekMediaData={ektatekMediaData} handleImageSelection={handleImageSelection} />
            {
                <ChosenImagesPreview isDisabled={isDisabled} images={ektatekValueJson.value !== "" ? [ektatekValueJson.value] : []} handleRemoveImage={handleRemoveImage} />
            }
        </>
    )
}

export default EktatekType;