import { getAuthTokenWithRefresh, getBackendApiUrl, getEasyPlvApiToken } from "../config";
import { checkApiResponse } from "./auth";
import { setUpdatedCartItemsAndCount } from "./cart";

export const saveFreePoster = async (data) => {
    const authToken = await getAuthTokenWithRefresh();
	const apiKey = getEasyPlvApiToken();
    const saveFreePosterPromise = await fetch(getBackendApiUrl(`free_posters/create`), {
        method: "POST",
        headers: {
            Authorization: `Bearer ${authToken}`,
            "X-EasyPLV-Api-Token": apiKey,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    }).catch(e => console.log(e));

    const response = await saveFreePosterPromise.json();
    if ('updatedCartData' in response) {
        if (response.updatedCartData) {
            setUpdatedCartItemsAndCount(response.updatedCartData);
        }
    }

}

export const editFreePoster = async (data, freePosterId) => {
    data["id"] = freePosterId;
    const authToken = await getAuthTokenWithRefresh();
	const apiKey = getEasyPlvApiToken();
    const editFreePosterPromise = await fetch(getBackendApiUrl(`free_posters/edit`), {
        method: "PUT",
        headers: {
            Authorization: `Bearer ${authToken}`,
            "X-EasyPLV-Api-Token": apiKey,
            "Content-Type": "application/json",
        },
        body: JSON.stringify(data)
    }).catch(e => console.log(e));

    const response = await editFreePosterPromise.json();
    if ('updatedCartData' in response) {
        if (response.updatedCartData) {
            setUpdatedCartItemsAndCount(response.updatedCartData);
        }
    }
}

export const getFreePosterWithMetadataById = async (freePosterId) => {
    if (!freePosterId) return null;
    try {
        const authToken = await getAuthTokenWithRefresh();
	    const apiKey = getEasyPlvApiToken();
        const getFreePosterDataPromise = await fetch(getBackendApiUrl(`free_posters/with_metadata/${freePosterId}`),{
            method: "GET",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "X-EasyPLV-Api-Token": apiKey,
                "Content-Type": "application/json",
            },
        });

        checkApiResponse(getFreePosterDataPromise);
        if (getFreePosterDataPromise.status === 200) {
            const response = await getFreePosterDataPromise.json();
            return response;
        }
        else return null;
    } catch (e) {
        return null;
    }
}